import { Box, Center, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { DateRangeType, DateRangeTypePicker } from '../../../common/components/DateRangeTypePicker';
import { useSelector } from 'react-redux';
import { selectCurrentDayForSite } from '../../site/siteSlice';
import ActivityDatePicker from '../ActivityDatePicker';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import { useGetUsageQuery } from '../activityApi';
import '../activity.scss';
import { useTranslation } from 'react-i18next';
import MatterOnlyActivityChart from './MatterOnlyActivityChart';
import FilterByMatterDevice from './FilterByMatterDevice';
import { orderBy } from 'lodash';
import { MAX_ACTIVITY_MATTER_DEVICES } from './matter-only-activity-helpers';

highchartsAccessibility(Highcharts);

type MatterActivityState = {
  // We use a separate `isLoaded` value to RTK query to ensure we select the correct devices after the data has loaded.
  isLoaded: boolean;
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  selectedDeviceIds: number[];
};

function getInitialSelectedDates(currentDay: Date) {
  return {
    [DateRangeType.Day]: new Date(currentDay),
    [DateRangeType.Week]: new Date(currentDay),
    [DateRangeType.Month]: new Date(currentDay),
    [DateRangeType.Year]: new Date(currentDay),
  };
}

export default function MatterOnlyActivity() {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { t } = useTranslation();
  const [state, setState] = useState<MatterActivityState>({
    selectedDateRangeType: DateRangeType.Day,
    rangeTypeToSelectedPeriod: getInitialSelectedDates(currentDay),
    selectedDeviceIds: [],
    isLoaded: false,
  });
  const { selectedDateRangeType, rangeTypeToSelectedPeriod, isLoaded, selectedDeviceIds } = state;
  const {
    data: usageData,
    isLoading: isUsageLoading,
    isFetching: isUsageFetching,
    isError: isUsageError,
  } = useGetUsageQuery(rangeTypeToSelectedPeriod[selectedDateRangeType], selectedDateRangeType, false, false);
  const { energyUsageData, powerUsageData, displayedDevices } = usageData;
  const isApiError = isUsageError;
  const isApiDataLoaded = !isUsageFetching && !isUsageLoading;

  useEffect(() => {
    // Whenever the displayed devices change, we want to update the selected devices to include all devices.
    setState((prevState) => ({
      ...prevState,
      selectedDeviceIds: orderBy(displayedDevices, (device) => device.energySummary, 'desc')
        .slice(0, MAX_ACTIVITY_MATTER_DEVICES)
        .map((device) => device.appliance_id),
      isLoaded: isApiDataLoaded,
    }));
  }, [isApiDataLoaded, isLoaded]);

  async function handleChangeSelectedDate(newDate: Date) {
    setState((prevState) => ({
      ...prevState,
      rangeTypeToSelectedPeriod: {
        ...rangeTypeToSelectedPeriod,
        [selectedDateRangeType]: newDate,
      },
      isLoaded: false,
    }));
  }

  async function handleToggleDevice(selectedDeviceId: number, isToggled: boolean) {
    setState((prevState) => ({
      ...prevState,
      selectedDeviceIds: isToggled
        ? [...selectedDeviceIds, selectedDeviceId] // Add device to the list
        : selectedDeviceIds.filter((deviceId) => deviceId !== selectedDeviceId), // Remove device from the list
    }));
  }

  async function handleChangeSelectedDateRangeType(newDateRangeType: DateRangeType) {
    setState((prevState) => ({
      ...prevState,
      selectedDateRangeType: newDateRangeType,
      isLoaded: false,
    }));
  }

  const commonProps = {
    isLoaded,
    selectedDateRangeType,
  };

  const DATE_RANGE_OPTIONS = [
    {
      label: t('Common.day')[0],
      value: DateRangeType.Day,
    },
    {
      label: t('Common.week')[0],
      value: DateRangeType.Week,
    },
    {
      label: t('Common.month')[0],
      value: DateRangeType.Month,
    },
    {
      label: t('Common.year')[0],
      value: DateRangeType.Year,
    },
  ];

  return (
    <SlidingAnimationPageBase
      includeBottomNav={true}
      includeTopNavHamButton={true}
      title={t('Activity.activity')}
      includeSchneiderHamButton={true}
    >
      <Box px={3}>
        <DateRangeTypePicker
          dateRangeOptions={DATE_RANGE_OPTIONS}
          selectedDateRangeType={selectedDateRangeType}
          setSelectedDateRangeType={handleChangeSelectedDateRangeType}
        />

        <ActivityDatePicker
          isLoaded={isApiDataLoaded}
          selectedDateRangeType={selectedDateRangeType}
          rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
          onChangeDate={handleChangeSelectedDate}
        />

        {isApiError ? (
          <Center h="320px" data-testid="no-data-error">
            <Heading textAlign="center">{t('Activity.error fetching data')}</Heading>
          </Center>
        ) : (
          <>
            <MatterOnlyActivityChart
              {...commonProps}
              selectedDeviceIds={selectedDeviceIds}
              energyUsageData={energyUsageData}
              powerUsageData={powerUsageData}
              rangeTypeToSelectedPeriod={rangeTypeToSelectedPeriod}
            />
          </>
        )}
      </Box>

      <Box id="filters-list">
        <FilterByMatterDevice
          {...commonProps}
          displayedDevices={displayedDevices}
          selectedDeviceIds={selectedDeviceIds}
          onToggleDevice={handleToggleDevice}
        />
      </Box>
    </SlidingAnimationPageBase>
  );
}
