import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useViewScrollTop() {
  const location = useLocation();
  // Scroll to top each time the url is changed.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
}
