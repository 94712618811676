import { ENV_TYPE } from '../../env-type';
import { IS_RUNNING_CYPRESS_TESTS, IS_NATIVE } from '../../common/constants';

export const IDENTITY_URL =
  ENV_TYPE === 'DEV' ? 'https://se--uatbfo22.sandbox.my.site.com' : 'https://secureidentity.schneider-electric.com';

export function getRedirectURI(useFullAppUrl = false) {
  if (IS_NATIVE && useFullAppUrl) return 'schneiderhome://';
  if (IS_NATIVE) return 'schneiderhome:/';
  if (import.meta.env.MODE !== 'development' && !IS_RUNNING_CYPRESS_TESTS) return 'https://prosumer.clipsalcortex.com';
  return 'http://localhost:1337';
}

// Note: This is not the Cognito client, but the OIDC client
const IDP_CLIENT_ID =
  ENV_TYPE === 'DEV'
    ? '3MVG9M43irr9JAuwrD5_t.uz0asbL5DVHHZNnwpeNeTBFNMVJRLTg6K0seIsu8vSE0NVGDwcPx06Szk75Q5re'
    : '3MVG9yZ.WNe6byQAvlJ.HwD94PQiUvhKWMlDp8IBW8z3nfqDmiLX7URgnmJqON6CfAxJxGmRMOrpQf80Ydy9J';

const AUTHORIZE_URL =
  `${IDENTITY_URL}/identity/idplogin?app=SchneiderHome` +
  `&client_id=${IDP_CLIENT_ID}` +
  `&redirect_uri=${getRedirectURI()}/oauth_response` +
  `&scope=openid+email+profile+refresh_token+id+api+web` +
  `&response_type=code`;

export const CIAM_IDMS_BASE_URL = ENV_TYPE === 'DEV' ? 'https://api.qa.se.com' : 'https://api.se.com';

export default AUTHORIZE_URL;
