import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { useSelector } from 'react-redux';
import { selectInverters, selectMeters } from '../../../site/siteSlice';
import AccountMenuButton from '../../AccountMenuButton';
import { SEHomeInverterIcon, SEHomeMeterIcon } from '../../../../styles/custom-icons';
import SEHomeCard from '../../../../common/components/SEHomeCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function HardwareWifiConfiguration() {
  const inverters = useSelector(selectInverters);
  const meters = useSelector(selectMeters);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase
      title={t('Set Up Hardware.configure new hardware')}
      backURL={`../../list?direction=back`}
    >
      <Box px={3}>
        <Box>{t('Set Up Hardware.configure wifi').toUpperCase()}</Box>

        <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'0px'} borderBottomLeftRadius={'0px'} p={0}>
          <AccountMenuButton
            data-testid="configure-meter"
            isDisabled={!!meters.length}
            onClick={() => {
              navigate(`../meter`);
            }}
          >
            <Flex align={'center'}>
              <SEHomeMeterIcon w={8} h={8} />
              <Text fontSize={13} fontWeight="normal" ml={3}>
                {t('Set Up Hardware.new device', { device: t('Set Up Hardware.schneider energy monitor') })}
              </Text>
            </Flex>
          </AccountMenuButton>
        </SEHomeCard>

        <SEHomeCard borderTopLeftRadius={'0px'} borderTopRightRadius={'0px'} p={0}>
          <AccountMenuButton
            data-testid="configure-inverter"
            isDisabled={!!inverters.length}
            includeBottomDivider={false}
            onClick={() => {
              navigate(`../inverter`);
            }}
          >
            <Flex align={'center'}>
              <SEHomeInverterIcon w={8} h={8} />
              <Text fontSize={13} fontWeight="normal" ml={3}>
                {t('Set Up Hardware.new device', { device: t('Set Up Hardware.schneider inverter') })}
              </Text>
            </Flex>
          </AccountMenuButton>
        </SEHomeCard>
      </Box>
    </SlidingAnimationPageBase>
  );
}
