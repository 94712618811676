import React, { MouseEvent, PropsWithChildren, TouchEvent } from 'react';
import { Flex, Icon, Text, useColorModeValue, Center } from '@chakra-ui/react';
import SEHomeCard from '../../common/components/SEHomeCard';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string | null;
  Icon: typeof Icon;
  currentPower: number;
  costToday?: number;
  shouldShowCurrentPowerInKw?: boolean;
  onClick: (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => void;
  'data-testid'?: string;
};

export default function DeviceListItem({
  name,
  Icon,
  currentPower,
  costToday,
  shouldShowCurrentPowerInKw = true,
  onClick,
  children,
  ...props
}: PropsWithChildren<Props>) {
  const textColor = useColorModeValue('customGrey.800', 'customGrey.300');
  const { t } = useTranslation();

  return (
    <SEHomeCard
      cursor="pointer"
      p={4}
      borderTopLeftRadius="5px"
      mb={2}
      data-testid={props['data-testid']}
      onClick={onClick}
    >
      <Flex align="center">
        <Center marginRight="auto">
          <Icon w={10} h={10} mr={4} _light={{ color: 'customGrey.800' }} />
          <Flex direction="column">
            <Text fontSize={17} fontWeight={400} lineHeight={1.2} overflowWrap="anywhere">
              {name}
            </Text>
            <Flex flexDir="row">
              <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1} mr={1}>
                {t('Common.current power')}:
              </Text>
              <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1}>
                {Intl.NumberFormat('en', { maximumFractionDigits: shouldShowCurrentPowerInKw ? 2 : 0 }).format(
                  shouldShowCurrentPowerInKw ? currentPower : currentPower * 1000
                )}{' '}
                {shouldShowCurrentPowerInKw ? 'kW' : 'W'}
              </Text>
            </Flex>
            {costToday !== undefined && (
              <Flex flexDir="row">
                <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1} mr={1}>
                  {t('Devices.cost today')}:
                </Text>
                <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1}>
                  ${Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(costToday)}
                </Text>
              </Flex>
            )}
          </Flex>
        </Center>
        {children && (
          <Flex
            align="center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </Flex>
        )}
      </Flex>
    </SEHomeCard>
  );
}
