import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

// These variants are used to style the Switch component so the unchecked bg colour is different
const solarGreen = definePartsStyle({
  track: {
    bg: '#6F6F76',
    _checked: {
      bg: 'primaryBranding.200',
      _light: {
        bg: 'primaryBranding.600',
      },
    },
  },
});

const batteryBlue = definePartsStyle({
  track: {
    bg: '#6F6F76',
    _checked: { bg: 'schneiderSkyBlue.200' },
  },
});

const gridRed = definePartsStyle({
  track: {
    bg: '#6F6F76',
    _checked: { bg: '#DB5E52' },
  },
});

const purple = definePartsStyle({
  track: {
    bg: '#6F6F76',
    _checked: { bg: '#8F4BCF' },
  },
});

const orange = definePartsStyle({
  track: {
    bg: '#6F6F76',
    _checked: { bg: '#F19F39' },
  },
});

const yellow = definePartsStyle({
  track: {
    bg: '#6F6F76',
    _checked: { bg: '#FEDC38' },
  },
});

export const switchTheme = defineMultiStyleConfig({
  variants: { solarGreen, batteryBlue, gridRed, purple, orange, yellow },
});
