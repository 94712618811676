import { Matter } from '@clipsalsolar/capacitor-matter';
import { Device } from '@capacitor/device';

export enum CommissioningWizardStatus {
  NOT_STARTED = 'NOT_STARTED',
  GET_PAIR_CODE = 'GET_PAIR_CODE',
  PERMISSION_CHECK = 'PERMISSION_CHECK',
  LOCATION_PERMISSION_REQUIRED = 'LOCATION_PERMISSION_REQUIRED',
  BLUETOOTH_PERMISSION_REQUIRED = 'BLUETOOTH_PERMISSION_REQUIRED',
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  BLUETOOTH_FAILURE = 'BLUETOOTH_FAILURE',
  DEVICE_ALREADY_REGISTERED = 'DEVICE_ALREADY_REGISTERED',
}

/**
 * Checks for Android permissions required for Matter commissioning.
 *
 * @returns The required Android permissions that the user needs to grant, or null if all permissions are granted.
 */
/* istanbul ignore next -- @preserve */
export async function checkAndroidPermissionsToRequest(): Promise<'BLUETOOTH' | 'LOCATION' | null> {
  const permissions = await Matter.checkPermissions();
  const { bluetoothConnect, bluetoothScan, bluetooth, accessFineLocation } = permissions;
  const deviceInfo = await Device.getInfo();

  // Android versions <= 11 require location permissions for Bluetooth usage
  // See: https://developer.android.com/develop/connectivity/bluetooth/bt-permissions#declare-android11-or-lower
  if (Number(deviceInfo.osVersion) <= 11) {
    if (bluetooth === 'granted' && accessFineLocation === 'granted') {
      return null;
    }
    return 'LOCATION';
  }

  if (bluetoothConnect === 'granted' && bluetoothScan === 'granted') {
    return null;
  }
  return 'BLUETOOTH';
}
