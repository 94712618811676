import React, { useCallback, useMemo } from 'react';
import { orderBy, sumBy } from 'lodash';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Box, Center, Divider, Flex, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react';
import Weather from '../Weather';
import WelcomeMessage from '../WelcomeMessage';
import { useGetUsageQuery } from '../../activity/activityApi';
import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { useSelector } from 'react-redux';
import { selectCurrentDayForSite } from '../../site/siteSlice';
import { useLiveData } from '../../site/live-data/liveDataApi';
import TopConsumersList from './TopConsumersList';
import { MAX_NUMBER_OF_TOP_CONSUMER_DEVICES, OTHER_DEVICES_COLOR, TOP_CONSUMERS_COLORS } from './constants';
import TodaysCostCard from './TodaysCostCard';
import PowerSummaryCards from './PowerSummaryCards';
import { useNavigate } from 'react-router-dom';
import AbsoluteChartBackground from './AbsoluteChartBackground';
import { useTranslation } from 'react-i18next';

// This is the home page for Sense-only sites
export default function SenseOnlyHome() {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: liveDataSummary, isMeterDataLoading } = useLiveData();
  const { data: usageData, isLoading: isUsageLoading } = useGetUsageQuery(currentDay, DateRangeType.Day);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { energyUsageData, displayedDevices } = usageData;
  const energyUsageSummary = energyUsageData[0];
  const isLoaded = !isUsageLoading && !isMeterDataLoading;

  const buildDataSet = useCallback(() => {
    if (!isLoaded) return [];
    // There is no consumption, so return an empty array
    if (!energyUsageSummary?.consumed) return [];

    // Find the top consumers
    const topConsumers = orderBy(displayedDevices, (device) => device.energySummary, 'desc').slice(
      0,
      MAX_NUMBER_OF_TOP_CONSUMER_DEVICES
    );

    let otherDeviceTotalEnergy = sumBy(
      displayedDevices.slice(MAX_NUMBER_OF_TOP_CONSUMER_DEVICES),
      (device) => device.energySummary
    );
    // In the case that there are no top consumers (this means there are no devices in the energySummary) -
    // - there is still consumption from unknown sources, so we display this as part of "Other Devices"
    if (!topConsumers.length) otherDeviceTotalEnergy += energyUsageSummary.consumed;

    const data = [];
    // Here we first set up the "Other Devices" slice
    data.push(
      {
        name: '__hidden',
        y: 0.1,
        color: 'none',
        states: { hover: { enabled: false } },
        dataLabels: { enabled: false },
      },
      {
        name: 'Other Devices',
        y: Math.min((otherDeviceTotalEnergy / energyUsageSummary.consumed) * 100, 100),
        color: OTHER_DEVICES_COLOR,
      }
    );

    const topConsumerDataPoints = topConsumers.flatMap((device, i) => [
      {
        name: '__hidden',
        y: 0.1,
        color: 'none',
        states: { hover: { enabled: false } },
        dataLabels: { enabled: false },
      },
      {
        name: device.display_name,
        y: Math.min((Math.abs(device.energySummary) / energyUsageSummary.consumed!) * 100, 100),
        color: TOP_CONSUMERS_COLORS[i],
      },
    ]);
    data.push(...topConsumerDataPoints);

    return data;
  }, [isLoaded]);

  const options = useMemo(
    () => ({
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        plotShadow: false,
        height: 280,
        width: 280,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: ``,
      },
      legend: {},
      plotOptions: {
        pie: {
          animation: false,
          innerSize: '99%',
          dataLabels: {
            enabled: false,
          },
          borderWidth: 20,
          borderColor: null,
          enableMouseTracking: false,
          color: 'transparent',
        },
      },
      series: [
        {
          data: buildDataSet(),
        },
      ],
    }),
    [buildDataSet, isLoaded]
  );

  return (
    <Box data-testid="sense-only-home-page">
      <Weather />
      <WelcomeMessage />
      <Center position="relative" height="280px">
        {isUsageLoading ? (
          <SkeletonCircle
            // Adds a bit of spacing between the skeleton and the card border, as the card has no padding
            isLoaded={!isUsageLoading}
            size="280px"
            position="absolute"
          />
        ) : (
          <Center
            onClick={() => {
              navigate(`../live_energy_flow`);
            }}
            data-testid="energy-use-breakdown-chart"
          >
            <AbsoluteChartBackground />
            <Flex position="absolute">
              <Chart highcharts={Highcharts} options={options} />
            </Flex>
            <Center flexDirection="column" margin="auto" textAlign={'center'} mt={-5}>
              <Flex alignItems="baseline">
                <Text fontSize={24} data-testid="consumed-today-value">
                  {Intl.NumberFormat('en', {
                    notation: 'compact',
                    maximumFractionDigits: 2,
                  }).format(energyUsageSummary?.consumed || 0)}
                </Text>
                <Text ml={1}>kWh</Text>
              </Flex>
              <Text maxW={185}>{t('Home Screen.consumed today').toUpperCase()}</Text>

              <Divider width="50%" my={2} />

              <Flex alignItems="baseline">
                <Skeleton isLoaded={!isMeterDataLoading} minW={6} borderRadius={4}>
                  <Text fontSize={24} lineHeight={6} data-testid="current-use-value">
                    {Intl.NumberFormat('en', {
                      notation: 'compact',
                      maximumFractionDigits: 2,
                    }).format(liveDataSummary.consumption)}
                  </Text>
                </Skeleton>

                <Text ml={1}>kW</Text>
              </Flex>
              <Text maxW={170}>{t('Home Screen.current use').toUpperCase()}</Text>
            </Center>
          </Center>
        )}
      </Center>
      <TopConsumersList />
      <PowerSummaryCards />
      <TodaysCostCard />
    </Box>
  );
}
