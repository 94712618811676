import { Box, Flex, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { DateRangeType } from '../../../common/components/DateRangeTypePicker';

type TotalEnergyCardProps = {
  selectedDateRangeType: DateRangeType;
  totalEnergy: string;
  isParentLoaded: boolean;
};

/* istanbul ignore next -- @preserve */
export default function TotalEnergyCard({ selectedDateRangeType, totalEnergy, isParentLoaded }: TotalEnergyCardProps) {
  function getSelectedPeriod() {
    switch (selectedDateRangeType) {
      case DateRangeType.Day:
        return 'today';
      case DateRangeType.Week:
        return 'this week';
      case DateRangeType.Month:
        return 'this month';
    }
  }

  return (
    <Box px={4}>
      <Flex
        color={useColorModeValue('black', 'white')}
        direction={'column'}
        bg={useColorModeValue('#E7E6E6', 'customGrey.800')}
        w="100%"
        py={2}
        borderRadius={4}
        alignItems="center"
      >
        <Text fontSize={14}>Total Energy used {getSelectedPeriod()}</Text>
        <Skeleton isLoaded={isParentLoaded} mt={2}>
          <Box color="#24B43F" as="span" fontWeight={600} fontSize={30}>
            {totalEnergy}
          </Box>
          <Box as="span" fontSize={14} ml={1}>
            kWh
          </Box>
        </Skeleton>
      </Flex>
    </Box>
  );
}
