import { Center, ComponentWithAs, IconProps, useColorModeValue } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { OutlineGradientIcon, OutlineGradientIconAnimated } from '../../styles/custom-icons';
import { DEVICE_ICON_RESPONSIVE_SIZE, getIconForDevice } from './devices-helper';

type Props = {
  assignment?: string | null;
  isOn: boolean;
  icon?: ComponentWithAs<'svg', IconProps>;
};

export default function DeviceStatusIcon({ assignment, isOn, icon }: Readonly<Props>) {
  const deviceOnColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const deviceOffColor = 'customGrey.400';

  const Icon = useMemo(() => {
    if (icon) return icon;
    else return getIconForDevice(assignment);
  }, [assignment, icon]);

  return (
    <Center
      flexDirection="column"
      mb={1}
      w={DEVICE_ICON_RESPONSIVE_SIZE}
      h={DEVICE_ICON_RESPONSIVE_SIZE}
      pos="relative"
      margin="auto"
    >
      {isOn ? (
        <OutlineGradientIconAnimated
          w={[275, 400]}
          h={[275, 400]}
          color={deviceOnColor}
          data-testid="device-is-on-icon"
        />
      ) : (
        <OutlineGradientIcon
          w={DEVICE_ICON_RESPONSIVE_SIZE}
          h={DEVICE_ICON_RESPONSIVE_SIZE}
          color={deviceOffColor}
          data-testid="device-is-off-icon"
        />
      )}
      <Center w="100%" pos="absolute">
        <Icon color={isOn ? deviceOnColor : deviceOffColor} w={[120, 180]} h={[120, 180]} />
      </Center>
    </Center>
  );
}
