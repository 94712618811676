import { useToast, Switch, useDisclosure } from '@chakra-ui/react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useGetLiveSwitchQuery, useUpdateSwitchMutation } from './switchApi';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { getShutdownAlertUserPreference } from '../../../features/account/settings/settings-helpers';
import { useSelector } from 'react-redux';
import { selectSite } from '../../../features/site/siteSlice';
import { useTranslation } from 'react-i18next';

type Props = {
  switchId: number;
};

export default function SwitchToggle({ switchId }: Props) {
  const { data: device, isLoading } = useGetLiveSwitchQuery(switchId);
  const [pendingSwitchUpdate, setPendingSwitchUpdate] = useState<'open' | 'closed' | null>();
  const [updateSwitch, { isLoading: updateIsLoading }] = useUpdateSwitchMutation();
  const toast = useToast({ isClosable: true });
  const { isOpen: isShutdownAlertOpen, onOpen: onOpenShutdownAlert, onClose: onCloseShutdownAlert } = useDisclosure();
  const [updateUserPreferenceRequested, setUpdateUserPreferenceRequested] = useState<boolean>(false);
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();

  const showShutdownAlert = getShutdownAlertUserPreference();

  const deviceIsOn =
    device && device.status === 'online' && (device.state === 'closed' || (!updateIsLoading && device.power > 0));

  /* Hacky solution for an optimistic update on a switch update.
   * 1. We PATCH via the HTTP API - it's not immediately reflected in the live data APIs
   * 2. Flag the switch via state (pendingSwitchUpdate), ignore live data until it matches the new state, or it errors
   */
  useEffect(() => {
    if (device?.state === pendingSwitchUpdate) {
      setPendingSwitchUpdate(null);
    }
  }, [device?.state, device?.power, pendingSwitchUpdate]);

  const handleSwitchToggle = useCallback(
    async (toggleOnRequested: boolean) => {
      const requestedStateForApi = toggleOnRequested ? 'closed' : 'open';
      try {
        if (!device) throw Error('Device not found');
        setPendingSwitchUpdate(requestedStateForApi); // 'Hacky' optimistic update  - see above
        await updateSwitch({ siteId, switchId: device.id, body: { state: requestedStateForApi } }).unwrap();
        setPendingSwitchUpdate(requestedStateForApi);
      } catch (error) {
        console.error(error);
        toast({
          status: 'error',
          title: t('Devices.error updating device'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        });
        setPendingSwitchUpdate(null); // Unset the pending state on error
      }
    },
    [device]
  );

  return (
    <>
      <Switch
        isChecked={pendingSwitchUpdate ? pendingSwitchUpdate === 'closed' : deviceIsOn}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target.checked && showShutdownAlert) {
            onOpenShutdownAlert();
          } else handleSwitchToggle(e.target.checked);
        }}
        variant="solarGreen"
        size="lg"
        data-testid={`switch-${switchId}-toggle`}
        isDisabled={isLoading || updateIsLoading || !!pendingSwitchUpdate || device?.status === 'offline'}
      />
      <AlertDialogModal
        isOpen={isShutdownAlertOpen}
        onClose={onCloseShutdownAlert}
        header={t('Devices.turn off device?', { name: device?.site_switch_label })}
        subHeader={t('Devices.are you sure turn off?')}
        confirmButtonName={t('Devices.turn off')}
        cancelButtonName={t('Common.cancel')}
        onConfirm={() => {
          updateUserPreferenceRequested && localStorage.setItem('showShutdownAlerts', 'false');
          handleSwitchToggle(false);
        }}
        onSelectDontShowAgain={() => setUpdateUserPreferenceRequested(true)}
        dontShowRadioText={t('Common.dont show alerts anymore')}
      />
    </>
  );
}
