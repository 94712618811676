import { enUS, es, frCA } from 'date-fns/locale';
import { fr as yupFr, es as yupEs } from 'yup-locales';
import { SUPPORTED_LOCALES } from '../../i18n';
import { useTranslation } from 'react-i18next';

/**
 * Promisifies the `setTimeout` function
 *
 * @param ms MS to wait
 */
export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export type SupportedLocales = keyof typeof SUPPORTED_LOCALES;
/**
 * Returns the relevant date-fns locale for the given language code
 *
 * @param locale code for the locale
 */
export function getLocaleForDateFns(locale: SupportedLocales) {
  switch (locale) {
    case 'en-US':
      return enUS;
    case 'fr-CA':
      return frCA;
    case 'es-US':
      return es;
    default:
      return enUS;
  }
}

/**
 * Returns the relevant yup locale for the given language code
 *
 * @param locale code for the locale
 */
export function getLocaleForYup(locale: SupportedLocales) {
  switch (locale) {
    case 'en-US':
      // Yup does not have an en-US locale exported
      // It is the default locale
      return undefined;
    case 'fr-CA':
      return yupFr;
    case 'es-US':
      return yupEs;
    default:
      return undefined;
  }
}

export function useTranslatedMonths() {
  const { t } = useTranslation();
  return [
    t('Common.january'),
    t('Common.february'),
    t('Common.march'),
    t('Common.april'),
    t('Common.may'),
    t('Common.june'),
    t('Common.july'),
    t('Common.august'),
    t('Common.september'),
    t('Common.october'),
    t('Common.november'),
    t('Common.december'),
  ];
}
