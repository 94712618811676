import { Box, useColorModeValue } from '@chakra-ui/react';
import MultiToggleSwitch from './MultiToggleSwitch';
import React from 'react';
import { SwitchOption } from 'clipsal-cortex-ui/src/components/MultiToggleSwitch';
import { useTranslation } from 'react-i18next';

enum DateRangeType {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

type Props = {
  setSelectedDateRangeType: (dateRange: DateRangeType) => void;
  selectedDateRangeType: DateRangeType;
  dateRangeOptions?: SwitchOption<DateRangeType>[];
};

export const DateRangeTypePicker = ({ setSelectedDateRangeType, selectedDateRangeType, dateRangeOptions }: Props) => {
  const { t } = useTranslation();
  if (!dateRangeOptions) {
    dateRangeOptions = [
      { value: DateRangeType.Day, label: t('Common.day') },
      { value: DateRangeType.Week, label: t('Common.week') },
      { value: DateRangeType.Month, label: t('Common.month') },
    ];
  }
  return (
    <Box
      my={1}
      fontWeight={'bold'}
      className={useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch')}
    >
      <MultiToggleSwitch
        switchOptions={dateRangeOptions}
        onChange={(newValue: string) => setSelectedDateRangeType(newValue as DateRangeType)}
        value={selectedDateRangeType}
      />
    </Box>
  );
};

export { DateRangeType };
