import { Skeleton } from '@chakra-ui/react';
import React from 'react';

export default function NotificationsListSkeletonLoader({ numItems = 5 }: { numItems?: number }) {
  return (
    <>
      {Array.from(Array(numItems)).map((_, i) => (
        <Skeleton w={'100%'} height={'80px'} my={2} key={i} />
      ))}
    </>
  );
}
