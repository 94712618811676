import licenses from '../../../../../public/licenses/licenses.json';
import { useMemo } from 'react';
import { capitalizeFirst } from 'clipsal-cortex-utils/src/formatting/formatting';

interface Licenses {
  [key: string]: License;
}

export interface License {
  licenses: string;
  repository: string;
  path: string;
  publisher?: string;
  licenseFile?: string;
  packageName?: string;
  packageId?: string;
  packageVersion?: string;
}

type PublisherToLicenses = {
  [publisher: string]: License[];
};

type LicensesByPublisher = {
  publisherId: string;
  publisher: string;
  licenses: License[];
};

export function useLicenses(): LicensesByPublisher[] {
  return useMemo(() => {
    const licensesByPublisher = Object.entries(licenses as unknown as Licenses)
      .map(([packageName, values]) => {
        let licenseFile = !values?.licenseFile?.includes('README') ? values?.licenseFile : undefined;
        if (licenseFile) {
          licenseFile = licenseFile?.split('/node_modules/')[1];
          licenseFile = licenseFile?.includes('.md') ? licenseFile : `${licenseFile}.txt`;
        }
        const packageParts = packageName.split('@');
        return {
          ...values,
          packageName,
          packageId: toURL(packageParts[packageParts.length - 2]),
          packageVersion: packageParts[packageParts.length - 1],
          publisher: capitalizeFirst(values.publisher ?? 'Unknown'),
          licenseFile: licenseFile,
        };
      })
      .reduce((grouped: PublisherToLicenses, license: License) => {
        const { publisher = 'Unknown' } = license;
        if (!grouped[publisher]) {
          grouped[publisher] = [];
        }
        grouped[publisher].push(license);
        return grouped;
      }, {});
    return Object.entries(licensesByPublisher)
      .sort(([publisherA], [publisherB]) => publisherA.localeCompare(publisherB))
      .map(([publisher, licenses]) => ({
        publisherId: toURL(publisher.replace('@', '')),
        publisher,
        licenses,
      }));
  }, [licenses]);
}

export function toURL(str: string | undefined = '') {
  return encodeURIComponent(str.trim().toLowerCase().replace(/\s+/g, '-'));
}
