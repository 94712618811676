import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { IS_NATIVE } from '../../common/constants';

/**
 * Responsible for toggling privacy screen security features on native devices. This is toggled off by default.
 *
 * On Android: Prevents the screen from appearing in sceenshots and being viewed on non-secure displays.
 * On iOS: Hides the WebView window when the app isn't active and loses focus to show a gray screen instead. A black --
 * -- screen is captured in screenshots.
 *
 */
export const togglePrivacyScreen = async (enable: boolean) => {
  if (IS_NATIVE) {
    if (enable) {
      await PrivacyScreen.enable();
    } else {
      await PrivacyScreen.disable();
    }
  }
};
