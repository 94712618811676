import React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';

// This 'chart' is used as the background for chart on the Sense-only home page
export default function AbsoluteChartBackground() {
  const color = useColorModeValue('#F1F1F1', '#2A3039');

  return (
    <Flex position="absolute">
      <Chart
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            shadow: false,
            height: 280,
            width: 280,
          },
          credits: {
            enabled: false,
          },
          title: '',
          plotOptions: {
            pie: {
              animation: false,
              dataLabels: {
                enabled: false,
              },
              borderWidth: 40,
              color,
              enableMouseTracking: false,
            },
          },
          series: [
            {
              data: {
                name: 'background',
                y: 100,
              },
            },
          ],
        }}
      />
    </Flex>
  );
}
