import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Weather from '../Weather';
import WelcomeMessage from '../WelcomeMessage';
import { useTranslation } from 'react-i18next';
import { MatterDevicesList } from '../../devices/matter/MatterDevicesList';
import SunriseSunset from './SunriseSunset';

export default function MatterOnlyHome() {
  const { t } = useTranslation();

  return (
    <Box data-testid="matter-only-home-page">
      <Flex justifyContent="space-between">
        <SunriseSunset />
        <Weather shouldShowIcon={false} />
      </Flex>
      <WelcomeMessage />
      <MatterDevicesList sortBy="ALPHABETICAL" title={t('Devices.in use').toUpperCase()} state="closed" />
    </Box>
  );
}
