import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';
import ArcButton from '../../../../common/components/ArcButton';
import { Matter } from '@clipsalsolar/capacitor-matter';
import TopNavProgressLoader from '../../../../common/components/TopNavProgressLoader';
import { useTranslation } from 'react-i18next';
import phoneBluetoothImg from '../../../../assets/images/mobile_bluetooth_add.svg';
import phoneLocationImg from '../../../../assets/images/mobile_location_add.svg';

import { checkAndroidPermissionsToRequest, CommissioningWizardStatus } from './commissioning-helpers';
import * as Sentry from '@sentry/react';

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

type State = {
  status: CommissioningWizardStatus;
};

const INITIAL_STATE: State = {
  status: CommissioningWizardStatus.PERMISSION_CHECK,
};

export default function AndroidPermissionCheck({ onSuccess, onFailure }: Props) {
  const [state, setState] = useState(INITIAL_STATE);
  const { status } = state;
  const { t } = useTranslation();

  /* istanbul ignore next -- @preserve */
  useEffect(() => {
    checkPermissions();
  }, []);

  async function checkPermissions() {
    try {
      const permissionToRequest = await checkAndroidPermissionsToRequest();
      if (!permissionToRequest) {
        return onSuccess();
      }
      if (permissionToRequest === 'BLUETOOTH') {
        // User has explicitly declined, they need to manually enable it in settings
        return setState((p) => ({ ...p, status: CommissioningWizardStatus.BLUETOOTH_PERMISSION_REQUIRED }));
      }
      if (permissionToRequest === 'LOCATION') {
        // User has explicitly declined, they need to manually enable it in settings
        return setState((p) => ({ ...p, status: CommissioningWizardStatus.LOCATION_PERMISSION_REQUIRED }));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error checking permissions:', error);
      return onFailure();
    }
  }

  /* istanbul ignore next -- @preserve */
  async function handleRequestPermissions() {
    await Matter.requestPermissions();
    await checkPermissions();
  }

  let content = (
    <>
      <TopNavProgressLoader />
      <Heading m={6} size="md" textAlign="center">
        {t('Devices.checking permissions')}...
      </Heading>
    </>
  );

  /* istanbul ignore next -- @preserve */
  if (status === CommissioningWizardStatus.BLUETOOTH_PERMISSION_REQUIRED) {
    content = (
      <Box mx={5}>
        <Image w={'40%'} src={phoneBluetoothImg} alt="Phone code" m="auto" />
        <Heading size="lg" mt={2}>
          {t('Devices.turn on bluetooth title')}
        </Heading>
        <Text mt={2}>{t('Devices.turn on bluetooth description')}</Text>
        <Text my={2} fontWeight={550}>
          {t('Devices.permission required instructions')}
        </Text>
        <ArcButton mt={8} w={'80%'} arcColor="#3DCD57" data-testid="continue-button" onClick={handleRequestPermissions}>
          {t('Common.continue')}
        </ArcButton>
      </Box>
    );
  }

  /* istanbul ignore next -- @preserve */
  if (status === CommissioningWizardStatus.LOCATION_PERMISSION_REQUIRED) {
    content = (
      <Box mx={5}>
        <Image w={'40%'} src={phoneLocationImg} alt="Phone code" m="auto" />
        <Heading size="lg" mt={2}>
          {t('Devices.location required title')}
        </Heading>
        <Text mt={2}>{t('Devices.location required description')}</Text>
        <Text my={2} fontWeight={550}>
          {t('Devices.permission required instructions')}
        </Text>
        <ArcButton mt={8} w={'80%'} arcColor="#3DCD57" data-testid="continue-button" onClick={handleRequestPermissions}>
          {t('Common.continue')}
        </ArcButton>
      </Box>
    );
  }

  return content;
}
