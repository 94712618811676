import React, { TouchEvent, useEffect, useRef, useState } from 'react';
import SEHomeCard, { SEHomeCardProps } from './SEHomeCard';
import { Box, Center, CenterProps, Flex, FlexProps } from '@chakra-ui/react';

type Props = {
  primaryButtonProps: CenterProps & { 'data-testid'?: string };
  secondaryButtonProps?: CenterProps & { 'data-testid'?: string };
  disableSlide?: boolean;
  containerProps?: FlexProps & { 'data-testid'?: string };
  // Use this prop to allow consumer to force the slider to close. Sort of hacky, but without lifting all the slider
  // state to the parent component (unnecessarily complex), this is the best way to do this I can see, aside from some
  // sort of observer pattern implementation (bit of a React anti-pattern).
  shouldForceResetSlide?: boolean;
} & SEHomeCardProps;

const MINIMUM_INITIAL_DRAG_THRESHOLD_PX = 20;

export default function SwipeableSEHomeCard({
  children,
  primaryButtonProps,
  secondaryButtonProps,
  disableSlide = false,
  shouldForceResetSlide = false,
  containerProps,
  ...cardProps
}: Props) {
  const moveXCoords = useRef<{ start: number | null; current: number | null; isDragging: boolean; isOpen: boolean }>({
    start: null,
    current: null,
    isDragging: false,
    isOpen: false,
  });
  const buttonSetContainerRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const redBgRef = useRef<HTMLDivElement | null>(null);
  const greyBgRef = useRef<HTMLDivElement | null>(null);
  const [cardHeight, setCardHeight] = useState<number | null>(null);

  useEffect(() => {
    if (shouldForceResetSlide) resetSlide();
  }, [shouldForceResetSlide]);

  useEffect(() => {
    // Force a re-render and set height on hidden elements
    setCardHeight(cardRef.current!.clientHeight);
  }, []);

  // When there's just one button, use half the width
  const BUTTON_VISIBILITY_THRESHOLD_PX = !secondaryButtonProps ? 75 : 150;

  function resetSlide() {
    moveXCoords.current = { start: null, current: null, isDragging: false, isOpen: false };
    cardRef.current!.style.transform = `translateX(0px)`;
    buttonSetContainerRef.current!.style.width = '0px';
    redBgRef.current!.style.visibility = 'hidden';
    greyBgRef.current!.style.visibility = 'hidden';
  }

  function handleTouchStart(event: TouchEvent<HTMLDivElement>) {
    if (disableSlide) return;

    const touch = event.touches[0];
    moveXCoords.current = {
      start: touch.pageX,
      current: touch.pageX,
      isDragging: true,
      isOpen: moveXCoords.current.isOpen,
    };
  }

  function handleTouchEnd() {
    if (disableSlide) return;

    const distance = moveXCoords.current.start! - moveXCoords.current.current!;
    if (distance >= BUTTON_VISIBILITY_THRESHOLD_PX) {
      moveXCoords.current = { start: null, current: null, isDragging: false, isOpen: true };
      cardRef.current!.style.transform = `translateX(-${BUTTON_VISIBILITY_THRESHOLD_PX}px)`;
      buttonSetContainerRef.current!.style.width = `${BUTTON_VISIBILITY_THRESHOLD_PX}px`;
    } else {
      resetSlide();
    }
  }

  function handleTouchMove(event: TouchEvent<HTMLDivElement>) {
    if (!moveXCoords.current.isDragging || disableSlide) return;

    const touch = event.touches[0];
    moveXCoords.current = {
      start: moveXCoords.current.start,
      current: touch.pageX,
      isDragging: true,
      isOpen: moveXCoords.current.isOpen,
    };

    const xDiff = moveXCoords.current.start! - touch.pageX;

    if (!moveXCoords.current.isOpen && xDiff > 0 && xDiff <= BUTTON_VISIBILITY_THRESHOLD_PX) {
      if (xDiff < MINIMUM_INITIAL_DRAG_THRESHOLD_PX) return;

      redBgRef.current!.style.visibility = 'visible';
      greyBgRef.current!.style.visibility = 'visible';
      cardRef.current!.style.transform = `translateX(${xDiff * -1}px)`;
      buttonSetContainerRef.current!.style.width = `${xDiff}px`;
    } else if (moveXCoords.current.isOpen && xDiff < 0) {
      if (xDiff * -1 > BUTTON_VISIBILITY_THRESHOLD_PX) {
        cardRef.current!.style.transform = `translateX(0px)`;
        buttonSetContainerRef.current!.style.width = `0px`;
      } else {
        cardRef.current!.style.transform = `translateX(${xDiff * -1 - BUTTON_VISIBILITY_THRESHOLD_PX}px)`;
        buttonSetContainerRef.current!.style.width = `${BUTTON_VISIBILITY_THRESHOLD_PX - xDiff * -1}px`;
      }
    }
  }

  return (
    <Flex {...containerProps} pos="relative">
      <SEHomeCard
        zIndex={6}
        ref={cardRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        {...cardProps}
      >
        {children}
      </SEHomeCard>
      {/* These backgrounds provide the overlap effect when dragging the menu open @TODO: looks weird on iPad */}
      <Box
        ref={redBgRef}
        zIndex={3}
        visibility={'hidden'}
        bg={"#ec510e"}
        height={`${cardHeight}px`}
        right={0}
        w={'60%'}
        pos={'absolute'}
        borderTopRightRadius={'5px'}
        borderBottomRightRadius={'25px'}
      />
      <Box
        ref={greyBgRef}
        zIndex={4}
        visibility={'hidden'}
        height={`${cardHeight}px`}
        right={0}
        w={'30%'}
        pos={'absolute'}
        borderTopRightRadius={'5px'}
        borderBottomRightRadius={'25px'}
      />
      {/* The menu itself */}
      <Box
        zIndex={5}
        height={`${cardHeight}px`}
        overflow={'hidden'}
        width={'0px'}
        right={0}
        position={'absolute'}
        ref={buttonSetContainerRef}
      >
        <Flex height={'100%'}>
          <Center
            as={'button'}
            flexDirection={'column'}
            borderTopRightRadius={'5px'}
            borderBottomRightRadius={'25px'}
            h="100%"
            w="100%"
            bg={"#ec510e"}
            color="#fff"
            {...primaryButtonProps}
            onClick={(e) => {
              primaryButtonProps.onClick && primaryButtonProps.onClick(e);
            }}
          >
            {primaryButtonProps.children}
          </Center>
          {secondaryButtonProps && (
            <Center
              as={'button'}
              
              flexDirection={'column'}
              borderTopRightRadius={'5px'}
              borderBottomRightRadius={'25px'}
              h="100%"
              w="100%"
              bg={"#5C6466"}
              color="#fff"
              {...secondaryButtonProps}
              onClick={(e) => {
                secondaryButtonProps.onClick && secondaryButtonProps.onClick(e);
              }}
            >
              {secondaryButtonProps.children}
            </Center>
          )}
        </Flex>
      </Box>
    </Flex>
  );
}
