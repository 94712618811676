import React from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { selectUser } from '../../user/userSlice';
import {
  fetchEvChargerStatus,
  selectChargeSimulationType,
  selectEvChargerData,
  setChargeSimulationType,
} from './evChargerSlice';
import { useReduxDispatch } from '../../../app/store';

/* istanbul ignore next -- @preserve */
export default function SimulationDeveloperTool() {
  const user = useSelector(selectUser);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const chargeSimulationType = useSelector(selectChargeSimulationType);
  const dispatch = useReduxDispatch();
  const { chargerId } = useSelector(selectEvChargerData);

  const buttonBg = useColorModeValue('#E7E6E6', 'customGrey.800');
  const buttonColor = useColorModeValue('black', 'white');

  async function handleSetChargeSimulationType(type: string) {
    await dispatch(setChargeSimulationType(type));
    dispatch(fetchEvChargerStatus(chargerId));
  }

  return (
    <>
      {user.role === 'SUPER_ADMIN' && localStorage.getItem('developerToolsEnabled') === 'true' && (
        <Button
          bg={buttonBg}
          color={buttonColor}
          borderRadius={0}
          border="none"
          size={'xl'}
          fontWeight={'400'}
          w="100%"
          py={5}
          onClick={onOpen}
          borderBottomLeftRadius={'5px'}
          borderBottomRightRadius={'25px'}
          borderTopRightRadius={'5px'}
          borderTopLeftRadius={'5px'}
        >
          <Flex align={'center'} justify="space-between" w="100%">
            <Text ml={6} fontSize={'16px'}>
              [Development Tools] State Simulation
            </Text>
            <ChevronRightIcon color={'#A6A6A6'} mr={4} w={6} h={6} />
          </Flex>
        </Button>
      )}

      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Charger Simulation Settings</DrawerHeader>
          <DrawerBody>
            <Text my={2}>Select a charge status to simulate:</Text>
            <RadioGroup onChange={handleSetChargeSimulationType} value={chargeSimulationType}>
              <Stack>
                <Radio value="None">None</Radio>
                <Radio value="Available">Available</Radio>
                <Radio value="Charging">Charging</Radio>
                <Radio value="Finishing">Finishing</Radio>
                <Radio value="Faulted">Faulted</Radio>
                <Radio value="Preparing">Preparing</Radio>
                <Radio value="SuspendedEVSE">Suspended EV</Radio>
                <Radio value="Unavailable_Charger">Unavailable</Radio>
                <Radio value="Wifi_Faulted">Wi-Fi Faulted</Radio>
              </Stack>
            </RadioGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
