import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Appliance } from 'clipsal-cortex-types/src/api/api-appliances';
import { baseApi } from '../../app/services/baseApi';
import { selectSite } from '../site/siteSlice';
import { useLiveData } from '../site/live-data/liveDataApi';
import { LiveApplianceData } from 'clipsal-cortex-types/src/api/api-live-data';
import { useGetSwitchesByManufacturerQuery, useGetSwitchesQuery, useGetSwitchQuery } from './switches/switchApi';
import { MANUFACTURER_ID_AYLA } from './devices-helper';
import { SenseSwitch } from 'clipsal-cortex-types/src/api/api-sense-switch';

type DevicePatch = {
  display_name?: string;
  user_assignment?: string | null;
};

export const devicesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.query<Appliance[], { siteId: number; shouldGetAylaAppliances?: boolean }>({
      query: ({ siteId, shouldGetAylaAppliances = false }) =>
        // Ayla appliances require using the extra_appliances query param. Probabaly a BE bug, but using false in --
        // -- the query param still returns Ayla appliances.
        `/v1/sites/${siteId}/appliances${shouldGetAylaAppliances ? '?extra_appliances=true' : ''}`,
      providesTags: ['Devices'],
      transformResponse: (devices: Appliance[]) => devices.filter((device) => device.assignment.startsWith('load_')),
    }),
    updateDevice: builder.mutation<Appliance, { siteId: number; deviceId: number; body: DevicePatch }>({
      query: ({ siteId, deviceId, body }) => {
        return {
          url: `/v1/sites/${siteId}/appliances/${deviceId}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Devices'],
    }),
  }),
});

export const { useGetDevicesQuery: useOriginalGetDevicesQuery, useUpdateDeviceMutation } = devicesApi;

// Represents a combination of the data provided from the websocket realtime stream, alongside the data provided from
// the appliances GET list API. This is important because any update to an appliance will not be updated in the
// real-time stream (for performance reasons) -- only in the HTTP GET list API.
export type CombinedDevice = Appliance & LiveApplianceData;

/**
 * A basic wrapper around the `useGetDevicesQuery` original, with some sensible default values.
 */
export function useGetDevicesQuery(skip = false) {
  const { site_id: siteId } = useSelector(selectSite);
  // We need to check if the site has Ayla switches to determine if we need to fetch them from appliances.
  const {
    data: aylaSwitchesFromApi,
    isLoading: isSwitchesLoading,
    isFetching: isSwitchesFetching,
  } = useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_AYLA, skip);
  const siteHasAylaSwitches = !!aylaSwitchesFromApi?.length;
  const result = useOriginalGetDevicesQuery(
    {
      siteId,
      shouldGetAylaAppliances: siteHasAylaSwitches,
    },
    { skip: isSwitchesLoading || skip }
  );

  return {
    ...result,
    isLoading: isSwitchesLoading || result.isLoading,
    isFetching: isSwitchesFetching || result.isFetching,
    data: result?.data ?? [],
  };
}

/**
 * A basic wrapper to get a single device from the `useGetDevicesQuery` original.
 */
export function useGetDeviceQuery({
  deviceId,
  switchId,
  skip = false,
}: {
  deviceId?: number;
  switchId?: number;
  skip?: boolean;
}) {
  const { site_id: siteId } = useSelector(selectSite);

  // We need to check if its an Ayla switch to determine if we need to send an extra query param
  const { data: apiSwitch, isLoading: isApiSwitchLoading } = useGetSwitchQuery(switchId!, skip || !switchId);
  const isAylaSwitch = apiSwitch?.manufacturer.manufacturer_id == MANUFACTURER_ID_AYLA;

  const result = useOriginalGetDevicesQuery(
    {
      siteId,
      shouldGetAylaAppliances: isAylaSwitch,
    },
    {
      skip: skip || isApiSwitchLoading || (!deviceId && !switchId),
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: data?.find((s) => (deviceId ? s.appliance_id === deviceId : s.switch_id === switchId)),
      }),
    }
  );

  return {
    ...result,
  };
}

/**
 * Combines the result of the websocket data stream for devices with the result from the HTTP API.
 * Combining these two sources is important to ensure that updates to devices are reflected in the UI, as any
 * updates made to a device will not be reflected in the real-time stream for performance reasons.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetLiveDevicesQuery(skip = false) {
  const { site_id: siteId } = useSelector(selectSite);
  const {
    data: devicesFromApi,
    isLoading: isDevicesLoading,
    isError: isDevicesError,
    isFetching: isDevicesFetching,
    ...rest
  } = useOriginalGetDevicesQuery({ siteId }, { skip });
  const { data, isMeterDataLoading: isLiveDataLoading, isMeterError } = useLiveData(skip);

  const isLiveDataError = isMeterError;
  const devicesFromWS = data.appliances;
  // We combine switches from the API and WS here.
  let combinedDevices: CombinedDevice[] = [];
  if (!isLiveDataLoading && !isDevicesLoading && !isLiveDataError && devicesFromApi?.length) {
    combinedDevices = devicesFromApi.map((apiDevice) => {
      const websocketDevice = devicesFromWS?.find((s) => s.appliance_id === apiDevice.appliance_id);

      return {
        ...apiDevice,
        power: websocketDevice?.power ?? 0,
      };
    });
  }

  return {
    ...rest,
    isLoading: isDevicesLoading || isLiveDataLoading,
    isFetching: isDevicesFetching,
    isError: isDevicesError || isLiveDataError,
    isLiveDataError,
    data: combinedDevices,
  };
}

/**
 * Provides a way to get a single device from the live data websocket, combined with the data from the API.
 * This is to help consolidate these two sources of data.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetLiveDevice(deviceId: number, skip = false) {
  const { site_id: siteId } = useSelector(selectSite);
  const {
    data: deviceFromApi,
    isLoading: isDevicesLoading,
    isError: isDevicesError,
    isFetching: isDevicesFetching,
    ...rest
  } = useOriginalGetDevicesQuery(
    { siteId },
    {
      skip,
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: data?.find((s) => s.appliance_id === deviceId),
      }),
    }
  );
  const { data, isMeterError, isMeterDataLoading: isLoading } = useLiveData(skip);

  const deviceNotFound = !isDevicesLoading && !isDevicesFetching && !deviceFromApi;

  // We combine the switch from the API and WS here.
  let combinedDevice: CombinedDevice | undefined;
  if (!isLoading && !isDevicesLoading && !isMeterError && !isDevicesFetching && deviceFromApi) {
    const devicesFromWS = data.appliances;
    const websocketDevice = devicesFromWS?.find((s) => s.appliance_id === deviceId);

    combinedDevice = {
      ...deviceFromApi,
      power: websocketDevice?.power ?? 0,
    };
  }

  return {
    ...rest,
    data: combinedDevice,
    isLoading: isDevicesLoading || isLoading,
    isFetching: isDevicesFetching,
    isError: isMeterError || isDevicesError || deviceNotFound,
  };
}

export type DeviceAndSwitch = Appliance & SenseSwitch;

/**
 * Combines the result of the Device API and the Switch API.
 * Combining these sources is important to ensure that updates to devices are reflected in the UI.
 *
 * @param skip parameter, if set to true, will skip the query.
 * @param excludeSwitches parameter, if set to true, will exclude switches from the query.
 *
 * @returns Devices with respective switch data (if a switch exists for the device)
 */
export function useGetDevicesWithSwitchesQuery(skip = false, excludeSwitches = false) {
  const {
    data: dataDevices,
    isLoading: isDevicesLoading,
    isError: isDevicesError,
    isFetching: isDevicesFetching,
    ...devicesOther
  } = useGetDevicesQuery(skip);
  const { site_id: siteId } = useSelector(selectSite);
  const {
    data: dataSwitches,
    isLoading: isSwitchesLoading,
    isError: isSwitchesError,
    isFetching: isSwitchesFetching,
    ...switchesOther
  } = useGetSwitchesQuery(siteId, {
    skip: skip || excludeSwitches,
  });

  const data: (Appliance | DeviceAndSwitch)[] = useMemo(
    () =>
      dataDevices.map((device) => {
        const switchData = dataSwitches?.find(({ id }) => id === device.switch_id);
        return {
          ...device,
          ...switchData,
        };
      }),
    [dataDevices, dataSwitches]
  );

  return {
    ...switchesOther,
    ...devicesOther,
    data,
    isLoading: isDevicesLoading || isSwitchesLoading,
    isError: isDevicesError || isSwitchesError,
    isFetching: isDevicesFetching || isSwitchesFetching,
  };
}
