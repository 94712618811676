import React, { useMemo } from 'react';
import { HomePageWidgetTileProps } from '../HomePageWidgetTile';
import { useGetSavingsQuery } from '../../../savings/savingsApi';
import { DateRangeType } from '../../../../common/components/DateRangeTypePicker';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import { useSelector } from 'react-redux';
import { selectCurrentDayForSite } from '../../../site/siteSlice';
import GenericHomePageWidgetTile from './GenericHomePageWidgetTile';
import { useTranslation } from 'react-i18next';

export default function SavingsWidgetTile({ item, index, isEditing, onRemoveGridItem }: HomePageWidgetTileProps) {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: savingsData, isLoading: isSavingsLoading } = useGetSavingsQuery(currentDay, DateRangeType.Day);
  const totalSaved = useMemo(() => {
    return savingsData.find((dailySavings) => dailySavings.date === formatDate(currentDay))?.total_saved || 0;
  }, [savingsData]);
  const { t } = useTranslation();

  return (
    <GenericHomePageWidgetTile
      isLoaded={!isSavingsLoading}
      item={{
        ...item,
        status: totalSaved ? formatDollarsCents(totalSaved) : '',
        title: totalSaved ? t("Widgets.Today's Savings.title") : t("Widgets.Today's Savings.savings"),
      }}
      index={index}
      isEditing={isEditing}
      onRemoveGridItem={onRemoveGridItem}
    />
  );
}
