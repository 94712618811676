import { useDispatch } from 'react-redux';
import {
  Action,
  CombinedState,
  combineReducers,
  configureStore,
  PayloadAction,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import sitesReducer from '../features/sites/sitesSlice';
import siteReducer from '../features/site/siteSlice';
import deviceSetupReducer from '../features/ev_charger/device-setup/deviceSetupSlice';
import evChargerReducer from '../features/ev_charger/dashboard/evChargerSlice';
import wifiConfigReducer from '../features/ev_charger/wifi-configuration/wifiConfigSlice';
import schedulerReducer from '../features/devices/switches/schedules/schedulerSlice';
import { baseApi } from './services/baseApi';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  sites: sitesReducer,
  user: userReducer,
  site: siteReducer,
  deviceSetup: deviceSetupReducer,
  evCharger: evChargerReducer,
  wifiConfig: wifiConfigReducer,
  scheduler: schedulerReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

/**
 * Wraps our root reducer, allowing store de-hydration from the top-level.
 *
 * @TODO: figure out why we can't use `RootState` generic type on `CombinedState`.
 *
 * @param state - The root state
 * @param action - The action sent.
 */
export const resettableRootReducer = (state: CombinedState<any>, action: PayloadAction<any>) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  // Disable default middleware in dev mode due to slowdown. Remove this property to re-enable.
  middleware: [thunk, baseApi.middleware],
});

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type ReduxDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
