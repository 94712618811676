import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../../site/siteSlice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { editWifiDetails, getSavedWifiDetails, WifiDetails } from './wifi-settings-helpers';
import SEHomeCard from '../../../../common/components/SEHomeCard';
import { PasswordInput } from '../../../../common/components/PasswordInput';

const FORM_ID = 'edit-saved-wifi-details-form';

export function EditWifiDetails() {
  const { site_id: siteId } = useSelector(selectSite);
  const [search] = useSearchParams();
  const savedWifiDetailsId = search.get('id');
  const { t } = useTranslation();
  const [wifiDetailsToEdit, setWifiDetailsToedit] = useState<WifiDetails>();

  useEffect(() => {
    async function fetchSavedWifi() {
      const savedWifiDetails = await getSavedWifiDetails(siteId);
      const savedWifiDetail = savedWifiDetails.find((wifi) => wifi.id === savedWifiDetailsId);
      if (savedWifiDetail) setWifiDetailsToedit(savedWifiDetail);
    }

    fetchSavedWifi();
  }, []);

  return (
    <SlidingAnimationPageBase
      title={t('Account.edit wifi settings')}
      backURL={`../view?direction=back`}
      customTopNavButton={
        <Button
          isDisabled={!wifiDetailsToEdit}
          form={FORM_ID}
          data-testid={'save-button'}
          type={'submit'}
          variant={'ghost'}
          colorScheme={'schneiderSkyBlue'}
          fontWeight={500}
          size={'sm'}
        >
          {t('Common.save')}
        </Button>
      }
    >
      <Box m={4}>
        <Text>{wifiDetailsToEdit?.ssid.toUpperCase()}</Text>
        <Box mb={16}>
          {wifiDetailsToEdit ? (
            <EditSavedWifiDetailsForm wifiDetailsToEdit={wifiDetailsToEdit} />
          ) : (
            <SEHomeCard borderTopLeftRadius={'5px'} mt={1}>
              <Text>{t('Account.wifi details not found')}</Text>
            </SEHomeCard>
          )}
        </Box>
      </Box>
    </SlidingAnimationPageBase>
  );
}

type EditWifiDetailsProps = {
  wifiDetailsToEdit: WifiDetails;
};

type EditWifiDetailsFormData = {
  ssid: string;
  password: string;
  isDefault: boolean;
};

function EditSavedWifiDetailsForm({ wifiDetailsToEdit }: EditWifiDetailsProps) {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();
  const bgColor = useColorModeValue('#FFFFFF', 'customGrey.800');

  const schema = yup.object({
    ssid: yup.string().required().label(t('Common.wifi ssid')),
    password: yup.string().required().label(t('Common.wifi pw')),
    isDefault: yup.boolean(),
  });

  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control,
  } = useForm<EditWifiDetailsFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      ssid: wifiDetailsToEdit.ssid,
      password: wifiDetailsToEdit.password,
      isDefault: wifiDetailsToEdit.isDefault,
    },
  });

  async function handleSubmit({ ssid, password, isDefault }: EditWifiDetailsFormData) {
    try {
      await editWifiDetails(siteId, wifiDetailsToEdit.id, ssid, password, isDefault);
      toast({
        status: 'success',
        title: t('Account.success saving wifi details'),
      });
      navigate(`../view?direction=back`);
    } catch {
      toast({
        status: 'error',
        title: t('Account.error saving wifi details'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }
  }

  return (
    <SEHomeCard borderTopLeftRadius={'5px'} mt={1}>
      <Box
        as={'form'}
        id={FORM_ID}
        onSubmit={handleFormSubmit(handleSubmit)}
        data-testid="edit-saved-wifi-details-form"
      >
        <Box pt={3} pb={5}>
          <FormControl isInvalid={!!errors?.ssid}>
            <Input
              borderRadius={0}
              backgroundColor={bgColor}
              size="md"
              data-testid="edit-ssid-input"
              placeholder={`${t('Account.enter ssid')}...`}
              {...register(`ssid`)}
            />
            <FormErrorMessage data-testid="ssid-error">{errors?.ssid?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Divider borderColor="rgba(151, 151, 151, 0.3)" />
        <Box py={5}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value, ref } }) => (
              <FormControl isInvalid={!!errors?.password}>
                <PasswordInput
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  placeholder={`${t('Account.enter password')}...`}
                />
                <FormErrorMessage data-testid="password-error">{errors?.password?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <Checkbox
            {...register('isDefault')}
            mt={3}
            colorScheme="primaryBranding"
            iconColor="#fff"
            data-testid="is-default-checkbox"
          >
            {t('Account.set default network')}
          </Checkbox>
        </Box>
      </Box>
    </SEHomeCard>
  );
}
