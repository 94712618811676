import { HTTP } from '@awesome-cordova-plugins/http';

const DEFAULT_JSON_HEADERS = {
  'Content-type': 'application/json',
  Accept: 'application/json',
};

// @TODO: consider refactoring to a singleton class which instantiates per wifi setup flow, e.g. one for meter, inverter
//        and others. main benefit here is that we can set the base IP address on each instance

/**
 * Helper function to assert response types from the native HTTP wrapper.
 *
 * @param url - The full URL to call, including the base domain (e.g. `https://google.com`)
 * @param headers - Any custom headers you might want to apply, defaulted to sensible JSON values.
 */
export async function get<T = any>(url: string, headers: Record<string, string> = DEFAULT_JSON_HEADERS): Promise<T> {
  const response = await HTTP.get(url, null, headers);
  // Response might be undefined, which can't be parsed as JSON.
  return response.data ? (JSON.parse(response.data) as T) : (response.data as T);
}

/**
 * Helper function to assert response types from the native HTTP wrapper.
 *
 * @param url - The full URL to call, including the base domain (e.g. `https://google.com`)
 * @param body - The body to be sent as the payload of the HTTP POST request.
 * @param headers - Any custom headers you might want to apply, defaulted to sensible JSON values.
 */
export async function post<T = any>(
  url: string,
  body: any,
  headers: Record<string, string> = DEFAULT_JSON_HEADERS
): Promise<T> {
  const response = await HTTP.post(url, body, headers);
  // Response might be undefined, which can't be parsed as JSON.
  return response.data ? (JSON.parse(response.data) as T) : (response.data as T);
}

/**
 * Helper function to assert response types from the native HTTP wrapper.
 *
 * @param url - The full URL to call, including the base domain (e.g. `https://google.com`)
 * @param body - The body to be sent as the payload of the HTTP PUT request.
 * @param headers - Any custom headers you might want to apply, defaulted to sensible JSON values.
 */
export async function put<T = any>(
  url: string,
  body: any,
  headers: Record<string, string> = DEFAULT_JSON_HEADERS
): Promise<T> {
  const response = await HTTP.put(url, body, headers);
  // Response might be undefined, which can't be parsed as JSON.
  return JSON.parse(response.data) as T;
}

/**
 * Helper function to assert response types from the native HTTP wrapper.
 *
 * @param url - The full URL to call, including the base domain (e.g. `https://google.com`)
 * @param body - The body to be sent as the payload of the HTTP PATCH request.
 * @param headers - Any custom headers you might want to apply, defaulted to sensible JSON values.
 */
export async function patch<T = any>(
  url: string,
  body: any,
  headers: Record<string, string> = DEFAULT_JSON_HEADERS
): Promise<T> {
  const response = await HTTP.patch(url, body, headers);
  // Response might be undefined, which can't be parsed as JSON.
  return response.data ? (JSON.parse(response.data) as T) : (response.data as T);
}

/**
 * Helper function to assert response types from the native HTTP wrapper.
 *
 * @param url - The full URL to call, including the base domain (e.g. `https://google.com`)
 * @param headers - Any custom headers you might want to apply, defaulted to sensible JSON values.
 */
export async function del<T = any>(url: string, headers: Record<string, string> = DEFAULT_JSON_HEADERS): Promise<T> {
  const response = await HTTP.delete(url, null, headers);
  // Response might be undefined, which can't be parsed as JSON.
  return response.data ? (JSON.parse(response.data) as T) : (response.data as T);
}
