import React, { useCallback, useMemo } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Heading,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  useColorModeValue,
} from '@chakra-ui/react';
import { DragHandleIcon } from '../../../../styles/custom-icons';
import { CloseIcon } from '@chakra-ui/icons';
import DraggableSEHomeCard from '../DraggableSEHomeCard';
import { HomePageWidgetTileProps } from '../HomePageWidgetTile';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useNavigate } from 'react-router-dom';
import { invalidateMeterQueryData, useLiveData } from '../../../site/live-data/liveDataApi';
import { useTranslation } from 'react-i18next';

export default function LiveEnergyWidgetTile({ item, index, isEditing, onRemoveGridItem }: HomePageWidgetTileProps) {
  const { data: liveDataSummary, isInverterError, isMeterError, isLoading, error } = useLiveData();
  const isError = isInverterError && isMeterError;
  const textColor = useColorModeValue('black', 'white');
  const navigate = useNavigate();
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { t } = useTranslation();

  const { solar, battery, grid, consumption } = liveDataSummary;
  const solarProduction = Math.max(solar, 0);
  const gridImport = Math.max(grid, 0);
  const batteryDischarge = Math.abs(Math.min(battery ?? 0, 0));
  const totalSources = solarProduction + batteryDischarge + gridImport;

  const buildDataSet = useCallback(() => {
    if (isLoading) return [];
    // There is no consumption
    if (totalSources === 0) return [];

    const data = [];

    // Note: The "hidden" data points are used to create the gaps between the bars in the donut chart, as there's no
    // real other way to do it with highcharts.
    if (solarProduction) {
      data.push(
        {
          name: '__hidden',
          y: 3,
          color: 'none',
          states: { hover: { enabled: false } },
          dataLabels: { enabled: false },
        },
        {
          name: 'Solar',
          y: Math.min((Math.abs(solarProduction) / totalSources) * 100, 100),
          color: '#27AE60',
        }
      );
    }

    if (gridImport > 0) {
      data.push(
        {
          name: '__hidden',
          y: 3,
          color: 'none',
          states: { hover: { enabled: false } },
          dataLabels: { enabled: false },
        },
        {
          name: 'Grid',
          // The grid value could be massive, but mostly charging the battery, so the max value here should be 100.
          y: Math.min((gridImport / totalSources) * 100, 100),
          color: '#DB5E52',
        }
      );
    }

    // Only add battery if it's discharging, not charging.
    if (batteryDischarge > 0) {
      data.push(
        {
          name: '__hidden',
          y: 3,
          color: 'none',
          states: { hover: { enabled: false } },
          dataLabels: { enabled: false },
        },
        {
          name: 'Battery',
          y: (batteryDischarge / totalSources) * 100,
          color: '#42B4E6',
        }
      );
    }

    return data;
  }, [isLoading, solarProduction, gridImport, batteryDischarge, totalSources]);

  const getSelfPoweredPercentage = useCallback(() => {
    // NOTE: This calculation assumes we're using the "maximise self consumption" operating mode.
    if (isLoading || isError) return 0;
    if (!Math.max(consumption, 0)) return 0;

    if (totalSources === 0) return 0;

    const selfPoweredPercentage = ((solarProduction + batteryDischarge) / totalSources) * 100;

    return Math.round(Math.min(selfPoweredPercentage, 100));
  }, [isLoading, consumption, solarProduction, batteryDischarge, totalSources, isError]);

  const getPoweredCopy = useCallback(() => {
    if (isError) {
      return (
        <>
          <Heading size="md">{t('Widgets.Live Power.error connecting to hardware')}</Heading>
          <Box>{t('Widgets.Live Power.unable to connect to hardware')}</Box>
        </>
      );
    }

    let copy = <Box>{t('Widgets.Live Power.powered by grid')}</Box>;

    if (solarProduction + gridImport + batteryDischarge === 0) {
      copy = <Box>{t('Widgets.Live Power.not consuming any power')}</Box>;
    } else if (solarProduction > 0 && batteryDischarge > 0 && solarProduction + batteryDischarge > gridImport) {
      copy = (
        <>
          <Heading size="md">{t('Widgets.Live Power.good job')}</Heading>
          <Box>{t('Widgets.Live Power.powered by solar and battery')}</Box>
        </>
      );
    } else if (solarProduction > gridImport && solarProduction > batteryDischarge) {
      copy = (
        <>
          <Heading size="md">{t('Widgets.Live Power.good job')}</Heading>
          <Box>{t('Widgets.Live Power.powered by clean energy')}</Box>
        </>
      );
    } else if (batteryDischarge > gridImport && batteryDischarge > solarProduction) {
      copy = (
        <>
          <Heading size="md">{t('Widgets.Live Power.good job')}</Heading>
          <Box>{t('Widgets.Live Power.powered by battery')}</Box>
        </>
      );
    }

    return (
      <>
        {copy}
        <Box data-testid="live-power-flow-link" color={linkColor}>
          {t('Widgets.Live Power.live power flow')}
        </Box>
      </>
    );
  }, [getSelfPoweredPercentage, isError]);

  const options = useMemo(
    () => ({
      chart: {
        type: 'pie',
        height: '200px',
        backgroundColor: 'transparent',
        plotShadow: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        verticalAlign: 'middle',
        floating: true,
        text: `<div style="color: ${textColor}">
               <div style="display: flex; justify-content: center; align-items: flex-end">
                   <span style="font-size: 20px">${getSelfPoweredPercentage()}</span>
                   <span style="font-size: 15px">%</span>
               </div>
               <div style="font-size: 12px">${t('Widgets.Live Power.self powered')}</div>
             </div>`,
        useHTML: true,
      },
      legend: {},
      plotOptions: {
        pie: {
          animation: false,
          innerSize: '95%',
          dataLabels: {
            enabled: false,
            connectorWidth: 0,
          },
          borderWidth: 6,
          borderColor: null,
          enableMouseTracking: false,
        },
      },
      series: [
        {
          data: buildDataSet(),
        },
      ],
    }),
    [buildDataSet, getSelfPoweredPercentage]
  );

  return (
    <DraggableSEHomeCard
      className="draggable-se-home-card"
      data-testid="live-energy-widget"
      data-testindex={index}
      key={item.id}
      dragId={item.id}
      style={{
        touchAction: isEditing ? 'none' : undefined, // Note: not supported by chakra style props
      }}
      gridColumnStart={1}
      gridColumnEnd={3}
      p={0}
      onClick={() => {
        if (isEditing) return;
        navigate(`../live_energy_flow`);
      }}
    >
      {isEditing && (
        <SimpleGrid columns={3} h={5}>
          <Center data-testid="drag-handle" mt={1} gridColumn={2}>
            <DragHandleIcon w={5} h={3} />
          </Center>{' '}
          <Box mt={1} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} pr={1} gridColumn={3}>
            <CloseIcon
              data-testid={`live-energy-widget-remove-btn`}
              mr={1}
              as={'button'}
              onClick={() => onRemoveGridItem(item.id)}
              w={3}
              h={3}
            />
          </Box>
        </SimpleGrid>
      )}
      <SimpleGrid columns={2} minH={'200px'}>
        <Center position={'relative'}>
          <Box position={'absolute'} width="100%">
            {isLoading ? (
              <SkeletonCircle isLoaded={!isLoading} size="125px" m="auto" />
            ) : (
              <Chart highcharts={Highcharts} options={options} />
            )}
          </Box>
        </Center>

        <Skeleton isLoaded={!isLoading} mx={3} my="auto">
          {getPoweredCopy()}
        </Skeleton>
      </SimpleGrid>

      {isMeterError && error === 'WEBSOCKET_ERROR' && (
        <Box pb={2} px={3}>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{t('Widgets.Live Power.unable to connect to sem')}</AlertTitle>
            <AlertDescription>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  invalidateMeterQueryData();
                }}
                variant={'ghost'}
                data-testid="retry-connection"
                color={linkColor}
              >
                {t('Widgets.Live Power.retry')}
              </Button>
            </AlertDescription>
          </Alert>
        </Box>
      )}
    </DraggableSEHomeCard>
  );
}
