import React, { JSX, useEffect, useMemo } from 'react';
import { selectUser } from '../user/userSlice';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Center, Grid, useColorModeValue } from '@chakra-ui/react';
import { fetchSite, selectInverters, selectMeters, selectSite } from './siteSlice';
import { useReduxDispatch } from '../../app/store';
import SideNav from '../navigation/SideNav';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { useViewportType } from '../../common/hooks/use-viewport-type';
import { BOTTOM_NAV_HEIGHT, IS_ANDROID, IS_NATIVE, IS_PRODUCTION, SIDENAV_WIDTH } from '../../common/constants';
import BottomNav from 'clipsal-cortex-ui/src/components/bottom-nav/BottomNav';
import { useGetNotificationsQuery } from '../notifications/notificationsApi';
import useAppVisibility from 'clipsal-cortex-utils/src/hooks/use-app-visibility';
import { NOTIFICATION_POLLING_TIME_MS } from '../notifications/constants';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { useGetSiteConsentByTypeQuery } from './new-user-journey/consentApi';
import { useTranslation } from 'react-i18next';
import { NAV_LINKS } from './nav-links';
import { MANUFACTURER_ID_SATURN, MANUFACTURER_ID_SEM } from '../devices/devices-helper';

// @NOTE: Configure regex for URL path names which will display the bottom nav here.
//        This is configured at the Site-level to avoid re-defining the component in each component it's needed in.
//        View this as a "whitelist" of allowed routes for the bottom nav.
const MATCHING_PATHNAME_REGEX = /\/site\/\d+\/((home)|(activity)|(devices\/list)|(notifications\/list$)(?!.*\?.*))/;

export function Site() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const user = useSelector(selectUser);
  const site = useSelector(selectSite);
  const dispatch = useReduxDispatch();
  const { id } = useParams<{ id: string }>();
  const { isDesktopViewport } = useViewportType();
  const shouldShowSidebar = isDesktopViewport && user.isLoggedIn;
  const backgroundColor = useColorModeValue('#ffffff', '#111111');
  const { isError: isSiteConsentsError } = useGetSiteConsentByTypeQuery('SHARE_WITH_INSTALLER');
  const { t } = useTranslation();
  const shouldShowNav = useMemo(
    () => MATCHING_PATHNAME_REGEX.test(pathname) && !search.get('source'),
    [pathname, search]
  );
  const navLinks = useNavLinks(shouldShowNav);

  // @TODO: move colours to theme!
  const bottomNavProps = useColorModeValue(
    {
      itemColor: 'customGrey.500',
      selectedItemColor: 'primaryBranding.600',
      backgroundColor: '#F7F7F7',
      selectedBackgroundColor: '#FFFFFF',
    },
    {
      itemColor: '#CBCBCB',
      selectedItemColor: 'primaryBranding.200',
      backgroundColor: '#293133',
      selectedBackgroundColor: '#363E40',
    }
  );

  useEffect(() => {
    async function setStyle() {
      if (IS_NATIVE && IS_ANDROID) {
        await NavigationBar.setColor({ color: shouldShowNav ? bottomNavProps.backgroundColor : backgroundColor });
      }
    }

    setStyle();
  }, [shouldShowNav]);

  useEffect(() => {
    async function dispatchFetchSite() {
      await dispatch(fetchSite({ siteID: Number(id), isProduction: IS_PRODUCTION }));
    }

    // No site in the store - hydrate from API, select site with ID from params
    if (!site.site_id) {
      dispatchFetchSite();
    }
  }, [dispatch, id, site.site_id, user.isLoggedIn]);

  useEffect(() => {
    // redirect to consent error page if there are any api errors
    if (isSiteConsentsError) navigate(`/consent-error?siteId=${site.site_id}`);
  }, [isSiteConsentsError]);

  return !site.site_id ? (
    <CenteredLoader text={`${t('Common.loading')}...`} />
  ) : (
    <Grid templateColumns={shouldShowSidebar ? `${SIDENAV_WIDTH} 1fr` : '1fr'}>
      {shouldShowNav &&
        (shouldShowSidebar ? (
          <Box position={'fixed'} overflowY={'auto'} top={0} bottom={0} width={SIDENAV_WIDTH} zIndex={11}>
            <SideNav user={user} links={navLinks} />
          </Box>
        ) : (
          <BottomNav
            links={navLinks}
            navHeight={BOTTOM_NAV_HEIGHT}
            selectedChildren={
              <Box position={'absolute'} top={0} height={'3px'} w={'100%'} bg={'primaryBranding.600'} />
            }
            boxShadow="0px -4px 4px 0px rgba(0, 0, 0, 0.25)"
            {...bottomNavProps}
          />
        ))}
      <Box gridArea={shouldShowSidebar ? '2/2' : undefined}>
        <Box>
          <Outlet />
        </Box>
      </Box>
    </Grid>
  );
}

/**
 * Builds the links to display on the nav.
 *
 * @param shouldShowNav - Whether the bottom nav should be displayed.
 */
function useNavLinks(shouldShowNav: boolean) {
  const { t } = useTranslation();
  const { site_id: siteId } = useSelector(selectSite);
  const siteInverters = useSelector(selectInverters);
  const siteMeters = useSelector(selectMeters);
  const { pathname } = useLocation();
  const isAppVisible = useAppVisibility();
  // We poll notifications only when the bottom nav & app is visible.
  const { data: notifications } = useGetNotificationsQuery(
    { limit: 0, offset: 0, read: false, archived: false },
    { pollingInterval: isAppVisible && shouldShowNav ? NOTIFICATION_POLLING_TIME_MS : 0 }
  );

  return useMemo(() => {
    const siteHasSenseMeter = siteMeters.some((meter) => [MANUFACTURER_ID_SEM].includes(meter.manufacturer_id));
    const siteHasSaturnInverter = siteInverters.some((inverter) =>
      [MANUFACTURER_ID_SATURN].includes(inverter.manufacturer_id)
    );

    const links = NAV_LINKS.filter(
      ({ route }) => route !== '/notifications' || siteHasSenseMeter || siteHasSaturnInverter
    );

    const currentNavIndex = links.findIndex(({ route }) => pathname.includes(route));

    return links.map(({ route, ariaLabel, icon, activeIcon, ...other }, i) => {
      const direction = currentNavIndex > i ? 'back' : 'forward';
      const hasNotification = route.includes('/notifications') && notifications && notifications.count > 0;
      return {
        ...other,
        route: `/site/${siteId}${route}?direction=${direction}`,
        ariaLabel: t(`Nav Bar.${ariaLabel}`),
        icon: hasNotification ? NotificationIcon(notifications.count, icon) : icon,
        activeIcon: hasNotification ? NotificationIcon(notifications.count, activeIcon) : activeIcon,
      };
    });
  }, [siteInverters, siteMeters, pathname, notifications]);
}

function NotificationIcon(count: number, icon: JSX.Element) {
  return (
    <Box position={'relative'}>
      {React.cloneElement(icon!, { w: '30px', h: '30px' })}
      <Center
        data-testid="num-unread-notifications"
        fontSize={'9px'}
        borderRadius={'50%'}
        pos={'absolute'}
        top={'-3px'}
        right={'-4px'}
        bg={'#BA3430'}
        color={'white'}
        w={'16px'}
        h={'16px'}
      >
        {count >= 100 ? '99+' : count}
      </Center>
    </Box>
  );
}
