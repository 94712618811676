import { ChargerStatus } from 'clipsal-cortex-types/src/api/api-evcharger';
import chargerPreparingImg from '../../../assets/images/charger_preparing.svg';
import chargerPreparingImgLight from '../../../assets/images/charger_preparing_light.svg';
import chargerAvailableImg from '../../../assets/images/charger_available.svg';
import chargerAvailableImgLight from '../../../assets/images/charger_available_light.svg';
import chargingPausedImg from '../../../assets/images/charging_paused.svg';
import chargingPausedImgLight from '../../../assets/images/charging_paused_light.svg';
import chargerErrorImg from '../../../assets/images/charger_error.svg';
import chargerErrorImgLight from '../../../assets/images/charger_error_light.svg';
import chargerUnavailableImg from '../../../assets/images/charger_unavailable.svg';
import chargerUnavailableImgLight from '../../../assets/images/charger_unavailable_light.svg';

type ChargerConfiguration = {
  imgSrc: {
    light: string;
    dark: string;
  };
};

export const CHARGER_STATUS_UI_CONFIG: Record<ChargerStatus, ChargerConfiguration> = {
  Available: {
    imgSrc: {
      light: chargerAvailableImgLight,
      dark: chargerAvailableImg,
    },
  },
  Preparing: {
    imgSrc: {
      light: chargerPreparingImgLight,
      dark: chargerPreparingImg,
    },
  },
  Charging: {
    imgSrc: {
      light: chargingPausedImg,
      dark: chargingPausedImg,
    },
  },
  SuspendedEV: {
    imgSrc: {
      light: chargingPausedImgLight,
      dark: chargingPausedImg,
    },
  },
  SuspendedEVSE: {
    imgSrc: {
      light: chargingPausedImgLight,
      dark: chargingPausedImg,
    },
  },
  Finishing: {
    imgSrc: {
      light: chargingPausedImgLight,
      dark: chargingPausedImg,
    },
  },
  Unavailable_Disconnect: {
    imgSrc: {
      light: chargerUnavailableImgLight,
      dark: chargerUnavailableImg,
    },
  },
  Unavailable_Restart: {
    imgSrc: {
      light: chargerUnavailableImgLight,
      dark: chargerUnavailableImg,
    },
  },
  Unavailable_Charger: {
    imgSrc: {
      light: chargerUnavailableImgLight,
      dark: chargerUnavailableImg,
    },
  },
  Faulted: {
    imgSrc: {
      light: chargerErrorImgLight,
      dark: chargerErrorImg,
    },
  },
};

type ArcButtonStyle = {
  arcColor: {
    light: string;
    dark: string;
  };
  text: string;
};

export const ARC_BUTTON_UI_CONFIG: Record<
  Extract<ChargerStatus, 'Charging' | 'Faulted'> | 'wifiError',
  ArcButtonStyle
> = {
  Charging: {
    arcColor: {
      light: '#1772ED',
      dark: '#42B4E6',
    },
    text: 'Stop Charging',
  },
  Faulted: {
    arcColor: {
      light: '#FF5B44',
      dark: '#FF5B44',
    },
    text: 'Contact Support',
  },
  wifiError: {
    arcColor: {
      light: '#3DCD57',
      dark: '#3DCD57',
    },
    text: 'Configure Wi-Fi',
  },
};
