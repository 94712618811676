import { Amplify, API } from 'aws-amplify';
import { Browser } from '@capacitor/browser';
import { ENV_TYPE } from '../../env-type';
import { ENDPOINT, IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';
import { LocalForageStorage } from '../../common/classes/local-forage';
import { getRedirectURI } from '../../features/auth/auth-helpers';

/**
 * Configures the AWS Amplify end-point setup for this repo.
 *
 * Note that we need to separately configure the `API` class, as for some reason in this monorepo architecture it does
 * not correctly initialize itself.
 *
 * see: https://github.com/aws-amplify/amplify-js/issues/5756
 */
export function setupAPI() {
  const apiConfig = {
    endpoints: [
      {
        name: 'site',
        endpoint: ENDPOINT,
        region: 'ap-southeast-2',
      },
    ],
  };

  const authOpts: Record<string, any> = {
    region: 'ap-southeast-2',
    userPoolId: import.meta.env?.[`VITE_${ENV_TYPE}_USER_POOL_ID`],
    userPoolWebClientId: import.meta.env?.[`VITE_${ENV_TYPE}_CLIENT_ID`],
  };

  // Don't use indexedDB in Cypress tests since it causes flakes
  if (!IS_RUNNING_CYPRESS_TESTS) {
    authOpts.storage = new LocalForageStorage();
  }

  Amplify.configure({
    Auth: authOpts,
    API: apiConfig,
    oauth: {
      domain: `${ENV_TYPE === 'DEV' ? 'clippy-staging' : 'clippy-prod'}.auth.ap-southeast-2.amazoncognito.com`,
      // Uncomment this when working in preview environments
      // domain: `clippy-preview.auth.ap-southeast-2.amazoncognito.com`,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: getRedirectURI(true),
      redirectSignOut: getRedirectURI(true),
      responseType: 'code',
      urlOpener: async (url: string) => {
        await Browser.open({ url, windowName: '_self' });
      },
    },
  });
  API.configure(apiConfig);
}
