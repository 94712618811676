import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part You're going to style
  control: {
    height: 7,
    width: 7,
    borderColor: '#999999',
    _light: {
      background: 'unset',
      color: 'primaryBranding.200',
      '::before': { height: '80%', width: '80%' },
      _checked: {
        borderColor: 'primaryBranding.200',
      },
      _disabled: {
        opacity: 0.5,
        borderColor: 'customGrey.400',
        color: 'customGrey.400',
      },
    },
    _dark: {
      background: 'unset',
      color: 'primaryBranding.200',
      '::before': { height: '80%', width: '80%' },
      _checked: {
        borderColor: 'primaryBranding.200',
      },
      _disabled: {
        opacity: 0.5,
        borderColor: 'customGrey.400',
        color: 'customGrey.400',
      },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
