import React, { useMemo, useState } from 'react';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { Box, Button, Center, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import ArcButton from '../../../common/components/ArcButton';
import SeasonSelectForm, { ActiveDatePickerState } from './SeasonSelectForm';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslatedEmptySeasonsTemplate, useTranslatedTariffSeasonSchema } from './tariff-season-helpers';
import { useNavigate } from 'react-router-dom';
import { useTariffFormContext } from '../useTariffFormContext';
import { ValidationError } from 'yup';
import { MIN_TARIFF_PAGE_HEIGHT, TARIFF_TO_DEFAULT_FUNCTION_MAP } from '../tariff-constants';
import { useTranslation } from 'react-i18next';
import useParamBackUrl from '../useParamBackUrl';
import { selectSite } from '../../site/siteSlice';
import { useSelector } from 'react-redux';

export const EditTariffSeasons = () => {
  const { control, getValues, setValue } = useTariffFormContext();
  const site = useSelector(selectSite);
  const { id } = useWatch({ control });
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const EMPTY_SEASONS_TEMPLATE = useTranslatedEmptySeasonsTemplate();
  const tariffSeasonSchema = useTranslatedTariffSeasonSchema();
  // Ensures only one datepicker is active at a time
  const [activeDatePickerState, setActiveDatePickerState] = useState<ActiveDatePickerState>({
    seasonIndex: null,
    type: 'start',
  });

  const { fields, append } = useFieldArray({
    name: 'tariff.seasons',
    control,
  });

  const navigate = useNavigate();
  const paramBackUrl = useParamBackUrl();

  const backURL = useMemo(() => {
    // Otherwise, go back to tariff details page
    return paramBackUrl ?? `../../tariff-details?direction=back`;
  }, [paramBackUrl]);

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.manual energy rates set up')}
      includeTopNav
      px={4}
      backURL={backURL}
      data-testid="tariff-season"
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT}>
        <Text>{t('Energy Rates.define the seasons')}</Text>

        <Flex justify="space-between" mt={4}>
          <Text>{t('Energy Rates.seasons')}</Text>
          <Button
            variant={'link'}
            fontWeight={400}
            colorScheme={'schneiderSkyBlue'}
            onClick={() => {
              const { tariff } = getValues();
              const { seasons, rates, tariffType } = tariff;

              // Add season and index
              const newSeasonIndex = seasons.length + 1;
              append({ ...EMPTY_SEASONS_TEMPLATE[0], seasonIndex: newSeasonIndex });

              // add default rates for the season
              const getNewSeasonRates = TARIFF_TO_DEFAULT_FUNCTION_MAP[tariffType];
              setValue('tariff.rates', [...(rates || []), getNewSeasonRates(newSeasonIndex)], { shouldDirty: true });
            }}
            data-testid="add-season-button"
          >
            {t('Common.add')}
          </Button>
        </Flex>
        <Divider borderColor={'#C6C6C6'} />

        <Box mb={4} data-testid="season-list-container">
          {fields.map((field, index) => (
            <SeasonSelectForm
              key={`season-${field.id}`}
              {...{
                index,
                resetError: () => setError(''),
                activeDatePickerState,
                onUpdateActiveDatePickerState: (newState: ActiveDatePickerState) => setActiveDatePickerState(newState),
              }}
            />
          ))}
        </Box>

        <Center mt="auto" mb={10} flexDir={'column'}>
          {!!error && (
            <Text color="red.500" mb={2} data-testid="season-error">
              {error}
            </Text>
          )}
          <ArcButton
            arcColor="#3DCD58"
            minWidth="280px"
            py={6}
            onClick={() => {
              const { seasons, tariffType } = getValues().tariff;
              try {
                // this will fail for invaild data
                tariffSeasonSchema.validateSync(seasons);
                const redirectBackURL = paramBackUrl
                  ? `?backURL=/site/${site.site_id}/account/tariff/${id || 'new'}/select-season?backURL=${paramBackUrl}`
                  : '';
                navigate(`../${tariffType.toLowerCase()}/start${redirectBackURL}`);
              } catch (error) {
                const errorMessage = (error as ValidationError).message as string;
                if (errorMessage) setError(errorMessage);
              }
            }}
            data-testid="continue-button"
          >
            {t('Common.continue')}
          </ArcButton>
        </Center>
      </VStack>
    </SlidingAnimationPageBase>
  );
};
