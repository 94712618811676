import React, { useMemo } from 'react';
import { Flex, Text, Skeleton } from '@chakra-ui/react';
import { useGetSunriseSunsetQuery } from '../../site/sunriseSunsetApi';
import { utcToZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import { TbSunset2, TbSunHigh } from 'react-icons/tb';
import { getFormattedTime } from '../../account/settings/settings-helpers';

export default function SunriseSunset() {
  const { timezone } = useSelector(selectSite);
  const currentTime = utcToZonedTime(new Date(), timezone);
  const { data: sunTimes, isLoading } = useGetSunriseSunsetQuery(currentTime);
  const formattedTimes = useMemo(() => {
    if (sunTimes) {
      return {
        sunrise: getFormattedTime(new Date(sunTimes.sunrise)),
        sunset: getFormattedTime(new Date(sunTimes.sunset)),
      };
    }
  }, [sunTimes]);
  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex alignItems="center" data-testid="sunrise-sunset-container">
        <TbSunHigh size={20} />
        <Text ml={1} mr={2}>
          {formattedTimes?.sunrise}
        </Text>
        <TbSunset2 size={20} />
        <Text ml={1} mr={2}>
          {formattedTimes?.sunset}
        </Text>
      </Flex>
    </Skeleton>
  );
}
