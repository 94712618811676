import React from 'react';

/* eslint-disable max-len */
type HouseWithPowerPoleOutlineProps = {
  doorAndWindowsRef: React.RefObject<SVGGElement>;
  powerPoleRef: React.RefObject<SVGGElement>;
  housePowerBoxRef: React.RefObject<SVGGElement>;
};
export function HouseWithPowerPoleOutline({
  doorAndWindowsRef,
  powerPoleRef,
  housePowerBoxRef,
}: HouseWithPowerPoleOutlineProps) {
  return (
    <svg data-testid="house-with-power-pole-outline-icon">
      <g id="house">
        <g id="lights">
          <path
            d="M120.029,190.049 C119.963,190.049 120.029,190.094 120.029,190.149 V199.98 H124.474 V189.98 C124.474,189.98 120.111,189.98 120.029,190.049 "
            fillOpacity="0.7"
            fill="#F2C94C"
          />
          <path
            d="M126.293,190.049 C126.227,190.049 126.293,190.094 126.293,190.149 V199.98 H130.737 V189.98 C130.737,189.98 126.374,189.98 126.293,190.049 "
            fillOpacity="0.7"
            fill="#F2C94C"
          />
          <path
            d="M132.555,190.049 C132.489,190.049 132.555,190.094 132.555,190.149 V199.98 H136.999 V189.98 C136.999,189.98 132.636,189.98 132.555,190.049 "
            fillOpacity="0.7"
            fill="#F2C94C"
          />
          <path
            d="M88.5,141.791 C93.4708,141.791 97.5,145.82 97.5,150.791 C97.5,155.762 93.4708,159.791 88.5,159.791 C83.5292,159.791 79.5,155.762 79.5,150.791 C79.5,145.82 83.5292,141.791 88.5,141.791 "
            fillRule="evenodd"
            fillOpacity="0.65"
            fill="#F2C94C"
            clipRule="evenodd"
          />
          <path
            d="M46.0785,187.029 C45.9019,187.029 46.0785,187.066 46.0785,187.11 V195.005 H58 V186.975 C58,186.975 46.2967,186.975 46.0785,187.029 "
            fillOpacity="0.7"
            fill="#F2C94C"
          />
          <path
            d="M46.0785,198.235 C45.9019,198.235 46.0785,198.38 46.0785,198.557 V230.137 H58 V198.016 C58,198.016 46.2967,198.016 46.0785,198.235 "
            fillOpacity="0.7"
            fill="#F2C94C"
          />
          <path
            d="M68.0785,198.235 C67.9019,198.235 68.0785,198.38 68.0785,198.557 V230.137 H80 V198.016 C80,198.016 68.2967,198.016 68.0785,198.235 "
            fillOpacity="0.7"
            fill="#F2C94C"
          />
          <path
            d="M68.0785,187.029 C67.9019,187.029 68.0785,187.066 68.0785,187.11 V195.005 H80 V186.975 C80,186.975 68.2967,186.975 68.0785,187.029 "
            fillOpacity="0.7"
            fill="#F2C94C"
          />
        </g>
        <g key="door-and-windows" ref={doorAndWindowsRef}>
          <path d="M140.742,184.939 H116.09 V240.769 H140.742 V184.939 " stroke="#9FA0A4" data-testid="house-door" />
          <path
            d="M88.5,138.791 C95.1288,138.791 100.5,144.162 100.5,150.791 C100.5,157.42 95.1288,162.791 88.5,162.791 C81.8712,162.791 76.5,157.42 76.5,150.791 C76.5,144.162 81.8712,138.791 88.5,138.791 "
            stroke="#9FA0A4"
            data-testid="window-1"
          />
          <path d="M83.1066,184.01 H64.877 V232.78 H83.1066 V184.01 " stroke="#9FA0A4" data-testid="window-2" />
          <path d="M61.132,184.01 H42.9023 V232.78 H61.132 V184.01 " stroke="#9FA0A4" data-testid="window-3" />
        </g>
        <g id="porch">
          <path d="M115.752,248.291 H141.095 " stroke="#9FA0A4" id="porch-1" />
          <path d="M114.668,255.926 H141.97 " stroke="#9FA0A4" id="porch-2" />
          <path d="M107.262,263.998 L111.287,240.76 L145.439,240.788 L149.47,264.05 " stroke="#9FA0A4" id="porch-3" />
        </g>
        <g id="roof">
          <path
            d="M102.872,253.79 H23.502 V179.195 M23.502,174.146 L88.6844,124.84 L122.523,150.433 L85.3102,178.369 L89.5408,184.008 L102.872,174.14 M111.293,240.764 H145.446 V182.69 C132.469,174.983 121.233,175.538 111.293,182.69 V240.764 "
            stroke="#9FA0A4"
            id="roof-and-wall"
          />
          <path
            d="M153.86,174.134 L166.906,183.721 L171.136,178.082 L116.973,137.391 H155.613 H186.674 "
            stroke="#9FA0A4"
            id="roof-1"
          />
          <path d="M263,137.391 H286.533 L300.92,193.5 H153.861 " stroke="#9FA0A4" id="roof-2" />
          <g id="details">
            <path d="M149.785,171.002 V179.191 H157.938 V177.351 " stroke="#9FA0A4" id="roof-detail-1" />
            <path d="M106.948,171.002 V179.191 H98.7891 V177.351 " stroke="#9FA0A4" id="roof-detail-2" />
            <path d="M124.287,157.972 V161.605 H132.446 V157.938 " stroke="#9FA0A4" id="roof-detail-3" />
            <path d="M84.6074,127.699 V131.337 H92.7602 V127.67 " stroke="#9FA0A4" id="roof-detail-4" />
            <path d="M27.5843,171.002 V179.191 H19.4258 V177.351 " stroke="#9FA0A4" id="roof-detail-5" />
          </g>
          <path
            d="M128.364,146.011 L88.6844,116 L5.08398,179.231 L9.32024,184.87 L88.6844,124.837 L122.523,150.435 L128.364,146 "
            stroke="#9FA0A4"
            id="roof-3"
          />
        </g>

        <path
          d="M153.855,178.723 L153.74,253.78 H147.295 M102.871,174.132 L128.369,154.85 L153.866,174.132 M104.784,253.78 H102.871 V179.376 M111.292,240.76 H145.445 V182.688 C132.468,174.981 121.232,175.536 111.292,182.688 V240.76 "
          stroke="#9FA0A4"
          id="wall-1"
        />
        <line stroke="#9FA0A4" y1="193.75" x1="290" strokeWidth="1" y2="253" x2="290" id="wall-2" />

        <g id="foundation">
          <line stroke="#9FA0A4" y1="253.75" x1="345" strokeWidth="1" y2="253.75" x2="247" id="foundation-1" />
          <line stroke="#9FA0A4" y1="253.75" x1="154" strokeWidth="1" y2="253.75" x2="169" id="foundation-2" />
          <line stroke="#9FA0A4" y1="263.5" x1="344.5" strokeWidth="1" y2="253.25" x2="344.5" id="foundation-3" />
          <path d="M20.8984,253.789 H109.032 L107.262,263.963 H20.8984 V253.789 " stroke="#9FA0A4" id="foundation-4" />
          <path d="M348,264 H9 V268.836 H348 V264 " stroke="#9FA0A4" id="foundation-5" />
        </g>
      </g>

      <g id="connections">
        <line id="main-line" stroke="#94b2de" y1="203" x1="284" strokeWidth="1" y2="203" x2="154" />
        <path
          id="curved-line-to-house"
          d="M332,171 L332,176 C326,179 317,180 308,180 C301,180.5 283.75,179.69 286,178.039 "
          strokeWidth="1"
          strokeLinejoin="round"
          stroke="#94b2de"
        />
        <path id="roof-to-house-line" d="M283.75 186.5, L283.75 203" stroke="#94b2de" strokeWidth="1" />
      </g>

      <g key="house-power-box" ref={housePowerBoxRef}>
        <path
          d="M285.8,173.539 H281.6 V186.039 H285.8 V173.539 "
          stroke="#9FA0A4"
          data-testid="house-power-box-icon"
          fill="#9FA0A4"
        />
      </g>

      <g id="power-pole">
        <g key="pole-box" ref={powerPoleRef}>
          <path d="M336.58,157.77 H338.48" stroke="#9FA0A4" data-testid="power-pole-box-line-1" />
          <path d="M336.58,160.76 H338.48" stroke="#9FA0A4" data-testid="power-pole-box-line-2" />
          <path d="M329,153.529 H334.56" stroke="#9FA0A4" data-testid="power-pole-box-line-3" />
          <path
            d="M336.59,150.539 H327.02 V171.039 H336.59 V150.539"
            stroke="#9FA0A4"
            data-testid="power-pole-box-line-4"
          />
        </g>

        <path
          d="M338.02,136.78 V135.82 H337.08 C336.95,135.82 336.83,135.77 336.74,135.68 C336.65,135.59 336.6,135.47 336.6,135.34 V134.99 C336.6,134.86 336.65,134.74 336.74,134.65 C336.83,134.56 336.95,134.51 337.08,134.51 H338.02 V133.85 H337.08 C336.95,133.85 336.83,133.8 336.74,133.71 C336.65,133.62 336.6,133.5 336.6,133.37 V133.02 C336.6,132.89 336.65,132.77 336.74,132.68 C336.83,132.59 336.95,132.54 337.08,132.54 H338.02 V132 H339.13 V132.54 H340.07 C340.2,132.54 340.32,132.59 340.41,132.68 C340.5,132.77 340.55,132.89 340.55,133.02 V133.37 C340.55,133.5 340.5,133.62 340.41,133.71 C340.32,133.8 340.19,133.85 340.07,133.85 H339.13 V134.51 H340.07 C340.2,134.51 340.32,134.56 340.41,134.65 C340.5,134.74 340.55,134.86 340.55,134.99 V135.34 C340.55,135.47 340.5,135.59 340.41,135.68 C340.32,135.77 340.19,135.82 340.07,135.82 H339.13 V136.78 "
          fill="#9FA0A4"
        />
        <path d="M334,150 V137.211 H342.719 V253.5 H334.719 V171" stroke="#9FA0A4" />
      </g>
    </svg>
  );
}

type SolarPanelProps = {
  solarPanelRef: React.RefObject<SVGGElement>;
};
export function SolarPanel({ solarPanelRef }: SolarPanelProps) {
  return (
    <svg data-testid="solar-panel-icon" id="solar-panel">
      <path d="M224.9,181.209 V203" id="connection" stroke="#A4C0E4" />
      <g key="solar-panel-lines" ref={solarPanelRef}>
        <path
          d="M192.526,180.75 C189.306,180.75 177.5,180.75 177.5,180.75 L188.949,127.75 H261.051 L272.5,180.75 C272.5,180.75 260.14,180.75 259.413,180.75"
          stroke="#9FA0A4"
          data-testid="solar-panel-line-1"
        />
        <path d="M227.463,145.111 H255.036" stroke="#9FA0A4" data-testid="solar-panel-line-2" />
        <path d="M194.578,145.111 H227.908" stroke="#9FA0A4" data-testid="solar-panel-line-3" />
        <path d="M192.365,158.758 H257.251" stroke="#9FA0A4" data-testid="solar-panel-line-4" />
        <path d="M238.671,171.956 L235.156,135.217" stroke="#9FA0A4" data-testid="solar-panel-line-5" />
        <path d="M214.13,135.217 L210.947,171.956" stroke="#9FA0A4" data-testid="solar-panel-line-6" />
        <path d="M259.554,180.738 H192.166" stroke="#9FA0A4" data-testid="solar-panel-line-7" />
        <path
          d="M266.137,175.981 L257.233,132.055 H192.541 L183.637,175.981 H266.137"
          stroke="#9FA0A4"
          data-testid="solar-panel-line-8"
        />
      </g>
    </svg>
  );
}

export function Roof() {
  return (
    <line
      data-testid="roof-icon"
      id="roof"
      stroke="#9FA0A4"
      y1="137.4"
      strokeWidth="1"
      x1="263"
      y2="137.4"
      x2="186.5"
    />
  );
}

export function HouseFoundation() {
  return (
    <line
      id="house-foundation"
      data-testid="house-foundation-icon"
      stroke="#9FA0A4"
      y1="253.75"
      strokeWidth="1"
      x1="169"
      y2="253.75"
      x2="247"
    />
  );
}

type EVGarageProps = {
  EVRef: React.RefObject<SVGGElement>;
};
export function EVGarage({ EVRef }: EVGarageProps) {
  return (
    <g data-testid="ev-garage-icon" id="ev-garage">
      <g id="car" ref={EVRef}>
        <path
          d="M229.164,233.27 C227.834,234.863 226.021,234.863 226.021,234.863 H194.978 C194.978,234.863 193.159,234.863 191.834,233.27"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-1"
        />
        <path
          d="M237.497,252.381 C236.497,255.085 233.479,254.821 233.479,254.821 H187.52 C187.52,254.821 184.502,255.085 183.502,252.381"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-2"
        />
        <path d="M210.486,242.915 H198.119 L194.816,240.359 L187.27,237.369" strokeWidth="1.25" stroke="#9FA0A4" />
        <path
          d="M229.289,233.407 C229.427,232.638 230.262,232.572 230.262,232.572 L233.466,232.215 C233.747,232.182 233.955,231.951 233.955,231.671 V230.192 C233.955,229.89 233.708,229.643 233.406,229.643 H230.191 C229.888,229.643 229.641,229.89 229.641,230.192 V231.324 C229.641,231.324 229.751,232.05 228.492,232.33"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-3"
        />
        <path d="M210.512,242.915 H222.884 L226.187,240.359 L233.728,237.369" strokeWidth="1.25" stroke="#9FA0A4" />
        <path
          d="M210.511,244.012 C212.446,244.012 214.012,243.512 214.012,242.891 C214.012,242.27 212.435,241.77 210.5,241.77 C208.566,241.77 206.988,242.27 206.988,242.891 C206.988,243.512 208.555,244.012 210.489,244.012 H210.511"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-4"
        />
        <path
          d="M191.707,233.407 C191.57,232.638 190.734,232.572 190.734,232.572 L187.53,232.215 C187.25,232.182 187.041,231.951 187.041,231.671 V230.192 C187.041,229.89 187.288,229.643 187.591,229.643 H190.806 C191.108,229.643 191.356,229.89 191.356,230.192 V231.324 C191.356,231.324 191.246,232.05 192.504,232.33"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-5"
        />
        <path
          d="M227.071,255.046 V257.47 C227.071,258.382 227.808,259.119 228.72,259.119 H235.849 C236.761,259.119 237.497,258.382 237.497,257.47 V243.581 C237.497,243.581 237.739,240.223 233.485,237.189 C233.485,237.189 230.495,234.693 229.292,233.407 C227.973,232 226.241,227.878 226.241,227.878 C226.241,227.878 224.944,223.871 220.096,224.003 H200.909 C196.061,223.871 194.764,227.878 194.764,227.878 C194.764,227.878 193.027,231.995 191.714,233.407 C190.51,234.693 187.52,237.189 187.52,237.189 C183.261,240.223 183.503,243.581 183.503,243.581 V257.47 C183.503,258.382 184.239,259.119 185.151,259.119 H192.28 C193.192,259.119 193.929,258.382 193.929,257.47 V255.046"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-6"
        />
        <path
          d="M225.279,254.514 V253.035 C225.279,253.035 225.416,251.887 223.536,251.265 C222.289,250.853 219.31,249.743 210.505,249.754 C201.694,249.743 198.721,250.853 197.473,251.265 C195.588,251.887 195.731,253.035 195.731,253.035 V254.514"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-7"
        />
        <path
          d="M195.726,241.061 L193.939,242.918 H186.8 C186.8,242.918 185.47,242.962 183.975,241.368"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-8"
        />
        <path
          stroke="#9FA0A4"
          strokeWidth="1.25"
          d="M179.649,245.328 C178.136,245.328 176.909,246.555 176.909,248.068 C176.909,249.581 178.136,250.809 179.649,250.809 L183.4,250.809 L183.4,245.333 L179.649,245.333 L179.649,245.328"
          data-testid="ev-garage-line-9"
        />
        <path
          d="M225.279,241.061 L227.066,242.918 H234.2 C234.2,242.918 235.53,242.962 237.025,241.368"
          strokeWidth="1.25"
          stroke="#9FA0A4"
          data-testid="ev-garage-line-10"
        />
      </g>
      <polyline points="168.5,264 168.5,211.5 247,211.5 247,264" stroke="#8f8f8f" strokeWidth="1" id="garage-door" />
      <g id="line">
        <path d="M160,202.959 V248 C160,248 160,248 160,248 H176.5 " stroke="#A4C0E4" />
      </g>
    </g>
  );
}

export function MoonAnimation() {
  return (
    <svg data-testid="moon-animation">
      <path
        fill="#9FA0A4"
        d="m34.42 50.8-.66 3.464a.748.748 0 0 0 .611.87c.165.03.335.005.485-.068l3.219-1.597 3.172 1.682a.778.778 0 0 0 1.039-.316c.075-.14.103-.3.08-.455l-.562-3.482 2.62-2.426a.733.733 0 0 0 .027-1.053.769.769 0 0 0-.433-.223l-3.565-.554L38.9 43.46a.764.764 0 0 0-.678-.425.734.734 0 0 0-.69.406l-1.642 3.14-3.58.457a.75.75 0 0 0-.658.837.742.742 0 0 0 .216.429l2.551 2.495h.001Zm1.946-2.886a.817.817 0 0 0 .623-.43l1.201-2.297 1.138 2.329a.814.814 0 0 0 .61.445l2.61.406-1.918 1.775a.788.788 0 0 0-.246.705l.412 2.548-2.322-1.232a.838.838 0 0 0-.762-.01l-2.355 1.17.482-2.537a.79.79 0 0 0-.225-.71l-1.868-1.827 2.621-.336v.001Z"
      />
      <path
        fill="#9FA0A4"
        d="m46.036 29.5-.907 4.852a1.044 1.044 0 0 0 .842 1.216c.226.042.46.008.667-.096l4.425-2.236 4.362 2.356a1.06 1.06 0 0 0 1.428-.443 1.05 1.05 0 0 0 .111-.637l-.773-4.874 3.603-3.396a1.04 1.04 0 0 0 .039-1.475 1.052 1.052 0 0 0-.597-.312l-4.902-.777-2.136-4.454a1.05 1.05 0 0 0-.933-.595 1.007 1.007 0 0 0-.95.569l-2.257 4.394-4.922.642a1.045 1.045 0 0 0-.607 1.772l3.507 3.493v.002Zm2.283-4.349c.427-.055.799-.316.995-.697l1.917-3.734 1.817 3.785c.185.386.549.657.973.724l4.166.66-3.062 2.885a1.302 1.302 0 0 0-.392 1.144l.657 4.143-3.707-2.002a1.315 1.315 0 0 0-1.216-.016l-3.759 1.9.77-4.121a1.296 1.296 0 0 0-.36-1.156l-2.981-2.968 4.183-.546Z"
      >
        <animate attributeName="opacity" dur="4s" repeatCount="indefinite" values="0.25;1;1;0.25" />
      </path>
      <path
        fill="#9FA0A4"
        d="M55.195 50.835a.748.748 0 0 0-.943-.438 19.104 19.104 0 0 1-9.523.657c-.4-.075-.79.178-.868.563a.713.713 0 0 0 .587.834c1.248.231 2.512.347 3.774.347 2.213 0 4.419-.355 6.518-1.056a.703.703 0 0 0 .456-.905l-.001-.002Z"
      />
      <path
        fill="#9FA0A4"
        d="M64.495 41.93a.712.712 0 0 0-.829.335 17.67 17.67 0 0 1-2.895 3.822 17.648 17.648 0 0 1-3.915 2.967.717.717 0 0 0-.277.974c.19.347.625.47.97.279a19.1 19.1 0 0 0 5.602-4.738 22.713 22.713 0 0 1-6.134 11.356c-4.268 4.284-9.945 6.644-15.982 6.644-6.038 0-11.713-2.36-15.983-6.644a22.579 22.579 0 0 1-6.257-11.979A22.734 22.734 0 0 1 20.3 31.833a.717.717 0 0 0-.37-.942.711.711 0 0 0-.94.372 24.17 24.17 0 0 0-1.6 13.937 24.004 24.004 0 0 0 6.653 12.736c4.539 4.556 10.573 7.065 16.992 7.065 6.418 0 12.453-2.509 16.992-7.065A24.154 24.154 0 0 0 65 42.67a.718.718 0 0 0-.505-.738Z"
      />
      <path
        fill="#9FA0A4"
        d="M39.75 17.502a.708.708 0 0 0-.73-.5 23.845 23.845 0 0 0-15.08 6.893 23.837 23.837 0 0 0-3.583 4.564.705.705 0 0 0 .242.969.71.71 0 0 0 .972-.241 22.445 22.445 0 0 1 3.37-4.294 22.42 22.42 0 0 1 11.216-6.064 18.815 18.815 0 0 0-6.984 13.157c-.354 4.399.851 8.794 3.393 12.376a.706.706 0 0 0 .987.17.704.704 0 0 0 .169-.984 17.394 17.394 0 0 1-3.137-11.45 17.406 17.406 0 0 1 5.06-10.915 17.541 17.541 0 0 1 3.776-2.863.704.704 0 0 0 .33-.819Z"
      />
    </svg>
  );
}

type SunAnimationProps = {
  sunToSolarPanelLineRef?: React.RefObject<SVGPathElement>;
};
export function SunAnimation({ sunToSolarPanelLineRef }: SunAnimationProps) {
  return (
    <svg data-testid="sun-animation">
      <path
        ref={sunToSolarPanelLineRef}
        data-testid="sun-to-solar-panel-line"
        d="M41.75 72 L41.75 147"
        stroke="#FFCF44"
        strokeLinecap="round"
        display="none"
      />
      <circle cx="34" cy="20" r="1" fill="#FFCF44" />
      <path
        stroke="#FFCF44"
        d="M27.17 24.568c-5.047 4.229-8.263 10.573-8.277 17.674-.026 12.762 10.3 23.128 23.061 23.153 12.762.025 23.128-10.3 23.153-23.062.025-12.762-10.3-23.128-23.061-23.153-.495 0-.985.016-1.472.045"
      />
      <path stroke="#FFCF44" d="M50.98 57.537c4.998-2.542 8.425-7.728 8.437-13.72" />
      <g>
        <animate attributeName="opacity" dur="2s" repeatCount="indefinite" values="1;0;1" />
        <path
          stroke="#FFCF44"
          d="m71.313 42.28 11.668.022M1.02 42.139l11.668.023M62.685 62.988l8.235 8.267M13.08 13.185l8.234 8.268M41.942 71.534l-.023 11.668M42.08 1.239l-.022 11.668M21.232 62.904l-8.267 8.235M71.035 13.299l-8.267 8.234M69.03 53.563l5.004 2.048M9.965 28.828l5.004 2.049M53.13 69.34l2.052 5.002M28.819 10.099l2.053 5.003M30.895 69.347l-2.067 4.997M53.008 15.387l2.165-5.29M14.882 53.349l-5.003 2.053M74.12 29.037l-5.003 2.053"
        />
      </g>
      <g transform="rotate(22.5 42 42)">
        <animate attributeName="opacity" dur="2s" repeatCount="indefinite" values="0;1;0" />
        <path
          stroke="#FFCF44"
          d="m71.313 42.28 11.668.022M1.02 42.139l11.668.023M62.685 62.988l8.235 8.267M13.08 13.185l8.234 8.268M41.942 71.534l-.023 11.668M42.08 1.239l-.022 11.668M21.232 62.904l-8.267 8.235M71.035 13.299l-8.267 8.234M69.03 53.563l5.004 2.048M9.965 28.828l5.004 2.049M53.13 69.34l2.052 5.002M28.819 10.099l2.053 5.003M30.895 69.347l-2.067 4.997M53.008 15.387l2.165-5.29M14.882 53.349l-5.003 2.053M74.12 29.037l-5.003 2.053"
        />
      </g>
    </svg>
  );
}

export function StormAnimation() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="72" fill="none" data-testid="storm-animation">
      <path
        stroke="#9FA0A4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M53.856 37.033h20.986c4.103 0 7.47-3.298 7.5-7.401v-.087c0-5.262-3.553-9.657-8.293-10.719.492-3.222-.702-6.614-3.382-8.7-3.249-2.53-7.638-2.335-10.654.204C56.67 4.729 50.715 1 43.925 1c-9.76 0-17.794 7.702-18.826 17.598a10.484 10.484 0 0 0-.598-.018c-5.806 0-10.513 4.91-10.513 10.965v.087c.031 4.103 3.397 7.4 7.501 7.4h14.725"
      />
      <g>
        <animate attributeName="opacity" dur="1s" repeatCount="indefinite" values="0.5;1;0.5" />
        <circle
          cx="8.736"
          cy="49.432"
          r=".625"
          fill="#9FA0A4"
          fillOpacity=".25"
          transform="rotate(38.42 8.736 49.432)"
        />
        <circle
          cx="14.418"
          cy="51.727"
          r=".625"
          fill="#9FA0A4"
          fillOpacity=".25"
          transform="rotate(-141.58 14.418 51.727)"
        />
        <path fill="#9FA0A4" fillOpacity=".25" d="m14.068 42.654-3.723 4.705 3.723-4.705Z" />
        <circle
          cx="24.134"
          cy="49.336"
          r=".625"
          fill="#9FA0A4"
          fillOpacity=".25"
          transform="rotate(38.42 24.134 49.336)"
        />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity=".25"
          strokeWidth="1.2"
          d="m29.467 42.558-3.724 4.705M9.086 58.505l3.723-4.705"
        />
        <circle
          cx="61.738"
          cy="49.432"
          r=".625"
          fill="#9FA0A4"
          fillOpacity=".25"
          transform="rotate(38.42 61.738 49.432)"
        />
        <circle
          cx="67.42"
          cy="51.727"
          r=".625"
          fill="#9FA0A4"
          fillOpacity=".25"
          transform="rotate(-141.58 67.42 51.727)"
        />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity=".25"
          strokeWidth="1.2"
          d="m67.07 42.654-3.723 4.705"
        />
        <circle
          cx="77.136"
          cy="49.336"
          r=".625"
          fill="#9FA0A4"
          fillOpacity=".25"
          transform="rotate(38.42 77.136 49.336)"
        />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity=".25"
          strokeWidth="1.2"
          d="m82.469 42.558-3.724 4.705M62.088 58.505 65.81 53.8M14.068 42.654l-3.723 4.705"
        />
      </g>
      <g>
        <animate attributeName="opacity" dur="1s" repeatCount="indefinite" values="1;0.5;1" />
        <circle cx="1.877" cy="58.042" r=".625" fill="#9FA0A4" transform="rotate(38.42 1.877 58.042)" />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="m7.21 51.264-3.723 4.705"
        />
        <circle cx="17.275" cy="57.945" r=".625" fill="#9FA0A4" transform="rotate(38.42 17.275 57.945)" />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="m22.61 51.167-3.724 4.705"
        />
        <circle cx="21.428" cy="42.928" r=".625" fill="#9FA0A4" transform="rotate(-141.58 21.428 42.928)" />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="m16.096 49.706 3.723-4.705"
        />
        <circle cx="54.879" cy="58.042" r=".625" fill="#9FA0A4" transform="rotate(38.42 54.879 58.042)" />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="m60.213 51.264-3.724 4.705"
        />
        <circle cx="70.277" cy="57.945" r=".625" fill="#9FA0A4" transform="rotate(38.42 70.277 57.945)" />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="m75.611 51.167-3.723 4.705"
        />
        <circle cx="74.43" cy="42.928" r=".625" fill="#9FA0A4" transform="rotate(-141.58 74.43 42.928)" />
        <path
          stroke="#9FA0A4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="m69.098 49.706 3.723-4.705"
        />
      </g>
      <g>
        <animate
          attributeName="stroke"
          dur="2s"
          repeatCount="indefinite"
          values="rgb(159, 160, 164);rgb(242, 201, 76);rgb(159, 160, 164)"
        />
        <path d="M30.988 50.025 53.898 21 46.26 42.186h12.727L35.868 71l7.635-20.975H30.988Z" />
      </g>
    </svg>
  );
}

type BatteryProps = {
  percentageFilled: number;
  batteryRef: React.RefObject<SVGGElement>;
};

export function BatteryIcon({ percentageFilled, batteryRef }: BatteryProps) {
  const inverseFill = Math.round(100 - percentageFilled * 0.8);
  return (
    <svg data-testid="battery-icon" id="battery-with-line" transform="translate(260, 119)">
      <g transform="translate(260, 119)">
        <path d="M9,95 V84" stroke="#94b2de" />
        <svg viewBox="0 0 349 270" width="103">
          <g ref={batteryRef}>
            <path
              d={`M54.0349 ${inverseFill}H8.96507C8.43208 ${inverseFill} 8 ${inverseFill}.3304 8 ${inverseFill}.7379V107.262C8 107.67 8.43208 108 8.96507 108H54.0349 C54.5679 108 55 107.67 55 107.262 C55 ${inverseFill}.3304 55 ${inverseFill} 54.0349 ${inverseFill}Z`}
              fill="#42B4E6"
              stroke="#42B4E6"
              data-testid="battery-icon-line-1"
            />
            <path
              d="M62.8654 16.5814C62.8654 13.0979 60.0322 10.2647 56.5487 10.2647H45.7034V6.96697C45.7034 3.11191 42.5915 0 38.7365 0H24.1058C20.2508 0 17.1389 3.11191 17.1389 6.96697V10.2647H6.34008C2.83338 10.2414 0.000141754 13.0747 0.000141754 16.5814V109.683C-0.0230815 113.167 2.81015 116 6.29364 116H56.5255C60.009 116 62.8422 113.167 62.8422 109.683V16.5814H62.8654ZM59.3355 109.707C59.3355 111.262 58.0814 112.517 56.5255 112.517H6.29364C4.73768 112.517 3.48363 111.262 3.48363 109.707V16.5814C3.50685 15.0254 4.7609 13.7714 6.31686 13.7714H18.9503C19.9024 13.7714 20.692 12.9818 20.692 12.0296V6.8973C20.692 5.03944 22.2015 3.52993 24.0594 3.52993H38.69C40.5479 3.52993 42.0574 5.03944 42.0574 6.8973V12.0296C42.0574 12.9818 42.847 13.7714 43.7991 13.7714H56.5255C58.0814 13.7714 59.3355 15.0254 59.3355 16.5814V109.683V109.707Z"
              fill="#9FA0A4"
              stroke="#9FA0A4"
              data-testid="battery-icon-line-2"
            />
          </g>
        </svg>
      </g>
    </svg>
  );
}

type GeneratorProps = {
  generatorRef: React.RefObject<SVGGElement>;
};
export function GeneratorIcon({ generatorRef }: GeneratorProps) {
  return (
    <g id="generator-with-connection">
      <path d="M297.5,240.599 L283.762,240.599 L283.762,203.699 " strokeWidth="1.5" stroke="#A4C0E4" />
      <g id="generator" ref={generatorRef}>
        <path
          d="M305.898,240.58 C305.898,244.48 309.032,247.66 312.898,247.66 C316.764,247.66 319.898,244.469 319.898,240.58 C319.898,236.691 316.764,233.5 312.898,233.5 C309.032,233.5 305.898,236.691 305.898,240.58 M306.585,240.58 C306.585,237.057 309.41,234.209 312.887,234.209 C316.364,234.209 319.189,237.069 319.189,240.58 C319.189,244.092 316.364,246.951 312.887,246.951 C309.41,246.951 306.585,244.092 306.585,240.58"
          strokeWidth="0.75"
          stroke="#9FA0A4"
        />
        <path
          d="M316.222,239.39 H312.358 L312.881,235.422 L309.285,241.643 H313.06 L312.642,245.716 L316.208,239.405 L316.222,239.39 "
          strokeWidth="1"
          stroke="#9FA0A4"
        />
        <path
          d="M300.49,247.3 C299.11,247.3 298,246.17 298,244.8 V236.62 C298,235.25 299.11,234.12 300.48,234.12 L300.46,230.23 C300.46,229.6 300.97,229.09 301.6,229.09 H324.27 C324.9,229.09 325.41,229.6 325.41,230.23 V248.75 C325.41,249.38 324.9,249.89 324.27,249.89 H301.6 C300.97,249.89 300.46,249.38 300.46,248.75 L300.49,247.3"
          stroke="#9FA0A4"
        />
        <path d="M300.48,234.51 V248.73" stroke="#9FA0A4" strokeLinecap="round" />
        <path d="M303.51,231.73 H322.41" stroke="#9FA0A4" strokeLinecap="round" />
        <path
          d="M322.79,228.85 V225.44 C322.79,225.2 322.6,225 322.36,225 H319.26 C319.02,225 318.83,225.2 318.83,225.44 V228.84 H319.66 V225.82 H321.97 V228.84 H322.79 V228.85"
          strokeWidth="0.7"
          stroke="#9FA0A4"
        />
      </g>
    </g>
  );
}
/* eslint-enable max-len */
