import React from 'react';
import { ComponentWithAs, IconProps, Flex, useColorModeValue } from '@chakra-ui/react';
import Select, { components, OptionProps, SingleValueProps, ActionMeta } from 'react-select';

type CommonProps = {
  options: SelectOption[];
  isSearchable?: boolean;
  placeholder?: string;
  backgroundColor?: string;
};

export interface SelectOption {
  label: string;
  value: string;
  icon?: ComponentWithAs<'svg', IconProps>; // Optional icon in selected and dropdown options
}

export const CustomSelect = React.forwardRef<
  any,
  {
    // The following 2 properties are typed as optional because they're injected automatically by react-select
    value?: SelectOption;
    onChange?: (value: SelectOption | null, actionMeta: ActionMeta<SelectOption>) => void;
  } & CommonProps
>(({ onChange, value, options, isSearchable, placeholder, backgroundColor, ...props }, ref) => {
  const textColor = useColorModeValue('#111111', '#EEEEEE');
  backgroundColor = useColorModeValue(backgroundColor ?? '#F1F1F1', backgroundColor ?? '#363E40');

  return (
    <Select
      {...props}
      ref={ref}
      isSearchable={isSearchable}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      options={options}
      components={{
        Option: CustomOption,
        IndicatorSeparator: () => null,
        SingleValue: CustomSingleValue,
      }}
      styles={{
        option: (base) => ({
          ...base,
          color: textColor,
          backgroundColor,
        }),
        menu: (base) => ({
          ...base,
          backgroundColor,
          color: textColor,
          zIndex: 10,
          overflowY: 'scroll',
          marginBottom: '75px',
        }),
        control: (base) => ({
          ...base,
          height: '50px',
          color: textColor,
          backgroundColor,
          borderRadius: 2,
          border: '1px solid #9FA0A4',
          boxShadow: 'none',
        }),
        singleValue: (base) => ({
          ...base,
          color: textColor,
          paddingLeft: '5px',
        }),
        placeholder: (base) => ({ ...base, color: textColor }),
        input: (base) => ({
          ...base,
          color: textColor,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: textColor,
        }),
      }}
    />
  );
});

CustomSelect.displayName = 'CustomSelect';

const CustomOption = (
  props: OptionProps<{ label: string; value: string; icon?: ComponentWithAs<'svg', IconProps> }, false>
) => {
  const Icon = props.data?.icon;
  return (
    <Flex alignItems={'center'} my={2} data-testid={`${props.data.value.replaceAll('_', '-')}-custom-option`}>
      {Icon && <Icon boxSize={9} mx={3} />}
      <components.Option {...props} />
    </Flex>
  );
};

const CustomSingleValue = (
  props: SingleValueProps<{ label: string; value: string; icon?: ComponentWithAs<'svg', IconProps> }>
) => {
  const Icon = props.data?.icon;
  return (
    <Flex alignItems={'center'} data-testid={`${props.data.value.replaceAll('_', '-')}-custom-single-value`}>
      {Icon && <Icon boxSize={9} mx={3} />}
      <Flex>
        <components.SingleValue {...props} />
      </Flex>
    </Flex>
  );
};
