import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Skeleton,
  PopoverTrigger,
  useDisclosure,
  useToast,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import React from 'react';
import SettingSwitch from '../SettingSwitch';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../user/userSlice';
import {
  useCreateSiteConsentMutation,
  useGetSiteConsentByTypeQuery,
  useUpdateSiteConsentMutation,
  CreateSiteConsent,
} from '../../../site/new-user-journey/consentApi';
import { selectSite } from '../../../site/siteSlice';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { useTranslation } from 'react-i18next';

export default function InstallerConsentToggle() {
  const { site_id: siteId } = useSelector(selectSite);
  const user = useSelector(selectUser);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onCloseAlert } = useDisclosure();
  const [updateSiteConsent] = useUpdateSiteConsentMutation();
  const [createSiteConsent] = useCreateSiteConsentMutation();
  const { data: shareDataWithInstallerConsent, isLoading } = useGetSiteConsentByTypeQuery('SHARE_WITH_INSTALLER');
  const toast = useToast({ isClosable: true });
  const isLoaded = !isLoading;
  const bgColor = useColorModeValue('#fff', 'customGrey.700');
  const { t } = useTranslation();

  const handleInstallerConsentToggle = async (hasPermission: boolean) => {
    if (!hasPermission && !isAlertOpen) {
      return onAlertOpen();
    }
    try {
      if (shareDataWithInstallerConsent) {
        await updateSiteConsent({
          siteId,
          consentId: shareDataWithInstallerConsent.id,
          body: { consent_type: 'SHARE_WITH_INSTALLER', permission: hasPermission },
        }).unwrap();
      } else {
        // If for some reason the onboarding flow to create a site consent was bypassed (and therefore there isn't a -
        // - site consent object for this site), we create it here
        const body: CreateSiteConsent = {
          permission: hasPermission,
          consent_type: 'SHARE_WITH_INSTALLER',
        };
        await createSiteConsent({ siteId, body }).unwrap();
      }
    } catch (error) {
      console.error(error);
      toast({
        title: t('Installer Consent.error saving'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  };

  return (
    <>
      <Skeleton isLoaded={isLoaded} borderRadius="5px" borderBottomRightRadius="25px">
        <SettingSwitch
          isDisabled={user.role !== 'SYSTEM_OWNER'} // Installer consent is API restricted to system owners
          onChange={handleInstallerConsentToggle}
          isChecked={shareDataWithInstallerConsent?.permission || false}
          data-testid="share-my-data-with-installers-switch"
          alignItems="center"
          text={t('Installer Consent.share my energy data')}
        >
          <Popover>
            <PopoverTrigger>
              <IconButton
                size={'lg'}
                aria-label={'More info'}
                variant={'ghost'}
                icon={<InfoOutlineIcon />}
                data-testid="installer-consent-info-popover-button"
              />
            </PopoverTrigger>
            <PopoverContent
              borderRadius={8}
              w="200px"
              border="none"
              bg={bgColor}
              data-testid="installer-consent-info-popover-content"
            >
              <PopoverArrow bg={bgColor} />
              <PopoverCloseButton />
              <PopoverBody borderRadius="14px" boxShadow="rgba(0, 0, 0, 0.3) 0px 0px 10px 0px">
                <Text>{t('Installer Consent.my energy data is shared')}</Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </SettingSwitch>
      </Skeleton>

      <AlertDialogModal
        isOpen={isAlertOpen}
        onClose={onCloseAlert}
        header={t('Common.are you sure?')}
        subHeader={t('Installer Consent.your installer will have no access')}
        confirmButtonName={t('Common.yes')}
        cancelButtonName={t('Common.no')}
        onConfirm={() => handleInstallerConsentToggle(false)}
      />
    </>
  );
}
