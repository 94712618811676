import { Center, Skeleton, useColorModeValue, Image, Text, Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import DeviceListItem from './DeviceListItem';
import { SortByOptions } from './SortByPopover';
import { getIconForDevice } from './devices-helper';
import { orderBy } from 'lodash';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { useGetCostsQuery } from '../site/costApi';
import { useSelector } from 'react-redux';
import { selectCurrentDayForSite, selectSite } from '../site/siteSlice';
import { Appliance } from 'clipsal-cortex-types/src/api/api-appliances';
import { useNavigate } from 'react-router-dom';
import { CombinedDevice, useGetLiveDevicesQuery } from './devicesApi';
import { useTranslation } from 'react-i18next';
import poweredBySenseWhite from '../../assets/images/powered_by_sense_white.svg';
import poweredBySenseGrey from '../../assets/images/powered_by_sense_grey.svg';
import AlertBanner from 'clipsal-cortex-ui/src/components/AlertBanner';

type Props = {
  sortBy: SortByOptions;
};

type DeviceWithCostToday = CombinedDevice & { costToday: number };

export function DevicesList({ sortBy }: Props) {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const { data: devicesData, isLoading: isDevicesLoading, isError, isLiveDataError } = useGetLiveDevicesQuery();
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: costsData, isLoading: isCostsLoading } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const { t } = useTranslation();
  const isApiDataLoaded = !isDevicesLoading && !isCostsLoading;
  const poweredBySenseIcon = useColorModeValue(poweredBySenseGrey, poweredBySenseWhite);

  const devicesWithCostToday = useMemo<DeviceWithCostToday[]>(() => {
    if (!costsData) return [];

    return devicesData
      .filter((device) => !device.switch_id)
      .map((device) => {
        const costForDevice =
          costsData.devices.find((appliance) => appliance.appliance_id === device.appliance_id)?.cost ?? 0;

        return {
          ...device,
          costToday: costForDevice,
        };
      });
  }, [costsData, devicesData]);

  const devices = useMemo(() => {
    if (sortBy === 'ALPHABETICAL') {
      return orderBy(devicesWithCostToday, [(device) => device.display_name.toLowerCase()], ['asc']);
    } else if (sortBy === 'POWER') {
      return orderBy(devicesWithCostToday, [(device) => device.power], ['desc']);
    } else if (sortBy === 'COST') {
      return orderBy(devicesWithCostToday, [(device) => device.costToday], ['desc']);
    } else return devicesWithCostToday;
  }, [devicesWithCostToday, sortBy]);

  const handleViewDevice = (device: Appliance) => {
    navigate(`/site/${siteId}/devices/${device.appliance_id}/view?direction=forward`);
  };

  return (
    <Skeleton
      isLoaded={isApiDataLoaded}
      borderRadius={5}
      borderBottomRightRadius="25px"
      height={isApiDataLoaded ? 'unset' : 75}
      data-testid="devices-list"
    >
      {isLiveDataError && (
        <Box mb={4}>
          <AlertBanner
            title={t('Devices.error fetching live data')}
            type="error"
            isCollapsible={false}
            isDismissable={false}
            containerTestId="devices-error-alert"
          >
            {t('Common.please try again')} {t('Common.if this persists contact support')}
          </AlertBanner>
        </Box>
      )}
      {devices.map((device, i) => {
        const Icon = getIconForDevice(device.user_assignment);
        return (
          <DeviceListItem
            name={device.display_name}
            Icon={Icon}
            currentPower={device.power}
            costToday={device.costToday}
            onClick={() => handleViewDevice(device)}
            key={device.appliance_id}
            data-testid={`${device.assignment.split('__')[0].replaceAll('_', '-')}-${i}-device-list-item`}
          />
        );
      })}
      {!devices.length && !isError && <Text color="gray.400">{t('Devices.no devices found')}</Text>}
      <Center>
        <Image my={4} src={poweredBySenseIcon} hidden={isError} data-testid="powered-by-sense-icon" />
      </Center>
    </Skeleton>
  );
}
