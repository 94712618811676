import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Skeleton,
  SkeletonCircle,
  useToast,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateSwitchMutation } from '../switches/switchApi';
import DeviceStatusIcon from '../DeviceStatusIcon';
import SwitchScheduleList from '../switches/schedules/SwitchScheduleList';
import { DEVICE_ICON_RESPONSIVE_SIZE } from '../devices-helper';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useTranslation } from 'react-i18next';
import { getMatterDeviceType, useAylaSwitchData, useTranslatedUIConfig } from './matter-helpers';
import EditMatterDeviceForm, { EditDeviceFormData } from './EditMatterDeviceForm';

const FORM_ID = 'edit-device-form'; // Passed to the form to be able to submit with an external button

export function EditMatterDevice() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const params = useParams<{ switchId: string }>();
  const switchId = Number(params.switchId);
  const { data: aylaSwitch, isLiveDataLoading, isApiDataLoading, isApiSwitchError } = useAylaSwitchData(switchId);
  const { t } = useTranslation();
  const toast = useToast({ isClosable: true });
  const [updateSwitch] = useUpdateSwitchMutation();
  const MATTER_DEVICES_UI_CONFIG = useTranslatedUIConfig();
  const deviceType = getMatterDeviceType(aylaSwitch?.display_name);

  const handleFormSubmit = async ({ displayName }: EditDeviceFormData) => {
    try {
      const bodyForSwitch = {
        site_switch_label: displayName,
      };
      await updateSwitch({ siteId, switchId, body: bodyForSwitch }).unwrap();
      navigate(`../view?direction=back`);
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        title: t('Devices.error updating device'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }
  };

  const backURL = useMemo(
    () => (isApiSwitchError ? `../../../devices/list?direction=back` : `../view?direction=back`),
    [isApiSwitchError]
  );

  return (
    <SlidingAnimationPageBase
      backURL={backURL}
      includeBottomNav
      title={t('Devices.edit device')}
      customTopNavButton={
        <Button
          data-testid="save-button"
          variant={'ghost'}
          size={'sm'}
          hidden={isApiSwitchError}
          type="submit"
          form={FORM_ID}
          fontWeight={400}
          colorScheme="schneiderSkyBlue"
        >
          {t('Common.save')}
        </Button>
      }
    >
      <Box data-testid="matter-device-detail-contents" px={3}>
        {isApiSwitchError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Devices.error fetching device')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <SkeletonCircle
              isLoaded={!isLiveDataLoading}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              <DeviceStatusIcon isOn={aylaSwitch.state === 'closed'} icon={MATTER_DEVICES_UI_CONFIG[deviceType].icon} />
            </SkeletonCircle>
            <Skeleton isLoaded={!isApiDataLoading} borderBottomRightRadius="25px" mt={2} mb={4}>
              <EditMatterDeviceForm
                displayName={aylaSwitch.site_switch_label ?? ''}
                onSubmit={handleFormSubmit}
                formId={FORM_ID}
              />
            </Skeleton>
            <SwitchScheduleList switchId={switchId} />
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
