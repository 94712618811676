import { defineStyle, defineStyleConfig, cssVar } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const baseStyle = defineStyle({
  _light: {
    [$startColor.variable]: '#d9d9d9',
    [$endColor.variable]: '#c1c1c1',
  },
});

export const skeletonTheme = defineStyleConfig({ baseStyle });
