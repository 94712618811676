import React from 'react';
import { BoxProps, Flex, Icon, Switch, Text } from '@chakra-ui/react';

type Props = {
  text: string;
  children?: React.JSX.Element;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  isDisabled?: boolean;
  Icon?: typeof Icon;
  'data-testid'?: string;
} & Omit<BoxProps, 'onChange'>;

export default function SettingSwitch({
  text,
  children,
  isChecked,
  onChange,
  isDisabled,
  Icon,
  ['data-testid']: testId,
  ...boxProps
}: Props) {
  return (
    <Flex {...boxProps}>
      {!!Icon && <Icon w={6} h={6} mr={5} />}
      <Text fontSize={16} fontWeight={500}>
        {text}
      </Text>
      {children}
      <Switch
        data-testid={testId}
        isDisabled={!!isDisabled}
        isChecked={isChecked}
        onChange={(event) => onChange(event.target.checked)}
        size="lg"
        variant="solarGreen"
        ml="auto"
        mr={4}
      />
    </Flex>
  );
}
