import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Switch,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { capitalizeFirst } from 'clipsal-cortex-utils/src/formatting/formatting';
import { ActivityDevice } from './types';
import { useTranslation } from 'react-i18next';
import { getIconForDevice } from '../devices/devices-helper';
import { getMatterDeviceType, useTranslatedUIConfig } from '../devices/matter/matter-helpers';

type Props = {
  isLoaded: boolean;
  onSelectDevice: (deviceId: number, deviceAssignment: string) => void;
  selectedDateRangeType: DateRangeType;
  selectedDeviceId: number | null;
  isSelectedDeviceDisplayedInChart: boolean;
  onToggleDeviceDisplay: () => void;
  displayedDevices: ActivityDevice[];
};

export default function FilterByDevice({
  onSelectDevice,
  selectedDateRangeType,
  selectedDeviceId,
  isLoaded,
  isSelectedDeviceDisplayedInChart,
  onToggleDeviceDisplay,
  displayedDevices,
}: Props) {
  const subHeadingColor = useColorModeValue('customGrey.500', 'customGrey.400');
  const { t } = useTranslation();
  const MATTER_DEVICES_UI_CONFIG = useTranslatedUIConfig();

  function getPeriodCopy() {
    const dateRangeToPeriod = {
      [DateRangeType.Day]: t('Activity.today'),
      [DateRangeType.Week]: t('Activity.this week'),
      [DateRangeType.Month]: t('Activity.this month'),
      [DateRangeType.Year]: t('Activity.this year'),
    };

    return dateRangeToPeriod[selectedDateRangeType];
  }

  const devicesInPeriod = displayedDevices.length ? (
    displayedDevices.map((device, index) => {
      const deviceType = getMatterDeviceType(device.display_name);
      const Icon = device.assignment.includes('ayla')
        ? MATTER_DEVICES_UI_CONFIG[deviceType].icon
        : getIconForDevice(device.user_assignment);
      const isCheckedAttribute = device.appliance_id === selectedDeviceId ? { 'data-checked': true } : undefined;

      return (
        <Box
          opacity={isSelectedDeviceDisplayedInChart ? 1 : 0.4}
          role="button"
          onClick={() => onSelectDevice(device.appliance_id, device.assignment)}
          key={index}
          py={4}
          data-testid={`displayed-device-radio-${index}-container`}
          borderTop={'1px solid'}
          borderBottom={index === displayedDevices.length - 1 ? '1px solid' : undefined}
          borderColor={'#BDBEC0'}
        >
          <Grid templateColumns={'50px 1fr 40px'}>
            <Center>
              <Icon color={subHeadingColor} w={'40px'} h={'40px'} />
            </Center>

            <Box pl={1}>
              <Text>{device.site_switch_label ?? device.display_name}</Text>
              <Text color={subHeadingColor} fontSize={14}>
                {capitalizeFirst(getPeriodCopy())}: ${device.cost!.toFixed(2)} | {device.energySummary.toFixed(1)}
                {' kWh'}
              </Text>
            </Box>

            <Center>
              <Box
                data-testid={`displayed-device-radio-${index}`}
                p={'3px'}
                w={'30px'}
                h={'30px'}
                border={'2px solid'}
                borderColor={device.appliance_id === selectedDeviceId ? 'devicePurpleStatic.500' : '#999999'}
                rounded={10000}
                {...isCheckedAttribute}
              >
                {device.appliance_id === selectedDeviceId && (
                  <Box rounded={10000} bg={'devicePurpleStatic.500'} h={'100%'} w={'100%'} />
                )}
              </Box>
            </Center>
          </Grid>
        </Box>
      );
    })
  ) : (
    <Center>
      <Heading size="md">{t('Activity.no power consumers')}</Heading>
    </Center>
  );

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            <Text>
              {t('Activity.top power consumers')} {getPeriodCopy()}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel data-testid="activity-device-filter-panel">
          <Text fontSize={13} color={subHeadingColor} mb={1}>
            {t('Activity.select a device')}
          </Text>
          {!!displayedDevices.length && (
            <Flex mb={4} align="center" justify="space-between">
              <Box>{t('Activity.show device')}</Box>
              <Switch
                data-testid="toggle-hide-in-chart"
                onChange={onToggleDeviceDisplay}
                isChecked={isSelectedDeviceDisplayedInChart}
                variant="purple"
                size="lg"
              />
            </Flex>
          )}
          {isLoaded ? devicesInPeriod : <SkeletonLoader />}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

const EMPTY_ARRAY = Array.from(Array(6));

function SkeletonLoader() {
  return (
    <Box>
      {EMPTY_ARRAY.map((_, i) => (
        <Box
          key={i}
          py={4}
          borderTop={'1px solid'}
          borderBottom={i === EMPTY_ARRAY.length - 1 ? '1px solid' : undefined}
          borderColor={'#BDBEC0'}
        >
          <Skeleton w={'100%'} h={'75px'} isLoaded={false} />
        </Box>
      ))}
    </Box>
  );
}
