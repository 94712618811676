import { Box, Button, Center, Heading, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../../../features/site/siteSlice';
import { IS_DEMO_LOGIN } from '../../../../common/constants';
import { useTranslation } from 'react-i18next';
import wifiConnectingImg from '../../../../assets/images/wifi_connecting.svg';
import { getSavedWifiDetails, WifiDetails } from './wifi-settings-helpers';
import AccountMenuButton from '../../AccountMenuButton';
import SEHomeCard from '../../../../common/components/SEHomeCard';

export function ViewWifiSettings() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const [savedWifiDetails, setSavedWifiDetails] = useState<WifiDetails[]>([]);

  useEffect(() => {
    async function fetchSavedWifi() {
      const savedWifiDetails = await getSavedWifiDetails(siteId);
      setSavedWifiDetails(savedWifiDetails);
    }

    fetchSavedWifi();
  }, []);

  const handleAddButtonClick = async () => {
    navigate(`../add`);
  };

  return (
    <SlidingAnimationPageBase
      title={t('Account.wifi settings')}
      backURL={`../../home?direction=back`}
      customTopNavButton={
        <Button
          data-testid="add-button"
          onClick={handleAddButtonClick}
          variant={'ghost'}
          size={'sm'}
          isDisabled={IS_DEMO_LOGIN}
          colorScheme={'schneiderSkyBlue'}
          fontWeight={500}
        >
          {t('Common.add')}
        </Button>
      }
    >
      <Box m={4}>
        <Center flexDir="column">
          <Image w="65%" src={wifiConnectingImg} alt="wi-fi" />
          <Heading size="lg" fontWeight={600} mt={4}>
            {t('Account.wifi connections')}
          </Heading>
          <SEHomeCard p={0} borderTopLeftRadius="5px" w="100%" mt={4}>
            {savedWifiDetails.map((savedWifiDetail, i) => (
              <AccountMenuButton
                key={i}
                data-testid={`wifi-detail-button-${i}`}
                onClick={() => {
                  navigate(`../edit?id=${savedWifiDetail.id}&direction=forward`);
                }}
                includeBottomDivider={i !== savedWifiDetails.length - 1}
              >
                <Text fontSize={16} fontWeight="normal" overflow="auto">
                  {savedWifiDetail.ssid}
                </Text>
              </AccountMenuButton>
            ))}

            {!savedWifiDetails.length && (
              <SEHomeCard borderTopLeftRadius={'5px'} mt={1} data-testid="empty-wifi-settings-message">
                <Text>{t('Account.no wifi details added')}</Text>
              </SEHomeCard>
            )}
          </SEHomeCard>
        </Center>
      </Box>
    </SlidingAnimationPageBase>
  );
}
