import React, { useState } from 'react';
import { fetchSite } from '../../site/siteSlice';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import SEHomeCard from '../../../common/components/SEHomeCard';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressFormData } from '../../site/new-user-journey/SetAddress';
import * as yup from 'yup';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import axios from 'axios';
import { SiteDataToSave } from 'clipsal-cortex-types/src/api/api-site';
import { post } from '../../../common/api/api-helpers';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ArcButton from '../../../common/components/ArcButton';
import { useNavigate } from 'react-router-dom';
import { useReduxDispatch } from '../../../app/store';
import { useTranslation } from 'react-i18next';
import { IS_PRODUCTION } from '../../../common/constants';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY as string;

export type CreateHomeFormData = {
  name: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  latitude: number;
  longitude: number;
  timezone: string;
};

/**
 * required fields for new site:
 *
 * - name
 * - timezone
 * - Zipcode (single zip input with google autocomplete, to populate lat/long, city, state, country)
 */
export function CreateHome() {
  const toast = useToast({ isClosable: true });
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: ['postal_code'],
      fields: ['address_components', 'geometry', 'place_id'],
    } as any,
  });
  const [shouldDisplayAutocompleteOptions, setShouldDisplayAutocompleteOptions] = useState(false);
  const { t } = useTranslation();
  const schema = yup.object().shape({
    postCode: yup.string().required().label(t('Common.zipcode')).min(4).max(7),
    name: yup.string().max(50).required().label(t('Common.home name')),
  });
  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AddressFormData>({
    resolver: yupResolver(schema),
  });

  async function handleSelectLocation(location: google.maps.places.AutocompletePrediction) {
    const currentValues = getValues();

    placesService?.getDetails({ placeId: location.place_id }, async (details) => {
      const locationData: Record<string, string | undefined> = {};

      // iterate over address components to populate location data
      details?.address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          locationData.city = component?.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          locationData.state = component?.short_name;
        } else if (component.types.includes('country')) {
          locationData.country = component?.long_name;
        } else if (component.types.includes('postal_code')) {
          locationData.postCode = component?.long_name;
        }
      });

      const latitude = details?.geometry?.location?.lat();
      const longitude = details?.geometry?.location?.lng();

      const {
        data: { timeZoneId: timezone },
      } = await axios.get(
        'https://maps.googleapis.com/maps/api/timezone/json?location=' +
          latitude +
          ',' +
          longitude +
          '&timestamp=' +
          Math.floor(Date.now() / 1000) +
          '&language=es&key=' +
          GOOGLE_MAPS_API_KEY
      );

      const termsLength = location.terms.length;

      reset({
        ...currentValues,
        address: location.description,
        city: locationData.city || location.terms[termsLength - 3]?.value,
        state: locationData.state || location.terms[termsLength - 2]?.value,
        country: locationData.country || location.terms[termsLength - 1]?.value,
        timezone,
        latitude,
        longitude,
        postCode: locationData.postCode,
      });
      setShouldDisplayAutocompleteOptions(false);
    });
  }

  async function handleSubmit(values: AddressFormData) {
    const body: SiteDataToSave = {
      lat: values.latitude,
      lon: values.longitude,
      city: values.city,
      post_code: values.postCode,
      state: values.state,
      country: values.country,
      address: values.address,
      timezone: values.timezone,
      site_name: values.name,
    };

    try {
      const newSite = await post<SiteDataToSave & { site_id: number; tenant_id: number }>(`/v1/sites`, body);
      await dispatch(fetchSite({ siteID: Number(newSite.site_id), isProduction: IS_PRODUCTION }));
      navigate(`/site/${newSite.site_id}/home`);
    } catch (e) {
      console.log(e);
      toast({ title: t('Manage Homes.error creating home'), status: 'error', duration: 3000 });
    }
  }

  function PredictionsList() {
    return placePredictions.length ? (
      <Box data-testid="site-postcode-prediction-list" width={{ base: '100%', lg: '50%' }} border="1px solid #9FA0A4">
        {placePredictions.map((location, i) => (
          <Box
            data-testid={`site-postcode-prediction-${i}`}
            borderBottom={'1px solid grey'}
            key={`google-maps-location-${i}`}
            onClick={async () => handleSelectLocation(location)}
            cursor={'pointer'}
            py={2}
            px={2}
          >
            <Flex align={'center'} as={'button'} type={'button'}>
              <Box mr={1}>
                <FaMapMarkerAlt />
              </Box>
              <Text fontWeight={600} fontSize={'sm'}>
                {location.description}
              </Text>
            </Flex>
          </Box>
        ))}
        <Text m={2} fontSize={'xs'}>
          {t('Common.powered by google')}
        </Text>
      </Box>
    ) : null;
  }

  const backgroundColor = useColorModeValue('#FFFFFF', 'customGrey.900');

  return (
    <SlidingAnimationPageBase
      title={t('Manage Homes.create new home')}
      includeTopNav
      px={4}
      backURL={`../home?direction=back`}
    >
      <SEHomeCard mb={5} borderTopLeftRadius={'5px'}>
        <Box data-testid="new-home-form" onSubmit={handleFormSubmit(handleSubmit)} as={'form'}>
          <FormControl my={3} isInvalid={!!errors.postCode} position="relative">
            <InputGroup>
              <Input
                {...register('postCode')}
                borderRadius={0}
                height="50px"
                onChange={(e) => {
                  getPlacePredictions({ input: e.currentTarget.value });
                  setValue('postCode', e.currentTarget.value);
                  setShouldDisplayAutocompleteOptions(true);
                }}
                type="text"
                data-private
                autoComplete="off"
                data-testid="site-postcode"
                background={backgroundColor}
                borderColor="customGrey.400"
                placeholder={`${t('Common.zipcode')}...`}
              />
            </InputGroup>
            {/* Site address autocomplete */}
            <Box w={'100%'} position="absolute" zIndex={99} background={backgroundColor} data-private>
              {!isPlacePredictionsLoading ? (
                shouldDisplayAutocompleteOptions && <PredictionsList />
              ) : (
                <Flex direction={'column'} justify={'center'} align={'center'} border="1px solid #9FA0A4" py={6}>
                  <Spinner size={'lg'} />
                  <Text fontSize={'sm'}>{t('Common.loading suggestions')}...</Text>
                </Flex>
              )}
            </Box>
            <FormHelperText mb={1}>{t('Common.zipcode is used')}</FormHelperText>
            <FormErrorMessage data-testid="site-postcode-error">{errors?.postCode?.message}</FormErrorMessage>
          </FormControl>

          <FormControl mt={3} isInvalid={!!errors.name}>
            <Input
              {...register('name')}
              borderRadius={0}
              height="50px"
              type="text"
              data-private
              autoComplete="off"
              data-testid="site-home-name"
              background={backgroundColor}
              borderColor="customGrey.400"
              placeholder={`${t('Common.home name')}...`}
            />
            <FormHelperText mb={1}>{t('Common.home name example')}</FormHelperText>
            <FormErrorMessage data-testid="site-name-error">{errors?.name?.message}</FormErrorMessage>
          </FormControl>

          <Center>
            <ArcButton
              isLoading={isSubmitting}
              my={5}
              type="submit"
              data-testid="save-new-home-btn"
              w={'80%'}
              arcColor="#3DCD57"
            >
              {t('Manage Homes.create home')}
            </ArcButton>
          </Center>
        </Box>
      </SEHomeCard>
    </SlidingAnimationPageBase>
  );
}
