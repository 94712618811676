import React from 'react';
import { Progress } from '@chakra-ui/react';

// The height of the top nav is based on SlidingAnimationPageBase, but could theoretically differ when implemented in
// other pages, due to the fact that a custom button could be supplied to make it larger. Because of this, we can
// alternatively use a different height via props if needed.
const MOBILE_TOP_NAV_HEIGHT_PX = 40;

type Props = {
  topNavHeight?: number;
};

export default function TopNavProgressLoader({ topNavHeight = MOBILE_TOP_NAV_HEIGHT_PX }: Props) {
  return (
    <Progress
      pos={'absolute'}
      top={`calc(${topNavHeight}px + env(safe-area-inset-top))`}
      w={'100%'}
      colorScheme="primaryBranding"
      isIndeterminate
    />
  );
}
