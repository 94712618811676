import * as yup from 'yup';
import { useTranslatedTariffSeasonSchema } from './tariff-season/tariff-season-helpers';
import {
  CHARGE_CLASSES,
  CHARGE_PERIODS,
  CHARGE_TYPES,
  ChargeClass,
  ChargePeriod,
  ChargeType,
  DAY_OF_WEEK,
  DayOfWeek,
  TARIFF_TYPES,
  TOU_RATE_TYPE,
  TRANSACTION_TYPES,
  TariffType,
  TOURateType,
  TransactionType,
} from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { TOUTariffRatesPerType, TariffData } from './tariff-types';
import { areIntervalsOverlapping, differenceInMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

/**
 * Breaks down season to range such that it can be compared to other ranges.
 *
 * @param seasons season to get range from
 * @returns array of ranges for the season
 */
const getTimeRanges = (rates: TOUTariffRatesPerType) => {
  type HourMinuteTuple = [number, number];
  type RangeTuple = [HourMinuteTuple, HourMinuteTuple];
  const ranges: RangeTuple[] = [];

  const allRates = [...(rates?.peak || []), ...(rates?.partialPeak || []), ...(rates?.offPeak || [])];
  allRates.forEach((rate) => {
    if (!rate.timeOfUse?.periods) return null;
    const periods = rate.timeOfUse?.periods;
    return periods.forEach(({ fromHour, toHour, fromMinute, toMinute }) => {
      // if hours span across two days, split it into two ranges
      const isEndTimeMidnight = toHour === 0 && toMinute === 0;
      if ((fromHour > toHour || (toHour === fromHour && fromMinute > toMinute)) && !isEndTimeMidnight) {
        const newRanges: RangeTuple[] = [
          [
            [0, 0],
            [toHour, toMinute],
          ],
          [
            [fromHour, fromMinute],
            [0, 0],
          ],
        ];
        ranges.push(...newRanges);

        // If same start and end time but not midnight, split it into two ranges
      } else if (toHour === fromHour && fromMinute === toMinute && !isEndTimeMidnight) {
        const newRanges: RangeTuple[] = [
          [
            [0, 0],
            [fromHour, fromMinute],
          ],
          [
            [fromHour, fromMinute],
            [0, 0],
          ],
        ];
        ranges.push(...newRanges);
      } else {
        ranges.push([
          [fromHour, fromMinute],
          [toHour, toMinute],
        ]);
      }
    });
  });

  // sort ranges in ascending order
  return ranges.sort(
    ([[fromHourA, fromMinuteA], [toHourA, toMinuteA]], [[fromHourB, fromMinuteB], [toHourB, toMinuteB]]) => {
      if (fromHourA > fromHourB) return 1;
      if (fromHourA < fromHourB) return -1;
      if (fromMinuteA > fromMinuteB) return 1;
      if (fromMinuteA < fromMinuteB) return -1;
      if (toHourA > toHourB) return 1;
      if (toHourA < toHourB) return -1;
      if (toMinuteA > toMinuteB) return 1;
      if (toMinuteA < toMinuteB) return -1;
      return 0;
    }
  );
};

const checkIfTOUTimeCoversWholeDay = (rates: TOUTariffRatesPerType) => {
  const hourMinuteRanges = getTimeRanges(rates);

  const totalMinutes = hourMinuteRanges.reduce((acc, [[fromHour, fromMinute], [toHour, toMinute]]) => {
    const startFullDateA = new Date();
    startFullDateA.setHours(fromHour, fromMinute, 0, 0);
    const endFullDateA = new Date();
    // If it is midnight, make it next day
    if (toHour === 0) endFullDateA.setDate(endFullDateA.getDate() + 1);
    endFullDateA.setHours(toHour, toMinute, 0, 0);
    const totalMinutes = differenceInMinutes(endFullDateA, startFullDateA);
    return acc + totalMinutes;
  }, 0);

  return totalMinutes >= 24 * 60;
};

const checkIfTOUTimeOverlaps = (rates: TOUTariffRatesPerType) => {
  const hourMinuteRanges = getTimeRanges(rates);

  // compare each range to all other ranges
  const hasOverlappingRanges = hourMinuteRanges.some((rangeA, indexA) => {
    const [[startHourA, startMinuteA], [endHourA, endMinuteA]] = rangeA;
    const startFullDateA = new Date();
    startFullDateA.setHours(startHourA, startMinuteA, 0, 0);
    const endFullDateA = new Date();
    // If it is midnight, make it next day
    if (endHourA === 0) endFullDateA.setDate(endFullDateA.getDate() + 1);
    endFullDateA.setHours(endHourA, endMinuteA, 0, 0);

    // compare this range to all exisitng ranges
    return hourMinuteRanges.some((rangeB, indexB) => {
      // do not compare to self
      if (indexA === indexB) return false;

      const [[startHourB, startMinuteB], [endHourB, endMinuteB]] = rangeB;
      const startFullDateB = new Date();
      startFullDateB.setHours(startHourB, startMinuteB, 0, 0);
      const endFullDateB = new Date();
      // If it is midnight, make it next day
      if (endHourB === 0) endFullDateB.setDate(endFullDateB.getDate() + 1);
      endFullDateB.setHours(endHourB, endMinuteB, 0, 0);

      // checks if two ranges overlap
      return areIntervalsOverlapping(
        { start: startFullDateA, end: endFullDateA },
        { start: startFullDateB, end: endFullDateB }
      );
    });
  });

  return hasOverlappingRanges;
};

export const useTranslatedTariffSchema = () => {
  const { t } = useTranslation();
  const touPeriodSchema = yup.object().shape({
    days: yup
      .array()
      .of(yup.mixed<DayOfWeek>().oneOf([...DAY_OF_WEEK]))
      .required()
      .label(t('Energy Rates.days')),
    fromHour: yup.number().required().label(t('Energy Rates.from hour')),
    toHour: yup.number().required().label(t('Energy Rates.to hour')),
    fromMinute: yup.number().required().label(t('Energy Rates.from minute')),
    toMinute: yup.number().required().label(t('Energy Rates.to minute')),
    publicHoliday: yup.boolean().default(false),
  });

  const timeOfUseSchema = yup
    .object()
    .shape({
      touName: yup.string().max(100),
      touRateType: yup
        .mixed<TOURateType>()
        .oneOf([...TOU_RATE_TYPE])
        .required()
        .label(t('Energy Rates.rate type')),
      periods: yup.array(touPeriodSchema).required().label(t('Common.periods')).min(1),
    })
    .nullable()
    .default(null);

  const rateBandSchema = yup.object().shape({
    id: yup.number(),
    sequenceNumber: yup.number(),
    rate: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value)) // Prevents ugly 'NaN' error messages for empty number fields
      .required()
      .label(t('Common.value'))
      .min(0.0001),
    hasConsumptionLimit: yup.boolean().default(false),
    consumptionUpperLimit: yup
      .number()
      .nullable()
      .label(t('Common.value'))
      .transform((value) => (isNaN(value) ? 0 : value)) // Prevents ugly 'NaN' error messages for empty number fields
      .min(0.0001),
    hasDemandLimit: yup.boolean().default(false),
    demandUpperLimit: yup.number().nullable(),
  });

  const tariffRateSchema = yup.object().shape({
    id: yup.number(),
    seasonIndex: yup.number(),
    seasonId: yup.number(),
    // yup requires to put null and undefined as values in the oneOf array
    chargePeriod: yup.mixed<ChargePeriod>().oneOf([...CHARGE_PERIODS, null, undefined]),
    chargeType: yup
      .mixed<ChargeType>()
      .oneOf([...CHARGE_TYPES])
      .required()
      .label(t('Energy Rates.charge type')),
    chargeClass: yup
      .mixed<ChargeClass>()
      .oneOf([...CHARGE_CLASSES])
      .required()
      .label(t('Energy Rates.charge class')),
    transactionType: yup.mixed<TransactionType>().oneOf([...TRANSACTION_TYPES, null]),
    rateBands: yup.array(rateBandSchema).required().label(t('Energy Rates.rate bands')).min(1),
    timeOfUse: timeOfUseSchema,
  });

  // Silly way to make this type safe
  // yup does not have other better option at the moment
  // Used this based on https://stackoverflow.com/a/74901506
  type YupFromContext = {
    from: {
      value: TariffData['tariff'];
    }[];
  };
  type YupContext = yup.TestContext & YupFromContext;

  const checkIfIgnoreTOUTariffValidation = (context: YupContext, rates?: TOUTariffRatesPerType) => {
    // If this is not TOU tariff, ignore validation
    const tariffType = context?.from?.[2]?.value?.tariffType;
    if (tariffType !== 'TOU') return true;

    // If this is empty, it means this is optional and can be ignored
    const totalRates = Object.values(rates ?? {}).flat().length;
    if (!totalRates) return true;
    return false;
  };

  const TOUTariffRatesPerTypeSchema = yup
    .object()
    .shape({
      peak: yup.array().of(tariffRateSchema),
      partialPeak: yup.array().of(tariffRateSchema),
      offPeak: yup.array().of(tariffRateSchema),
    })
    .test('test-if-overlaps', t('Energy Rates.hours shouldnt overlap'), (value, context) => {
      const canIgnoreValidation = checkIfIgnoreTOUTariffValidation(
        context as YupContext,
        value as TOUTariffRatesPerType
      );
      if (canIgnoreValidation) return true;
      return !checkIfTOUTimeOverlaps(value as TOUTariffRatesPerType);
    })
    .test('test-covers-all-hours-of-the-day', t('Energy Rates.hours should cover day'), (value, context) => {
      const canIgnoreValidation = checkIfIgnoreTOUTariffValidation(
        context as YupContext,
        value as TOUTariffRatesPerType
      );
      if (canIgnoreValidation) return true;
      return checkIfTOUTimeCoversWholeDay(value as TOUTariffRatesPerType);
    });

  const tariffSeasonSchema = useTranslatedTariffSeasonSchema();
  const TarrifDetailsSchema = yup.object().shape({
    type: yup
      .mixed<TariffType>()
      .oneOf([...TARIFF_TYPES])
      .default('FLAT'),
    planName: yup.string().max(100),
    seasons: tariffSeasonSchema,
    deliveryCharge: tariffRateSchema.default(undefined),
    rates: yup.array().of(
      yup.object().shape({
        import: yup.array().of(tariffRateSchema),
        export: yup.array().of(tariffRateSchema),
        weekday: TOUTariffRatesPerTypeSchema,
        weekend: TOUTariffRatesPerTypeSchema,
        allWeek: TOUTariffRatesPerTypeSchema,
      })
    ),
    retailer: yup.object().shape({
      id: yup.mixed(),
      name: yup.string().max(100),
    }),
    utility: yup.object().shape({
      id: yup.mixed(),
      name: yup.string().max(100),
      state: yup.string().max(50),
    }),
    distributorId: yup.number().nullable(),
    holidayCountry: yup.string().max(50).nullable(),
    holidaySubdiv: yup.string().max(50).nullable(),
  });

  return yup.object().shape({
    id: yup.number(),
    zipCode: yup.mixed(),
    effectiveDate: yup.string().max(50).required().label(t('Energy Rates.effective date')),
    tariff: TarrifDetailsSchema,
  });
};
