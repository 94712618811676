import React from 'react';
import { NotificationType } from 'clipsal-cortex-types/src/api/api-notifications';
import {
  BatteryErrorIcon,
  BatteryLowNotificationIcon,
  BCSErrorIcon,
  DeviceOfflineIcon,
  GridOutageIcon,
  GridRestoredIcon,
  InverterIcon,
  InverterErrorIcon,
  LowPowerModeActiveIcon,
  MeterErrorIcon,
  NewDeviceDetectedIcon,
  OffGridModeActiveIcon,
  StormModeActiveIcon,
  TestNotificationIcon,
} from '../../styles/notification-icons';
import { NotificationSwitchesList } from './NotificationSwitchesList';
import CallSupportButton from './CallSupportButton';

export const NOTIFICATION_POLLING_TIME_MS = 15000;

export const NOTIFICATION_TYPE_TO_CONFIG: Record<
  NotificationType,
  { icon: React.JSX.Element; child?: React.JSX.Element }
> = {
  LOW_BATTERY: {
    icon: <BatteryLowNotificationIcon data-testid="battery-low-notification-icon" w={10} h={10} />,
    child: <NotificationSwitchesList />,
  },
  TEST: { icon: <TestNotificationIcon w={10} h={10} /> },
  // @NOTE: This hasn't been finalized yet, just using the test one
  START_CHARGING: {
    icon: <TestNotificationIcon w={10} h={10} data-testid="charge-started-notification-icon" />,
  },
  GRID_RESTORED: {
    icon: <GridRestoredIcon w={10} h={10} data-testid="grid-restored-notification-icon" />,
    child: <NotificationSwitchesList type="GRID_RESTORED" />,
  },
  GRID_OUTAGE: {
    icon: <GridOutageIcon w={10} h={10} data-testid="grid-outage-notification-icon" />,
    child: <NotificationSwitchesList />,
  },
  NEW_DEVICE_DETECTED: {
    icon: <NewDeviceDetectedIcon w={10} h={10} data-testid="new-device-detected-notification-icon" />,
  },
  STORM_MODE_TRIGGERED: {
    icon: <StormModeActiveIcon w={10} h={10} data-testid="storm-mode-active-notification-icon" />,
  },
  STORM_MODE_DEACTIVATE_SOON: {
    icon: <StormModeActiveIcon w={10} h={10} data-testid="storm-mode-deactivate-soon-notification-icon" />,
  },
  OFF_GRID_MODE_TRIGGERED: {
    icon: <OffGridModeActiveIcon w={10} h={10} data-testid="off-grid-mode-active-notification-icon" />,
  },
  LOW_POWER_MODE_TRIGGERED: {
    icon: <LowPowerModeActiveIcon data-testid="low-power-mode-active-notification-icon" w={10} h={10} />,
  },
  HARDWARE_CONNECTION_LOST_SENSE: {
    icon: <DeviceOfflineIcon w={10} h={10} data-testid="meter-connection-lost-notification-icon" />,
  },
  HARDWARE_CONNECTION_LOST_SATURN: {
    icon: <DeviceOfflineIcon w={10} h={10} data-testid="inverter-connection-lost-notification-icon" />,
  },
  INVERTER_ERROR_DETECTED: {
    icon: <InverterErrorIcon w={10} h={10} data-testid="inverter-error-notification-icon" />,
    child: <CallSupportButton />,
  },
  INVERTER_ONLINE: {
    icon: <InverterIcon w={10} h={10} data-testid="inverter-online-notification-icon" />,
  },
  INVERTER_OFFLINE: {
    icon: <InverterErrorIcon w={10} h={10} data-testid="inverter-error-notification-icon" />,
  },
  OVERLOAD_NOTICE: {
    icon: <InverterErrorIcon w={10} h={10} data-testid="inverter-error-notification-icon" />,
    child: <NotificationSwitchesList type="OVERLOAD_NOTICE" />,
  },
  OVERLOAD_TRIPPED: {
    icon: <InverterErrorIcon w={10} h={10} data-testid="inverter-error-notification-icon" />,
    child: <NotificationSwitchesList type="OVERLOAD_TRIPPED" />,
  },
  BATTERY_ERROR_DETECTED: {
    icon: <BatteryErrorIcon w={10} h={10} data-testid="battery-error-notification-icon" />,
    child: <CallSupportButton />,
  },
  METER_ERROR_DETECTED: {
    icon: <MeterErrorIcon w={10} h={10} data-testid="meter-error-notification-icon" />,
    child: <CallSupportButton />,
  },
  BCS_ERROR_DETECTED: {
    icon: <BCSErrorIcon w={10} h={10} data-testid="bcs-error-notification-icon" />,
    child: <CallSupportButton />,
  },
};
