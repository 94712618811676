import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ArcButton from '../../../common/components/ArcButton';
import { patch } from '../../../common/api/api-helpers';
import { SiteDataToSave } from 'clipsal-cortex-types/src/api/api-site';
import { useSelector } from 'react-redux';
import { selectSite, setCurrentSite } from '../siteSlice';
import { useReduxDispatch } from '../../../app/store';
import { useNavigate } from 'react-router-dom';
import { OutlineGradientIconAnimated, SEHomeNavHomeIcon } from '../../../styles/custom-icons';
import PrivacyNoticeModal from '../../account/settings/privacy/PrivacyNoticeModal';
import TermsOfUseModal from '../../account/TermsOfUseModal';
import { useTranslation } from 'react-i18next';
import useOnboardingWizardProgress from '../../home/useOnboardingWizardProgress';
import LanguageSelector from '../../../common/components/LanguageSelector';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY as string;

export type AddressFormData = {
  name: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  latitude: number;
  longitude: number;
  timezone: string;
};

export function SetAddress() {
  const site = useSelector(selectSite);
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const schema = yup.object().shape({
    postCode: yup.string().label(t('Common.zipcode')).required().min(4).max(7),
    name: yup.string().max(50).required().label(t('Common.home name')),
  });
  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AddressFormData>({
    resolver: yupResolver(schema),
  });
  const toast = useToast({ isClosable: true });
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const navigate = useNavigate();
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: ['postal_code'],
      fields: ['address_components', 'geometry', 'place_id'],
    } as any,
  });

  const [shouldDisplayAutocompleteOptions, setShouldDisplayAutocompleteOptions] = useState(false);
  const { isOpen: isTermsOpen, onOpen: onTermsOpen, onClose: onTermsClose } = useDisclosure();
  const { isOpen: isPrivacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure();
  const { needsToProvideInstallerConsent } = useOnboardingWizardProgress();

  async function handleSelectLocation(location: google.maps.places.AutocompletePrediction) {
    const currentValues = getValues();
    placesService?.getDetails({ placeId: location.place_id }, async (details) => {
      const locationData: Record<string, string | undefined> = {};

      details?.address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          locationData.city = component?.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          locationData.state = component?.short_name;
        } else if (component.types.includes('country')) {
          locationData.country = component?.long_name;
        } else if (component.types.includes('postal_code')) {
          locationData.postCode = component?.long_name;
        }
      });

      const latitude = details?.geometry?.location?.lat();
      const longitude = details?.geometry?.location?.lng();

      const {
        data: { timeZoneId: timezone },
      } = await axios.get(
        'https://maps.googleapis.com/maps/api/timezone/json?location=' +
          latitude +
          ',' +
          longitude +
          '&timestamp=' +
          Math.floor(Date.now() / 1000) +
          '&language=es&key=' +
          GOOGLE_MAPS_API_KEY
      );

      const termsLength = location.terms.length;

      reset({
        ...currentValues,
        address: location.description,
        city: locationData.city ?? location.terms[termsLength - 3]?.value,
        state: locationData.state ?? location.terms[termsLength - 2]?.value,
        country: locationData.country ?? location.terms[termsLength - 1]?.value,
        timezone,
        latitude,
        longitude,
        postCode: locationData.postCode,
      });
      setShouldDisplayAutocompleteOptions(false);
    });
  }

  async function handleSubmit(values: AddressFormData) {
    const body: SiteDataToSave = {
      lat: values.latitude,
      lon: values.longitude,
      city: values.city,
      post_code: values.postCode,
      state: values.state,
      country: values.country,
      address: values.address,
      timezone: values.timezone,
      site_name: values.name,
    };

    try {
      const siteData = await patch<SiteDataToSave>(`/v1/sites/${site.site_id}`, body);
      dispatch(setCurrentSite({ ...site, ...siteData }));

      if (needsToProvideInstallerConsent) {
        navigate(`../set_installer_consent`);
      } else {
        navigate(`../home`);
      }
    } catch (e) {
      console.log(e);
      toast({ title: t('Set Address.error saving address'), status: 'error', duration: 3000 });
    }
  }

  const backgroundColor = useColorModeValue('#FFFFFF', 'customGrey.900');
  return (
    <SlidingAnimationPageBase px={5} includeTopNav title={t('Common.get started')}>
      <Center flexDirection="column">
        <Center w="300px" h="300px">
          <OutlineGradientIconAnimated height="100%" width="100%" color={iconColor} data-testid="device-is-off-icon" />
          <Center pos="absolute">
            <SEHomeNavHomeIcon color={iconColor} w="100px" h="100px" marginBottom="15px" />
          </Center>
        </Center>
        <Text fontSize={32} fontWeight={400} textAlign="center">
          {t('Set Address.welcome')}
        </Text>
        <Text textAlign="center">
          {t('Set Address.turn sunshine into savings')} {t('Set Address.well spot bill boosters')}
        </Text>
      </Center>
      <Box onSubmit={handleFormSubmit(handleSubmit)} as={'form'}>
        <FormControl mt={5} mb={4} isInvalid={!!errors.postCode} position="relative">
          <InputGroup>
            <Input
              {...register('postCode')}
              borderRadius={0}
              height="50px"
              onChange={(e) => {
                getPlacePredictions({ input: e.currentTarget.value });
                setValue('postCode', e.currentTarget.value);
                setShouldDisplayAutocompleteOptions(true);
              }}
              type="text"
              data-private
              autoComplete="off"
              data-testid="site-postcode"
              background={backgroundColor}
              borderColor={'customGrey.400'}
              placeholder={`${t('Common.zipcode')}...`}
            />
          </InputGroup>

          <Box w={'100%'} position="absolute" zIndex={99} background={backgroundColor} data-private>
            {!isPlacePredictionsLoading ? (
              shouldDisplayAutocompleteOptions && (
                <PredictionsList placePredictions={placePredictions} onSelectLocation={handleSelectLocation} />
              )
            ) : (
              <Flex direction={'column'} justify={'center'} align={'center'} border="1px solid #9FA0A4" py={6}>
                <Spinner size={'lg'} />
                <Text fontSize={'sm'}>{t('Common.loading suggestions')}...</Text>
              </Flex>
            )}
          </Box>
          <FormHelperText mb={1}>{t('Common.zipcode is used')}</FormHelperText>
          <FormErrorMessage data-testid="site-postcode-error">{errors?.postCode?.message}</FormErrorMessage>
        </FormControl>

        <FormControl mt={3} isInvalid={!!errors.name}>
          <Input
            {...register('name')}
            borderRadius={0}
            height="50px"
            type="text"
            data-private
            autoComplete="off"
            data-testid="site-home-name"
            background={backgroundColor}
            borderColor={'customGrey.400'}
            placeholder={`${t('Common.home name')}...`}
            defaultValue={site.site_name.includes('cIAM') ? '' : site.site_name}
          />
          <FormHelperText mb={1}>{t('Common.home name example')}</FormHelperText>
          <FormErrorMessage data-testid="site-name-error">{errors?.name?.message}</FormErrorMessage>
        </FormControl>

        <LanguageSelector mt={4} />

        <Center flexDirection="column">
          <ArcButton
            isLoading={isSubmitting}
            my={5}
            type="submit"
            data-testid="save-site-address-button"
            w={'80%'}
            arcColor="#3DCD57"
          >
            {t('Common.continue')}
          </ArcButton>
          <Text mx={8} mb={6} textAlign="center">
            {t('Set Address.by clicking continue')}{' '}
            <Link fontWeight={600} data-testid="privacy-notice-link" color={linkColor} onClick={onPrivacyOpen}>
              {t('Privacy Notice.privacy notice')}
            </Link>{' '}
            {t('Set Address.and')}{' '}
            <Link fontWeight={600} data-testid="terms-of-use-link" color={linkColor} onClick={onTermsOpen}>
              {t('Common.terms of use')}
            </Link>
          </Text>
          <PrivacyNoticeModal isOpen={isPrivacyOpen} onClose={onPrivacyClose} />
          <TermsOfUseModal isOpen={isTermsOpen} onClose={onTermsClose} />
        </Center>
      </Box>
    </SlidingAnimationPageBase>
  );
}

type PredictionsListProps = {
  placePredictions: google.maps.places.AutocompletePrediction[];
  onSelectLocation: (location: google.maps.places.AutocompletePrediction) => void;
};

function PredictionsList({ placePredictions, onSelectLocation }: PredictionsListProps) {
  const { t } = useTranslation();

  return placePredictions.length ? (
    <Box data-testid="site-postcode-prediction-list" width={{ base: '100%', lg: '50%' }} border="1px solid #9FA0A4">
      {placePredictions.map((location, i) => (
        <Box
          data-testid={`site-postcode-prediction-${i}`}
          borderBottom={'1px solid grey'}
          key={`google-maps-location-${location.place_id}`}
          onClick={async () => onSelectLocation(location)}
          cursor={'pointer'}
          py={2}
          px={2}
        >
          <Flex align={'center'} as={'button'} type={'button'}>
            <Box mr={1}>
              <FaMapMarkerAlt />
            </Box>
            <Text fontWeight={600} fontSize={'sm'}>
              {location.description}
            </Text>
          </Flex>
        </Box>
      ))}
      <Text m={2} fontSize={'xs'}>
        {t('Common.powered by google')}
      </Text>
    </Box>
  ) : null;
}
