import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  subHeader?: string;
  children?: React.ReactNode;
};

/* istanbul ignore next -- @preserve */
const WifiConfigAlertDialogModal = ({ isOpen, onClose, header, subHeader, children }: Props) => {
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const backgroundColor = useColorModeValue('#ffffff', 'customGrey.800');
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent
          data-testid="alert-dialog-modal"
          mx={[4, 0]}
          bg={backgroundColor}
          maxWidth="xs"
          rounded={20}
          overflow="hidden"
        >
          <Flex direction={'column'} align="center">
            {header && (
              <AlertDialogHeader lineHeight={'22px'} textAlign="center" pb={0} fontSize="lg" fontWeight="bold">
                {header}
              </AlertDialogHeader>
            )}
            {subHeader && (
              <AlertDialogBody textAlign="center" fontSize={'13px'} pt={2} mb={4}>
                {subHeader}
              </AlertDialogBody>
            )}
          </Flex>
          {children}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

type ConfirmRelatedProps = {
  confirmButtonName?: string;
  onConfirm: () => void | Promise<void>;
  isLoading?: boolean;
};

type WifiConfigAlertDialogModalConfirmOnlyProps = ConfirmRelatedProps & Props;

/* istanbul ignore next -- @preserve */
export const WifiConfigAlertDialogModalConfirmOnly = ({
  onConfirm,
  confirmButtonName,
  isLoading,
  ...props
}: WifiConfigAlertDialogModalConfirmOnlyProps) => {
  const borderColor = useColorModeValue('#D9D9D9', '#494B50');

  return (
    <WifiConfigAlertDialogModal {...props}>
      <Button
        isLoading={isLoading}
        variant="ghost"
        colorScheme="primaryBranding"
        onClick={onConfirm}
        w={'100%'}
        rounded={0}
        py={6}
        borderTop="1px solid"
        borderColor={borderColor}
        data-testid="wifi-alert-dialog-confirm-btn"
      >
        {confirmButtonName}
      </Button>
    </WifiConfigAlertDialogModal>
  );
};

type CancelRelatedProps = {
  cancelButtonName?: string;
  onCancel?: () => void;
};
type WifiConfigAlertDialogModalDualButtonProps = CancelRelatedProps & ConfirmRelatedProps & Props;

/* istanbul ignore next -- @preserve */
export const WifiConfigAlertDialogModalDualButton = ({
  confirmButtonName,
  cancelButtonName = 'Cancel',
  onConfirm,
  onCancel,
  isLoading,
  ...props
}: WifiConfigAlertDialogModalDualButtonProps) => {
  const borderColor = useColorModeValue('#D9D9D9', '#494B50');

  return (
    <WifiConfigAlertDialogModal {...props}>
      <Flex w="100%" justify={'space-evenly'}>
        <Button
          variant="ghost"
          w="50%"
          rounded={0}
          py={6}
          borderTop="1px solid"
          fontWeight={'normal'}
          borderColor={borderColor}
          onClick={onCancel || props.onClose}
          data-testid="wifi-alert-dialog-cancel-btn"
        >
          {cancelButtonName}
        </Button>
        <Button
          isLoading={isLoading}
          variant="ghost"
          colorScheme="primaryBranding"
          onClick={onConfirm}
          w={'50%'}
          rounded={0}
          py={6}
          borderTop="1px solid"
          borderLeft={'1px solid'}
          borderColor={borderColor}
          data-testid="wifi-alert-dialog-confirm-btn"
        >
          {confirmButtonName}
        </Button>
      </Flex>
    </WifiConfigAlertDialogModal>
  );
};

export default WifiConfigAlertDialogModal;
