import React, { useMemo, useState } from 'react';
import { Center, FormControl, FormErrorMessage, Heading, Image, Input, Text, useToast } from '@chakra-ui/react';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import { useSelector } from 'react-redux';
import { selectSite } from '../../../../site/siteSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArcButton from '../../../../../common/components/ArcButton';
import { get } from '../../../../../common/api/api-helpers';
import { Wifi } from '@capacitor-community/wifi/src';
// import { get as nativeGet } from '../api-helpers';
// import { MonitorStatus } from './wireless-access-api-types';
// import { BASE_IPV4_ADDRESS } from './constants';
import { WirelessAccessPointConnectionStatus } from './types';
import wifiConnectingImg from '../../../../../assets/images/wifi_connecting.svg';
import wifiConnectionFailed from '../../../../../assets/images/inverter_connection_failed.svg';
import TopNavProgressLoader from '../../../../../common/components/TopNavProgressLoader';
import { timeout } from '../../../../../utils/common/common-utils';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import semQrCodeImg from '../../../../../assets/images/sem_qr_code_scan.svg';

type State = {
  isSerialNumInvalid: boolean;
  isSubmitting: boolean;
  serialNum: string;
  status: WirelessAccessPointConnectionStatus;
};

const INITIAL_STATE: State = {
  isSerialNumInvalid: false,
  isSubmitting: false,
  serialNum: '',
  status: 'NONE',
};

/* istanbul ignore next -- @preserve */
export function MeterManualSerialEntry() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const toast = useToast({ isClosable: true });
  const [state, setState] = useState<State>(INITIAL_STATE);
  const { serialNum, isSerialNumInvalid, isSubmitting, status } = state;
  const { t } = useTranslation();

  async function fetchAccountId(serialNum: string): Promise<{ account_id: number | null } | null> {
    try {
      return await get<{ account_id: number }>(`/v1/sites/${site.site_id}/sense_account_id/${serialNum}`);
    } catch (e) {
      const error = e as AxiosError;
      console.error(e);

      if (error.response?.status === 404) {
        // The SEM may not yet be in our fleet, so a 404 can happen -- let the user proceed with an empty account id,
        // since in this case there should be no requirement for digest auth.
        return {
          account_id: null,
        };
      } else {
        toast({
          title: t('Set Up Hardware.error fetching account id'),
          description: t('Set Up Hardware.please ensure the network has internet'),
          status: 'error',
        });
      }

      return null;
    }
  }

  async function handleConnectToNetwork() {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    try {
      const { password } = await get<{ password: string }>(`/v1/sense/${serialNum}/password`);
      // Before connecting to the Wi-Fi, we need to fetch the accountId for the site.
      // It's expected the user will have an internet connection at this point.
      const accountIdData = await fetchAccountId(serialNum);
      if (!accountIdData) return;
      const { account_id: accountId } = accountIdData;

      try {
        const ssid = `Schneider Monitor ${serialNum}`;
        await Wifi.connect({ ssid, password, isHiddenSsid: false });
        // Here we wait a bit after connecting to the Wi-Fi before moving on to ensure we are connected -
        // - before immediately making any network requests
        await timeout(2000);
        try {
          // This ensures when coming from hardware details page, we can go back to the same page when going back
          const params = new URLSearchParams(search);
          params.append('connectedSSID', ssid);
          params.append('accountId', accountId?.toString() ?? '');
          navigate(`../connection_tests?${params.toString()}`);
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
          setState((prevState) => ({ ...prevState, isSubmitting: false }));
          toast({
            title: t('Set Up Hardware.error running commissioning tests'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'info',
          });
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setState((prevState) => ({ ...prevState, isSerialNumInvalid: true, isSubmitting: false }));
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
      toast({
        title: t('Set Up Hardware.error generating sem pw'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'info',
      });
    }
  }

  let contents = (
    <>
      <Image w={'70%'} my={4} src={semQrCodeImg} alt="Schneider Energy Monitor sketch" />

      <Heading mt={2}>{t('Set Up Hardware.type serial number of device')}</Heading>
      <Text mt={1}>{t('Set Up Hardware.search your wifi for sem')}</Text>
      <Text mt={1}>{t('Set Up Hardware.for example sem wifi')}</Text>

      <FormControl mt={3} isInvalid={isSerialNumInvalid}>
        <Input
          data-testid={'meter-serial-number'}
          value={serialNum}
          onChange={(e) => setState((p) => ({ ...p, serialNum: e.target.value }))}
          borderRadius={0}
          type="text"
          data-private
          background="white"
          _dark={{
            background: '#363E40',
          }}
          borderColor={'#9FA0A4'}
        />
        <FormErrorMessage>{t('Set Up Hardware.no wifi for serial')}</FormErrorMessage>
      </FormControl>

      <ArcButton
        data-testid={'meter-connect-to-network'}
        isLoading={isSubmitting}
        onClick={handleConnectToNetwork}
        isDisabled={!serialNum}
        mt={8}
        w={'80%'}
        arcColor="#3DCD57"
      >
        {t('Common.connect')}
      </ArcButton>
    </>
  );

  if (status === 'CONNECTING') {
    contents = (
      <>
        <TopNavProgressLoader />
        <Image w={'70%'} src={wifiConnectingImg} alt="Connecting to meter" />
        <Heading mt={2}>{t('Set Up Hardware.searching wifi')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.this allows the device', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
      </>
    );
  }

  if (status === 'FAILED') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="Connection failed" />
        <Heading mt={2}>{t('Set Up Hardware.unable to connect')}</Heading>
        <Text mt={1}>
          {' '}
          {t('Set Up Hardware.unable to connect to device wap', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
        <Text mt={1}>{t('Common.please try again')}</Text>
        <Text mt={1}>{t('Common.if this persists contact support')}</Text>
      </>
    );
  }

  if (status === 'STATUS_CHECK_FAILED') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="Connection failed" />
        <Heading mt={2}>{t('Set Up Hardware.device setup problem')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.issue with hardware config')}</Text>
      </>
    );
  }

  // This ensures when coming from hardware details page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    const params = new URLSearchParams(search);
    params.append('direction', 'back');
    return params.toString();
  }, []);

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../power_up_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={3} mt={5} textAlign="center">
        {contents}
      </Center>
    </SlidingAnimationPageBase>
  );
}
