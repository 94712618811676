import { AngleDescription, angleToPosition } from './circularGeometry';

/**
 * Get position of the start and end position of an arc/circle.
 *
 * @param startAngle - The start angle of the arc/circle.
 * @param endAngle - The end angle of the arc/circle.
 * @param angleType - The angle description of the start and end angle.
 * @param radius - The radius of the arc/circle.
 * @param svgSize - The size of the svg.
 */
function getStartAndEndPosition(opts: {
  startAngle: number;
  endAngle: number;
  angleType: AngleDescription;
  radius: number;
  svgSize: number;
}) {
  const { startAngle, endAngle, radius, svgSize, angleType } = opts;

  let isCircle = false;
  if (startAngle !== endAngle && startAngle % 360 === endAngle % 360) {
    // if it's a full circle, we can't naively draw an arc...
    // https://stackoverflow.com/questions/5737975/circle-drawing-with-svgs-arc-path
    isCircle = true;
  }

  const startPosition = angleToPosition({ degree: startAngle, ...angleType }, radius, svgSize);
  const endPosition = angleToPosition(
    { degree: isCircle ? endAngle - 0.001 : endAngle, ...angleType },
    radius,
    svgSize
  );

  return { startPosition, endPosition, isCircle };
}

/**
 * Generate a path for a pie shaped element i.e. track of the slider.
 *
 * @param startAngle - The start angle of the pie.
 * @param endAngle - The end angle of the pie.
 * @param angleType - The angle description of the start and end angle.
 * @param radius - The radius of the pie.
 * @param svgSize - The size of the svg.
 * @param direction - The direction of the pie.
 */
export function pieShapedPath(opts: {
  startAngle: number;
  endAngle: number;
  angleType: AngleDescription;
  radius: number;
  svgSize: number;
  direction: 'cw' | 'ccw';
}) {
  const { radius, svgSize, direction } = opts;
  const { startPosition, endPosition } = getStartAndEndPosition(opts);
  return `
    M ${svgSize / 2},${svgSize / 2}
    L ${startPosition.x},${startPosition.y}
    A ${radius} ${radius} 0 ${direction === 'cw' ? '1 1' : '0 0'}
      ${endPosition.x} ${endPosition.y}
    Z
  `;
}

/**
 * Generate a path for an arc shaped element i.e. thumb of the slider.
 *
 * @param startAngle - The start angle of the arc.
 * @param endAngle - The end angle of the arc.
 * @param angleType - The angle description of the start and end angle.
 * @param radius - The radius of the arc.
 * @param svgSize - The size of the svg.
 * @param direction - The direction of the arc.
 */
export function arcShapedPath(opts: {
  startAngle: number;
  endAngle: number;
  angleType: AngleDescription;
  radius: number;
  svgSize: number;
  direction: 'cw' | 'ccw';
}) {
  const { startAngle, endAngle, radius, direction } = opts;
  const { startPosition, endPosition, isCircle } = getStartAndEndPosition(opts);

  const largeArc = endAngle - startAngle >= 180;

  return `
      M ${startPosition.x},${startPosition.y}
      A ${radius} ${radius} 0
        ${largeArc ? '1' : '0'}
        ${direction === 'cw' ? '1' : '0'}
        ${endPosition.x} ${endPosition.y}
        ${isCircle ? 'Z' : ''}
    `;
}

/**
 * Generate a path for an arc with rounded ends.
 *
 * @param startAngle - The start angle of the arc.
 * @param endAngle - The end angle of the arc.
 * @param angleType - The angle description of the start and end angle.
 * @param innerRadius - The inner radius of the arc.
 * @param thickness - The thickness of the arc.
 * @param svgSize - The size of the svg.
 * @param direction - The direction of the arc.
 */
export function arcPathWithRoundedEnds(opts: {
  startAngle: number;
  endAngle: number;
  angleType: AngleDescription;
  innerRadius: number;
  thickness: number;
  svgSize: number;
  direction: 'cw' | 'ccw';
  isArcRounded?: boolean;
}) {
  const { startAngle, innerRadius, thickness, direction, angleType, svgSize, isArcRounded = true } = opts;
  let { endAngle } = opts;

  if (startAngle % 360 === endAngle % 360 && startAngle !== endAngle) {
    // Drawing a full circle, slightly offset end angle
    // https://stackoverflow.com/questions/5737975/circle-drawing-with-svgs-arc-path
    endAngle = endAngle - 0.001;
  }
  const largeArc = endAngle - startAngle >= 180;
  const outerRadius = innerRadius + thickness;

  const innerArcStart = angleToPosition({ degree: startAngle, ...angleType }, innerRadius, svgSize);
  const startPoint = `
    M ${innerArcStart.x},${innerArcStart.y}
  `;

  const innerArcEnd = angleToPosition({ degree: endAngle, ...angleType }, innerRadius, svgSize);
  const innerArc = `
    A ${innerRadius} ${innerRadius} 0
      ${largeArc ? '1' : '0'}
      ${direction === 'cw' ? '1' : '0'}
      ${innerArcEnd.x} ${innerArcEnd.y}
  `;

  const outerArcStart = angleToPosition({ degree: endAngle, ...angleType }, outerRadius, svgSize);
  const firstButt = `
    A ${thickness / 2} ${thickness / 2} 0
      ${largeArc ? '1' : '0'}
      ${direction === 'cw' ? '0' : '1'}
      ${outerArcStart.x} ${outerArcStart.y}
  `;

  const outerArcEnd = angleToPosition({ degree: startAngle, ...angleType }, outerRadius, svgSize);
  const outerArc = `
    A ${outerRadius} ${outerRadius} 0
      ${largeArc ? '1' : '0'}
      ${direction === 'cw' ? '0' : '1'}
      ${outerArcEnd.x} ${outerArcEnd.y}
  `;

  const secondButt = `
    A ${thickness / 2} ${thickness / 2} 0
      ${largeArc ? '1' : '0'}
      ${direction === 'cw' ? '0' : '1'}
      ${innerArcStart.x} ${innerArcStart.y}
  `;

  return startPoint + innerArc + firstButt + outerArc + (isArcRounded ? secondButt : '') + ' Z';
}
