export const MATTER_DEVICE_ACTIVITY_UI_CONFIG: MatterDeviceActivityUIConfig[] = [
  {
    switchVariant: 'gridRed',
    color: '#DB5E52',
    fillGradientStart: 'rgba(219, 94, 82, 0.3)',
    fillGradientStop: 'rgba(219, 94, 82, 0)',
  },
  {
    switchVariant: 'orange',
    color: '#F19F39',
    fillGradientStart: 'rgba(241, 159, 57, 0.3)',
    fillGradientStop: 'rgba(241, 159, 57, 0)',
  },
  {
    switchVariant: 'yellow',
    color: '#FEDC38',
    fillGradientStart: 'rgba(254, 220, 56, 0.3)',
    fillGradientStop: 'rgba(254, 220, 56, 0)',
  },
  {
    switchVariant: 'solarGreen',
    color: '#3DCD58',
    fillGradientStart: 'rgba(61, 205, 88, 0.3)',
    fillGradientStop: 'rgba(61, 205, 88, 0)',
  },
  {
    switchVariant: 'batteryBlue',
    color: '#42B4E6',
    fillGradientStart: 'rgba(66, 180, 230, 0.3)',
    fillGradientStop: 'rgba(66, 180, 230, 0)',
  },
];

export type MatterDeviceActivityUIConfig = {
  switchVariant: string;
  color: string;
  fillGradientStart: string;
  fillGradientStop: string;
};

export const MAX_ACTIVITY_MATTER_DEVICES = MATTER_DEVICE_ACTIVITY_UI_CONFIG.length;
