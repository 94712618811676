import React from 'react';
import { Flex, Center, Skeleton, Text } from '@chakra-ui/react';
import SEHomeCard from '../../../common/components/SEHomeCard';
import { useLiveData } from '../../site/live-data/liveDataApi';
import { useTranslation } from 'react-i18next';

export default function PowerSummaryCards() {
  const { data: liveDataSummary, isMeterDataLoading } = useLiveData();
  const { t } = useTranslation();

  return (
    <Flex flexDir="column">
      <Skeleton
        isLoaded={!isMeterDataLoading}
        borderBottomLeftRadius={'5px'}
        borderBottomRightRadius={'25px'}
        borderTopRightRadius={'5px'}
        borderTopLeftRadius={'25px'}
        my={2}
      >
        {!!liveDataSummary.solar ? (
          <SEHomeCard width="100%" mr={2} data-testid="solar-power-summary-card">
            <Center alignItems="baseline">
              <Text textAlign="center" fontSize={30} mr={1}>
                {Intl.NumberFormat('en', { maximumFractionDigits: 1 }).format(liveDataSummary.solar)}
              </Text>
              kW
            </Center>

            <Text textAlign="center" fontSize={19}>
              {t('Home Screen.solar power')}
            </Text>
          </SEHomeCard>
        ) : (
          <></>
        )}
      </Skeleton>
      <SEHomeCard width="100%" data-testid="grid-power-summary-card">
        <Center alignItems="baseline">
          <Skeleton isLoaded={!isMeterDataLoading} minW={6} borderRadius={4} mr={1}>
            <Text textAlign="center" fontSize={30}>
              {Intl.NumberFormat('en', {
                maximumFractionDigits: 1,
              }).format(liveDataSummary.grid)}
            </Text>
          </Skeleton>
          kW
        </Center>

        <Text textAlign="center" fontSize={19}>
          {t('Home Screen.grid power')}
        </Text>
      </SEHomeCard>
    </Flex>
  );
}
