import React, { useState } from 'react';
import { mapTariffApiToForm } from './tariff-helpers';
import { MIN_TARIFF_PAGE_HEIGHT, useTranslatedDefaultTariffData } from './tariff-constants';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { useSelector } from 'react-redux';
import { selectSite } from '../site/siteSlice';
import { useDeleteTariffMutation, useTariffs } from './tariffApi';
import { Badge, Box, Center, Heading, IconButton, Text, useToast, VStack } from '@chakra-ui/react';
import ArcButton from '../../common/components/ArcButton';
import { useNavigate } from 'react-router-dom';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { DeleteIcon } from '@chakra-ui/icons';
import { useTariffFormContext } from './useTariffFormContext';
import { format } from 'date-fns';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { useTranslation } from 'react-i18next';

export const TariffList = () => {
  const { reset } = useTariffFormContext();
  const site = useSelector(selectSite);
  const { isLoading, tariffs } = useTariffs();
  const navigate = useNavigate();
  const [{ isDeleteModalOpen, tariffId, tariffName }, setState] = useState({
    isDeleteModalOpen: false,
    tariffId: 0,
    tariffName: '',
  });
  const toast = useToast({ isClosable: true, duration: 5000 });
  const [deleteTariff, { isLoading: isDeleting }] = useDeleteTariffMutation();
  const { t } = useTranslation();
  const DEFAULT_TARIFF_DATA = useTranslatedDefaultTariffData();

  if (isLoading) return <CenteredLoader text={`${t('Common.loading')}...`} minHeight={'50vh'} />;

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.manual energy rates set up')}
      includeTopNav
      px={4}
      backURL={`../../dashboard?direction=back`}
      data-testid="tariff-list"
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT}>
        <Text px={2}>
          {tariffs.length ? t('Energy Rates.select a tariff to edit') : t('Energy Rates.you have not created tariffs')}
        </Text>
        {!tariffs.length && (
          <Text px={2} data-testid="empty-tariff-message">
            {t('Energy Rates.click on the create new tariff')}
          </Text>
        )}
        <Box
          maxHeight={`calc(${MIN_TARIFF_PAGE_HEIGHT} - 120px)`}
          overflowY={'auto'}
          bg="rgba(0,0,0,0.05)"
          _dark={{ bg: 'rgba(255,255,255,0.05)' }}
          borderRadius={4}
          data-testid="tariff-list-container"
        >
          {tariffs.map((siteTariff, index, tariffList) => {
            const { id, tariff, tariff_effective_date: effectiveDate } = siteTariff;
            const getTariffEffectiveDateRange = () => {
              if (index === 0) return `${effectiveDate} ${t('Energy Rates.to')} ${t('Energy Rates.present')}`;
              const previousTariff = tariffList[index - 1];
              return `${effectiveDate} ${t('Energy Rates.to')} ${previousTariff.tariff_effective_date}`;
            };
            return (
              <Box
                aria-label={'view'}
                onClick={() => {
                  reset(mapTariffApiToForm(siteTariff));
                  const redirectBackURL = `/site/${site.site_id}/account/tariff/home`;
                  navigate(`../${id}/${tariff.tariff_type.toLowerCase()}/confirm?backURL=${redirectBackURL}`);
                }}
                m={2}
                p={5}
                key={id}
                shadow="md"
                borderWidth="1px"
                bg="white"
                _dark={{ bg: '#111111' }}
                data-testid={`tariff-list-item-${index}`}
              >
                <Center justifyContent={'space-between'}>
                  <Heading fontSize="xl" maxW={'85vw'} noOfLines={1}>
                    {tariff.plan_name}
                  </Heading>
                  <Badge>{t(`Energy Rates.types.${tariff.tariff_type}.short label`)}</Badge>
                </Center>

                <Text noOfLines={1} mt={1}>
                  {getTariffEffectiveDateRange()}
                </Text>

                <Center justifyContent={'space-between'}>
                  <Text>{tariff.retailer?.name}</Text>
                  <IconButton
                    aria-label="delete-tariff"
                    data-testid={`delete-tariff-button-${index}`}
                    variant={'ghost'}
                    minH={'fit-content'}
                    minW={'fit-content'}
                    h={7}
                    w={7}
                    borderRadius={50}
                    onClick={(e) => {
                      e.stopPropagation();
                      setState((state) => ({
                        ...state,
                        isDeleteModalOpen: true,
                        tariffId: id || 0,
                        tariffName: tariff.plan_name || '',
                      }));
                    }}
                  >
                    <DeleteIcon color="red.500" />
                  </IconButton>
                </Center>
              </Box>
            );
          })}
        </Box>

        <AlertDialogModal
          isOpen={isDeleteModalOpen}
          onClose={() => setState((state) => ({ ...state, isDeleteModalOpen: false }))}
          header={t('Energy Rates.delete plan', { tariffName })}
          subHeader={t('Energy Rates.are you sure delete tariff')}
          confirmButtonName={t('Common.delete')}
          onConfirm={async () => {
            const response = await deleteTariff(tariffId);
            const isError = 'error' in response;

            toast({
              title: isError ? t('Energy Rates.error deleting tariff') : t('Energy Rates.successfully deleted'),
              status: isError ? 'error' : 'success',
            });
            if (!isError) setState((state) => ({ ...state, isDeleteModalOpen: false }));
          }}
          confirmButtonTextColor="red.500"
          isConfirming={isDeleting}
          closeOnConfirm={false}
        />

        <Center mt="auto" mb={5} flexDir={'column'}>
          <ArcButton
            arcColor="#3DCD58"
            minWidth="280px"
            py={6}
            mt="auto"
            data-testid="create-new-tariff-button"
            onClick={() => {
              // check if the effective date of DEFAULT_TARIFF_DATA is unique
              const isUniqueEffectiveDate = tariffs.every(
                (tariff) => tariff.tariff_effective_date !== DEFAULT_TARIFF_DATA.effectiveDate
              );
              const newTariffData = DEFAULT_TARIFF_DATA;

              // if effective date is not unique, reset the date to the next day of latest tariff in effect
              if (!isUniqueEffectiveDate) {
                const newEffectiveDate = new Date(tariffs[0].tariff_effective_date);
                newEffectiveDate.setDate(newEffectiveDate.getDate() + 1);
                newTariffData.effectiveDate = format(newEffectiveDate, 'yyyy-MM-dd');
              }

              reset(newTariffData);
              navigate(`../new/home`);
            }}
          >
            {t('Energy Rates.create new tariff')}
          </ArcButton>
        </Center>
      </VStack>
    </SlidingAnimationPageBase>
  );
};
