import React from 'react';
import { createIcon } from '@chakra-ui/react';

export function createBatteryReserveIcon(percentageFilled: number, fillColor = '#42B4E6', borderColor = '#42B4E6') {
  // We only fill ~80% of the space to account for the cap
  const inverseFill = Math.round(100 - percentageFilled * 0.8);

  return createIcon({
    displayName: 'BatteryReserveIcon',
    path: (
      <svg width="63" height="116" viewBox="0 0 63 116" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d={`M54.0349 ${inverseFill}H8.96507C8.43208 ${inverseFill} 8 ${inverseFill}.3304 8 ${inverseFill}.7379V107.262C8 107.67 8.43208 108 8.96507 108H54.0349 C54.5679 108 55 107.67 55 107.262 C55 ${inverseFill}.3304 55 ${inverseFill} 54.0349 ${inverseFill}Z`}
          fill={fillColor}
        />
        <path
          // eslint-disable-next-line max-len
          d="M62.8654 16.5814C62.8654 13.0979 60.0322 10.2647 56.5487 10.2647H45.7034V6.96697C45.7034 3.11191 42.5915 0 38.7365 0H24.1058C20.2508 0 17.1389 3.11191 17.1389 6.96697V10.2647H6.34008C2.83338 10.2414 0.000141754 13.0747 0.000141754 16.5814V109.683C-0.0230815 113.167 2.81015 116 6.29364 116H56.5255C60.009 116 62.8422 113.167 62.8422 109.683V16.5814H62.8654ZM59.3355 109.707C59.3355 111.262 58.0814 112.517 56.5255 112.517H6.29364C4.73768 112.517 3.48363 111.262 3.48363 109.707V16.5814C3.50685 15.0254 4.7609 13.7714 6.31686 13.7714H18.9503C19.9024 13.7714 20.692 12.9818 20.692 12.0296V6.8973C20.692 5.03944 22.2015 3.52993 24.0594 3.52993H38.69C40.5479 3.52993 42.0574 5.03944 42.0574 6.8973V12.0296C42.0574 12.9818 42.847 13.7714 43.7991 13.7714H56.5255C58.0814 13.7714 59.3355 15.0254 59.3355 16.5814V109.683V109.707Z"
          fill={borderColor}
        />
      </svg>
    ),
    viewBox: '0 0 63 116',
  });
}
