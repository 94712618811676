import React, { useMemo } from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import semQrCodeImg from '../../../../../assets/images/sem_qr_code_scan.svg';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function MeterPowerUpInstructions() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();

  const backURL = useMemo(() => {
    const deviceId = search.get('deviceId');
    // If we have a deviceId, we are coming from the meter details page
    return deviceId ? `../../../${deviceId}/view?direction=back` : `../start?direction=back`;
  }, [search]);

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={backURL}>
      <Center flexDirection="column" px={3} mt={6} textAlign="center">
        <Image w={'70%'} my={4} src={semQrCodeImg} alt="Schneider Energy Monitor sketch" />

        <Heading mt={2}>{t('Set Up Hardware.activate sem title')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.on your device there is a qr', {
            device: t('Set Up Hardware.energy monitor'),
          })}{' '}
          {t('Set Up Hardware.use your camera to scan', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
        <Text mt={1}>{t('Set Up Hardware.alternatively you can manually enter serial')}</Text>

        {/* @TODO: info box describing scenario where there is no broadcast AP? */}

        <ArcButton
          data-testid={'meter-scan-qr-code'}
          onClick={() => {
            navigate(`../scan?${search.toString() || ''}`);
          }}
          mt={8}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.scan qr code')}
        </ArcButton>

        <ArcButton
          data-testid={'meter-enter-serial-number'}
          onClick={() => {
            navigate(`../manual_serial_entry?${search.toString() || ''}`);
          }}
          mt={3}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Set Up Hardware.enter serial number')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
