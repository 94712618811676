export type EnvironmentType = 'development' | 'production' | 'staging';
export type ShorthandEnvironmentType = 'DEV' | 'PROD';
export let ENV_TYPE = import.meta.env?.MODE === 'production' ? 'PROD' : 'DEV';

if (window['Cypress' as any]) {
  ENV_TYPE = 'DEV';
}

// The clipsal deploy account will always default to DEV, the schneider account will default to prod.
// This is related to the way we handle push notification certs in the back-end system.
if (import.meta.env?.VITE_DEPLOY_ACCOUNT === 'clipsal') {
  ENV_TYPE = 'DEV';
}

// Check local storage for a different env setting (users can override this)
const fromStorage = localStorage.getItem('seHomeEnvType');

if (fromStorage) {
  ENV_TYPE = fromStorage;
}
