import { baseApi } from '../../../app/services/baseApi';
import { EnergyModesResponse, EnergyMode } from 'clipsal-cortex-types/src/api/api-energy-modes';

export const energyModesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEnergyModes: build.query<EnergyModesResponse, number>({
      query: (siteId) => `/v1/sites/${siteId}/energy_mode`,
      providesTags: ['EnergyModes'],
    }),
    updateEnergyModes: build.mutation<EnergyModesResponse, { siteId: number; energyMode: EnergyMode }>({
      query: ({ siteId, energyMode }) => ({
        url: `/v1/sites/${siteId}/energy_mode`,
        method: 'PATCH',
        body: {
          energy_mode: energyMode,
        },
      }),
      invalidatesTags: ['EnergyModes'],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        // Optimistic update so the user gets fast feedback. The mutation happens in the background.
        const { siteId, energyMode } = arg;
        const patchResult = dispatch(
          energyModesApi.util.updateQueryData('getEnergyModes', siteId, (draft) => {
            // Update this setting in the drafted state. Note that immer provides mutability here!
            draft.pending = {
              energy_mode: energyMode,
            };
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetEnergyModesQuery, useUpdateEnergyModesMutation } = energyModesApi;
