import React, { useEffect, useMemo } from 'react';
import { Alert, AlertDescription, AlertIcon, Box, Center, Grid, Heading, Skeleton, Text } from '@chakra-ui/react';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { useLocation, useParams } from 'react-router-dom';
import { useGetNotificationByIdQuery, useUpdateNotificationByIdMutation } from './notificationsApi';
import ReactMarkdown from 'react-markdown';
import NotificationTimestamp from './NotificationTimestamp';
import { useGetNotificationConfigForType } from './notifications-helper';
import { useTranslation } from 'react-i18next';
import { IS_DEMO_LOGIN } from '../../common/constants';

export function ViewNotification() {
  const { notificationId = '' } = useParams<{ notificationId: string }>();
  const { t } = useTranslation();
  const [updateNotification] = useUpdateNotificationByIdMutation();
  const { data: notification, isLoading, isError } = useGetNotificationByIdQuery(notificationId);
  const { state } = useLocation();
  // If the user navigated here directly, there won't be a previous path in state
  const backURL = useMemo(() => state?.prevPath ?? `../list?direction=back`, [state]);
  const { icon, title, child } = useGetNotificationConfigForType(notification?.notification_type ?? 'TEST');

  useEffect(() => {
    if (!isLoading && !isError && !notification!.read && !IS_DEMO_LOGIN) {
      updateNotification({ notificationId: notificationId, body: { read: true } });
    }
  }, [notification, isLoading]);

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Notifications.message')}>
      <Box mx={3}>
        {isError ? (
          <Alert status="error" my={4}>
            <AlertIcon w={8} h={8} />
            <AlertDescription py={4}>
              {t('Notifications.error fetching notifications')}.{' '}
              {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
            </AlertDescription>
          </Alert>
        ) : (
          <>
            <Skeleton isLoaded={!isLoading}>
              <Grid templateColumns={'75px 3fr 1fr'} gap={1}>
                <Center>{icon}</Center>
                <Text data-testid="notification-title">{title}</Text>
                {notification && <NotificationTimestamp notification={notification} />}
              </Grid>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Box px={2} mt={5}>
                <Box data-testid="notification-content-text">
                  <ReactMarkdown
                    components={{
                      h1({ children }) {
                        return (
                          <Heading mb={2} size={'xl'}>
                            {children}
                          </Heading>
                        );
                      },
                      h2({ children }) {
                        return (
                          <Heading mb={2} size={'lg'}>
                            {children}
                          </Heading>
                        );
                      },
                      h3({ children }) {
                        return (
                          <Heading mb={2} size={'md'}>
                            {children}
                          </Heading>
                        );
                      },
                      p({ children }) {
                        return (
                          <Text my={2} fontSize={'md'}>
                            {children}
                          </Text>
                        );
                      },
                      a({ children }) {
                        return (
                          <Box as={'span'} color={'schneiderSkyBlue.500'}>
                            {children}
                          </Box>
                        );
                      },
                    }}
                  >
                    {notification?.content ?? ''}
                  </ReactMarkdown>
                  {child}
                </Box>
              </Box>
            </Skeleton>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
