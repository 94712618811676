import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function FactoryResetAccordion() {
  const { t } = useTranslation();

  return (
    <Accordion allowToggle w="100%" my={4} textAlign={'left'} data-testid="help-guide-accordion">
      <AccordionItem>
        <AccordionButton pl={0} pr={1}>
          <Box as="span" flex="1" textAlign="left" ml={2}>
            <Text fontSize={20} fontWeight={800}>
              {t('Devices.factory reset instructions')}
            </Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={1} data-testid="help-guide-content">
          <OrderedList>
            <ListItem mb={1}>{t('Devices.factory reset step 1')}</ListItem>
            <ListItem mb={1}>{t('Devices.factory reset step 2')}</ListItem>
            <ListItem>{t('Devices.factory reset step 3')}</ListItem>
          </OrderedList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
