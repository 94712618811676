import { convertCelsiusToFahrenheit } from 'clipsal-cortex-utils/src/calculations/number-utils';

/**
 *
 * @returns The temperature format based on the user's preference
 */
export function getTemperatureFormat(): TemperatureFormat {
  if (localStorage.getItem('temperatureFormat') == 'CELSIUS') return 'CELSIUS';
  else return 'FAHRENHEIT';
}
export type TemperatureFormat = 'CELSIUS' | 'FAHRENHEIT';

/**
 *
 * @param temperature - The temperature in Celsius
 * @returns The temperature in the preferred format based on the user's preference
 */
export function getFormattedTemperature(temperature: number): number {
  const temperatureFormat = getTemperatureFormat();
  if (temperatureFormat === 'CELSIUS') return temperature;
  else return convertCelsiusToFahrenheit(temperature);
}

/**
 *
 * @returns The time format based on the user's preference
 */
export function getTimeFormat(): TimeFormat {
  if (localStorage.getItem('timeFormat') == '24HR') return '24HR';
  else return 'AM/PM';
}
export type TimeFormat = '24HR' | 'AM/PM';

/**
 *
 * @param datetime - The datetime to format
 * @returns The datetime formatted based on the user's preference
 */
export function getFormattedTime(datetime: Date): string {
  const timeFormat = getTimeFormat();
  const hours = datetime.getHours();
  const mins = datetime.getMinutes();
  const minutesFormatted = mins < 10 ? '0' + mins : mins;

  if (timeFormat === '24HR') {
    const hoursFormatted = hours < 10 ? '0' + hours : hours;
    return `${hoursFormatted}:${minutesFormatted}`;
  } else {
    let hoursFormatted = hours > 12 ? hours - 12 : hours;
    if (hoursFormatted === 0) hoursFormatted = 12;
    return `${hoursFormatted}:${minutesFormatted} ${hours >= 12 ? 'PM' : 'AM'}`;
  }
}

/**
 *
 * @returns A boolean indicating the user's preference for showing alerts
 */
export function getShutdownAlertUserPreference(): boolean {
  return localStorage.getItem('showShutdownAlerts') !== 'false';
}
