import React from 'react';
import { Flex, Center, Skeleton, Text, Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import SEHomeCard from '../../../common/components/SEHomeCard';
import { useGetCostsQuery } from '../../../features/site/costApi';
import { selectCurrentDayForSite } from '../../../features/site/siteSlice';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import { useTranslation } from 'react-i18next';

export default function TodaysCostCard() {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: costsData, isLoading: isCostsLoading } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const { t } = useTranslation();
  const todaysCost = costsData.costData[0]?.total_cost || 0;

  return (
    <SEHomeCard my={4} data-testid="todays-cost-card">
      <Flex justifyContent="space-evenly" alignItems="center">
        <Text textAlign="center" fontSize={19}>
          {t('Home Screen.todays cost')}
        </Text>
        <Center height="30px">
          <Divider orientation="vertical" />
        </Center>
        <Skeleton isLoaded={!isCostsLoading}>
          <Text textAlign="center" fontSize={28}>
            {formatDollarsCents(todaysCost)}
          </Text>
        </Skeleton>
      </Flex>
    </SEHomeCard>
  );
}
