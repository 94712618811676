import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { ChannelType } from 'aws-sdk/clients/pinpoint';
import { put } from '../../common/api/api-helpers';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { IS_IOS } from '../../common/constants';
import { NavigateFunction } from 'react-router-dom';

type Platform = 'web' | 'ios' | 'android';
export const PLATFORM: Platform = Capacitor.getPlatform() as Platform;

/**
 * Sets up the application to receive push notifications.
 */
const onRegistration = async ({ value }: Token) => {
  // Send our token to the API for registration in our own database
  const platformToChannelType: Record<Platform, ChannelType | null> = {
    web: null,
    android: 'GCM',
    ios: 'APNS',
    // @NOTE: uncomment this when working in the development environment, or PNs will not be received.
    // ios: 'APNS_SANDBOX'
  };

  const channelType = platformToChannelType[PLATFORM];
  if (channelType && value) {
    try {
      await put('/v1/notifications/token', {
        token: value,
        channel_type: channelType,
        app_name: 'SE_HOME',
      });
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * @NOTE
 * This method of listening for deep links is used because setting the app up for deep links as per the
 * Capacitor documentation is not actually possible, because serving a file without an extension from the public
 * directory does not work at all. There is no known solution to this issue and as such deep links cannot work
 * with this app in iOS.
 * Instead, we can just hook onto an action performed on a PN to redirect the user - this is a hacky
 * solution, and if a solution to the above issue with the `apple-app-site-association` file is found at some
 * point the preferred listener for `appUrlOpen` should be implemented instead. Ejecting from CRA at some point
 * might also give more flexibility for accessing static resources, but this is currently out of scope.
 *
 * See: https://bit.ly/3xyrDpl
 */
const onPushNotificationAction = async (action: ActionPerformed, navigate: NavigateFunction) => {
  if (action.actionId === 'tap') {
    let route: string | undefined;
    let url: string | undefined;

    if (IS_IOS) {
      route = action.notification?.data?.aps?.alert?.data?.route;
      url = action.notification?.data?.aps?.alert?.data?.url;
    } else {
      route = action.notification?.data?.route;
      url = action.notification?.data?.url;
    }

    if (route) {
      console.log('going to route: ' + route);
      navigate(route);
      return;
    }
    if (url) {
      console.log('going to url: ' + url);
      await Browser.open({ url: url });
      return;
    }
  }
};

const onNotification = (notification: PushNotificationSchema) => {
  // 'pushNotificationReceived' -> good for when you might want to update the app state based on some event
  console.log(notification);
};

export default async function setupPushNotifications(navigate: NavigateFunction) {
  let permStatus = await PushNotifications.checkPermissions();

  if (['prompt', 'prompt-with-rationale'].includes(permStatus.receive)) {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive === 'granted') {
    // Initialize registration listener functions before beginning the registration process
    PushNotifications.addListener('registration', onRegistration);

    PushNotifications.addListener('pushNotificationReceived', onNotification);

    PushNotifications.addListener('registrationError', (error) => {
      console.error(error);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (action) =>
      onPushNotificationAction(action, navigate)
    );

    await PushNotifications.register();
  }
}
