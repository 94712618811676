import React from 'react';
import SEHomeCard, { SEHomeCardProps } from '../../../common/components/SEHomeCard';
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AnimateLayoutChanges } from '@dnd-kit/sortable/dist/hooks/types';

// Wrapper component for draggable cards.
export default function DraggableSEHomeCard({ children, dragId, ...boxProps }: SEHomeCardProps & { dragId: number }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: dragId,
    animateLayoutChanges,
  });

  return (
    <SEHomeCard
      {...boxProps}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
      {...attributes}
      {...listeners}
    >
      {children}
    </SEHomeCard>
  );
}

// Handles animated layout changes when items are removed.
function animateLayoutChanges(args: Parameters<AnimateLayoutChanges>[0]) {
  const { isSorting, wasDragging } = args;

  if (isSorting || wasDragging) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
}
