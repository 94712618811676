import React from 'react';
import { Button, Text, useToast } from '@chakra-ui/react';
import SEHomeCard from '../../../../common/components/SEHomeCard';
import { configureConsentManagementSettings } from '../consent-management';
import { IS_DEMO_LOGIN, IS_NATIVE } from '../../../../common/constants';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import AccountMenuButton from '../../AccountMenuButton';

export default function ConsentManagementButton() {
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function handleConsentManagementClick() {
    if (IS_DEMO_LOGIN) return;
    /*istanbul ignore if -- @preserve*/
    if (IS_NATIVE) {
      try {
        await configureConsentManagementSettings();
      } catch (e) {
        console.error(e);
        if ((e as AxiosError)?.response?.status === 400) {
          // The consent module requires a valid user access token via cIAM --
          // -- user needs to relog to access this module in the event of an auth error.
          toast({
            title: t('Account.log in required'),
            description: (
              <>
                {t('Account.error fetching consent')}
                <Button mt={2} onClick={() => navigate('/logout')} display="block" bgColor="white" color="black">
                  {t('Account.tap here to logout')}
                </Button>
              </>
            ),
            status: 'error',
            duration: 15000,
            isClosable: true,
          });
        } else {
          Sentry.captureException(e);
          toast({
            title: t('Account.error configuring consent'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'error',
          });
        }
      }
    } else {
      toast({
        title: t('Common.unavailable on web'),
        description: t('Common.download app to use feature'),
        status: 'error',
      });
    }
  }

  return (
    <SEHomeCard mb={5} borderTopLeftRadius="5px" p={0}>
      <AccountMenuButton
        data-testid="consent-management-button"
        onClick={handleConsentManagementClick}
        includeBottomDivider={false}
      >
        <Text fontSize={16} fontWeight="normal">
          {t('Account.consent management')}
        </Text>
      </AccountMenuButton>
    </SEHomeCard>
  );
}
