import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customBaseQuery } from '../../common/api/api-helpers';

export const baseApi = createApi({
  baseQuery: customBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [
    'NotificationSettings',
    'Notifications',
    'Notification',
    'Devices',
    'MeterLiveData',
    'InverterLiveData',
    'AylaLiveData',
    'Usage',
    'Costs',
    'SunriseSunset',
    'Savings',
    'SwitchSchedules',
    'Investments',
    'EnergyModes',
    'Switches',
    'Weather',
    'Invitations',
    'SiteUsers',
    'Retailers',
    'Tariffs',
    'Utilities',
    'SwitchGroups',
    'SiteConsents',
    'BatteryReserve',
    'LocalMatterDevices'
  ],
});

export const {} = baseApi;
