import { baseApi } from '../../../app/services/baseApi';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import { liveDataApi, useGetAylaLiveDataQuery, useLiveData } from '../../site/live-data/liveDataApi';
import { LiveDataSwitchWithPower } from '../../site/live-data/types';
import { SenseSwitch } from 'clipsal-cortex-types/src/api/api-sense-switch';
import useAppVisibility from 'clipsal-cortex-utils/src/hooks/use-app-visibility';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { MANUFACTURER_ID_AYLA, MANUFACTURER_ID_SEM } from '../devices-helper';

type SenseSwitchPatchKey =
  | 'site_switch_label'
  | 'state'
  | 'scheduling'
  | 'restore_requested_state'
  | 'restore_requested_state_on_backup'
  | 'properties';

export type SenseSwitchPatch = Pick<SenseSwitch, SenseSwitchPatchKey>;

export const switchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Currently Ayla switches are also returned from this endpoint - the request path is misleading
    getSwitches: builder.query<SenseSwitch[], number>({
      query: (siteId) => `/v1/sense/sites/${siteId}/switches`,
      providesTags: ['Switches'],
    }),
    // Currently Ayla switches can also be updated via this endpoint - the request path is misleading
    updateSwitch: builder.mutation<SenseSwitch, { siteId: number; switchId: number; body: Partial<SenseSwitchPatch> }>({
      // The siteId is passed here so the optimistic update knows which cache to update.
      query: ({ siteId: _siteId, switchId, body }) => {
        return {
          url: `/v1/sense/switches/${switchId}`,
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { siteId, switchId, body } = arg;
        let switchManufacturerId;
        const patchResult = dispatch(
          switchApi.util.updateQueryData('getSwitches', siteId, (draft) => {
            function updateSwitchesData(arr: SenseSwitch[]) {
              arr.forEach((senseSwitch: SenseSwitch) => {
                if (senseSwitch.id === switchId) {
                  switchManufacturerId = senseSwitch.manufacturer.manufacturer_id;
                  Object.keys(body).forEach((key) => {
                    // SenseSwitchPatch can update 'state' which is from WS data, not GET data here.
                    // This is quite an annoying typing issue to resolve.
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    senseSwitch[key] = body[key];
                  });
                }
              });
            }

            updateSwitchesData(draft);
          })
        );
        try {
          await queryFulfilled;
          // NOTE: If state is the only field updated, invalidate live data without invalidating switches list.
          // This isn't necessary for Sense live data because the state is reflected in the WS data.
          if (body.state) {
            if (switchManufacturerId === MANUFACTURER_ID_AYLA) {
              dispatch(liveDataApi.util.invalidateTags(['AylaLiveData']));
            }
          }
          // Invalidate switches list if any other field is updated
          if (!body.state || Object.keys(body).length > 1) {
            dispatch(switchApi.util.invalidateTags(['Switches']));
          }
        } catch {
          patchResult.undo();
        }
      },
    }),
    registerAylaDevice: builder.mutation<SenseSwitch, { siteId: number; deviceSerialNumber: string }>({
      query: ({ siteId, deviceSerialNumber }) => {
        return {
          url: `/v1/sites/${siteId}/register_ayla_device`,
          method: 'POST',
          body: { dsn: deviceSerialNumber },
        };
      },
      invalidatesTags: ['AylaLiveData', 'Switches', 'Devices'],
    }),
    // Currently only Ayla/Matter switches are able to be deleted - the request path is misleading
    deleteAylaDevice: builder.mutation<SenseSwitch, { switchId: number }>({
      query: ({ switchId }) => {
        return {
          url: `/v1/sense/switches/${switchId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['AylaLiveData', 'Switches', 'Devices'],
    }),
  }),
});

export const {
  useGetSwitchesQuery,
  useUpdateSwitchMutation,
  useRegisterAylaDeviceMutation,
  useDeleteAylaDeviceMutation,
} = switchApi;

// Represents a combination of the data provided from the websocket realtime stream, alongside the data provided from
// the switches GET list API. This is important because any update to a switch besides its state (open/closed) will
// not be updated in the real-time stream for performance reasons -- only in the HTTP GET list API.
export type CombinedSwitch = SenseSwitch & LiveDataSwitchWithPower;

/**
 * Provides a way to get switches data from the live data APIs, combined with the data from the switch metadata API.
 * This is to help consolidate these two sources of data.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetLiveSenseSwitchesQuery(skip = false) {
  const {
    data: switchesFromApi,
    isLoading: isSwitchesLoading,
    isError: isSwitchesError,
    isFetching: isSwitchesFetching,
    ...rest
  } = useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_SEM, skip);

  const { data: liveSenseData, isMeterError, isMeterDataLoading: isSenseDataLoading } = useLiveData(skip);

  let combinedSwitches: CombinedSwitch[] = [];

  if (
    !isSenseDataLoading &&
    !isSwitchesLoading &&
    !isMeterError &&
    switchesFromApi?.length &&
    liveSenseData.switches.length
  ) {
    combinedSwitches = switchesFromApi
      .map((switchFromApi) => {
        const liveSwitch = liveSenseData.switches.find((s) => s.switch_id === switchFromApi.id);
        // We overwrite site_switch_label with API data since it's more up-to-date than WS switch data.
        // When the display name of a switch is renamed, it only propagates to WS switch data in batch jobs.
        return !liveSwitch
          ? null
          : {
              ...switchFromApi,
              ...liveSwitch,
              site_switch_label: switchFromApi.site_switch_label,
            };
      })
      .filter(Boolean) as CombinedSwitch[];
  }

  return {
    ...rest,
    data: combinedSwitches,
    isLoading: isSwitchesLoading || isSenseDataLoading,
    isFetching: isSwitchesFetching,
    isError: isSwitchesError || isMeterError,
    isLiveDataError: isMeterError,
  };
}

/**
 * Provides a way to get switches data from the live data APIs, combined with the data from the switch metadata API.
 * This is to help consolidate these two sources of data.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetLiveAylaSwitchesQuery(skip = false) {
  const isAppVisible = useAppVisibility() || IS_DEMO_LOGIN; // No need to skip API calls in demo mode
  const { site_id: siteId } = useSelector(selectSite);

  const {
    data: switchesFromApi,
    isLoading: isSwitchesLoading,
    isFetching: isSwitchesFetching,
    isError: isSwitchesError,
  } = useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_AYLA, skip);

  const {
    data: liveAylaData = [],
    isLoading: isAylaDataLoading,
    isError: isAylaError,
  } = useGetAylaLiveDataQuery(siteId, {
    skip: skip || !isAppVisible || isSwitchesFetching,
    pollingInterval: 5000,
  });

  let combinedSwitches: CombinedSwitch[] = [];

  if (!isAylaDataLoading && !isSwitchesLoading && !isAylaError && switchesFromApi?.length && liveAylaData.length) {
    combinedSwitches = switchesFromApi
      .map((switchFromApi) => {
        const liveSwitch = liveAylaData?.find((s) => s.id === switchFromApi.id);
        return !liveSwitch
          ? null
          : {
              ...switchFromApi,
              ...liveSwitch,
              status: 'online', // Note: Ayla live data doesnt provide the status, we hardcode it here for the FE
              // -- mainly for the SwitchToggle/SliderDimmer components.
            };
      })
      .filter(Boolean) as CombinedSwitch[];
  }

  return {
    data: combinedSwitches,
    isLoading: isSwitchesLoading || isAylaDataLoading,
    isSwitchesFetching,
    isError: isSwitchesError || isAylaError,
    isLiveDataError: isAylaError,
  };
}

/**
 * Provides a way to get a single switch from the live data APIs, combined with the data from the switch metadata API.
 * This is to help consolidate these two sources of data.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetLiveSwitchQuery(switchId: number) {
  const {
    data: switchFromApi,
    isLoading: isSwitchLoading,
    isError: isSwitchError,
    isFetching: isSwitchFetching,
  } = useGetSwitchQuery(switchId);
  const switchNotFound = !isSwitchLoading && !isSwitchFetching && !switchFromApi;

  let combinedSwitch: CombinedSwitch | undefined;
  let isLoading = isSwitchLoading;
  let isLiveDataError = false;

  // Determine which live switch data to source based on the manufacturer
  const isSenseSwitch = switchFromApi?.manufacturer.manufacturer_id == MANUFACTURER_ID_SEM;
  const isAylaSwitch = switchFromApi?.manufacturer.manufacturer_id == MANUFACTURER_ID_AYLA;

  const {
    data: liveSenseData,
    isError: isLiveSenseDataError,
    isLoading: isLiveSenseDataLoading,
  } = useGetLiveSenseSwitchesQuery(isAylaSwitch);

  const {
    data: liveAylaData,
    isLoading: isLiveAylaDataLoading,
    isError: isLiveAylaDataError,
  } = useGetLiveAylaSwitchesQuery(isSenseSwitch);

  let liveSwitchesData: CombinedSwitch[] = [];
  if (isSenseSwitch) {
    liveSwitchesData = liveSenseData;
    isLoading = isLiveSenseDataLoading;
    isLiveDataError = isLiveSenseDataError;
  } else if (isAylaSwitch) {
    liveSwitchesData = liveAylaData;
    isLoading = isLiveAylaDataLoading;
    isLiveDataError = isLiveAylaDataError;
  }

  if (!isLoading && !isLiveDataError) {
    combinedSwitch = liveSwitchesData.find((s) => s.switch_id === switchId || s.id === switchId);
  }

  return {
    data: combinedSwitch,
    isLoading,
    isSwitchFetching,
    isError: isLiveDataError || isSwitchError || switchNotFound,
    isSwitchError,
  };
}

/**
 * Provides a way to get a single switch from the API - for when live data isn't required
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetSwitchQuery(switchId: number, skip = false) {
  const { site_id: siteId } = useSelector(selectSite);

  const {
    data: switchFromApi,
    isLoading: isSwitchesLoading,
    isError: isSwitchesError,
    isFetching: isSwitchesFetching,
    ...rest
  } = useGetSwitchesQuery(siteId, {
    skip,
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.find((s) => s.id === switchId),
    }),
  });
  const switchNotFound = !isSwitchesLoading && !isSwitchesFetching && !switchFromApi;

  return {
    ...rest,
    data: switchFromApi,
    isLoading: isSwitchesLoading,
    isFetching: isSwitchesFetching,
    isError: isSwitchesError || switchNotFound,
  };
}

export function useGetSwitchesByManufacturerQuery(manufacturerId: number, skip = false) {
  const site = useSelector(selectSite);
  const { data = [], ...rest } = useGetSwitchesQuery(site.site_id, {
    skip,
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.filter((s) => s.manufacturer.manufacturer_id === manufacturerId),
    }),
  });
  return {
    data,
    ...rest,
  };
}
