import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ArcButton from '../../../common/components/ArcButton';
import { useSelector } from 'react-redux';
import { selectSite } from '../siteSlice';
import { useNavigate } from 'react-router-dom';
import { SchneiderHamIcon } from 'clipsal-cortex-icons/src/custom-icons';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { CreateSiteConsent, useCreateSiteConsentMutation, useGetSiteConsentByTypeQuery } from './consentApi';
import useOnboardingWizardProgress from '../../home/useOnboardingWizardProgress';
import { useTranslation } from 'react-i18next';

export type InstallerConsentFormData = {
  hasPermission: 'yes' | 'no';
};

export function SetInstallerConsent() {
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const schema = yup.object().shape({
    hasPermission: yup.string().nullable().required(t('Common.please select an option')),
  });
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<InstallerConsentFormData>({
    resolver: yupResolver(schema),
  });
  const [createSiteConsent] = useCreateSiteConsentMutation();
  const { refetch } = useGetSiteConsentByTypeQuery('SHARE_WITH_INSTALLER');
  const { needsToConfigureHardware } = useOnboardingWizardProgress();

  const toast = useToast({ isClosable: true });
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const navigate = useNavigate();
  const { isOpen: isAlertOpen, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

  const handleSubmitInstallerConsent = async (values: InstallerConsentFormData) => {
    if (values.hasPermission === 'no' && !isAlertOpen) {
      return onOpenAlert();
    }
    const body: CreateSiteConsent = {
      permission: values.hasPermission === 'yes',
      consent_type: 'SHARE_WITH_INSTALLER',
    };
    try {
      await createSiteConsent({ siteId, body }).unwrap();
      // refetch consents so that redirection works as intended
      // avoids race condition where redirection happens before the consent is fetched
      await refetch().unwrap();
      if (needsToConfigureHardware) {
        navigate(`../first_time_hardware_configuration`);
      } else {
        navigate(`../home`);
      }
    } catch (e) {
      console.error(e);
      toast({ title: t('Installer Consent.error saving'), status: 'error' });
    }
  };

  return (
    <SlidingAnimationPageBase px={5} includeTopNav title={t('Common.get started')}>
      <Center flexDirection="column">
        <SchneiderHamIcon w={'100px'} h={'100px'} color={iconColor} my={4} />

        <Text fontSize={21} fontWeight={600} textAlign="center" mb={5}>
          {t('Installer Consent.access to your')}
        </Text>
      </Center>
      <Text mb={4}>{t('Installer Consent.by granting access')}</Text>
      <Text mb={4}>{t('Installer Consent.if you decline')}</Text>
      <Text mb={2}>{t('Installer Consent.grant access?')}</Text>
      <Box onSubmit={handleFormSubmit(handleSubmitInstallerConsent)} as={'form'} data-testid="installer-consent-form">
        <FormControl mt={3} isInvalid={!!errors.hasPermission}>
          <RadioGroup>
            <Stack spacing={10} direction="row">
              <Radio value="yes" {...register('hasPermission')} data-testid="radio-input-yes">
                {t('Common.yes')}
              </Radio>
              <Radio value="no" {...register('hasPermission')} data-testid="radio-input-no">
                {t('Common.no')}
              </Radio>
            </Stack>
          </RadioGroup>
          <FormErrorMessage data-testid="installer-consent-input-error">
            {errors?.hasPermission?.message}
          </FormErrorMessage>
        </FormControl>

        <Center flexDirection="column">
          <ArcButton
            isLoading={isSubmitting}
            my={5}
            type="submit"
            data-testid="save-installer-consent-button"
            w={'80%'}
            arcColor="#3DCD57"
          >
            {t('Common.continue')}
          </ArcButton>
        </Center>
        <AlertDialogModal
          isOpen={isAlertOpen}
          onClose={onCloseAlert}
          header={t('Common.are you sure?')}
          subHeader={t('Installer Consent.by opting out')}
          confirmButtonName={t('Common.continue')}
          onConfirm={handleFormSubmit(handleSubmitInstallerConsent)}
          cancelButtonName={t('Common.cancel')}
        />
      </Box>
    </SlidingAnimationPageBase>
  );
}
