import { WiFiConnectionStatus } from './wireless-access-api-types';

// The base IP address of the wireless access point's HTTP API, operating on the default HTTPS port
export const BASE_IPV4_ADDRESS = 'https://192.168.10.1/esetup';

export type ErrorType =
  | 'INVALID_SSID'
  | 'INVALID_PASSWORD'
  | 'AP_CONNECTION_FAILED'
  | 'GENERIC_ERROR'
  | 'NO_ROUTER_INTERNET_CONNECTION'
  | 'WIFI_RECONNECT_FAILED';

export type WiFiErrorConnectionStatus = Exclude<WiFiConnectionStatus, 'CONNECTED'>;

export const AP_WIFI_STATUS_TO_ERROR_TYPE: Record<WiFiErrorConnectionStatus, ErrorType> = {
  INVALID: 'GENERIC_ERROR',
  NOT_CONNECTED: 'NO_ROUTER_INTERNET_CONNECTION',
  INCORRECT_PASSWORD: 'INVALID_PASSWORD',
  CONFIGURATION_ERROR: 'INVALID_SSID',
};
