import React from 'react';
import { Center, Text } from '@chakra-ui/react';
import { TOP_BANNER_HEIGHT } from '../../common/constants';
import { useTranslation, Trans } from 'react-i18next';

export default function DemoLoginBanner() {
  const { t } = useTranslation();
  return (
    <Center
      py="4px"
      height={TOP_BANNER_HEIGHT}
      background="primaryBranding.600"
      color="white"
      position="fixed"
      data-testid="demo-login-banner"
      w="100vw"
      top={'env(safe-area-inset-top)'}
      zIndex={50}
      onClick={() => {
        localStorage.removeItem('isDemoLogin');
        window.location.replace('/');
      }}
      lineHeight={1}
      textAlign="center"
    >
      <Text ml={1} fontSize="15px">
        <Trans t={t} i18nKey="Demo Mode.message" />
      </Text>
    </Center>
  );
}
