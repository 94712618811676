import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  title: string;
}>;

export default function DeviceListAccordion({ title, children }: Props) {
  return (
    <Accordion defaultIndex={0} allowToggle>
      <AccordionItem borderBottom="none">
        <AccordionButton pl={0} pr={1}>
          <Box as="span" flex="1" textAlign="left">
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
