import React, { useMemo } from 'react';
import { Box, Button, HStack, Skeleton, Spinner, VStack, useDisclosure, useOutsideClick, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useUtilities } from './tariffApi';
import { useTariffFormContext } from './useTariffFormContext';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type RetailerSelectParams = {
  selectedRetailerId: number;
  selectedRetailerName: string;
  onSelect: (retailerId: number, planName: string, state: string) => void;
};

const RetailerSelect = ({ selectedRetailerId, selectedRetailerName, onSelect }: RetailerSelectParams) => {
  const { utilities, isLoading, isFetching } = useUtilities();
  const { control } = useTariffFormContext();
  const { zipCode } = useWatch({ control });
  const hasUtilities = !isLoading && !!utilities.length;
  const isDisabled = !zipCode || !hasUtilities;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const retailerName = useMemo(() => {
    if (isLoading || isFetching) return `${t('Common.loading')}...`;
    if (!zipCode) return t('Common.zipcode required');
    if (!hasUtilities) return t('Energy Rates.no retailers found for zipcode');
    if (selectedRetailerName) return selectedRetailerName;
    return `${t('Energy Rates.select energy provider')}...`;
  }, [selectedRetailerName, isLoading, isFetching, zipCode, hasUtilities]);

  const ref = React.useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: ref,
    handler: onClose,
  });

  const getDropdownContents = () => {
    if (isLoading || isFetching) {
      return (
        <VStack>
          {Array.from({ length: 5 }, (_, i) => i).map((index) => (
            <Skeleton w={'100%'} h={10} rounded={2} key={`skeleton-${index}`} />
          ))}
        </VStack>
      );
    }

    if (!hasUtilities)
      return (
        <Box px={4} py={3}>
          {t('Energy Rates.no retailers found')}
        </Box>
      );

    return utilities.map(({ eid: id, name, state }, index) => {
      const isSelected = id === selectedRetailerId;
      return (
        <Button
          variant="ghost"
          w="100%"
          rounded={0}
          fontWeight={400}
          justifyContent={'flex-start'}
          key={`${name}-${id}`}
          onClick={() => {
            onSelect(id, name, state);
            onClose();
          }}
          py={6}
          color={isSelected ? 'white' : undefined}
          bg={isSelected ? 'primaryBranding.200' : undefined}
          _dark={{ bg: isSelected ? 'primaryBranding.200' : undefined }}
          data-testid={`retailer-option-${index}`}
        >
          <Text overflow="hidden" textOverflow="ellipsis">
            {name}
          </Text>
        </Button>
      );
    });
  };

  return (
    <Box position={'relative'} ref={ref} bg={'#F1F1F1'} _dark={{ bg: '#363E40' }}>
      <HStack
        w="100%"
        justify={'space-between'}
        px={4}
        py={3}
        color={isDisabled ? 'customGrey.400' : undefined}
        onClick={() => {
          if (isDisabled) return;
          if (isOpen) {
            onClose();
          } else {
            onOpen();
          }
        }}
        borderColor={'gray.300'}
        _dark={{ borderColor: 'customGrey.400' }}
        borderBottom="1px solid"
        data-testid="retailer-select-value"
      >
        <Box>{retailerName}</Box>
        {hasUtilities && (
          <Box>{isFetching ? <Spinner size="sm" /> : <ChevronDownIcon w={6} h={6} color="#A6A6A6" />}</Box>
        )}
      </HStack>

      {isOpen && (
        <Box
          zIndex={2000}
          mt={49}
          position="absolute"
          top={0}
          left={0}
          w="100%"
          bg={'#F1F1F1'}
          _dark={{ bg: 'customGrey.800' }}
          data-testid="retailer-select-dropdown"
        >
          <Box>{getDropdownContents()}</Box>
        </Box>
      )}
    </Box>
  );
};

export default RetailerSelect;
