import React, { useEffect } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { SEHomeHeaderIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { useSelector } from 'react-redux';
import { selectSite, setCurrentSite } from '../site/siteSlice';
import { useNavigate } from 'react-router-dom';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import DefaultHome from './default-home/DefaultHome';
import SenseOnlyHome from './sense-only-home/SenseOnlyHome';
import MatterOnlyHome from './matter-only-home/MatterOnlyHome';
import useOnboardingWizardProgress from './useOnboardingWizardProgress';
import { useReduxDispatch } from '../../app/store';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { useTranslation } from 'react-i18next';
import { useSiteHardwareConfigurationType } from '../../common/hooks/use-site-hardware-configuration-type';

export function Home() {
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const { t } = useTranslation();
  const iconColor = useColorModeValue('customGrey.900', 'customGrey.400');
  const isLoadingWizard = useOnboardingWizardRedirect();
  const { hardwareConfigurationType } = useSiteHardwareConfigurationType();

  // If there is a pending inverter configuration for this page, redirect to that before the onboarding wizard.
  // See the `PendingInverterInternetConnection` component docblock comment for details.
  useEffect(() => {
    if (localStorage.getItem(`inverterConnectionPendingMACAddress_${site.site_id}`)) {
      navigate(`../account/hardware/configure/inverter/pending_inverter_internet_connection`);
    }
  }, [site]);

  return isLoadingWizard ? (
    <CenteredLoader text={`${t('Common.loading')}...`} mt="70%" />
  ) : (
    <SlidingAnimationPageBase
      includeTopNavHamButton
      includeSchneiderHamButton
      includeBottomNav
      title={<SEHomeHeaderIcon w={'100%'} color={iconColor} />}
    >
      <Box px={3}>
        {{
          sense: <SenseOnlyHome />,
          default: <DefaultHome />,
          matter: <MatterOnlyHome />,
          none: null,
        }[hardwareConfigurationType] ?? null}
      </Box>
    </SlidingAnimationPageBase>
  );
}

/**
 * Responsible for handling various cases of user site or account configuration required before they can use the app
 * for their site.
 * The order these steps should appear for system owners is as follows:
 *
 * 1. Redirect the user to set the ZIP code on their site, if it does not yet exist
 * 2. Get the user's consent requirements for data sharing with their installers
 * 3. Ask the user which hardware they've installed in their home
 *
 * @returns The redirect component, to be returned by the caller component as JSX.
 */
function useOnboardingWizardRedirect() {
  const navigate = useNavigate();
  const { needsSiteAddress, needsToConfigureHardware, needsToProvideInstallerConsent, isLoaded } =
    useOnboardingWizardProgress();
  const site = useSelector(selectSite);
  const dispatch = useReduxDispatch();
  useEffect(() => {
    if (isLoaded) {
      const needsToCompleteOnboarding = needsSiteAddress || needsToConfigureHardware || needsToProvideInstallerConsent;
      if (!needsToCompleteOnboarding && site.isOnboarding) {
        // This onboarding flag is used to keep track of additional 'non-required' steps in first time hardware  --
        // configuration (tariffs & energy modes). This can be safely reset when on the site's home page.
        dispatch(setCurrentSite({ ...site, isOnboarding: false }));
      }
    }
  }, [isLoaded, needsSiteAddress, needsToConfigureHardware, needsToProvideInstallerConsent]);

  useEffect(() => {
    if (isLoaded) {
      // Initiate the onboarding flow if the user has not set their site address yet
      if (needsSiteAddress) {
        navigate('../set_address');
      }
      // The next onboarding requirement is setting installer consent - it is API restricted to system owners
      else if (needsToProvideInstallerConsent) {
        navigate('../set_installer_consent');
      }
      // The next onboarding requirement is for users to set up their hardware (connecting devices to the Internet)
      else if (needsToConfigureHardware) {
        navigate('../first_time_hardware_configuration');
      }
    }
  }, [isLoaded, needsSiteAddress, needsToProvideInstallerConsent, needsToConfigureHardware]);

  return !isLoaded;
}
