import React from 'react';
import { createIcon } from '@chakra-ui/react';

/**
 * Creates a circle outline icon that has a coloured overlay to represent a percentage of the circle to be filled
 *
 * @param percentageFilled - A number between 0 and 100 to represent the coloured circle segment size
 */
export function createCirclePercentageFilledIcon(percentageFilled: number) {
  const svgWidth = 234;
  const svgHeight = 234;
  const centerX = svgWidth / 2;
  const centerY = svgHeight / 2;
  const outerLineRadius = svgWidth / 2 - 2;
  const innerLineRadius = svgWidth / 2 - 10; // Subtracting the stroke and space width

  let outerLinePath = '';
  let innerFilledLinePath = '';
  let innerUnfilledLinePath = '';

  function getFullCircle(radius: number) {
    return `M${centerX - radius},${centerY} a ${radius},${radius} 0 1,0 ${radius * 2},0 a ${radius},${radius} 0 1,0 -${
      radius * 2
    },0`;
  }

  if (percentageFilled >= 100) {
    outerLinePath = getFullCircle(outerLineRadius);
    innerFilledLinePath = getFullCircle(innerLineRadius);
  }

  if (percentageFilled <= 0) {
    innerUnfilledLinePath = getFullCircle(innerLineRadius);
  }

  if (percentageFilled > 0 && percentageFilled < 100) {
    const endAngle = (percentageFilled / 100) * 360;
    const largeArcFlag = endAngle <= 180 ? 0 : 1;
    const startRad = -90 * (Math.PI / 180);
    const endRad = (endAngle - 90) * (Math.PI / 180);

    outerLinePath = createOuterLine();
    innerFilledLinePath = createInnerFilledLine();
    innerUnfilledLinePath = createInnerUnfilledLine();

    function createOuterLine() {
      const startX = centerX + outerLineRadius * Math.cos(startRad);
      const startY = centerY + outerLineRadius * Math.sin(startRad);
      const endX = centerX + outerLineRadius * Math.cos(endRad);
      const endY = centerY + outerLineRadius * Math.sin(endRad);
      return `M${startX},${startY} A${outerLineRadius},${outerLineRadius} 0 ${largeArcFlag},1 ${endX},${endY}`;
    }

    function createInnerFilledLine() {
      const startX = centerX + innerLineRadius * Math.cos(startRad);
      const startY = centerY + innerLineRadius * Math.sin(startRad);
      const endX = centerX + innerLineRadius * Math.cos(endRad);
      const endY = centerY + innerLineRadius * Math.sin(endRad);
      // eslint-disable-next-line max-len
      return `M${centerX},${centerY} L${startX},${startY} A${innerLineRadius},${innerLineRadius} 0 ${largeArcFlag},1 ${endX},${endY}`;
    }

    function createInnerUnfilledLine() {
      const largeArcFlag = endAngle >= 180 ? 0 : 1;
      const startX = centerX + innerLineRadius * Math.cos(startRad);
      const startY = centerY + innerLineRadius * Math.sin(startRad);
      const endX = centerX + innerLineRadius * Math.cos(endRad);
      const endY = centerY + innerLineRadius * Math.sin(endRad);
      // eslint-disable-next-line max-len
      return `M${centerX},${centerY} L${startX},${startY} A${innerLineRadius},${innerLineRadius} 0 ${largeArcFlag},0 ${endX},${endY}`;
    }
  }

  return createIcon({
    displayName: 'CirclePercentageFilledIcon',
    path: (
      <svg width="234" height="234" viewBox="0 0 234 234" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="117" cy="117" r="115" stroke="#ccc" strokeWidth="2" />
        <path data-testid="circle-line-segment" d={outerLinePath} stroke="currentColor" strokeWidth="2" />
        <path data-testid="circle-filled-segment" d={innerFilledLinePath} fill="url(#filled)" />
        <path data-testid="circle-unfilled-segment" d={innerUnfilledLinePath} fill="url(#unfilled)" />

        <defs>
          <radialGradient
            id="filled"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(117 117) rotate(93.4766) scale(107.77 107.77)"
          >
            <stop offset="0.854167" stopColor="currentColor" stopOpacity="0" />
            <stop offset="1" stopColor="currentColor" />
          </radialGradient>
          <radialGradient
            id="unfilled"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(117 117) rotate(93.4766) scale(107.77 107.77)"
          >
            <stop offset="0.854167" stopColor="#ccc" stopOpacity="0" />
            <stop offset="1" stopColor="#ccc" />
          </radialGradient>
        </defs>
      </svg>
    ),
    viewBox: '0 0 234 234',
  });
}
