import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountMenuButton from '../../AccountMenuButton';
import SEHomeCard from '../../../../common/components/SEHomeCard';
import { useLicenses } from './useLicenses';

export function LicensesPublishersList() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const licenses = useLicenses();

  return (
    <SlidingAnimationPageBase title={i18n.t('Account.publishers')} backURL={`../home?direction=back`}>
      <Box px={5}>
        <SEHomeCard pr={0} pl={2} py={0} mb={4} borderTopLeftRadius="5px">
          {licenses.map(({ publisherId, publisher }, index) => (
            <AccountMenuButton
              key={publisherId}
              includeBottomDivider={index !== licenses.length - 1}
              data-testid={`license-publisher-button-${publisherId}`}
              onClick={() => {
                navigate(`${publisherId}`);
              }}
            >
              <Text fontSize={16} fontWeight="normal" overflow="auto">
                {publisher}
              </Text>
            </AccountMenuButton>
          ))}
        </SEHomeCard>
      </Box>
    </SlidingAnimationPageBase>
  );
}
