/**
 * Extracts Wi-Fi credentials from a decoded string. This function assumes the string will follow a structure like:
 *
 * WIFI:S:<ssid>;T:<security>;P:<password>;;
 *
 * Where the order of S:, T: and P: can change.
 *
 * This function also assumes that the values for SSID, password and security method do not include characters 'S:',
 * 'T:' or 'P:'.
 *
 * @param decodedCredentials - Wi-Fi credentials string decoded from a QR code
 * @returns An object containing the ssid and password extracted from the provided string.
 */
export function extractWifiCredentials(decodedCredentials: string) {
  const split = decodedCredentials.split(';');
  const ssidSegment = split.find((substring) => substring.includes('S:'));
  const passwordSegment = split.find((substring) => substring.includes('P:'));

  if (!ssidSegment || !passwordSegment) throw new Error(`Invalid wifi credentials string supplied: ${split}`);

  const ssid = ssidSegment.split('S:')[1];
  const password = passwordSegment.split('P:')[1];

  if (!ssid || !password) throw new Error('Invalid QR code');

  return {
    ssid,
    password,
  };
}
