import React, { useMemo } from 'react';
import { Box, Center, Heading, useColorModeValue } from '@chakra-ui/react';
import Chart from 'highcharts-react-official';
import Highcharts, { Options } from 'highcharts';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { format } from 'date-fns';
import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { CostsData } from 'clipsal-cortex-types/src/api/api-costs';
import { getTimezoneOffset } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { selectSite } from '../site/siteSlice';
import { convertToTimezoneAwareDate } from './utils';
import { getUIConfigForRangeType } from './activity-helpers';
import { useTranslation } from 'react-i18next';

type Props = {
  isLoaded: boolean;
  selectedDateRangeType: Exclude<DateRangeType, DateRangeType.Day>;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  costData: CostsData[];
  selectedDeviceAssignment?: string;
  isSelectedDeviceDisplayedInChart: boolean;
};

export default function CostChart({
  isLoaded,
  selectedDateRangeType,
  rangeTypeToSelectedPeriod,
  costData,
  selectedDeviceAssignment,
  isSelectedDeviceDisplayedInChart,
}: Props) {
  const lineChartColor = useColorModeValue('#5f5f5f', '#b5b5b5');
  const markerColor = useColorModeValue('#000', '#BCBCBC');
  const { timezone } = useSelector(selectSite);
  const { t } = useTranslation();
  const options = useMemo<Options>(() => {
    function getCostsData() {
      return costData.map((interval) => {
        return {
          x: convertToTimezoneAwareDate(interval.date, timezone).getTime(),
          y: interval.components?.length ? interval.total_cost : null,
        };
      });
    }

    function getCostForApplianceData() {
      return costData.map((interval) => {
        const appliance = interval.assignments!.find((a) => a.assignment === selectedDeviceAssignment);
        return {
          x: convertToTimezoneAwareDate(interval.date, timezone).getTime(),
          y: appliance?.amount || null,
        };
      });
    }

    function getSeries(): Highcharts.SeriesAreaOptions[] {
      if (!isLoaded) return [];

      const areas: Highcharts.SeriesAreaOptions[] = [];
      areas.push({
        type: 'area',
        color: lineChartColor,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(181, 181, 181, 0.3)'],
            [1, 'rgba(181, 181, 181, 0)'],
          ],
        },
        data: [{ x: 0, y: 0 }, ...getCostsData()],
        threshold: -Infinity, // This ensures that the area is always filled
      });

      if (
        costData[0]?.hasOwnProperty('assignments') &&
        costData[0]?.assignments?.length &&
        isSelectedDeviceDisplayedInChart
      ) {
        areas.push({
          type: 'area',
          color: '#9B51E0',
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, 'rgba(155, 81, 224, 0.3)'],
              [1, 'rgba(155, 81, 224, 0)'],
            ],
          },
          data: [{ x: 0, y: 0 }, ...getCostForApplianceData()],
          connectNulls: true,
          threshold: 0,
        });
      }
      return areas;
    }

    const { xAxisTickFormat, tooltipDateFormat, xAxisTickInterval } = getUIConfigForRangeType(selectedDateRangeType);

    function getMinDateTime() {
      const startDate = rangeTypeToSelectedPeriod[selectedDateRangeType];

      if (selectedDateRangeType === DateRangeType.Week) {
        const firstDayOfWeek = getFirstDayOfWeek(startDate); // Monday
        const firstDayOfWeekDateString = format(firstDayOfWeek, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfWeekDateString, timezone).getTime();
      } else if (selectedDateRangeType === DateRangeType.Month) {
        const firstDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        const firstDayOfMonthString = format(firstDayOfMonth, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfMonthString, timezone).getTime();
      } else {
        const firstDayOfYear = new Date(`${startDate.getFullYear()}-01-01`).getTime();
        const firstDayOfYearString = format(firstDayOfYear, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfYearString, timezone).getTime();
      }
    }

    function getMaxDateTime() {
      const startDate = rangeTypeToSelectedPeriod[selectedDateRangeType];

      if (selectedDateRangeType === DateRangeType.Week) {
        const firstDayOfWeek = getFirstDayOfWeek(startDate); // Monday
        const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
        const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 6)); // Friday
        const lastDayOfWeekDateString = format(lastDayOfWeek, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(lastDayOfWeekDateString, timezone).getTime();
      } else if (selectedDateRangeType === DateRangeType.Month) {
        const firstDayOfNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        const lastDayOfMonth = new Date(firstDayOfNextMonth);
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
        const lastDayOfMonthString = format(lastDayOfMonth, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(lastDayOfMonthString, timezone).getTime();
      } else {
        const firstDayOfLastMonthOfYear = new Date(`${startDate.getFullYear()}-12-01`);
        const firstDayOfLastMonthOfYearString = format(firstDayOfLastMonthOfYear, 'yyyy-MM-dd');
        return convertToTimezoneAwareDate(firstDayOfLastMonthOfYearString, timezone).getTime();
      }
    }

    return {
      chart: {
        type: 'datetime',
        height: '320px',
        backgroundColor: 'rgba(255,255,255,0)',
        spacingLeft: 2,
        spacingRight: 2,
        style: { marginRight: '-18px', marginLeft: '0px' },
      },
      title: {
        text: '',
      },
      series: getSeries(),
      tooltip: {
        valuePrefix: '$',
        valueDecimals: 2,
        shared: true,
        xDateFormat: tooltipDateFormat,
        backgroundColor: 'rgba(0,0,0,0.7)',
        borderRadius: 50,
        borderWidth: 0,
        shadow: false,
        padding: 12,
        style: {
          color: 'white',
          fontSize: '16px',
        },
        headerFormat: '<span style="font-size: 16px; font-weight: bold;">{point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}; font-size: 28px;">∎</span> <span>{point.y}</span><br/>',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        min: getMinDateTime(),
        max: getMaxDateTime(),
        accessibility: { enabled: true, description: t('Activity.time of the day') },
        // shows long crosshair when hovered in chart
        crosshair: {
          color: 'rgb(204, 204, 204)',
        },
        endOnTick: false,
        // style ticks
        tickWidth: 2,
        tickLength: 6,
        tickInterval: xAxisTickInterval,
        tickColor: '#C6C6C6',
        type: 'datetime',
        // removes default padding in the chart
        minPadding: 0,
        maxPadding: 0,
        lineWidth: 0,
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            return format(new Date(data.value ?? 0), xAxisTickFormat);
          },
        },
      },
      yAxis: {
        gridLineDashStyle: 'ShortDash',
        accessibility: { enabled: true, description: t('Activity.cost in $') },
        title: {
          text: '',
        },
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            data.value = Number(data.value);
            if (data.value >= 0) return `$${data.value.toFixed(2)}`;
            else {
              return `-$${(data.value * -1).toFixed(2)}`;
            }
          },
        },
      },
      time: {
        getTimezoneOffset: (timestamp: number) => {
          // The offset returned by this function differs from `Date.prototype.getTimezoneOffset` in that
          // it returns the offset from UTC time, instead of the difference between this date as evaluated
          // in the UTC time zone, and the same date as evaluated in the local timezone.
          // The result means that the offset is the inverse sign of the result
          // of `Date.prototype.getTimezoneOffset`.
          const offsetMins = getTimezoneOffset(timezone, new Date(timestamp)) / 60_000;
          // The chart expects the polarity of the Date.prototype method spec, so we need to invert the sign.
          return offsetMins > 0 ? offsetMins * -1 : Math.abs(offsetMins);
        },
      },
      legend: {
        enabled: false,
      },
    };
  }, [costData, selectedDateRangeType, isSelectedDeviceDisplayedInChart, selectedDeviceAssignment, isLoaded]);

  if (isLoaded && !costData.find((interval) => interval.total_cost && interval?.total_cost != 0)) {
    return (
      <Center minH="300px" flexDirection="column">
        <Heading size="md" textAlign="center">
          {t('Activity.data unavailable', {
            time: t('Common.period').toLowerCase(),
          })}
        </Heading>
      </Center>
    );
  }

  return isLoaded ? (
    <Box maxW="100vw" position={'relative'} minH="320px">
      <Box data-testid="cost-chart-container" position={'absolute'} width="100%">
        <Chart highcharts={Highcharts} options={options} />
      </Box>
    </Box>
  ) : (
    <CenteredLoader text={`${t('Common.loading')}...`} minH="320px" />
  );
}
