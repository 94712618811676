import React from 'react';
import { Box, BoxProps, Flex, Heading, HeadingProps, Text, TextProps, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';

export const TOP_NAV_HEIGHT = 48;
export const TOP_NAV_SPACING_AFFORDANCE = `${TOP_NAV_HEIGHT + 10}px`;

type MobileTopNavProps = (
  | {
      backURL: string;
      onClickBack?: never;
    }
  | { onClickBack: () => void; backURL?: never }
) &
  CommonProps;

type CommonProps = {
  background?: string;
  darkModeBackground?: string;
  children?: React.ReactNode;
} & BoxProps;

export default function MobileTopNav({
  backURL,
  onClickBack,
  background = 'white',
  darkModeBackground = 'gray.900',
  children,
  ...boxProps
}: MobileTopNavProps) {
  const navigate = useNavigate();
  const backgroundColor = useColorModeValue(background, darkModeBackground);
  const { isDesktopViewport } = useViewportType();

  return (
    <>
      <Box
        width={!isDesktopViewport ? '100vw' : '100%'}
        background={backgroundColor}
        zIndex={10}
        position="fixed"
        height={'env(safe-area-inset-top)'}
        top="0px"
        left={0}
      />
      <Box
        width={!isDesktopViewport ? '100vw' : '100%'}
        background={backgroundColor}
        zIndex={10}
        left={0}
        position="fixed"
        height={TOP_NAV_HEIGHT + 'px'}
        top="env(safe-area-inset-top)"
        {...boxProps}
      >
        {(backURL || onClickBack) && (
          <Box
            _hover={{ background: 'rgba(0,0,0,0.05)' }}
            rounded={4}
            ml={isDesktopViewport ? 4 : 2}
            position={'absolute'}
            top="50%"
            transform="translateY(-50%)"
            onClick={() => {
              if (onClickBack) onClickBack();
              else if (backURL) navigate(backURL);
            }}
            as={'button'}
          >
            <ChevronLeftIcon w={'26px'} h={'26px'} data-testid="go-back-btn" />
          </Box>
        )}
        <Box display="inline" alignItems={'center'}>
          <Flex height={'100%'} align={'center'} justify={'center'}>
            {children}
          </Flex>
        </Box>
      </Box>
    </>
  );
}

type NoChildrenInProps = Omit<MobileTopNavProps, 'children'>;

type MobileTopNavWithHeadingProps = { headingProps?: HeadingProps; title: string } & NoChildrenInProps;

// Applies sensible defaults for a top nav which includes a heading, noting that the padding on the top nav component
// is equal to (TOP_NAV_HEIGHT - fontSize) / 2, ensuring it is centered.
export function MobileTopNavWithHeading({
  title,
  headingProps,
  backURL,
  onClickBack,
  ...topNavProps
}: MobileTopNavWithHeadingProps) {
  const backProps = backURL ? { backURL: backURL! } : { onClickBack: onClickBack! };
  return (
    <MobileTopNav py={'10px'} {...topNavProps} {...backProps}>
      <Heading fontSize={'18px'} {...headingProps}>
        {title}
      </Heading>
    </MobileTopNav>
  );
}

type MobileTopNavWithTextProps = { textProps?: TextProps; title: string } & NoChildrenInProps;

export function MobileTopNavWithText({
  title,
  textProps,
  backURL,
  onClickBack,
  ...topNavProps
}: MobileTopNavWithTextProps) {
  const backProps = backURL ? { backURL: backURL! } : { onClickBack: onClickBack! };
  return (
    <MobileTopNav {...topNavProps} {...backProps}>
      <Text {...textProps}>{title}</Text>
    </MobileTopNav>
  );
}
