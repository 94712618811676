import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { IS_IOS } from './common/constants';
import RouteNotFound from './common/components/route/RouteNotFound';

import { Account } from './features/account/Account';
import { AccountDashboard } from './features/account/AccountDashboard';
import { Activity } from './features/activity/Activity';
import { AddDesignatedCircuit } from './features/account/energy-modes/AddDesignatedCircuit';
import { AddDeviceHome } from './features/devices/AddDeviceHome';
import { AddNetwork } from './features/ev_charger/wifi-configuration/AddNetwork';
import { AddWifiDetails } from './features/account/settings/wifi-settings/AddWifiDetails';
import { AndroidCommissioningWizard } from './features/devices/matter/commissioning/AndroidCommissioningWizard';
import { BatteryHome } from './features/battery/BatteryHome';
import { ChargeHistory } from './features/ev_charger/charge-history/ChargeHistory';
import { ConfirmFlatTariff } from './features/tariff/flat-tariff/ConfirmFlatTariff';
import { ConfirmTOUTariff } from './features/tariff/tou-tariff/ConfirmTOUTariff';
import { ConfirmTieredTariff } from './features/tariff/tiered-tariff/ConfirmTieredTariff';
import { ConsentError } from './features/site/ConsentError';
import { CreateHome } from './features/account/manage-homes/CreateHome';
import { CreateResetPin } from './features/ev_charger/wifi-configuration/CreateResetPin';
import { DeleteAccountArchive } from './features/account/settings/privacy/delete-account/DeleteAccountArchive';
import { DeleteAccountConfirm } from './features/account/settings/privacy/delete-account/DeleteAccountConfirm';
import { DeleteAccountStart } from './features/account/settings/privacy/delete-account/DeleteAccountStart';
import { DeleteAccountVerify } from './features/account/settings/privacy/delete-account/DeleteAccountVerify';
import { DesignatedCircuits } from './features/account/energy-modes/DesignatedCircuits';
import { DeviceChargerUnavailable } from './features/ev_charger/device-setup/DeviceChargerUnavailable';
import { DeviceDetails } from './features/ev_charger/dashboard/DeviceDetails';
import { DeviceGenericError } from './features/ev_charger/device-setup/DeviceGenericError';
import { DeviceJoin } from './features/ev_charger/device-setup/DeviceJoin';
import { DevicePreSetup } from './features/ev_charger/device-setup/DevicePreSetup';
import { DeviceRegister } from './features/ev_charger/device-setup/DeviceRegister';
import { DeviceScan } from './features/ev_charger/device-setup/DeviceScan';
import { DevicesHome } from './features/devices/DevicesHome';
import { EVChargerHome } from './features/ev_charger/dashboard/EVChargerHome';
import { Eco } from './features/eco/Eco';
import { EditDevice } from './features/devices/EditDevice';
import { EditFamilyMembers } from './features/account/settings/family-members/EditFamilyMembers';
import { EditInvestmentsForm } from './features/savings/EditInvestmentsForm';
import { EditMatterDevice } from './features/devices/matter/EditMatterDevice';
import { EditSwitch } from './features/devices/switches/EditSwitch';
import { EditTariffSeasons } from './features/tariff/tariff-season/EditTariffSeasons';
import { EditWifiDetails } from './features/account/settings/wifi-settings/EditWifiDetails';
import { EnergyModesHome } from './features/account/energy-modes/EnergyModesHome';
import { EnterPin } from './features/ev_charger/wifi-configuration/EnterPin';
import { EvChargerStatus } from './features/ev_charger/dashboard/EvChargerStatus';
import { EvWifiConfiguration } from './features/ev_charger/wifi-configuration/EvWifiConfiguration';
import { EvWifiConfigurationHome } from './features/ev_charger/wifi-configuration/EvWifiConfigurationHome';
import { FlatTariffRates } from './features/tariff/flat-tariff/FlatTariffRates';
import { Generator } from './features/generator/Generator';
import { HardwareDetails } from './features/account/hardware/HardwareDetails';
import { HardwareList } from './features/account/hardware/HardwareList';
import { HardwareWifiConfiguration } from './features/account/hardware/wifi-configuration/HardwareWifiConfiguration';
import { Home } from './features/home/Home';
import { IOSCommissioningWizard } from './features/devices/matter/commissioning/IOSCommissioningWizard';
import { InverterPendingInternetConnection } from './features/account/hardware/wifi-configuration/inverter/InverterPendingInternetConnection';
import { InverterPowerUpInstructions } from './features/account/hardware/wifi-configuration/inverter/InverterPowerUpInstructions';
import { InverterScanBarcode } from './features/account/hardware/wifi-configuration/inverter/InverterScanBarcode';
import { InverterSetupComplete } from './features/account/hardware/wifi-configuration/inverter/InverterSetupComplete';
import { InverterStart } from './features/account/hardware/wifi-configuration/inverter/InverterStart';
import { InverterWifiCredentials } from './features/account/hardware/wifi-configuration/inverter/InverterWifiCredentials';
import { LicensesPublishersList } from './features/account/settings/licenses/LicensesPublishersList';
import { LicensesDetail } from './features/account/settings/licenses/LicensesDetail';
import { LiveEnergyFlow } from './features/live-energy-flow/LiveEnergyFlow';
import { Login } from './features/auth/Login';
import { Logout } from './features/auth/logout/Logout';
import { ManageHomes } from './features/account/manage-homes/ManageHomes';
import { MatterDeviceSpecs } from './features/devices/matter/MatterDeviceSpecs';
import { MeterConnectionTests } from './features/account/hardware/wifi-configuration/meter/MeterConnectionTests';
import { MeterManualSerialEntry } from './features/account/hardware/wifi-configuration/meter/MeterManualSerialEntry';
import { MeterPowerUpInstructions } from './features/account/hardware/wifi-configuration/meter/MeterPowerUpInstructions';
import { MeterScanBarcode } from './features/account/hardware/wifi-configuration/meter/MeterScanBarcode';
import { MeterSetupComplete } from './features/account/hardware/wifi-configuration/meter/MeterSetupComplete';
import { MeterStart } from './features/account/hardware/wifi-configuration/meter/MeterStart';
import { NotificationsArchive } from './features/notifications/NotificationsArchive';
import { NotificationsList } from './features/notifications/NotificationsList';
import { NotificationsListByType } from './features/notifications/NotificationsListByType';
import { OAuthRedirect } from './features/auth/OAuthRedirect';
import { PersonalDetails } from './features/account/PersonalDetails';
import { PrivacySettingsHome } from './features/account/settings/privacy/PrivacySettingsHome';
import { SavingsHome } from './features/savings/SavingsHome';
import { SetAddress } from './features/site/new-user-journey/SetAddress';
import { SetInstallerConsent } from './features/site/new-user-journey/SetInstallerConsent';
import { SetReserveCapacity } from './features/battery/SetReserveCapacity';
import { SettingsHome } from './features/account/settings/SettingsHome';
import { SetupHardware } from './features/site/new-user-journey/SetupHardware';
import { Site } from './features/site/Site';
import { SiteRedirect } from './features/site/SiteRedirect';
import { SitesAdminView } from './features/sites/SitesAdminView';
import { Solar } from './features/solar/Solar';
import { Support } from './features/account/Support';
import { TOUTariffRates } from './features/tariff/tou-tariff/TOUTariffRates';
import { TariffDetails } from './features/tariff/TariffDetails';
import { TariffList } from './features/tariff/TariffList';
import { TariffRoutes } from './features/tariff/TariffRoutes';
import { TariffSetup } from './features/tariff/TariffSetup';
import { TariffSetupRoutes } from './features/tariff/TariffSetupRoutes';
import { TieredTariffRates } from './features/tariff/tiered-tariff/TieredTariffRates';
import { UpdateRequired } from './features/auth/UpdateRequired';
import { ViewDevice } from './features/devices/ViewDevice';
import { ViewFamilyMembers } from './features/account/settings/family-members/ViewFamilyMembers';
import { ViewMatterDevice } from './features/devices/matter/ViewMatterDevice';
import { ViewNotification } from './features/notifications/ViewNotification';
import { ViewSwitch } from './features/devices/switches/ViewSwitch';
import { ViewWifiSettings } from './features/account/settings/wifi-settings/ViewWifiSettings';
import { WattbuyRetailRatesSelect } from './features/tariff/WattbuyRetailRatesSelect';
import { WidgetSettings } from './features/account/settings/WidgetSettings';
import { LicensesPackagesList } from './features/account/settings/licenses/LicensesPackagesList';
// import { NotificationSettingsHome } from './features/account/settings/NotificationSettingsHome';
// import { SEHomeNotificationSettings } from './features/account/settings/SEHomeNotificationSettings';

export interface Route {
  path: string;
  element: ReactElement;
  children?: Route[];
  isPublic?: boolean;
}

export const routes: Route[] = [
  {
    element: <SiteRedirect />,
    path: '/',
    isPublic: true,
  },
  {
    element: <Login />,
    path: '/login',
    isPublic: true,
  },
  {
    element: <Logout />,
    path: '/logout',
    isPublic: true,
  },
  {
    element: <OAuthRedirect />,
    path: '/oauth_response',
    isPublic: true,
  },
  {
    element: <UpdateRequired />,
    path: '/update-required',
    isPublic: true,
  },
  {
    element: <SitesAdminView />,
    path: '/sites',
  },
  {
    element: <Site />,
    path: '/site/:id',
    children: [
      {
        path: '',
        element: <Navigate replace to="home" />,
      },
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'activity',
        element: <Activity />,
      },
      {
        path: 'devices',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="list" />,
          },
          {
            path: 'list',
            element: <DevicesHome />,
          },
          {
            path: ':deviceId',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate replace to="view" />,
              },
              {
                path: 'view',
                element: <ViewDevice />,
              },
              {
                path: 'edit',
                element: <EditDevice />,
              },
            ],
          },
          {
            path: 'add',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate replace to="home" />,
              },
              {
                path: 'home',
                element: <AddDeviceHome />,
              },
            ],
          },
        ],
      },
      {
        path: 'switches',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="../devices/list" />, // This meant to be a redirect to devices,
          },
          {
            path: ':switchId',
            element: <Outlet />,
            children: [
              {
                path: 'view',
                element: <ViewSwitch />,
              },
              {
                path: 'edit',
                element: <EditSwitch />,
              },
            ],
          },
        ],
      },
      {
        path: 'matter-devices',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="../devices/list" />, // This meant to be a redirect to devices
          },
          {
            path: 'add',
            element: <Outlet />,
            children: [
              {
                path: '',
                /* istanbul ignore next -- @preserve */
                element: <Navigate replace to={IS_IOS ? 'start-ios' : 'start'} />,
              },
              {
                path: 'start',
                element: <AndroidCommissioningWizard />,
              },
              {
                path: 'start-ios',
                element: <IOSCommissioningWizard />,
              },
            ],
          },
          {
            path: ':switchId',
            element: <Outlet />,
            children: [
              {
                path: 'view',
                element: <ViewMatterDevice />,
              },
              {
                path: 'specs',
                element: <MatterDeviceSpecs />,
              },
              {
                path: 'edit',
                element: <EditMatterDevice />,
              },
            ],
          },
        ],
      },
      {
        path: 'notifications',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="list" />,
          },
          {
            path: 'list',
            element: <NotificationsList />,
          },
          {
            path: 'list_by_type/:type',
            element: <NotificationsListByType />,
          },
          {
            path: ':notificationId/view',
            element: <ViewNotification />,
          },
          {
            path: 'archive',
            element: <NotificationsArchive />,
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
        children: [
          {
            path: '',
            element: <Navigate replace to="dashboard" />,
          },
          {
            path: 'dashboard',
            element: <AccountDashboard />,
          },
          {
            path: 'personal-details',
            element: <PersonalDetails />,
          },
          {
            path: 'energy-modes',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate replace to="home" />,
              },
              {
                path: 'home',
                element: <EnergyModesHome />,
              },
              {
                path: 'tou-savings-mode',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <DesignatedCircuits />,
                  },
                  {
                    path: 'add',
                    element: <AddDesignatedCircuit />,
                  },
                ],
              },
              {
                path: 'low-power-mode',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <DesignatedCircuits />,
                  },
                  {
                    path: 'add',
                    element: <AddDesignatedCircuit />,
                  },
                ],
              },
            ],
          },
          {
            path: 'hardware',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate replace to="list" />,
              },
              {
                path: 'list',
                element: <HardwareList />,
              },
              {
                path: ':deviceId',
                element: <Outlet />,
                children: [
                  {
                    path: 'view',
                    element: <HardwareDetails />,
                  },
                ],
              },
              {
                path: 'configure',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Navigate replace to="home" />,
                  },
                  {
                    path: 'home',
                    element: <HardwareWifiConfiguration />,
                  },
                  {
                    path: 'inverter',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <Navigate replace to="start" />,
                      },
                      {
                        path: 'start',
                        element: <InverterStart />,
                      },
                      {
                        path: 'power_up_instructions',
                        element: <InverterPowerUpInstructions />,
                      },
                      {
                        path: 'scan',
                        element: <InverterScanBarcode />,
                      },
                      {
                        path: 'enter_wifi_credentials',
                        element: <InverterWifiCredentials />,
                      },
                      {
                        path: 'finish',
                        element: <InverterSetupComplete />,
                      },
                      {
                        path: 'pending_inverter_internet_connection',
                        element: <InverterPendingInternetConnection />,
                      },
                    ],
                  },
                  {
                    path: 'meter',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <Navigate replace to="start" />,
                      },
                      {
                        path: 'start',
                        element: <MeterStart />,
                      },
                      {
                        path: 'power_up_instructions',
                        element: <MeterPowerUpInstructions />,
                      },
                      {
                        path: 'scan',
                        element: <MeterScanBarcode />,
                      },
                      {
                        path: 'manual_serial_entry',
                        element: <MeterManualSerialEntry />,
                      },
                      {
                        path: 'connection_tests',
                        element: <MeterConnectionTests />,
                      },
                      {
                        path: 'finish',
                        element: <MeterSetupComplete />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'settings',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate replace to="home" />,
              },
              {
                path: 'home',
                element: <SettingsHome />,
              },
              /*
               {
                path: 'notifications',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Navigate replace to='home' />,
                  },
                  {
                    path: 'home',
                    element: <NotificationSettingsHome />,
                  },
                  {
                    path: 'schneider_home',
                    element: <SEHomeNotificationSettings />,
                  },
                ]
              },
               */
              {
                path: 'family-members',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Navigate replace to="view" />,
                  },
                  {
                    path: 'view',
                    element: <ViewFamilyMembers />,
                  },
                  {
                    path: 'edit',
                    element: <EditFamilyMembers />,
                  },
                ],
              },
              {
                path: 'widgets',
                element: <WidgetSettings />,
              },
              {
                path: 'wifi',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <Navigate replace to="view" />,
                  },
                  {
                    path: 'view',
                    element: <ViewWifiSettings />,
                  },
                  {
                    path: 'edit',
                    element: <EditWifiDetails />,
                  },
                  {
                    path: 'add',
                    element: <AddWifiDetails />,
                  },
                ],
              },
              {
                path: 'privacy',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <PrivacySettingsHome />,
                  },
                  {
                    path: 'delete-account',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <Navigate replace to="start" />,
                      },
                      {
                        path: 'start',
                        element: <DeleteAccountStart />,
                      },
                      {
                        path: 'request-data-archive',
                        element: <DeleteAccountArchive />,
                      },
                      {
                        path: 'verify-details',
                        element: <DeleteAccountVerify />,
                      },
                      {
                        path: 'confirm-deletion',
                        element: <DeleteAccountConfirm />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'licenses',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <LicensesPublishersList />,
                  },
                  {
                    path: ':publisherId',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <LicensesPackagesList />,
                      },
                      {
                        path: ':packageId',
                        element: <LicensesDetail />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'tariff',
            element: <TariffRoutes />,
            children: [
              {
                path: '',
                element: <Navigate replace to="home" />,
              },
              {
                path: 'home',
                element: <TariffList />,
              },
              {
                path: ':tariffId',
                element: <TariffSetupRoutes />,
                children: [
                  {
                    path: '',
                    element: <Navigate replace to="home" />,
                  },
                  {
                    path: 'season-select',
                    element: <Navigate replace to="home" />,
                  },
                  {
                    path: 'home',
                    element: <TariffSetup />,
                  },
                  {
                    path: 'tariff-details',
                    element: <TariffDetails />,
                  },
                  {
                    path: 'select-season',
                    element: <EditTariffSeasons />,
                  },
                  {
                    path: 'select-retail-rates',
                    element: <WattbuyRetailRatesSelect />,
                  },
                  {
                    path: 'flat',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <Navigate replace to="start" />,
                      },
                      {
                        path: 'start',
                        element: <FlatTariffRates />,
                      },
                      {
                        path: 'confirm',
                        element: <ConfirmFlatTariff />,
                      },
                    ],
                  },
                  {
                    path: 'tou',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <Navigate replace to="start" />,
                      },
                      {
                        path: 'start',
                        element: <TOUTariffRates />,
                      },
                      {
                        path: 'confirm',
                        element: <ConfirmTOUTariff />,
                      },
                    ],
                  },
                  {
                    path: 'tiered',
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <Navigate replace to="start" />,
                      },
                      {
                        path: 'start',
                        element: <TieredTariffRates />,
                      },
                      {
                        path: 'confirm',
                        element: <ConfirmTieredTariff />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'support',
            element: <Support />,
          },
          {
            path: 'manage-homes',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Navigate replace to="home" />,
              },
              {
                path: 'home',
                element: <ManageHomes />,
              },
              {
                path: 'create',
                element: <CreateHome />,
              },
            ],
          },
        ],
      },
      {
        path: 'set_address',
        element: <SetAddress />,
      },
      {
        path: 'set_installer_consent',
        element: <SetInstallerConsent />,
      },
      {
        path: 'live_energy_flow',
        element: <LiveEnergyFlow />,
      },
      {
        path: 'first_time_hardware_configuration',
        element: <SetupHardware />,
      },
      {
        path: 'ev_charger',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="home" />,
          },
          {
            path: 'home',
            element: <EVChargerHome />,
            children: [
              {
                path: 'ev_charger_status',
                element: <EvChargerStatus />,
              },
              {
                path: 'device_details',
                element: <DeviceDetails />,
              },
              {
                path: 'device_register',
                element: <DeviceRegister />,
              },
              {
                path: 'device_scan',
                element: <DeviceScan />,
              },
              {
                path: 'device_join',
                element: <DeviceJoin />,
              },
              {
                path: 'device_pre_setup',
                element: <DevicePreSetup />,
              },
              {
                path: 'device_generic_error',
                element: <DeviceGenericError />,
              },
              {
                path: 'device_charger_unavailable',
                element: <DeviceChargerUnavailable />,
              },
              {
                path: 'device_charge_history',
                element: <ChargeHistory />,
              },
            ],
          },
          {
            path: 'wifi',
            element: <EvWifiConfiguration />,
            children: [
              {
                path: '',
                element: <Navigate replace to="home" />,
              },
              {
                path: 'home',
                element: <EvWifiConfigurationHome />,
              },
              {
                path: 'create-pin',
                element: <CreateResetPin isCreateMode />,
              },
              {
                path: 'reset-pin',
                element: <CreateResetPin isCreateMode={false} />,
              },
              {
                path: 'enter-pin',
                element: <EnterPin />,
              },
              {
                path: 'add-network',
                element: <AddNetwork />,
              },
            ],
          },
        ],
      },
      {
        path: 'solar',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="home" />,
          },
          {
            path: 'home',
            element: <Solar />,
          },
        ],
      },
      {
        path: 'generator',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="home" />,
          },
          {
            path: 'home',
            element: <Generator />,
          },
        ],
      },
      {
        path: 'eco',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="home" />,
          },
          {
            path: 'home',
            element: <Eco />,
          },
        ],
      },
      {
        path: 'savings',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="home" />,
          },
          {
            path: 'home',
            element: <SavingsHome />,
          },
          {
            path: 'edit_investment_cost',
            element: <EditInvestmentsForm />,
          },
        ],
      },
      {
        path: 'battery',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate replace to="home" />,
          },
          {
            path: 'home',
            element: <BatteryHome />,
          },
          {
            path: 'set-reserve-capacity',
            element: <SetReserveCapacity />,
          },
        ],
      },
    ],
  },
  {
    element: <ConsentError />,
    path: '/consent-error',
  },
  {
    element: <RouteNotFound />,
    path: '*',
  },
];
