import { baseApi } from '../../app/services/baseApi';
import { BatteryReserve, BatteryReserveData } from 'clipsal-cortex-types/src/api/api-battery-reserve';

export const batteryReserveApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBatteryReserve: build.query<BatteryReserve, number>({
      query: (inverterId) => `/v1/site_devices/${inverterId}/battery_reserve`,
      providesTags: ['BatteryReserve'],
    }),

    updateBatteryReserve: build.mutation<BatteryReserve, { inverterId: number; body: BatteryReserveData }>({
      query: ({ inverterId, body }) => ({
        url: `/v1/site_devices/${inverterId}/battery_reserve`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['BatteryReserve'],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        // Optimistic update so the user gets fast feedback. The mutation happens in the background.
        const { inverterId, body } = arg;
        const patchResult = dispatch(
          batteryReserveApi.util.updateQueryData('getBatteryReserve', inverterId, (draft) => {
            // Update this setting in the drafted state. Note that immer provides mutability here!
            draft.pending = { reserve_percentage: body.reserve_percentage };
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetBatteryReserveQuery, useUpdateBatteryReserveMutation } = batteryReserveApi;
