import React from 'react';
import { Outlet } from 'react-router-dom';
import { Center } from '@chakra-ui/react';

export function Account() {
  return (
    <Center w="100%">
      <Outlet />
    </Center>
  );
}
