import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EVLinkCrossDevice } from '../../../assets/images/evlink_device_cross.svg';
import ArcButton from '../../../common/components/ArcButton';
import CommonTopNav from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';

/* istanbul ignore next -- @preserve */
export const DeviceGenericError = () => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('#F7F7F7', '#111111');

  return (
    <Flex
      bgColor={bgColor}
      direction={'column'}
      align="center"
      justify={'space-between'}
      minH={COMPONENT_MIN_HEIGHT}
      pb={BOTTOM_NAV_HEIGHT}
    >
      <CommonTopNav title="Device Setup" backURL="../device_scan" />
      <Text px={8} pt={TOP_NAV_SPACING_AFFORDANCE}>
        Although the app was unable to connect to your EV charger, you can still charge your EV.
      </Text>

      <Flex direction={'column'} align="center">
        <Box>
          <EVLinkCrossDevice style={{ width: 'fit-content', height: 'auto', maxHeight: '300px', marginLeft: '50px' }} />
        </Box>
        <Text px={8} mt={8} fontSize="24px" fontWeight={'bold'}>
          Something went wrong
        </Text>
        <Text px={8} mt={2}>
          Please try to connect again.
        </Text>
      </Flex>
      <ArcButton
        data-testid={'device-try-again-btn'}
        arcColor="#3DCD57"
        width="100%"
        maxWidth={'300px'}
        mb={16}
        onClick={() => navigate('../device_scan')}
      >
        Try Again
      </ArcButton>
    </Flex>
  );
};
