import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Center,
  Divider,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useNavigate, useParams } from 'react-router-dom';
import DeviceStatusIcon from '../DeviceStatusIcon';
import ArcButton from '../../../common/components/ArcButton';
import { useTranslation } from 'react-i18next';
import PairingCodeModal from './PairingCodeModal';
import { getMatterDeviceType, useAylaSwitchData, useTranslatedUIConfig } from './matter-helpers';
import { DEVICE_ICON_RESPONSIVE_SIZE } from '../devices-helper';
import { useDeleteDeviceMutation, useGeneratePairingCodeMutation } from './localMatterDevicesApi';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { useDeleteAylaDeviceMutation } from '../switches/switchApi';
import { IS_NATIVE } from '../../../common/constants';

export function MatterDeviceSpecs() {
  const navigate = useNavigate();
  const params = useParams<{ switchId: string }>();
  const switchId = Number(params.switchId);
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();
  const { isOpen: isPairCodeModalOpen, onOpen: onPairCodeModalOpen, onClose: onPairCodeModalClose } = useDisclosure();
  const { isOpen: isDeleteAlertOpen, onOpen: onOpenDeleteAlert, onClose: onCloseDeleteAlert } = useDisclosure();
  const {
    data: aylaSwitch,
    isLiveDataLoading,
    isApiDataLoading,
    isLocalDataLoading,
    isApiSwitchError,
  } = useAylaSwitchData(switchId, true);
  const [generatePairingCode, { data: pairCode, isLoading: isPairCodeLoading }] = useGeneratePairingCodeMutation();
  const [deleteLocalMatterDevice] = useDeleteDeviceMutation();
  const [deleteAylaDevice] = useDeleteAylaDeviceMutation();
  const MATTER_DEVICES_UI_CONFIG = useTranslatedUIConfig();
  const deviceType = getMatterDeviceType(aylaSwitch.display_name);
  const isDeviceCommissionedLocally = IS_NATIVE && aylaSwitch.localMatterDevice?.reachable;

  const handleGeneratePairCode = async () => {
    try {
      if (!aylaSwitch.localMatterDevice?.matterDeviceId) throw Error('Matter device ID not found');
      await generatePairingCode({
        matterDeviceId: aylaSwitch.localMatterDevice.matterDeviceId,
      }).unwrap();
      onPairCodeModalOpen();
    } catch (error) {
      toast({
        title: t('Devices.error creating pairing code'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  };

  const handleUnavailableFeatureClick = () => {
    if (!isDeviceCommissionedLocally) {
      toast({
        title: t('Devices.feature only available if commissioned locally'),
        status: 'info',
      });
    }
  };

  const handleRemoveDevice = async () => {
    try {
      if (isDeviceCommissionedLocally) {
        await deleteLocalMatterDevice({
          matterDeviceId: aylaSwitch.localMatterDevice!.matterDeviceId,
        }).unwrap();
      }
      await deleteAylaDevice({
        switchId,
      }).unwrap();
      toast({
        title: t('Devices.device deleted toast title'),
        status: 'success',
      });
      navigate(`../../../devices/list?direction=back`);
    } catch (error) {
      console.error('Something went wrong removing Matter device... ', error);
      toast({
        title: t('Devices.error removing device'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  };

  const backURL = useMemo(
    () => (isApiSwitchError ? `../../../devices/list?direction=back` : `../view?direction=back`),
    [isApiSwitchError]
  );

  return (
    <SlidingAnimationPageBase backURL={backURL} includeBottomNav title={t('Devices.device specs')}>
      <Box data-testid="matter-device-detail-contents" px={4}>
        {isApiSwitchError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Devices.error fetching device')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <SkeletonCircle
              isLoaded={!isLiveDataLoading}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              {aylaSwitch && (
                <DeviceStatusIcon
                  isOn={aylaSwitch.state == 'closed'}
                  icon={MATTER_DEVICES_UI_CONFIG[deviceType].icon}
                />
              )}
            </SkeletonCircle>
            <Skeleton isLoaded={!isApiDataLoading} borderBottomRightRadius="25px" py={2}>
              <Text
                m={3}
                minH="30px"
                fontSize={24}
                data-testid="switch-name"
                overflowWrap="anywhere"
                textAlign="center"
              >
                {aylaSwitch.site_switch_label}
              </Text>
            </Skeleton>
            <Divider my={4} />
            <Box width="100%" textAlign="center" position="relative">
              <Box
                onClick={handleUnavailableFeatureClick}
                position="absolute"
                w="100%"
                h="100%"
                zIndex={999}
                hidden={isDeviceCommissionedLocally}
              />
              <ArcButton
                arcColor="#3DCD57"
                width="90%"
                data-testid="share-pairing-code-button"
                isLoading={isPairCodeLoading || (IS_NATIVE && isLocalDataLoading)}
                isDisabled={!isDeviceCommissionedLocally}
                onClick={handleGeneratePairCode}
              >
                {t('Devices.share pair code button')}
              </ArcButton>
            </Box>
            <Divider my={4} />
            <Box my={4}>
              <Text fontWeight="bold" data-testid="serial-number-title">
                {t('Common.serial number')}
              </Text>
              <Skeleton isLoaded={!isApiDataLoading} minHeight="1.25rem">
                <Text data-testid="serial-number-text">{aylaSwitch?.oem_switch_id}</Text>
              </Skeleton>
            </Box>
            <Box my={4}>
              <Text fontWeight="bold" data-testid="model-name-title">
                {t('Common.model name')}
              </Text>
              <Skeleton isLoaded={!isLocalDataLoading} minHeight="1.25rem">
                <Box data-testid="model-name-text">
                  {isDeviceCommissionedLocally ? (
                    aylaSwitch?.localMatterDevice?.productName
                  ) : (
                    <Badge onClick={handleUnavailableFeatureClick}>{t('Common.not found')}</Badge>
                  )}
                </Box>
              </Skeleton>
            </Box>
            <Box my={4}>
              <Text fontWeight="bold" data-testid="model-number-title">
                {t('Common.model number')}
              </Text>
              <Skeleton isLoaded={!isLocalDataLoading} minHeight="1.25rem">
                <Box data-testid="model-number-text">
                  {isDeviceCommissionedLocally ? (
                    aylaSwitch.localMatterDevice?.partNumber
                  ) : (
                    <Badge onClick={handleUnavailableFeatureClick}>{t('Common.not found')}</Badge>
                  )}
                </Box>
              </Skeleton>
            </Box>
            <Box my={4}>
              <Text fontWeight="bold" data-testid="description-title">
                {t('Common.description')}
              </Text>
              <SkeletonText isLoaded={!isApiDataLoading}>
                <Text data-testid="description-text">{MATTER_DEVICES_UI_CONFIG[deviceType].description}</Text>
              </SkeletonText>
            </Box>
            <Box my={4}>
              <Text fontWeight="bold" data-testid="mac-address-title">
                {t('Common.mac address')}
              </Text>
              <Skeleton isLoaded={!isLocalDataLoading} minHeight="1.25rem">
                <Box data-testid="mac-address-text">
                  {isDeviceCommissionedLocally ? (
                    aylaSwitch.localMatterDevice?.macAddress
                  ) : (
                    <Badge onClick={handleUnavailableFeatureClick}>{t('Common.not found')}</Badge>
                  )}
                </Box>
              </Skeleton>
            </Box>
            <Box my={4}>
              <Text fontWeight="bold" data-testid="firmware-title">
                {t('Common.firmware')}
              </Text>
              <Skeleton isLoaded={!isLocalDataLoading} minHeight="1.25rem">
                <Box data-testid="firmware-text">
                  {isDeviceCommissionedLocally ? (
                    aylaSwitch.localMatterDevice?.softwareVersionString
                  ) : (
                    <Badge onClick={handleUnavailableFeatureClick}>{t('Common.not found')}</Badge>
                  )}
                </Box>
              </Skeleton>
            </Box>
            <Center flexDir="column">
              <ArcButton
                arcColor="#FF5B44"
                width="80%"
                data-testid="remove-device-button"
                onClick={onOpenDeleteAlert}
                isLoading={isLocalDataLoading || isApiDataLoading}
                mt={6}
              >
                {t('Devices.delete device')}
              </ArcButton>
              <AlertDialogModal
                isOpen={isDeleteAlertOpen}
                onClose={onCloseDeleteAlert}
                header={t('Devices.delete device modal title', { deviceName: aylaSwitch?.site_switch_label })}
                subHeader={t('Devices.delete device modal text')}
                confirmButtonName={t('Common.delete')}
                cancelButtonName={t('Common.cancel')}
                onConfirm={handleRemoveDevice}
              />
              {/* istanbul ignore next -- @preserve */}
              {pairCode && (
                <PairingCodeModal isOpen={isPairCodeModalOpen} onClose={onPairCodeModalClose} pairingCode={pairCode} />
              )}
            </Center>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
