import { NotificationType } from 'clipsal-cortex-types/src/api/api-notifications';
import { NOTIFICATION_TYPE_TO_CONFIG } from './constants';
import { useTranslation } from 'react-i18next';

/**
 * Accepts a notification type and returns the respective UI config.
 * The default test config is returned if there is no match.
 *
 * @param type - The notification type
 * @returns The UI config object
 */
export function useGetNotificationConfigForType(type: NotificationType) {
  const { t } = useTranslation();

  return {
    ...NOTIFICATION_TYPE_TO_CONFIG[type],
    title: t(`Notifications.types.${type}.title`),
  };
}
