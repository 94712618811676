import { useEffect, useRef, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { APP_VERSION, IS_NOT_DEVELOPMENT, IS_PRODUCTION } from '../constants';
import { useReduxDispatch } from '../../app/store';
import { checkUserLoggedIn, fetchUserDetails } from '../../features/user/userSlice';

export function useAppReady() {
  const [isLoaded, setLoaded] = useState(false);
  const dispatch = useReduxDispatch();
  // Android seems to setup PNs multiple times in the simulator, use this to avoid that
  // Avoids double effect invocations in strict mode while in development mode
  const isFirstRender = useRef(true);
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();

  useEffect(() => {
    const checkUserStatus = async () => {
      const { isLoggedIn } = unwrapResult(await dispatch(checkUserLoggedIn()));

      if (isLoggedIn && isFirstRender.current) {
        isFirstRender.current = false;
        const userDetailsResponse = await dispatch(
          fetchUserDetails({
            isProduction: IS_PRODUCTION,
            isNotDevelopment: IS_NOT_DEVELOPMENT,
            version: APP_VERSION,
          })
        );
        if (userDetailsResponse.meta.requestStatus === 'rejected') {
          toast({
            title: t('Common.unable to fetch user details'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'error',
          });
        }
      }

      setLoaded(true);
    };

    checkUserStatus();
  }, []);

  return isLoaded;
}
