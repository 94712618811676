import { useEffect } from 'react';
import Highcharts from 'highcharts';
import { useTranslation } from 'react-i18next';
import locale from 'yup/lib/locale';
import { setLocale } from 'yup';
import { setDefaultOptions } from 'date-fns';
import { MONTHS } from 'clipsal-cortex-utils/src/constants/common-constants';
import { getLocaleForDateFns, getLocaleForYup, SupportedLocales } from '../../utils/common/common-utils';

export function useHighchartSetup() {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    // Set Highcharts language options
    // Adding this at the top level of the app to avoid having to set it in every chart component
    // See: https://api.highcharts.com/highcharts/lang
    // Highcharts is currently setting up language packs
    // https://github.com/highcharts/highcharts/pull/20474
    // Once the PR is merged, maybe we can remove this and use the language pack directly
    Highcharts.setOptions({
      lang: {
        months: MONTHS.map((month) => t(`Common.${month.toLowerCase()}`)),
        shortMonths: MONTHS.map((month) => t(`Common.${month.toLowerCase()}`).slice(0, 3)),
      },
    });

    // set locale for date-fns to use app-wide
    setDefaultOptions({ locale: getLocaleForDateFns(i18n.language as SupportedLocales) });

    // set locale for yup to use app-wide
    const yupLocale = getLocaleForYup(i18n.language as SupportedLocales);
    if (yupLocale) {
      setLocale(yupLocale);
    } else {
      setLocale(locale); // Resets locale to default (en-us)
    }
  }, [i18n.language]);
}
