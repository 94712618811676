import { Box, Center, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { NotificationGrouping, useGetNotifications } from './notificationsApi';
import NotificationListItem from './NotificationListItem';
import NotificationsListSkeletonLoader from './NotificationsListSkeletonLoader';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { ChevronRightIcon } from '@chakra-ui/icons';
import SEHomeCard from '../../common/components/SEHomeCard';
import { useNavigate } from 'react-router-dom';
import { selectSite } from '../site/siteSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Props = {
  groupBy: NotificationGrouping;
  isArchived?: boolean;
};

export default function NotificationsListChronological({ groupBy, isArchived = false }: Props) {
  const {
    data: { chronological },
    isLoading,
    isError,
  } = useGetNotifications(groupBy, { limit: 10, offset: 0, archived: isArchived });
  const notifications = chronological?.data?.results;
  const totalNumNotifications = chronological?.data?.count;
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const { t } = useTranslation();
  const notificationsList = isLoading ? (
    <NotificationsListSkeletonLoader />
  ) : (
    notifications?.map((notification, notificationIndex) => (
      <NotificationListItem notification={notification} key={notificationIndex} isArchived={isArchived} />
    ))
  );

  if (isError) {
    return (
      <Alert data-testid="notifications-error-alert" status="error" my={4}>
        <AlertIcon w={8} h={8} />
        <AlertDescription py={4}>
          {t('Notifications.error fetching notifications')}.{' '}
          {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Box>
      <Text data-testid="list-type-title">{t('Notifications.chronological').toUpperCase()}</Text>
      <Box data-testid="notifications-list-chronological" mb={3}>
        {!notifications?.length && !isLoading ? (
          <Center data-testid="no-notifications-message" my={4}>
            <Text>{t('Notifications.no notifications')}</Text>
          </Center>
        ) : (
          notificationsList
        )}

        {!!totalNumNotifications && totalNumNotifications > 10 && (
          <SEHomeCard
            mt={2}
            cursor="pointer"
            onClick={() =>
              navigate(
                `/site/${site.site_id}/notifications/list_by_type/chronological?archived=${isArchived}` +
                  `&direction=forward`
              )
            }
            borderTopLeftRadius="5px"
            mb={2}
            as={'button'}
            w="100%"
            data-testid="view-all-chronological-notifications-btn"
          >
            <Flex justify="space-between" align="center">
              <Text>{t('Common.view all')}</Text>
              <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
            </Flex>
          </SEHomeCard>
        )}
      </Box>
    </Box>
  );
}
