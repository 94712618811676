import React from 'react';
import { Flex, IconButton, useToast, useDisclosure } from '@chakra-ui/react';
import { useGetEnergyModeUIConfig } from './energy-modes-helpers';
import {
  useGetSwitchGroupForType,
  useRemoveDeviceFromSwitchGroupMutation,
} from '../../devices/switches/switchGroupApi';
import { CloseIcon } from '@chakra-ui/icons';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { useGetSwitchQuery } from '../../devices/switches/switchApi';
import { useTranslation } from 'react-i18next';

type Props = {
  switchId: number;
};

export default function RemoveDesignatedCircuitButton({ switchId }: Props) {
  const { data: device } = useGetSwitchQuery(Number(switchId));
  const energyMode = useGetEnergyModeUIConfig();
  const [removeDeviceFromSwitchGroup] = useRemoveDeviceFromSwitchGroupMutation();
  const { isOpen: isRemoveDeviceOpen, onOpen: onRemoveDeviceOpen, onClose: onCloseRemoveDevice } = useDisclosure();
  const toast = useToast({ isClosable: true });
  const { data: switchGroup, isError, isLoading } = useGetSwitchGroupForType(energyMode.groupType!);
  const { t } = useTranslation();

  const handleRemoveDevice = async () => {
    try {
      await removeDeviceFromSwitchGroup({ switchId, groupId: switchGroup!.id }).unwrap();
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        title: t('Energy Modes.error removing circuit'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }
    onCloseRemoveDevice();
  };

  return (
    <Flex>
      <IconButton
        onClick={onRemoveDeviceOpen}
        bg="none"
        width="100%"
        justifyContent="flex-end"
        aria-label="Cancel"
        icon={<CloseIcon boxSize={3} />}
        data-testid={`remove-designated-circuit-btn-${switchId}`}
        height="100%"
        pb={2}
        isDisabled={isError || isLoading}
      />
      <AlertDialogModal
        isOpen={isRemoveDeviceOpen}
        onClose={onCloseRemoveDevice}
        header={`${t('Common.remove')} ${device?.site_switch_label || 'circuit'}?`}
        subHeader={t('Energy Modes.are you sure you want to remove', {
          energyMode: t(`Energy Modes.${energyMode.groupType}.title`),
        })}
        confirmButtonName={t('Common.remove')}
        onConfirm={handleRemoveDevice}
        cancelButtonName={t('Common.cancel')}
      />
    </Flex>
  );
}
