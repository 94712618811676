import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface SiteNavLink {
  route: string;
  routeName: string; // The name of the route, without wrapped uri / navigation id
  ariaLabel: string;
  icon: React.JSX.Element;
  activeIcon: React.JSX.Element;
  options?: {
    shouldPreventCloneIcon?: boolean;
  };
}

/**
 * Handles auto-setting of a selected index for navigation.
 * Useful for instances where a user is navigated to a route through a direct URL, meaning that no event is raised
 * in the associated component to set the link as active. We simply add an effect which matches the current path
 * against each link and sets the selected index as the match, so the component can handle styling.
 *
 * @param links The array of link objects to diff the `path` string against, to find our current route.
 */
export default function useSelectedIndexFromLinks(links: SiteNavLink[]): [number, (n: number) => void] {
  const { pathname } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    let mounted = true;

    const index = links.findIndex((link) => pathname.includes(link.routeName.toLowerCase()));

    if (index !== -1 && mounted) {
      setSelectedIndex(index);
    }

    return () => {
      mounted = false;
    };
  }, [setSelectedIndex, links, pathname]);

  return [selectedIndex, setSelectedIndex];
}
