import 'clipsal-cortex-utils/src/build/polyfills';
import 'react-app-polyfill/stable';
import 'focus-visible/dist/focus-visible'; // Removes blue border when clicking focusable items
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import './i18n';
import { AppProvider } from './Provider';
import { setupSentry } from './utils/common/setup-sentry';
import { setupAPI } from './utils/common/setup-api';
import { createRoutes } from './common/components/route/createRoutes';
import { routes } from './routes';

/**
 * Initialises the React application. Handles setup for the following top-level systems:
 *
 * - Sentry (error and performance tracking)
 * - Logrocket (error and performance tracking)
 * - Google Analytics (analytics tracking)
 * - Amplify (API automation system from AWS)
 *
 * Finally, the root DOM node (<App />) is rendered.
 */
async function setupReactDOM() {
  // Only send events to sentry in staging and production environments
  if (import.meta.env['MODE'] !== 'development') setupSentry();

  setupAPI();

  const container = document.getElementById('root');
  const root = createRoot(container!);

  const reactRoutes = createRoutes(routes);
  const router = createBrowserRouter([{ element: <AppProvider />, children: reactRoutes }]);

  // eslint-disable-next-line react/jsx-no-undef
  root.render(<RouterProvider router={router} />);
}

setupReactDOM();
