import React from 'react';
import { Avatar, Box, Center, FormControl, FormErrorMessage, Input, InputGroup, Text } from '@chakra-ui/react';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../../../user/userSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

type VerifyDeleteAccountFormData = {
  email: string;
  verifyText: string;
};

export function DeleteAccountVerify() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required()
      .label(t('Common.email'))
      .email()
      .equals([user.email], t('Account.email doesnt match')),
    verifyText: yup
      .string()
      .required(t('Account.please type delete', { delete: t('Common.delete').toUpperCase() }))
      .equals(
        [t('Common.delete').toUpperCase()],
        t('Account.please type delete', { delete: t('Common.delete').toUpperCase() })
      ),
  });

  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<VerifyDeleteAccountFormData>({
    resolver: yupResolver(schema),
  });

  return (
    <SlidingAnimationPageBase title={t('Account.delete account')} backURL={`../request-data-archive?direction=back`}>
      <Box m={4}>
        <Center flexDirection="column">
          <Avatar size={'xl'} name={`${user.firstName} ${user.lastName}`} />
          <Text fontSize={22} mt={3} fontWeight={400} textAlign="center" data-testid="delete-my-account-title">
            {t('Account.delete my account')}
          </Text>
          <Text textAlign="center" fontSize={15}>
            {t('Account.enter username and type delete')}
          </Text>

          <Box
            data-testid="verify-delete-form"
            onSubmit={handleFormSubmit(() => navigate(`../confirm-deletion`))}
            as={'form'}
            width="100%"
          >
            <FormControl my={3} isInvalid={!!errors.email}>
              <InputGroup>
                <Input
                  {...register('email')}
                  borderRadius={0}
                  height="50px"
                  type="text"
                  data-private
                  autoComplete="off"
                  data-testid="email-input"
                  borderColor={'#9FA0A4'}
                  placeholder={`${t('Common.email')}...`}
                />
              </InputGroup>
              <FormErrorMessage data-testid="email-error">{errors?.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={3} isInvalid={!!errors.verifyText}>
              <Input
                {...register('verifyText')}
                borderRadius={0}
                height="50px"
                type="text"
                data-private
                data-testid="verify-text-input"
                borderColor={'#9FA0A4'}
                placeholder={t('Account.type delete')}
              />
              <FormErrorMessage data-testid="verify-text-error">{errors?.verifyText?.message}</FormErrorMessage>
            </FormControl>

            <Center>
              <ArcButton arcColor={'#3DCD58'} w={250} mt={10} data-testid="verify-button" type="submit">
                {t('Common.continue')}
              </ArcButton>
            </Center>
          </Box>
        </Center>
      </Box>
    </SlidingAnimationPageBase>
  );
}
