import { Avatar, Box, Center, Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { SEHomeHeaderIcon } from 'clipsal-cortex-icons/src/custom-icons';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { selectInverters, selectSite } from '../site/siteSlice';
import { selectUser } from '../user/userSlice';
import { IS_DEMO_LOGIN } from '../../common/constants';
import packageJson from '../../../package.json';
import SimulationDeveloperTool from '../ev_charger/dashboard/SimulationDeveloperTool';
import AccountMenuButton from './AccountMenuButton';
import SEHomeCard from '../../common/components/SEHomeCard';
import TermsOfUseModal from './TermsOfUseModal';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { useTranslation } from 'react-i18next';
import { MANUFACTURER_ID_SATURN } from '../devices/devices-helper';

export function AccountDashboard() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { site_id: siteId, devices } = useSelector(selectSite);
  const siteInverters = useSelector(selectInverters);
  const siteHasSaturnInverter = useMemo(
    () => siteInverters.some((inverter) => [MANUFACTURER_ID_SATURN].includes(inverter.manufacturer_id)),
    [devices]
  );
  const { isOpen: isLogoutOpen, onOpen: onLogoutOpen, onClose: onLogoutClose } = useDisclosure();
  const { isOpen: isTermsOpen, onOpen: onTermsOpen, onClose: onTermsClose } = useDisclosure();
  const { t } = useTranslation();
  const iconColor = useColorModeValue('customGrey.900', 'customGrey.400');

  return (
    <SlidingAnimationPageBase
      backURL={`../../home?direction=back`}
      title={<SEHomeHeaderIcon w={'100%'} color={iconColor} />}
    >
      <Box px={4}>
        <Box>
          <SEHomeCard mb={5} borderTopLeftRadius={'5px'} p={0}>
            <AccountMenuButton
              py={10}
              includeBottomDivider={false}
              data-testid={'personal-details'}
              onClick={() => navigate('../personal-details')}
            >
              <Flex>
                <Avatar size={'sm'} mr={2} name={`${user.firstName} ${user.lastName}`} />
                <Flex align="flex-start" flexDirection="column" whiteSpace="pre-wrap">
                  <Text fontSize={16} fontWeight="normal">
                    {user.firstName} {user.lastName}
                  </Text>
                  <Text color="#979797" fontSize={14} fontWeight="normal" overflowWrap="anywhere" textAlign="left">
                    {user.email}
                  </Text>
                </Flex>
              </Flex>
            </AccountMenuButton>
          </SEHomeCard>

          <SEHomeCard mb={5} borderTopLeftRadius={'5px'} p={0}>
            {siteHasSaturnInverter && (
              <AccountMenuButton data-testid="energy-modes" onClick={() => navigate(`../energy-modes/home`)}>
                <Text fontSize={16} fontWeight="normal">
                  {t('Energy Modes.energy modes')}
                </Text>
              </AccountMenuButton>
            )}

            <AccountMenuButton data-testid="hardware" onClick={() => navigate(`../hardware`)}>
              <Text fontSize={16} fontWeight="normal">
                {t('Hardware.hardware')}
              </Text>
            </AccountMenuButton>

            <AccountMenuButton data-testid="settings" onClick={() => navigate('../settings/home')}>
              <Text fontSize={16} fontWeight="normal">
                {t('Account.settings')}
              </Text>
            </AccountMenuButton>

            <AccountMenuButton data-testid="tariff" onClick={() => navigate('../tariff')} includeBottomDivider={false}>
              <Text fontSize={16} fontWeight="normal">
                {t('Energy Rates.energy rates')}
              </Text>
            </AccountMenuButton>
          </SEHomeCard>
        </Box>
        <SEHomeCard mb={5} borderTopLeftRadius={'5px'} p={0}>
          <AccountMenuButton data-testid={'support'} onClick={() => navigate('../support')}>
            <Text fontSize={16} fontWeight="normal">
              {t('Support.support')}
            </Text>
          </AccountMenuButton>

          <AccountMenuButton data-testid={'terms-of-use-button'} onClick={onTermsOpen}>
            <Text fontSize={16} fontWeight="normal">
              {t('Common.terms of use')}
            </Text>
          </AccountMenuButton>

          <AccountMenuButton
            data-testid={'privacy-settings-button'}
            includeBottomDivider={false}
            onClick={() =>
              navigate(`../settings/privacy?direction=forward&backURL=/site/${siteId}/account/dashboard?direction=back`)
            }
          >
            <Text fontSize={16} fontWeight="normal">
              {t('Common.privacy')}
            </Text>
          </AccountMenuButton>
        </SEHomeCard>

        <SEHomeCard mb={5} borderTopLeftRadius={'5px'} p={0}>
          <AccountMenuButton
            data-testid="manage-homes"
            onClick={() => navigate('../manage-homes')}
            includeChevron={false}
          >
            <Text fontSize={16} fontWeight="normal">
              {t('Manage Homes.manage homes')}
            </Text>
          </AccountMenuButton>

          {user.role === 'SUPER_ADMIN' && (
            <AccountMenuButton data-testid="change-homes" onClick={() => navigate('/sites')} includeChevron={false}>
              <Text fontSize={16} fontWeight="normal">
                View Sites
              </Text>
            </AccountMenuButton>
          )}

          <AccountMenuButton
            isDisabled={IS_DEMO_LOGIN}
            data-testid="logout"
            onClick={onLogoutOpen}
            includeChevron={false}
            includeBottomDivider={false}
          >
            <Text fontSize={16} fontWeight="normal">
              {t('Account.logout')}
            </Text>
          </AccountMenuButton>
        </SEHomeCard>

        <Center data-testid="app-version" mt={4}>
          <Text>
            {t('Account.app version')}: {packageJson.version}
          </Text>
        </Center>

        <SEHomeCard mt={5} borderTopLeftRadius={'5px'} p={0}>
          <SimulationDeveloperTool />
        </SEHomeCard>

        <AlertDialogModal
          isOpen={isLogoutOpen}
          onClose={onLogoutClose}
          header={t('Account.logout')}
          subHeader={t('Account.are you sure logout')}
          confirmButtonName={t('Account.logout')}
          onConfirm={() => navigate('/logout')}
          cancelButtonName={t('Common.cancel')}
        />

        <TermsOfUseModal isOpen={isTermsOpen} onClose={onTermsClose} />
      </Box>
    </SlidingAnimationPageBase>
  );
}
