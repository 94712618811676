import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Radio,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SEHomeCard from '../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import { useSearchParams } from 'react-router-dom';
import EnergyModeListItem from './EnergyModeListItem';
import {
  ENERGY_MODES_UI_CONFIG,
  getEnergyModeType,
  STANDARD_MODES,
  StandardMode,
  TEMPORARY_MODES,
  TemporaryMode,
} from './energy-modes-helpers';
import { useGetEnergyModesQuery, useUpdateEnergyModesMutation } from './energyModesApi';
import { useTariffs } from '../../tariff/tariffApi';
import { useTranslation } from 'react-i18next';

export function EnergyModesHome() {
  const [search] = useSearchParams();
  const [hasPendingValue, setHasPendingValue] = useState(false);
  const toast = useToast({ isClosable: true });
  const { site_id: siteId, devices } = useSelector(selectSite);
  const pollingInterval = hasPendingValue ? 3000 : 0;
  const { data: energyModesData, isError, isLoading } = useGetEnergyModesQuery(siteId, { pollingInterval });
  const [updateEnergyModes, { isLoading: isUpdateLoading }] = useUpdateEnergyModesMutation();
  const selectedType = getSelectedEnergyModeType();
  const selectedMode = getSelectedEnergyMode();
  const { isLoading: isLoadingTariffs, tariffs } = useTariffs();
  const { t } = useTranslation();

  const standardEnergyModes = useMemo(() => {
    let modes = STANDARD_MODES;

    // if site is not on TOU tariff, hide tou mode
    const siteTariffType = tariffs[0]?.tariff?.tariff_type || '';
    if (siteTariffType !== 'TOU') modes = modes.filter((mode) => mode !== 'TOU');

    // if site has a ja12 compliant device, hide battery max mode
    if (devices.find((device) => !!device.ja12_compliance)) modes = modes.filter((mode) => mode !== 'BATTERY_MAX');

    return modes;
  }, [tariffs, devices]);

  // A re-render is required to update the polling interval when a pending value exists -- we can't use the state from
  // the query itself, as this creates a circular reference, so state tracks the pending value.
  useEffect(() => {
    if (!isLoading && !isError) {
      setHasPendingValue(!!(energyModesData && energyModesData?.pending));
    }
  }, [energyModesData, isLoading, isError]);

  function getSelectedEnergyMode() {
    if (!energyModesData) return 'LOW_POWER';
    if (energyModesData?.pending) return energyModesData.pending.energy_mode;

    return energyModesData.energy_mode;
  }

  function getSelectedEnergyModeType() {
    if (!energyModesData) return getEnergyModeType('LOW_POWER');
    if (energyModesData?.pending) return getEnergyModeType(energyModesData.pending.energy_mode);

    return getEnergyModeType(energyModesData.energy_mode);
  }

  const handleChangeEnergyMode = useCallback(async (mode: StandardMode | TemporaryMode) => {
    try {
      await updateEnergyModes({ siteId, energyMode: mode }).unwrap();
    } catch (e) {
      toast({
        status: 'error',
        title: t('Energy Modes.error setting mode'),
      });
    }
  }, []);

  const backURL = useMemo(() => {
    // If the user arrives here via the home page widget, we update the backURL for the nav.
    return search.get('backURL') ?? `../../../account/dashboard?direction=back`;
  }, [search]);

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Energy Modes.energy modes')}>
      <Box mx={4} mb={4}>
        <Text mb={5}>{t('Energy Modes.this sets the config')}</Text>

        <Skeleton isLoaded={!isLoading && !isLoadingTariffs} borderRadius={5} borderBottomRightRadius="25px">
          {isError && (
            <Alert status="error" variant="left-accent" data-testid="error-alert">
              <AlertIcon />
              {t('Energy Modes.error fetching mode')}
            </Alert>
          )}
          <SEHomeCard pt={1} pb={3} pr={0} pl={0} borderTopLeftRadius="5px">
            <Accordion allowToggle defaultIndex={[selectedType === 'TEMPORARY' ? 1 : 0]} borderBottomWidth={0}>
              <AccordionItem borderTop="none">
                <AccordionButton py={4} borderBottomWidth={1} _hover={{ bg: 'unset' }}>
                  <Flex flex="1">
                    <Radio
                      isChecked={selectedType === 'STANDARD'}
                      size="lg"
                      mr={3}
                      data-testid="standard-mode-setting"
                      isReadOnly={true}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {selectedType === 'STANDARD' ? (
                      <Text>
                        {t('Energy Modes.standard mode')}: {t('Energy Modes.active')}
                      </Text>
                    ) : (
                      <Text opacity={0.7}>
                        {t('Energy Modes.standard mode')}: {t('Energy Modes.inactive')}
                      </Text>
                    )}
                  </Flex>
                  <AccordionIcon boxSize={8} color={'customGrey.400'} />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {standardEnergyModes.map((energyMode) => {
                    const { icon: Icon, route } = ENERGY_MODES_UI_CONFIG[energyMode];
                    return (
                      <Box key={energyMode}>
                        <EnergyModeListItem
                          title={t(`Energy Modes.${energyMode}.title`)}
                          text={t(`Energy Modes.${energyMode}.text`)}
                          Icon={Icon}
                          onClick={() => handleChangeEnergyMode(energyMode)}
                          isChecked={selectedMode === energyMode}
                          isDisabled={isUpdateLoading || hasPendingValue}
                          route={route}
                          data-testid={`standard-energy-mode-setting-${energyMode.toLowerCase().replace(/_/g, '-')}`}
                        />
                        <Divider borderColor={'rgba(151, 151, 151, 0.3)'} ml={5} />
                      </Box>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem borderTop="none" borderBottom="none">
                <AccordionButton py={4} _hover={{ bg: 'unset' }}>
                  <Flex flex="1">
                    <Radio
                      isChecked={selectedType === 'TEMPORARY'}
                      size="lg"
                      mr={3}
                      data-testid="temporary-mode-setting"
                      onClick={(e) => e.stopPropagation()}
                    />
                    {selectedType === 'TEMPORARY' ? (
                      <Text>
                        {t('Energy Modes.temporary mode')}: {t('Energy Modes.active')}
                      </Text>
                    ) : (
                      <Text opacity={0.7}>
                        {t('Energy Modes.temporary mode')}: {t('Energy Modes.inactive')}
                      </Text>
                    )}
                  </Flex>
                  <AccordionIcon boxSize={8} color={'customGrey.400'} />
                </AccordionButton>
                <AccordionPanel pb={4} borderTopWidth={1}>
                  {TEMPORARY_MODES.map((energyMode, index) => {
                    const isLastElement = index == TEMPORARY_MODES.length - 1;
                    const { icon: Icon, route } = ENERGY_MODES_UI_CONFIG[energyMode];
                    return (
                      <Box key={energyMode}>
                        <EnergyModeListItem
                          title={t(`Energy Modes.${energyMode}.title`)}
                          text={t(`Energy Modes.${energyMode}.text`)}
                          Icon={Icon}
                          onClick={() => handleChangeEnergyMode(energyMode)}
                          isChecked={selectedMode === energyMode}
                          isDisabled={isUpdateLoading || hasPendingValue}
                          route={route}
                          data-testid={`temporary-energy-mode-setting-${energyMode.toLowerCase().replace(/_/g, '-')}`}
                        />
                        {!isLastElement && <Divider borderColor={'rgba(151, 151, 151, 0.3)'} ml={5} />}
                      </Box>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </SEHomeCard>
        </Skeleton>
      </Box>
    </SlidingAnimationPageBase>
  );
}
