import React, { useMemo } from 'react';
import { EvChargeHistory, EvChargeHistoryData } from 'clipsal-cortex-types/src/api/api-evcharger';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import { DateRangeType } from '../../../common/components/DateRangeTypePicker';

type Props = {
  chargeHistorySessionData: EvChargeHistoryData;
  selectedDateRangeType: DateRangeType;
  selectedPeriod: string;
};

/* istanbul ignore next -- @preserve */
export default function ChargingSessionListAccordion({ chargeHistorySessionData, selectedDateRangeType }: Props) {
  let records = chargeHistorySessionData.records;
  const textColor = useColorModeValue('#0F0F0F', '#FFFFFF');
  const { timezone } = useSelector(selectSite);

  // Gross, but this is necessary as the API is consistent but the UI is not.
  if (selectedDateRangeType === DateRangeType.Day && chargeHistorySessionData.records.length) {
    const totals = chargeHistorySessionData.records.reduce(
      (acc, curr) => {
        return {
          totalChargeDuration: acc.totalChargeDuration + curr.total_charge_duration,
          usage: acc.usage + curr.usage,
        };
      },
      { totalChargeDuration: 0, usage: 0 }
    );

    records = [
      {
        charge_count: chargeHistorySessionData.total_charge_count,
        total_charge_duration: totals.totalChargeDuration,
        usage: totals.usage,
        // Note: Just getting the start/end from the first record so we avoid timezone problems
        start: chargeHistorySessionData.records[0].start,
        end: chargeHistorySessionData.records[0].end,
        records: chargeHistorySessionData.records.map((record) => ({
          ...record,
          duration: record.total_charge_duration,
        })),
      },
    ];
  }

  return (
    <Accordion allowMultiple allowToggle defaultIndex={0}>
      {records.map((data, index) => {
        const isMonthRange = selectedDateRangeType === DateRangeType.Month;
        const formattedStartDate = formatInTimeZone(new Date(data.start), timezone, 'MM/dd/yy');
        const formattedEndDate = formatInTimeZone(new Date(data.end), timezone, 'MM/dd/yy');

        return (
          <AccordionItem key={index} data-testid="charge-history-accordion-item">
            <AccordionButton data-testid={`charge-history-accordion-button-${index}`}>
              <Box color={textColor} flex="1" textAlign="left">
                {formattedStartDate}
                {isMonthRange ? ` - ${formattedEndDate}` : ''}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={0} pb={2} data-testid={`charge-history-accordion-panel-${index}`}>
              <HistoryDataCard data={data} key={data.start + index} />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

type HistoryDataCardProps = { data: EvChargeHistory };

const HistoryDataCard = ({ data }: HistoryDataCardProps) => {
  const formattedChargeDuration = useMemo(() => {
    const [hours, minutes] = convertSecondsToHoursMinutes(data.total_charge_duration);
    return `${hours} hours ${minutes} minutes`;
  }, [data.total_charge_duration]);
  const sessionsForPeriod = data.records;
  const textColor = useColorModeValue('#0F0F0F', '#FFFFFF');
  const { timezone } = useSelector(selectSite);

  return (
    <Flex direction={'column'} mt="2px" px={4} py={2} data-testid="charge-history-card">
      <Flex color={textColor} justify={'space-between'}>
        <Text>{formattedChargeDuration}</Text>
        <Box as="span" fontSize={'18px'} fontWeight="bold">
          <Box as="span" color="#24B43F">
            {data.usage.toFixed(1)}
          </Box>
          <Box as="b" fontWeight="normal" fontSize="13px" ml={1}>
            kWh
          </Box>
        </Box>
      </Flex>
      {sessionsForPeriod?.map((sessionData, index) => {
        const [hours, minutes] = convertSecondsToHoursMinutes(sessionData.duration);
        const sessionLengthString = `${hours ? hours + 'h ' : ''}${minutes}m`;

        return (
          <Flex
            textAlign="center"
            data-testid="charge-history-line-item"
            key={index}
            fontSize={14}
            justify="space-between"
          >
            <Grid templateColumns="1fr 0.1fr 1fr" width={['40%', '30%', '20%']}>
              <Box textAlign="start" data-testid={'start-time'} as="span">
                {formatInTimeZone(new Date(sessionData.start), timezone, 'HH:mm aaa')}
              </Box>{' '}
              <Box as="span">-</Box>{' '}
              <Box textAlign="end" data-testid={'end-time'} as="span">
                {formatInTimeZone(new Date(sessionData.end), timezone, 'HH:mm aaa')}
              </Box>
            </Grid>

            <Box width={['20%', '30%', '40%']} />

            <Grid templateColumns="1fr 0.1fr 1fr" width={['40%', '30%', '20%']}>
              <Box textAlign="start" as="span">
                {sessionLengthString}
              </Box>{' '}
              <Box textAlign="center" as="span">
                -
              </Box>{' '}
              <Box textAlign="end">
                <Box as="span" color="#24B43F">
                  {sessionData.usage}
                </Box>{' '}
                <Box as="span">kWh</Box>
              </Box>
            </Grid>
          </Flex>
        );
      })}
    </Flex>
  );
};

/**
 * Simple helper for converting seconds to hours/minutes.
 *
 * @param seconds - Seconds to convert to hours/minutes
 * @returns A tuple where index 0 = hours and index 1 = minutes
 */
function convertSecondsToHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return [hours, minutes];
}
