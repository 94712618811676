import { useTariffFormContext } from './useTariffFormContext';
import { mapTariffApiToForm, mapTariffDataToApi } from './tariff-helpers';
import { useConvertUtilityIdToRetailerMutation, useCreateTariffMutation, useUpdateTariffMutation } from './tariffApi';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectInverters, selectSite, setCurrentSite } from '../site/siteSlice';
import { useReduxDispatch } from '../../app/store';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { RTKQError } from '../../common/api/api-helpers';
import { MANUFACTURER_ID_SATURN } from '../devices/devices-helper';

const useHandleSubmitForm = () => {
  const { getValues, reset, isDirtyForm } = useTariffFormContext();
  const [createTariff, { isLoading: isCreating }] = useCreateTariffMutation();
  const [convertUtilityId, { isLoading: isConverting }] = useConvertUtilityIdToRetailerMutation();
  const [updateTariff, { isLoading: isUpdating }] = useUpdateTariffMutation();
  const site = useSelector(selectSite);
  const siteInverters = useSelector(selectInverters);
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const toast = useToast({ status: 'success', duration: 3000, isClosable: true });
  const { t } = useTranslation();
  const routeBase = `/site/${site.site_id}/account`;

  const handleSubmitForm = async () => {
    // If the form is not dirty, take user to home page
    if (!isDirtyForm) {
      navigate(`${routeBase}/tariff/home?direction=back`);
      return;
    }

    const tariffData = getValues();
    const apiData = mapTariffDataToApi(tariffData);

    try {
      const isNewTariff = !apiData.id;

      // convert utility id to retailer id
      if (isNewTariff && tariffData.zipCode && tariffData.tariff.utility.id) {
        const retailer = await convertUtilityId({
          utilityId: tariffData.tariff.utility.id,
          zipCode: tariffData.zipCode,
        }).unwrap();
        apiData.tariff.retailer_id = retailer.id;
      }

      // if id exists, update tariff, else create new tariff
      const tariffPromise = isNewTariff ? createTariff({ siteId: site.site_id, body: apiData }) : updateTariff(apiData);
      const response = await tariffPromise.unwrap();
      const newData = mapTariffApiToForm(response);
      reset(newData);
      toast({
        title: t('Energy Rates.successfully saved'),
        status: 'success',
      });

      const siteHasSaturnInverter = siteInverters.some((device) =>
        [MANUFACTURER_ID_SATURN].includes(device.manufacturer_id)
      );
      if (site.isOnboarding) {
        if (siteHasSaturnInverter) {
          navigate(`${routeBase}/energy-modes/home`);
        } else navigate(`/site/${site.site_id}/home`);

        // This is the end of onboarding flow
        dispatch(setCurrentSite({ ...site, isOnboarding: false }));
      } else {
        navigate(`${routeBase}/tariff/home?direction=back`);
      }
    } catch (error) {
      const e = error as RTKQError;
      console.error(e);
      Sentry.captureException(e, {
        extra: {
          // NOTE: this must be a string, nested structures don't show in Sentry
          tariffFormData: JSON.stringify(tariffData),
          responseData: e.originalResponse?.data ? JSON.stringify(e.originalResponse?.data) : 'NO_RESPONSE_DATA',
        },
      });

      toast({
        title: t('Energy Rates.error saving tariff'),
        description:
          e.originalResponse?.data?.detail ??
          `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  };

  return { handleSubmitForm, isSubmitting: isCreating || isUpdating || isConverting };
};

export default useHandleSubmitForm;
