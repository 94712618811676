import axios from 'axios';
import { CIAM_IDMS_BASE_URL } from '../auth/auth-helpers';
import { Auth } from 'aws-amplify';
import { get, post } from '../../common/api/api-helpers';

const customFetch = axios.create({
  baseURL: CIAM_IDMS_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,
});

export async function getValidUserAccessToken() {
  // We need the IdP access token from cIAM to fetch user details from the user endpoint
  const { attributes } = await Auth.currentAuthenticatedUser();
  const tokenIssuedAtTimestamp = attributes['custom:acc_token_issued_at'];
  const issuedAt = new Date(Number(tokenIssuedAtTimestamp));
  const now = new Date();
  const differenceMs = now.getTime() - issuedAt.getTime();
  const oneHourMs = 1000 * 60 * 60;

  if (differenceMs > oneHourMs) {
    const refreshToken = attributes['custom:refresh_token'];
    // Fetch a new access token, then save that to Cognito, before making an API call to the IDMS REST API
    const tokenResponse = await post('/v1/ciam_refresh_token', { refresh_token: refreshToken });

    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:acc_token_issued_at': tokenResponse['issued_at'],
      'custom:access_token': tokenResponse['access_token'],
    });
  }

  // We can now assume the user access token token is valid
  return attributes['custom:access_token'];
}

async function getValidApigeeAccessToken() {
  // The following logic extracts the apigee access_token from local storage and checks its expiry, to ensure that
  // we don't make consecutive API calls to request new APIGEE tokens.
  const existingApigeeJWT = localStorage.getItem('apigeeJWT');
  if (existingApigeeJWT) {
    const deserializedJWT = JSON.parse(existingApigeeJWT);
    const expiryDate = new Date(deserializedJWT.issued_at);
    const expiresInSeconds = Number(deserializedJWT.expires_in);
    expiryDate.setMinutes(expiryDate.getMinutes() + expiresInSeconds / 60);

    // Not using the `now` defined above due to potential async code + other factors leading to desync
    if (expiryDate.getTime() <= new Date().getTime()) {
      // Fetch a new IDMS API access token
      const response = await get('/v1/ciam_apigee_token');
      localStorage.setItem('apigeeJWT', JSON.stringify(response));
      return response.access_token;
    }

    return deserializedJWT.access_token;
  } else {
    // Fetch a new IDMS API access token
    const response = await get('/v1/ciam_apigee_token');
    localStorage.setItem('apigeeJWT', JSON.stringify(response));
    const { access_token: idmsAccessToken } = response;
    return idmsAccessToken;
  }
}

// Intercept any request made to the cIAM IDMS REST API to ensure we have two valid tokens.
customFetch.interceptors.request.use(async (config) => {
  config.headers['X-CIAM-Authorization'] = await getValidUserAccessToken();
  config.headers['Authorization'] = `Bearer ${await getValidApigeeAccessToken()}`;
  return config;
});

customFetch.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Try again only once if the request fails
    if (error.response.status === 401 && !error.config._retry) {
      error.config._retry = true;
      return customFetch(error.config);
    }

    return Promise.reject(error);
  }
);

export default customFetch;
