import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export type WifiConfigState = {
  bearerToken: string | null;
};

export type WifiConfigReducers = {
  addBearerToken: (state: WifiConfigState, action: PayloadAction<string>) => void;
  resetWifiConfig: (state: WifiConfigState, action: PayloadAction<void>) => void;
};

const initialState: WifiConfigState = {
  bearerToken: null,
};

export const wifiConfigSlice = createSlice<WifiConfigState, WifiConfigReducers>({
  name: 'wifiConfig',
  initialState,
  reducers: {
    addBearerToken: (state, { payload }) => {
      return { ...state, bearerToken: payload };
    },
    resetWifiConfig: () => {
      return { ...initialState };
    },
  },
});

export const { resetWifiConfig, addBearerToken } = wifiConfigSlice.actions;

export const selectBearerToken = (state: RootState) => {
  return state.wifiConfig.bearerToken;
};

export default wifiConfigSlice.reducer;
