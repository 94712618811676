import React, { useCallback } from 'react';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import ArcButton from '../../common/components/ArcButton';
import { useNavigate } from 'react-router-dom';
import RetailerSelect from './RetailerSelect';
import { Controller, useWatch } from 'react-hook-form';
import { useTariffFormContext } from './useTariffFormContext';
import { MIN_TARIFF_PAGE_HEIGHT } from './tariff-constants';
import { debounceEvent } from '../../utils/common/component-helpers';
import { CircleIcon, GridIcon } from '../../styles/custom-icons';
import { useTranslation } from 'react-i18next';

export const TariffSetup = () => {
  const { errors, setValue, register, clearErrors, control, setError, getValues } = useTariffFormContext();
  const navigate = useNavigate();
  const { utility, planName } = useWatch({ name: 'tariff', control });
  const linkButtonColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { t } = useTranslation();
  const handleZipCodeChange = useCallback(
    debounceEvent((zipCode: string) => {
      setValue('zipCode', zipCode, { shouldDirty: true });
      setValue('tariff.utility.id', NaN, { shouldDirty: true });
      setValue('tariff.utility.name', '', { shouldDirty: true });
    }, 500),
    []
  );

  const handleSubmit = (continuePath = '../tariff-details') => {
    const zipCode = getValues('zipCode');
    if (!utility.id || !zipCode) {
      if (!utility.id) setError('tariff.utility.id', { message: t('Energy Rates.select energy provider') });
      if (!zipCode) setError('zipCode', { message: t('Common.zipcode required') });
    } else {
      navigate(continuePath);
    }
  };

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.set up rates')}
      includeTopNav
      px={4}
      backURL={`../../home?direction=back`}
      data-testid="tariff-setup"
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT}>
        <Center position="relative" mb={1} flexDirection="column" textAlign="center">
          <Box w="80px" position={'absolute'}>
            <GridIcon w="100%" h="100%" color="#9FA0A4" data-testid={`grid-icon`} />
          </Box>
          <CircleIcon h="60%" w="60%" color="#9FA0A4" data-testid="circle-icon" zIndex={3} />
        </Center>
        <Heading textAlign="center" fontSize={24}>
          {t('Energy Rates.select energy provider')}
        </Heading>
        <Text textAlign="center">{t('Energy Rates.use the dropdown to select')}</Text>
        <Text lineHeight={'19px'} fontWeight={500} mt={2}>
          {t('Common.zipcode')}
        </Text>
        <FormControl isInvalid={!!errors?.zipCode?.message}>
          <Input
            {...{
              borderRadius: 0,
              borderColor: 'customGrey.400',
              py: 6,
              _dark: { background: 'customGrey.900' },
              size: 'md',
              placeholder: `${t('Common.zipcode')}...`,
              _placeholder: { color: '#929497' },
            }}
            {...register(`zipCode`, { required: t('Common.zipcode required') })}
            onChange={(e) => {
              handleZipCodeChange(e.target.value);
              clearErrors('zipCode');
            }}
            data-testid="zip-code-input"
          />
          <FormErrorMessage>{errors?.zipCode?.message}</FormErrorMessage>
        </FormControl>

        <Text lineHeight={'19px'} fontWeight={500} mt={2}>
          {t('Energy Rates.energy provider')}
        </Text>
        <FormControl isInvalid={!!errors?.tariff?.utility?.id?.message} data-testid="retailer-select">
          <Controller
            control={control}
            name="tariff.utility.name"
            render={() => (
              <RetailerSelect
                selectedRetailerId={utility?.id || 0}
                selectedRetailerName={utility?.name || ''}
                onSelect={(id, name, state) => {
                  setValue('tariff.utility.id', id, { shouldDirty: true });
                  setValue('tariff.utility.name', name, { shouldDirty: true });
                  setValue('tariff.utility.state', state, { shouldDirty: true });
                  if (!planName) setValue('tariff.planName', name, { shouldDirty: true });
                  if (errors.tariff?.utility?.id) clearErrors('tariff.utility.id');
                  if (errors.zipCode) clearErrors('zipCode');
                }}
              />
            )}
          />
          <FormErrorMessage>{errors?.tariff?.utility?.id?.message}</FormErrorMessage>
        </FormControl>
        <Flex fontSize={14}>
          {t('Energy Rates.options based on')}{' '}
          <Text color={linkButtonColor} mx={1}>
            {getValues('zipCode')}
          </Text>{' '}
          {t('Common.zipcode').toLowerCase()}
        </Flex>

        <Button
          variant="link"
          px={0}
          minWidth={'fit-content'}
          w="fit-content"
          fontWeight={400}
          fontSize={15}
          mt={4}
          color={linkButtonColor}
          onClick={() => {
            if (errors.tariff?.utility?.id) clearErrors('tariff.utility.id');
            if (errors.zipCode) clearErrors('zipCode');
            if (!planName) setValue('tariff.planName', 'Manual Tariff', { shouldDirty: true });
            navigate('../tariff-details');
          }}
          data-testid="manual-tariff-setup-button"
        >
          {t('Energy Rates.set up manually')}
        </Button>

        <Center mt="auto" mb={5} flexDir={'column'}>
          <ArcButton
            arcColor="#3DCD58"
            minWidth="280px"
            py={6}
            mt="auto"
            onClick={() => handleSubmit('../select-retail-rates')}
            data-testid="continue-button"
          >
            {t('Common.continue')}
          </ArcButton>
        </Center>
      </VStack>
    </SlidingAnimationPageBase>
  );
};
