import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import ArcButton from '../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { useSelector } from 'react-redux';
import { selectSite } from '../../../site/siteSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorStatusIcon } from '../../../../styles/custom-icons';
import SelectWifiDialog from '../../../../common/components/SelectWifiDialog';
import { ErrorStatus, Matter } from '@clipsalsolar/capacitor-matter';
import TopNavProgressLoader from '../../../../common/components/TopNavProgressLoader';
import { BleClient } from '@capacitor-community/bluetooth-le';
import { useTranslation } from 'react-i18next';
import useOnboardingWizardProgress from '../../../home/useOnboardingWizardProgress';
import { RTKQError } from '../../../../common/api/api-helpers';
import { useGetSwitchesByManufacturerQuery, useRegisterAylaDeviceMutation } from '../../switches/switchApi';
import { useCommissionLocalMatterDeviceAndroidMutation } from '../localMatterDevicesApi';
import { MANUFACTURER_ID_AYLA } from '../../devices-helper';
import phoneMatterImg from '../../../../assets/images/mobile_matter_add.svg';
import phoneCodeImg from '../../../../assets/images/mobile_code_add.svg';
import { FactoryResetAccordion } from './FactoryResetAccordion';
import ScanBarcodeButton from '../../../../common/components/ScanBarcodeButton';
import { ScanResult } from '@capacitor-community/barcode-scanner';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CommissioningWizardStatus } from './commissioning-helpers';
import * as Sentry from '@sentry/react';
import AndroidPermissionCheck from './AndroidPermissionCheck';

type State = {
  status: CommissioningWizardStatus;
  qrCodeId: string | null;
  manualCode: string | null;
};

const INITIAL_STATE: State = {
  status: CommissioningWizardStatus.NOT_STARTED,
  qrCodeId: null,
  manualCode: null,
};

/* istanbul ignore next -- @preserve */
export function AndroidCommissioningWizard() {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);
  const { status, qrCodeId, manualCode } = state;
  const { onOpen: onOpenWifiDialog, onClose: onCloseWifiDialog, isOpen: isWifiDialogOpen } = useDisclosure();
  const [search] = useSearchParams();
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();
  const [registerAylaDevice] = useRegisterAylaDeviceMutation();
  const [commissionLocalMatterDeviceAndroid] = useCommissionLocalMatterDeviceAndroidMutation();
  const { needsToConfigureHardware } = useOnboardingWizardProgress();
  const { data: aylaSwitchesFromApi, isLoading: isSwitchesLoading } =
    useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_AYLA);

  const backURL = useMemo(() => {
    if (needsToConfigureHardware) return `../../../first_time_hardware_configuration?direction=back`;
    return search.get('backURL') ?? `../../../devices/add?direction=back`;
  }, [needsToConfigureHardware, search]);

  /* istanbul ignore next -- @preserve */
  useEffect(() => {
    if (status === CommissioningWizardStatus.READY) {
      initialiseBLE();
    }

    async function initialiseBLE() {
      try {
        await BleClient.initialize({ androidNeverForLocation: true });
        onOpenWifiDialog();
      } catch (error) {
        console.error('Error initialising BLE:', error);
        setState((p) => ({ ...p, status: CommissioningWizardStatus.BLUETOOTH_FAILURE }));
      }
    }
  }, [status]);

  /* istanbul ignore next -- @preserve */
  const registerDevice = useCallback(
    async (matterDeviceId: string) => {
      try {
        const deviceDetails = await Matter.getDeviceDetails({ deviceId: matterDeviceId });
        if (!deviceDetails.serialNumber) throw Error('No serial number found');

        const deviceAlreadyRegistered = aylaSwitchesFromApi?.find(
          (aylaSwitch) => aylaSwitch.oem_switch_id === deviceDetails.serialNumber
        );
        if (deviceAlreadyRegistered) {
          return deviceAlreadyRegistered.id;
        } else {
          const registeredDevice = await registerAylaDevice({
            siteId,
            deviceSerialNumber: deviceDetails.serialNumber,
          }).unwrap();
          return registeredDevice.id;
        }
      } catch (e) {
        Sentry.captureException(e);
        const error = e as RTKQError;
        if (error?.status === 409) {
          setState((p) => ({ ...p, status: CommissioningWizardStatus.DEVICE_ALREADY_REGISTERED }));
        } else {
          setState((p) => ({ ...p, status: CommissioningWizardStatus.FAILED }));
          toast({
            title: t('Devices.error registering device'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'error',
          });
        }

        // Even though Matter commissioning was successful locally, we want to uncommission it from the local device --
        // -- if registration fails to ensure synchronity between the local and remote state.
        await Matter.unpairDevice({ deviceId: matterDeviceId });
        throw e;
      }
    },
    [aylaSwitchesFromApi]
  );

  /* istanbul ignore next -- @preserve */
  async function commissionDeviceLocally(ssid: string, ssidPassword: string): Promise<string> {
    try {
      const commissioningResult = await commissionLocalMatterDeviceAndroid({
        manualCode: manualCode?.trim() ?? null,
        qrCode: qrCodeId?.trim() ?? null,
        ssid,
        ssidPassword,
      }).unwrap();
      return commissioningResult.deviceId;
    } catch (e) {
      Sentry.captureException(e);
      const error = e as { message: ErrorStatus };
      setState((p) => ({ ...p, status: CommissioningWizardStatus.FAILED }));

      let errorToastTitle = t('Devices.error commissioning');
      if (error?.message === '-9' || error?.message == '-999' || error?.message == '-11') {
        errorToastTitle = t('Devices.bluetooth not enabled');
      }
      if (error?.message === '-10') errorToastTitle = t('Devices.invalid code supplied');
      if (error?.message === '-13') errorToastTitle = t('Devices.unable to commission unsupported device');
      toast({
        title: errorToastTitle,
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });

      throw e;
    }
  }

  /* istanbul ignore next -- @preserve */
  async function commissionDevice(ssid: string, ssidPassword: string) {
    try {
      setState((p) => ({ ...p, status: CommissioningWizardStatus.IN_PROGRESS }));
      const matterDeviceId = await commissionDeviceLocally(ssid, ssidPassword);
      const switchId = await registerDevice(matterDeviceId);
      toast({
        title: t('Devices.successfully added'),
        status: 'success',
      });
      navigate(`../../${switchId}/view`);
    } catch (e) {
      console.error(e);
    }
  }

  async function deleteLocalDeviceCache() {
    try {
      await Matter.unpairDevices();
      toast({
        title: t('Devices.success deleting matter cache'),
        status: 'success',
      });
    } catch (error) {
      console.error(error);
      toast({
        title: t('Devices.error deleting matter cache'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  }

  /* istanbul ignore next -- @preserve */
  async function handleScanBarcode(scanResult: ScanResult) {
    if (scanResult?.content) {
      // After retrieving the QR code data we perform permission checks for commissioning via Bluetooth
      setState((p) => ({ ...p, qrCodeId: scanResult.content, status: CommissioningWizardStatus.PERMISSION_CHECK }));
    }
  }

  let content = (
    <>
      <Image w={'40%'} src={phoneMatterImg} alt="Phone" />
      <Heading size="lg" my={4}>
        {t('Devices.lets connect your device')}
      </Heading>
      <Text mr="auto">{t('Devices.you will require')}:</Text>
      <UnorderedList textAlign="left" mb={3} mr="auto">
        <ListItem>{t('Devices.wifi required')}</ListItem>
        <ListItem>{t('Devices.bluetooth required')}</ListItem>
        <ListItem>{t('Devices.compatible device required')}</ListItem>
      </UnorderedList>
      <Text mb={2} mr="auto">
        {t('Devices.pair code instruction')}
      </Text>
      <Text fontWeight={600} mb={1} fontSize={16} mr="auto">
        {t('Devices.commission failure instruction')}
      </Text>
      <FactoryResetAccordion />
      <ScanBarcodeButton onScan={handleScanBarcode} onScanError={(_) => undefined} mt={1} mb={2} w={'80%'} />
      <ArcButton
        onClick={() => {
          setState((p) => ({ ...p, status: CommissioningWizardStatus.GET_PAIR_CODE }));
        }}
        my={4}
        w={'80%'}
        arcColor="#3DCD57"
        data-testid="manual-code-button"
      >
        {t('Devices.manual code')}
      </ArcButton>
    </>
  );

  type ManualCodeFormData = {
    manualCode: string;
  };

  const manualCodeSchema = yup.object().shape({
    manualCode: yup
      .string()
      .matches(/^[0-9]+$/, t('Devices.must only contain numbers'))
      .min(11)
      .max(11)
      .trim()
      .label(t('Devices.manual code'))
      .required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ManualCodeFormData>({
    resolver: yupResolver(manualCodeSchema),
  });

  const handleFormSubmit = async ({ manualCode }: ManualCodeFormData) => {
    setState((p) => ({ ...p, manualCode, status: CommissioningWizardStatus.PERMISSION_CHECK }));
  };

  if (status === CommissioningWizardStatus.GET_PAIR_CODE) {
    content = (
      <Box onSubmit={handleSubmit(handleFormSubmit)} as="form">
        <Image w={'40%'} src={phoneCodeImg} alt="Phone code" m="auto" />
        <Heading size="lg" mt={2}>
          {t('Devices.manual code title')}
        </Heading>
        <Text mt={2}>{t('Devices.manual code text')}</Text>
        <FormControl mt={3} isInvalid={errors.manualCode && !!errors.manualCode.message}>
          <Input
            {...register('manualCode')}
            borderRadius={0}
            type="text"
            data-private
            background="white"
            _dark={{
              background: '#363E40',
            }}
            borderColor={'#9FA0A4'}
            placeholder={t('Devices.manual code placeholder')}
            data-testid="manual-code-input"
          />
          <FormErrorMessage>{errors?.manualCode?.message}</FormErrorMessage>
        </FormControl>
        <ArcButton mt={8} w={'80%'} arcColor="#3DCD57" data-testid="continue-button" type="submit">
          {t('Common.continue')}
        </ArcButton>
      </Box>
    );
  }

  if (status === CommissioningWizardStatus.READY) {
    content = (
      <>
        <TopNavProgressLoader />
        <Heading m={6} size="md" textAlign="center">
          {t('Devices.checking for wifi')}...
        </Heading>
      </>
    );
  }

  if (status === CommissioningWizardStatus.BLUETOOTH_FAILURE) {
    content = (
      <Box mx={5}>
        <Center w="100%" flexDirection="column">
          <ErrorStatusIcon height={200} width={200} />
          <Heading size="md" my={3}>
            {t('Devices.bluetooth not enabled')}.
          </Heading>
          <Link
            fontSize={18}
            color="schneiderSkyBlue.600"
            onClick={async () => {
              await BleClient.openAppSettings();
            }}
            data-testid="go-to-settings-link"
          >
            {t('Common.go to settings')}
          </Link>
        </Center>
      </Box>
    );
  }

  if (status === CommissioningWizardStatus.PERMISSION_CHECK) {
    content = (
      <AndroidPermissionCheck
        onSuccess={() => setState((p) => ({ ...p, status: CommissioningWizardStatus.READY }))}
        onFailure={() => setState((p) => ({ ...p, status: CommissioningWizardStatus.FAILED }))}
      />
    );
  }

  if (status === CommissioningWizardStatus.IN_PROGRESS) {
    content = (
      <>
        <TopNavProgressLoader />
        <Heading m={6} size="md" textAlign="center">
          {t('Devices.commissioning in progress')}...
        </Heading>
      </>
    );
  }

  if (status === CommissioningWizardStatus.FAILED) {
    content = (
      <Box mx={4}>
        <Center flexDirection="column">
          <ErrorStatusIcon height={200} width={200} />
          <Heading size="md" my={3}>
            {t('Devices.error commissioning')}
          </Heading>
        </Center>

        <Text mb={1} fontSize={16}>
          {t('Devices.qr and manual code error instruction')}
        </Text>

        <FactoryResetAccordion />

        <Center flexDirection="column">
          <ArcButton
            onClick={() => setState((p) => ({ ...p, status: CommissioningWizardStatus.READY }))}
            my={4}
            w={'80%'}
            arcColor="#3DCD57"
          >
            {t('Common.try again')}
          </ArcButton>

          <Text mt={6} my={2}>
            {t('Devices.delete matter cache message')}
          </Text>
          <ArcButton onClick={deleteLocalDeviceCache} my={4} w={'80%'} arcColor="#FF5B44">
            {t('Devices.delete matter cache title')}
          </ArcButton>
        </Center>
      </Box>
    );
  }

  if (status === CommissioningWizardStatus.DEVICE_ALREADY_REGISTERED) {
    content = (
      <Box mx={3}>
        <Center flexDirection="column">
          <ErrorStatusIcon height={200} width={200} />
          <Heading size="lg" my={3}>
            {t('Devices.device already registered title')}
          </Heading>
        </Center>
        <Text my={3}>{t('Devices.device already registered message')}</Text>
        <Center>
          <ArcButton
            arcColor="#3DCD57"
            my={8}
            w={'80%'}
            onClick={() => navigate(`../../../account/support`)}
            data-testid="contact-support-button"
          >
            {t('Common.contact support')}
          </ArcButton>
        </Center>
      </Box>
    );
  }

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={backURL}>
      <Center flexDirection="column" textAlign="center" mx={6}>
        {content}
        <SelectWifiDialog
          isOpen={isWifiDialogOpen}
          isLoading={isSwitchesLoading}
          onClose={() => {
            onCloseWifiDialog();
            navigate(backURL);
          }}
          onConfirm={async ({ password, ssid }) => {
            onCloseWifiDialog();
            await commissionDevice(ssid, password);
          }}
        />
      </Center>
    </SlidingAnimationPageBase>
  );
}
