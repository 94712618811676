import { Box, BoxProps, Center, Skeleton } from '@chakra-ui/react';
import React from 'react';
import SEHomeCard from './SEHomeCard';

type DataSummaryCardProps = {
  value: string;
  suffix?: string;
  prefix?: string;
  title: string;
  isLoaded: boolean;
} & BoxProps;

export default function DataSummaryCard({ value, suffix, prefix, title, isLoaded, ...boxProps }: DataSummaryCardProps) {
  return (
    <SEHomeCard borderBottomRightRadius={'5px'} borderTopLeftRadius={'5px'} as={Center} {...boxProps}>
      <Center flexDirection="column">
        <Skeleton isLoaded={isLoaded} maxHeight="40px">
          <Box as={'span'} fontSize={'3xl'} fontWeight={'bold'}>
            <Box fontWeight={'normal'} fontSize={'sm'} as={'span'}>
              {prefix}
            </Box>
            {value}
            {suffix && (
              <Box fontWeight={'normal'} fontSize={'sm'} as={'span'}>
                {' '}
                {suffix}
              </Box>
            )}
          </Box>
        </Skeleton>

        <Box fontWeight={'normal'} fontSize={'sm'} textAlign="center">
          {title}
        </Box>
      </Center>
    </SEHomeCard>
  );
}
