import { TariffRatesPerType } from '../tariff-types';

export const getDefaultTieredTariffRates = (seasonIndex: number): TariffRatesPerType => {
  return {
    import: [
      {
        seasonIndex,
        chargeType: 'CONSUMPTION_BASED',
        chargeClass: 'SUPPLY',
        chargePeriod: 'DAILY',
        transactionType: 'BUY_IMPORT',
        rateBands: [
          {
            sequenceNumber: 1,
            rate: undefined,
            hasConsumptionLimit: true,
            hasDemandLimit: false,
            consumptionUpperLimit: NaN, // this ensures it is filled
          },
          {
            sequenceNumber: 2,
            rate: undefined,
            hasConsumptionLimit: true,
            hasDemandLimit: false,
            consumptionUpperLimit: NaN,
          },
          {
            sequenceNumber: 3,
            rate: undefined,
            hasConsumptionLimit: true,
            hasDemandLimit: false,
            consumptionUpperLimit: null,
          },
        ],
      },
    ],
    export: [
      {
        seasonIndex,
        chargeType: 'CONSUMPTION_BASED',
        chargeClass: 'SUPPLY',
        chargePeriod: 'DAILY',
        transactionType: 'SELL_EXPORT',
        rateBands: [
          {
            sequenceNumber: 1,
            rate: undefined,
            hasConsumptionLimit: false,
            hasDemandLimit: false,
          },
        ],
      },
    ],
  };
};
