// If there are no query parameters, navigationDirection is null
// getPageTransition uses the default value of 'forward'
export type NavigationDirection = 'back' | 'forward' | null;

interface PageTransition {
  navigationDirection: NavigationDirection;
  isNavigatingFromRedirect?: boolean;
}

type TransitionX = '-100%' | '+100%' | '0';

export const getPageTransition = ({
  navigationDirection = 'forward',
  isNavigatingFromRedirect = false,
}: PageTransition) => {
  let transitionX: TransitionX;
  transitionX = navigationDirection === 'back' ? '-100%' : '+100%';
  // If arriving on the page via redirect, don't transition the component in
  if (isNavigatingFromRedirect) transitionX = '0';

  return {
    in: {
      x: 0,
      transition: { duration: 0.15, type: 'tween' },
      transitionEnd: { x: 0, y: 0, z: 0 },
    },
    out: {
      x: transitionX,
      transition: { duration: 0.15, type: 'tween' },
    },
  };
};
