import { Box, Button, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { IS_DEMO_LOGIN, IS_WEB } from '../../common/constants';
import { SortByOptions, SortByPopover } from './SortByPopover';
import { SwitchesList } from './switches/SwitchesList';
import { DevicesList } from './DevicesList';
import { useGetSwitchesQuery } from './switches/switchApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMeters, selectSite } from '../site/siteSlice';
import { MatterDevicesList } from './matter/MatterDevicesList';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import OptionSelectAlertDrawer from '../../common/components/OptionSelectAlertDrawer';
import GridOutage from '../home/default-home/GridOutage';
import { useLiveData } from '../site/live-data/liveDataApi';
import { GridStatus } from 'clipsal-cortex-types/src/api/api-saturn-live-data';
import { useTranslation } from 'react-i18next';
import DeviceListAccordion from './DeviceListAccordion';
import { MANUFACTURER_ID_AYLA, MANUFACTURER_ID_SEM } from './devices-helper';
import AlertBanner from 'clipsal-cortex-ui/src/components/AlertBanner';

export function DevicesHome() {
  const site = useSelector(selectSite);
  const siteMeters = useSelector(selectMeters);
  const { data: aylaSwitchesFromApi, isError } = useGetSwitchesQuery(site.site_id, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.filter((s) => s.manufacturer.manufacturer_id === MANUFACTURER_ID_AYLA),
    }),
  });
  const siteHasSenseMeter = siteMeters.some((meter) => [MANUFACTURER_ID_SEM].includes(meter.manufacturer_id));
  const siteHasMatterDevices = !!aylaSwitchesFromApi?.length;
  const {
    data: { grid_status: gridStatus },
  } = useLiveData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sortBy, setSortBy] = useState<SortByOptions>('COST');

  const isPowerOutage = gridStatus === GridStatus.GRID_OUTAGE;

  async function handleChangeSortBy(sortBy: SortByOptions) {
    setSortBy(sortBy);
  }

  function handleSelectEditDrawerOption(value: string) {
    if (value === 'ADD_DEVICES') {
      /*istanbul ignore else -- @preserve*/
      if (IS_WEB) {
        toast({
          title: t('Common.unavailable on web'),
          description: t('Common.download app to use feature'),
          status: 'error',
        });
      } else {
        navigate(`../add`);
      }
    }
    onClose();
  }

  return (
    <SlidingAnimationPageBase
      includeTopNavHamButton
      includeSchneiderHamButton
      includeBottomNav
      title={t('Devices.devices')}
    >
      <Box w="100%" px={3}>
        <Flex align="center" justify="space-between" pb={1}>
          <SortByPopover sortBy={sortBy} onChangeSortBy={handleChangeSortBy} />
          <OptionSelectAlertDrawer
            isOpen={isOpen}
            onClose={onClose}
            onSelectOption={handleSelectEditDrawerOption}
            options={[
              {
                value: 'ADD_DEVICES',
                label: t('Devices.add devices'),
              },
            ]}
            title={t('Devices.customize rooms')}
          />
          <Button
            data-testid="add-device-button"
            variant={'ghost'}
            size={'sm'}
            isDisabled={IS_DEMO_LOGIN}
            colorScheme={'schneiderSkyBlue'}
            fontWeight={500}
            onClick={onOpen}
          >
            {t('Common.edit')}
          </Button>
        </Flex>
        {isPowerOutage && <GridOutage message={t('Widgets.Grid Outage.message devices')} />}
        {isError && (
          <AlertBanner
            title={t('Devices.error fetching devices')}
            type="error"
            isCollapsible={false}
            isDismissable={false}
            containerTestId="error-alert"
          >
            {t('Common.please try again')} {t('Common.if this persists contact support')}
          </AlertBanner>
        )}
        {siteHasSenseMeter && (
          <DeviceListAccordion title={t('Devices.smart circuits').toUpperCase()}>
            <SwitchesList sortBy={sortBy} />
          </DeviceListAccordion>
        )}
        {siteHasMatterDevices && (
          <DeviceListAccordion title={t('Devices.smart devices').toUpperCase()}>
            <MatterDevicesList sortBy={sortBy} />
          </DeviceListAccordion>
        )}
        {siteHasSenseMeter && (
          <DeviceListAccordion title={t('Devices.discovered devices').toUpperCase()}>
            <DevicesList sortBy={sortBy} />
          </DeviceListAccordion>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
