import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import packageJson from '../../../package.json';
import { Device, SiteDetailData } from 'clipsal-cortex-types/src/api/api-site';
import { get } from '../../common/api/api-helpers';
import { getCurrentDayInTimezone } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { baseApi } from '../../app/services/baseApi';

type SiteState = Omit<SiteDetailData, 'has_valid_subscription' | 'integrations'> & {
  isOnboarding: boolean;
};

export const APP_VERSION = packageJson.version;

export type SiteReducers = {
  setCurrentSite: (state: SiteState, action: PayloadAction<SiteState>) => SiteState;
  addSiteDevice: (state: SiteState, action: PayloadAction<Device>) => void;
  removeSiteDevice: (state: SiteState, action: PayloadAction<number>) => void;
  clearCurrentSite: (state: SiteState, action: PayloadAction<void>) => void;
  addEvCharger: (state: SiteState, action: PayloadAction<Device>) => void;
  removeEvCharger: (state: SiteState, action: PayloadAction<string>) => void;
};

// @NOTE: isProduction might be used later for analytics inside this thunk
export const fetchSite = createAsyncThunk<
  Omit<SiteState, 'isOnboarding'>,
  { siteID: number; isProduction: boolean },
  { state: RootState }
>('site/fetchSite', async ({ siteID }, { dispatch }) => {
  dispatch(baseApi.util.invalidateTags(['MeterLiveData'])); // Invalidates any site live data WS connections
  return await get<SiteState>(`/v1/sites/${siteID}`);
});

const initialState: SiteState = {
  site_name: '',
  lat: 0, // Signed float
  lon: 0, // Signed float
  city: '',
  post_code: '',
  state: '',
  country: '',
  address: '',
  timezone: '',
  distributor_id: 0,
  tenant_id: 0,
  is_consumption_site: false,
  monitoring_start: '',
  nmi: '',
  site_id: 0,
  devices: [],
  has_controlled_load: false,
  has_ac_battery: false,
  has_dc_battery: false,
  commissioning_status: 'PENDING',
  isOnboarding: false,
};

export const siteSlice = createSlice<SiteState, SiteReducers>({
  name: 'site',
  initialState,
  reducers: {
    setCurrentSite: (state, action) => {
      return action.payload;
    },
    clearCurrentSite: () => {
      return initialState;
    },
    addSiteDevice: (state, action) => {
      state.devices = [...state.devices, action.payload];
    },
    removeSiteDevice: (state, action) => {
      state.devices = state.devices.filter((d) => d.id !== action.payload);
    },
    addEvCharger: (state, action) => {
      return { ...state, devices: [...(state?.devices || []), action.payload] };
    },
    removeEvCharger: (state, action) => {
      return { ...state, devices: state?.devices?.filter((device) => device.site_identifier !== action.payload) || [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSite.fulfilled, (state, action) => {
      return { isOnboarding: state.isOnboarding, ...action.payload };
    });
  },
});

export const { setCurrentSite, clearCurrentSite, addEvCharger, removeEvCharger, addSiteDevice, removeSiteDevice } =
  siteSlice.actions;

export const selectSite = (state: RootState) => {
  return state.site;
};

export const selectDevices = (state: RootState) => {
  return state.site.devices;
};

export const selectEvChargers = (state: RootState) => {
  return state.site.devices?.filter((device) => device.device_type === 'EV_CHARGER') || [];
};

export const selectMeters = (state: RootState) => {
  return state.site.devices?.filter((device) => device.device_type === 'METER') || [];
};

export const selectInverters = (state: RootState) => {
  return state.site.devices?.filter((device) => device.device_type === 'INVERTER') || [];
};

export const selectBatteries = (state: RootState) => {
  return state.site.devices?.filter((device) => device.device_type === 'BATTERY_PACK') || [];
};

export const selectCurrentDayForSite = (state: RootState) => {
  return getCurrentDayInTimezone(state.site.timezone);
};

export default siteSlice.reducer;
