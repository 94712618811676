import { baseApi } from '../../app/services/baseApi';
import { useSelector } from 'react-redux';
import { selectSite } from './siteSlice';
import { LiveWeatherData } from 'clipsal-cortex-types/src/api/api-weather';

export const weatherApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWeather: builder.query<LiveWeatherData, number>({
      query: (siteId) => `/v1/sites/${siteId}/weather`,
      providesTags: ['Weather'],
    }),
  }),
});

export const { useGetWeatherQuery: useOriginalGetWeatherQuery } = weatherApi;

/**
 * Provides some sensible default values for the weather query.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetWeatherQuery() {
  const { site_id: siteId } = useSelector(selectSite);
  const result = useOriginalGetWeatherQuery(siteId);
  return {
    ...result,
    data: result?.data,
  };
}
