import React from 'react';
import { BoxProps, Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useSelectedIndexFromLinks, { SiteNavLink } from './custom-nav-hooks';

interface Props {
  links: SiteNavLink[];
  navHeight: string;
  itemColor?: string;
  selectedItemColor?: string;
  backgroundColor?: string;
  selectedBackgroundColor?: string;
  selectedChildren?: BoxProps; // Shown when selected
  boxShadow?: string;
}

export default function BottomNav({
  links,
  navHeight,
  itemColor = 'textGrey.500',
  selectedItemColor = 'blue.500',
  backgroundColor: backgroundColorProp,
  selectedBackgroundColor: selectedBackgroundColorProp,
  selectedChildren,
  boxShadow = 'none',
}: Props) {
  const backgroundColorDefault = useColorModeValue('white', 'gray.800');
  const backgroundColor = backgroundColorProp ?? backgroundColorDefault;
  const selectedBackgroundColor = selectedBackgroundColorProp ?? backgroundColor;
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useSelectedIndexFromLinks(links);

  function handleClick(redirectRoute: string, i: number) {
    window.scrollTo({ top: 0 });
    setSelectedIndex(i);
    navigate(redirectRoute, { replace: true });
  }

  return (
    <>
      <Grid
        background={backgroundColor}
        zIndex={10}
        position="fixed"
        height={navHeight}
        data-testid="bottom-nav"
        width="100%"
        bottom="0px"
        templateColumns={`repeat(${links.length}, 1fr)`}
        boxShadow={boxShadow}
      >
        {links.map((link, i) => {
          const formattedRouteName = link.routeName.replace('/', '').toLowerCase();
          const icon = selectedIndex === i ? link.activeIcon : link.icon;
          return (
            <Flex
              color={selectedIndex === i ? selectedItemColor : itemColor}
              data-testid={`${formattedRouteName}-bottom-link-button`}
              direction={'column'}
              alignItems="center"
              justifyContent="center"
              background={selectedIndex === i ? selectedBackgroundColor : backgroundColor}
              rounded={0}
              onClick={() => handleClick(link.route, i)}
              height="100%"
              position={'relative'}
              key={link.routeName}
              aria-label={link.routeName}
            >
              <>
                {selectedIndex === i ? selectedChildren : undefined}
                <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
                  {icon}
                  <Text mt={1} fontSize={'10px'}>
                    {link.ariaLabel}
                  </Text>
                </Flex>
              </>
            </Flex>
          );
        })}
      </Grid>
    </>
  );
}
