import React from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

export type SEHomeCardProps = React.PropsWithChildren<BoxProps & { to?: string | { pathname: string } }>;

// Note: The 'to' prop is optional, so we can use this component for both links and non-links.
// We also need to forward the ref to ensure that the component can be used with DnD.
const SEHomeCard = React.forwardRef<HTMLDivElement, SEHomeCardProps>(({ children, ...boxProps }, ref) => {
  const backgroundColor = useColorModeValue('#F1F1F1', '#3C4446');

  return (
    <Box
      ref={ref}
      bg={backgroundColor}
      p={4}
      borderBottomLeftRadius={'5px'}
      borderBottomRightRadius={'25px'}
      borderTopRightRadius={'5px'}
      borderTopLeftRadius={'25px'}
      {...boxProps}
    >
      {children}
    </Box>
  );
});

SEHomeCard.displayName = 'SEHomeCard';

export default SEHomeCard;
