import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, Flex, SimpleGrid, Switch, Text, useColorMode } from '@chakra-ui/react';
import { SHDarkModeSwitchIcon, SHLightModeSwitchIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../user/userSlice';
import SEHomeCard from '../../../common/components/SEHomeCard';
import AccountMenuButton from '../AccountMenuButton';
import { getTemperatureFormat, getTimeFormat, TemperatureFormat } from './settings-helpers';
import SettingSwitch from './SettingSwitch';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { DarkMode } from '@aparajita/capacitor-dark-mode';
import { useTranslation } from 'react-i18next';
import { IS_ANDROID } from '../../../common/constants';

export function SettingsHome() {
  const { colorMode, setColorMode } = useColorMode();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const isMouseDown = useRef(false);
  const [isDeveloperToolsSwitchVisible, setDeveloperToolsSwitchVisible] = useState(false);
  const [temperatureFormat, setTemperatureFormat] = useState<TemperatureFormat>(getTemperatureFormat());
  const [shiftColorModeAutomatically, setShiftColorModeAutomatically] = useState(
    localStorage.getItem('shiftColorModeAutomatically') === 'true'
  );
  const [shouldEnableDeveloperTools, setDeveloperToolsEnabled] = useState(
    localStorage.getItem('developerToolsEnabled') === 'true'
  );
  const [timeFormat, setTimeFormat] = useState<'24HR' | 'AM/PM'>(getTimeFormat());

  useEffect(() => {
    localStorage.setItem('temperatureFormat', temperatureFormat);
  }, [temperatureFormat]);

  /* istanbul ignore next -- @preserve */
  async function handleToggleSystemColorMode(event: React.ChangeEvent<HTMLInputElement>) {
    const shiftAutomatically = event.target.checked;
    setShiftColorModeAutomatically(shiftAutomatically);
    localStorage.setItem('shiftColorModeAutomatically', shiftAutomatically.toString());

    if (shiftAutomatically) {
      const isDarkMode = await DarkMode.isDarkMode();
      if (isDarkMode.dark) {
        setColorMode('dark');
      } else {
        setColorMode('light');
      }
    }

    DarkMode.update();
  }

  function handleSetDeveloperToolsEnabled(isEnabled: boolean) {
    setDeveloperToolsEnabled(isEnabled);
    // Set in local storage
    localStorage.setItem('developerToolsEnabled', isEnabled.toString());
  }

  function handleMouseDown() {
    isMouseDown.current = true;

    setTimeout(() => {
      if (isMouseDown.current) {
        setDeveloperToolsSwitchVisible(true);
      }
    }, 3000);
  }

  function handleMouseUp() {
    isMouseDown.current = false;
  }

  useEffect(() => {
    localStorage.setItem('timeFormat', timeFormat);
  }, [timeFormat]);

  return (
    <SlidingAnimationPageBase title={t('Account.settings')} backURL={`../../dashboard?direction=back`}>
      <Box px={5}>
        <Text>{t('Account.home configuration').toUpperCase()}</Text>
        <SEHomeCard pr={0} pl={2} py={0} borderTopLeftRadius="5px">
          <AccountMenuButton
            data-testid="family-members-button"
            onClick={() => {
              navigate('../family-members/view?direction=forward');
            }}
          >
            <Text fontSize={16} fontWeight="normal" overflow="auto">
              {t('Account.family members')}
            </Text>
          </AccountMenuButton>
          {/* Add notification settings back post-MVP */}
          {/* <AccountMenuButton
            data-testid="notification-settings"
            onClick={() => {
              navigate('../notifications?direction=forward');
            }}
          >
            <Text fontSize={16} fontWeight="normal">
              Notifications
            </Text>
          </AccountMenuButton> */}
          <AccountMenuButton
            data-testid="widget-settings"
            onClick={() => {
              navigate('../widgets?direction=forward');
            }}
            borderBottomRightRadius={'25px'}
          >
            <Text fontSize={16} fontWeight="normal" overflow="auto">
              {t('Account.homepage widgets')}
            </Text>
          </AccountMenuButton>

          {/* istanbul ignore next -- @preserve */}
          {IS_ANDROID && (
            <AccountMenuButton
              data-testid="wifi-settings-button"
              onClick={() => {
                navigate('../wifi?direction=forward');
              }}
              borderBottomRightRadius={'25px'}
            >
              <Text fontSize={16} fontWeight="normal" overflow="auto">
                {t('Account.wifi settings')}
              </Text>
            </AccountMenuButton>
          )}

          <AccountMenuButton
            data-testid="licenses-button"
            onClick={() => {
              navigate('../licenses');
            }}
          >
            <Text fontSize={16} fontWeight="normal" overflow="auto">
              {t('Account.licenses')}
            </Text>
          </AccountMenuButton>

          <Box pb={1} pl={3}>
            <SettingSwitch
              my={5}
              onChange={(isChecked) => {
                setTimeFormat(isChecked ? '24HR' : 'AM/PM');
              }}
              isChecked={timeFormat == '24HR'}
              data-testid={'time-format-switch'}
              text={t('Account.show time 24hr')}
            />
            <SettingSwitch
              my={5}
              onChange={(isChecked) => {
                setTemperatureFormat(isChecked ? 'CELSIUS' : 'FAHRENHEIT');
              }}
              isChecked={temperatureFormat === 'CELSIUS'}
              data-testid={'temperature-format-switch'}
              text={t('Account.show temp c')}
            />
          </Box>
        </SEHomeCard>

        <Text
          pt={5}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {t('Account.appearance').toUpperCase()}
        </Text>
        <SEHomeCard borderTopLeftRadius={'5px'} mb={5}>
          <SimpleGrid opacity={shiftColorModeAutomatically ? 0.3 : 1} px={2} pt={8} columns={2}>
            <Flex
              data-testid="dark-mode-option"
              data-is-enabled={colorMode === 'dark'}
              onClick={() => {
                if (!shiftColorModeAutomatically) setColorMode('dark');
              }}
              direction="column"
              alignItems="center"
            >
              <SHDarkModeSwitchIcon w="100%" h="170px" isEnabled={colorMode === 'dark'} />
              <Text fontWeight={500} mt={1}>
                {t('Account.dark mode')}
              </Text>
            </Flex>
            <Flex
              data-testid="light-mode-option"
              data-is-enabled={colorMode === 'light'}
              onClick={() => {
                if (!shiftColorModeAutomatically) setColorMode('light');
              }}
              direction="column"
              alignItems="center"
            >
              <SHLightModeSwitchIcon w="100%" h="170px" isEnabled={colorMode === 'light'} />
              <Text fontWeight={500} mt={1}>
                {t('Account.light mode')}
              </Text>
            </Flex>
          </SimpleGrid>

          <Divider borderColor={'rgba(151, 151, 151, 0.3)'} my={4} />

          <Flex align={'center'} justify="space-between" px={6} pb={5}>
            <Text fontSize={18} fontWeight={500} overflow="auto">
              {t('Account.shift auto')}
            </Text>
            <Switch
              isChecked={shiftColorModeAutomatically}
              data-testid="system-color-mode-enabled"
              onChange={handleToggleSystemColorMode}
              size="lg"
              variant="solarGreen"
            />
          </Flex>
        </SEHomeCard>

        {user.role === 'SUPER_ADMIN' && isDeveloperToolsSwitchVisible && (
          <Flex mt={5} align={'center'} justify="space-between" px={8} pb={5}>
            <Text fontSize={18} fontWeight={550}>
              Enable Developer Tools
            </Text>
            <Switch
              isChecked={shouldEnableDeveloperTools}
              onChange={(event) => handleSetDeveloperToolsEnabled(event.target.checked)}
              size="lg"
              variant="solarGreen"
            />
          </Flex>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
