import React, { Fragment, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectBatteries, selectCurrentDayForSite, selectInverters, selectSite } from '../site/siteSlice';
import { useLiveData } from '../site/live-data/liveDataApi';
import { Box, Center, Flex, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import SEHomeCard from '../../common/components/SEHomeCard';
import { ChevronRightIcon } from '@chakra-ui/icons';
import LiveEnergyAnimation from './LiveEnergyAnimation';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';
import { useGetSavingsQuery } from '../savings/savingsApi';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { GridStatus } from 'clipsal-cortex-types/src/api/api-saturn-live-data';
import { useTranslation } from 'react-i18next';

export function LiveEnergyFlow() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const siteHasInverter = useSelector(selectInverters).length > 0;
  const siteHasBattery = useSelector(selectBatteries).length > 0;
  const {
    data: liveDataSummary,
    isInverterDataLoading,
    isFetching,
    isMeterDataLoading,
    isInverterError,
    isMeterError,
  } = useLiveData();
  const currentDate = useSelector(selectCurrentDayForSite);
  const { data: savingsData, isLoading: isSavingsLoading } = useGetSavingsQuery(currentDate, DateRangeType.Day);
  const totalSaved = useMemo(() => {
    return savingsData.find((dailySavings) => dailySavings.date === formatDate(currentDate))?.total_saved || 0;
  }, [savingsData]);
  const { solar, grid, battery = 0, consumption } = liveDataSummary;
  const gridStatusText = useMemo(() => {
    if (grid < 0) return t('Live Power Flow.exporting');
    if (grid > 0) return t('Live Power Flow.importing');
    if (liveDataSummary.grid_status === GridStatus.GRID_OUTAGE) return t('Live Power Flow.outage');
    return t('Live Power Flow.idle');
  }, [grid, liveDataSummary.grid_status]);

  const batteryStatusText = useMemo(() => {
    if (battery < 0) return t('Live Power Flow.battery discharging');
    if (battery > 0) return t('Live Power Flow.battery charging');
    return t('Live Power Flow.battery idle');
  }, [battery]);

  function checkIfLoaded() {
    // No matter what, if the site has an inverter, that's the source of truth.
    if (siteHasInverter) {
      // Assume EIP connection to Sense meter via inverter, so inverter data is the source of truth
      return !isInverterDataLoading;
    }
    return !isMeterDataLoading;
  }

  const isLoaded = checkIfLoaded();

  return (
    <SlidingAnimationPageBase backURL={`../home?direction=back`} title={t('Live Power Flow.live power flow')}>
      <Box px={3} w="100vw">
        <Center mb={5}>
          <LiveEnergyAnimation isParentLoaded={isLoaded && !isFetching} />
        </Center>

        <SEHomeCard mb={5} hidden={!(isMeterError || isInverterError)}>
          {isInverterError ? (
            <Text data-testid="inverter-error">
              {t(`Live Power Flow.error inverter${isMeterError ? ' and meter' : ''}`)}
            </Text>
          ) : (
            isMeterError && <Text data-testid="meter-error">{t('Live Power Flow.error meter')}</Text>
          )}
        </SEHomeCard>

        <SimpleGrid gap={3} columns={2} hidden={isMeterError || isInverterError}>
          <PowerSourceCard
            isLoaded={isLoaded}
            color={'solarGreenStatic.500'}
            value={solar}
            testId="live-solar-power-card"
            title={t('Solar.solar')}
            link={`/site/${siteId}/solar/home`}
          />
          {siteHasBattery && (
            <PowerSourceCard
              isLoaded={isLoaded}
              color={'schneiderSkyBlue.500'}
              value={Math.abs(battery)}
              testId="live-battery-power-card"
              title={batteryStatusText}
              link={`/site/${siteId}/battery/home`}
            />
          )}
          {/* {!!generator && (
            <PowerSourceCard isLoaded={isLoaded} color={'generatorOrangeStatic.500'} value={+generator.toFixed(1)} testId="live-generator-power-card" title="Generator" />
            )} */}
          <PowerSourceCard
            isLoaded={isLoaded}
            color={'gridRedStatic.500'}
            value={Math.abs(grid)}
            testId="live-grid-power-card"
            title={t('Grid.grid')}
            subTitle={gridStatusText}
          />
          <PowerSourceCard
            isLoaded={isLoaded}
            color={'generatorOrangeStatic.500'}
            value={consumption}
            testId="live-consumption-power-card"
            title={t('Live Power Flow.consumption')}
          />
        </SimpleGrid>

        <SEHomeCard
          data-testid="live-page-savings-link-btn"
          my={3}
          onClick={() => navigate(`../savings/home?backURL=/site/${siteId}/live_energy_flow?direction=back`)}
          borderTopLeftRadius="5px"
        >
          <Flex justify="space-between">
            <Flex>
              <Text mr={1}>{t('Live Power Flow.current savings')}:</Text>
              <Skeleton isLoaded={!isSavingsLoading}>{formatDollarsCents(totalSaved)}</Skeleton>
            </Flex>
            <ChevronRightIcon w={6} h={6} />
          </Flex>
        </SEHomeCard>

        <SEHomeCard
          data-testid="live-page-activity-link-btn"
          onClick={() => navigate(`../activity`)}
          borderTopLeftRadius="5px"
          my={3}
        >
          <Flex justify="space-between">
            <Box>{t('Live Power Flow.home energy activity')}</Box>
            <ChevronRightIcon w={6} h={6} />
          </Flex>
        </SEHomeCard>
      </Box>
    </SlidingAnimationPageBase>
  );
}

interface PowerSourceCardProps {
  readonly isLoaded: boolean;
  readonly color: string;
  readonly value: number;
  readonly testId: string;
  readonly title: string;
  readonly subTitle?: string;
  readonly link?: string;
}

function PowerSourceCard({ isLoaded, color, value, testId, title, subTitle, link }: PowerSourceCardProps) {
  const { pathname } = useLocation();
  const linkProps = link
    ? {
        as: Link,
        to: {
          pathname: link,
          search: `?direction=forward&backURL=${pathname}`,
        },
      }
    : undefined;

  return (
    <SEHomeCard data-testid={testId} p={3} {...linkProps}>
      <Center flexDirection="column">
        <Skeleton isLoaded={isLoaded}>
          <Flex color={color} fontSize={'3xl'} fontWeight={'bold'} alignItems="baseline">
            {Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(value)}
            <Box fontWeight={'normal'} fontSize={'sm'} ml={1}>
              kW
            </Box>
          </Flex>
        </Skeleton>
        {subTitle ? (
          <Fragment>
            <Text lineHeight={1}>{title}</Text>
            <Text fontSize={12} lineHeight={1}>
              {subTitle}
            </Text>
          </Fragment>
        ) : (
          <Text>{title}</Text>
        )}
      </Center>
    </SEHomeCard>
  );
}
