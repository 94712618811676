import {
  SEHomeNavActivityIcon,
  SEHomeNavDevicesIcon,
  SEHomeNavHomeIcon,
  SEHomeNavNotificationsIcon,
} from '../../styles/custom-icons';
import React from 'react';
import { SiteNavLink } from 'clipsal-cortex-ui/src/components/bottom-nav/custom-nav-hooks';

const COMMON_NAV_ICON_PROPS = {
  width: '30px',
  height: '30px',
};

// Configure site-level routes in this array
// eslint-disable-next-line react-refresh/only-export-components
export const NAV_LINKS: SiteNavLink[] = [
  {
    route: '/home',
    icon: <SEHomeNavHomeIcon {...COMMON_NAV_ICON_PROPS} />,
    activeIcon: <SEHomeNavHomeIcon {...COMMON_NAV_ICON_PROPS} />,
    ariaLabel: 'Home',
    routeName: 'Home',
  },
  {
    route: '/activity',
    icon: <SEHomeNavActivityIcon {...COMMON_NAV_ICON_PROPS} />,
    activeIcon: <SEHomeNavActivityIcon {...COMMON_NAV_ICON_PROPS} />,
    ariaLabel: 'Activity',
    routeName: 'Activity',
  },
  {
    route: '/devices',
    icon: <SEHomeNavDevicesIcon {...COMMON_NAV_ICON_PROPS} />,
    activeIcon: <SEHomeNavDevicesIcon {...COMMON_NAV_ICON_PROPS} />,
    ariaLabel: 'Devices',
    routeName: 'Devices',
  },
  {
    route: '/notifications',
    icon: <SEHomeNavNotificationsIcon {...COMMON_NAV_ICON_PROPS} />,
    activeIcon: <SEHomeNavNotificationsIcon {...COMMON_NAV_ICON_PROPS} />,
    ariaLabel: 'Notifications',
    routeName: 'Notifications',
  },
];
