import { Box, Center, Heading, Image, ListItem, Text, UnorderedList, useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Matter } from '@clipsalsolar/capacitor-matter';
import { useSelector } from 'react-redux';
import { selectSite } from '../../../site/siteSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import TopNavProgressLoader from '../../../../common/components/TopNavProgressLoader';
import ArcButton from '../../../../common/components/ArcButton';
import { useTranslation } from 'react-i18next';
import { useGetSwitchesByManufacturerQuery, useRegisterAylaDeviceMutation } from '../../switches/switchApi';
import { useCommissionLocalMatterDeviceIOSMutation } from '../localMatterDevicesApi';
import useOnboardingWizardProgress from '../../../home/useOnboardingWizardProgress';
import { ErrorStatusIcon } from '../../../../styles/custom-icons';
import { RTKQError } from '../../../../common/api/api-helpers';
import { MANUFACTURER_ID_AYLA } from '../../devices-helper';
import { FactoryResetAccordion } from './FactoryResetAccordion';
import { CommissioningWizardStatus } from './commissioning-helpers';
import * as Sentry from '@sentry/react';
import phoneMatterImg from '../../../../assets/images/mobile_matter_add.svg';

type State = {
  status: CommissioningWizardStatus;
};

/* istanbul ignore next -- @preserve */
export function IOSCommissioningWizard() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { site_id: siteId, site_name: siteName } = useSelector(selectSite);
  const toast = useToast({ isClosable: true, duration: 3000 });
  const [state, setState] = useState<State>({
    status: CommissioningWizardStatus.NOT_STARTED,
  });
  const { status } = state;
  const { t } = useTranslation();
  const [registerAylaDevice] = useRegisterAylaDeviceMutation();
  const [commissionLocalMatterDevice] = useCommissionLocalMatterDeviceIOSMutation();
  const { needsToConfigureHardware } = useOnboardingWizardProgress();
  const { data: aylaSwitchesFromApi } = useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_AYLA);

  const backURL = useMemo(() => {
    if (needsToConfigureHardware) return `../../../first_time_hardware_configuration?direction=back`;
    return search.get('backURL') ?? `../../../devices/add?direction=back`;
  }, [needsToConfigureHardware, search]);

  async function commissionDeviceLocally(): Promise<string | undefined> {
    try {
      const commissioningResult = await commissionLocalMatterDevice({
        homeName: siteName,
      }).unwrap();
      if (commissioningResult.didComplete && commissioningResult.deviceId) {
        return commissioningResult.deviceId;
      } else return;
    } catch (e) {
      Sentry.captureException(e);
      toast({
        status: 'error',
        title: t('Devices.error commissioning'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
      setState((p) => ({ ...p, status: CommissioningWizardStatus.FAILED }));
      throw e;
    }
  }

  const handleStartNativeCommissioningWizard = useCallback(async () => {
    try {
      const matterDeviceId = await commissionDeviceLocally();
      if (matterDeviceId) {
        const switchId = await registerDevice(matterDeviceId);
        toast({
          title: t('Devices.successfully added'),
          status: 'success',
        });
        navigate(`../../${switchId}/view`);
      } else {
        navigate(backURL);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
  }, [siteId, siteName]);

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    if (status === CommissioningWizardStatus.IN_PROGRESS) {
      handleStartNativeCommissioningWizard();
    }
  }, [handleStartNativeCommissioningWizard, status]);

  const registerDevice = useCallback(
    async (matterDeviceId: string) => {
      try {
        const deviceDetails = await Matter.getDeviceDetails({ deviceId: matterDeviceId });
        if (!deviceDetails.serialNumber) throw Error('No serial number found');

        const deviceAlreadyRegistered = aylaSwitchesFromApi?.find(
          (aylaSwitch) => aylaSwitch.oem_switch_id === deviceDetails.serialNumber
        );
        if (deviceAlreadyRegistered) {
          return deviceAlreadyRegistered.id;
        } else {
          const registeredDevice = await registerAylaDevice({
            siteId,
            deviceSerialNumber: deviceDetails.serialNumber,
          }).unwrap();
          return registeredDevice.id;
        }
      } catch (e) {
        Sentry.captureException(e);
        const error = e as RTKQError;
        if (error.status === 409) {
          setState((p) => ({ ...p, status: CommissioningWizardStatus.DEVICE_ALREADY_REGISTERED }));
        } else {
          setState((p) => ({ ...p, status: CommissioningWizardStatus.FAILED }));
          toast({
            title: t('Devices.error registering device'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'error',
          });
        }
        // Even though Matter commissioning was successful locally, we want to uncommission it from the local device --
        // -- if registration fails to ensure synchronicity between the local and remote state.
        await Matter.unpairDevice({ deviceId: matterDeviceId });
        throw e;
      }
    },
    [aylaSwitchesFromApi]
  );

  async function deleteLocalDeviceCache() {
    try {
      await Matter.unpairDevices();
      toast({
        title: t('Devices.success deleting matter cache'),
        status: 'success',
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      toast({
        title: t('Devices.error deleting matter cache'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  }

  let contents = (
    <>
      <Image w={'40%'} src={phoneMatterImg} alt="Phone" />
      <Heading size="lg" textAlign="center" my={4}>
        {t('Devices.lets connect your device')}
      </Heading>
      <Text mr="auto">{t('Devices.you will require')}:</Text>
      <UnorderedList textAlign="left" mb={3} mr="auto">
        <ListItem>{t('Devices.wifi required')}</ListItem>
        <ListItem>{t('Devices.bluetooth required')}</ListItem>
        <ListItem>{t('Devices.compatible device required')}</ListItem>
      </UnorderedList>
      <Text mb={2} mr="auto">
        {t('Devices.pair code instruction')}
      </Text>
      <Text fontWeight={600} mb={1} fontSize={16} mr="auto">
        {t('Devices.commission failure instruction')}
      </Text>
      <FactoryResetAccordion />

      <ArcButton
        onClick={
          /* istanbul ignore next -- @preserve */ () => {
            setState((p) => ({ ...p, status: CommissioningWizardStatus.IN_PROGRESS }));
          }
        }
        mt={1}
        mb={2}
        w={'80%'}
        arcColor="#3DCD57"
        data-testid="continue-button"
      >
        {t('Common.continue')}
      </ArcButton>
    </>
  );

  /* istanbul ignore next -- @preserve */
  if (status === CommissioningWizardStatus.IN_PROGRESS) {
    contents = (
      <>
        <TopNavProgressLoader />
        <Heading m={6} size="md" textAlign="center">
          {t('Devices.commissioning in progress')}...
        </Heading>
      </>
    );
  }

  /* istanbul ignore next -- @preserve */
  if (status === CommissioningWizardStatus.FAILED) {
    contents = (
      <>
        <ErrorStatusIcon height={200} width={200} />

        <Heading my={3} size="md" textAlign="center">
          {t('Devices.error commissioning')}
        </Heading>

        <Text mb={1} fontSize={16}>
          {t('Devices.qr and manual code error instruction')}
        </Text>

        <FactoryResetAccordion />

        <ArcButton onClick={handleStartNativeCommissioningWizard} my={4} w={'80%'} arcColor="#3DCD57">
          {t('Common.try again')}
        </ArcButton>

        <Text mt={6} my={2}>
          {t('Devices.delete matter cache message')}
        </Text>

        <ArcButton onClick={deleteLocalDeviceCache} my={4} w={'80%'} arcColor="#FF5B44">
          {t('Devices.delete matter cache title')}
        </ArcButton>
      </>
    );
  }

  /* istanbul ignore next -- @preserve */
  if (status === CommissioningWizardStatus.DEVICE_ALREADY_REGISTERED) {
    contents = (
      <Box mx={3}>
        <Center flexDirection="column">
          <ErrorStatusIcon height={200} width={200} />
          <Heading size="lg" my={3}>
            {t('Devices.device already registered title')}
          </Heading>
        </Center>
        <Text my={3}>{t('Devices.device already registered message')}</Text>
        <Center>
          <ArcButton
            arcColor="#3DCD57"
            my={6}
            w={'80%'}
            onClick={() => navigate(`../../../account/support`)}
            data-testid="contact-support-button"
          >
            {t('Common.contact support')}
          </ArcButton>
        </Center>
      </Box>
    );
  }

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={backURL}>
      <Center flexDirection="column" mx={6}>
        {contents}
      </Center>
    </SlidingAnimationPageBase>
  );
}
