import { Slider, SliderFilledTrack, SliderThumb, SliderTrack, SliderMark } from '@chakra-ui/react';
import React, { useState } from 'react';
import { DEFAULT_DIMMER_LEVEL } from '../../devices-helper';

type Props = {
  onSliderChangeEnd: (value: number) => void;
  defaultValue?: number;
};

type State = {
  sliderValue: number;
};

export default function ScheduleSlider({ onSliderChangeEnd, defaultValue = DEFAULT_DIMMER_LEVEL }: Props) {
  const INITIAL_STATE: State = {
    sliderValue: defaultValue,
  };
  const [state, setState] = useState(INITIAL_STATE);
  const { sliderValue } = state;

  return (
    <Slider
      defaultValue={defaultValue}
      onChange={(value) => setState({ ...state, sliderValue: value })}
      onChangeEnd={onSliderChangeEnd}
      size="lg"
      mt={4}
      mb={1}
    >
      <SliderTrack height="0.5rem">
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb p="0.75rem" display="block" data-testid="schedule-switch-slider" />
      <SliderMark
        data-testid="schedule-switch-slider-marker"
        value={sliderValue}
        textAlign="center"
        mt="0.8rem"
        ml="-5"
        w="12"
      >
        {sliderValue}%
      </SliderMark>
    </Slider>
  );
}
