import React, { useEffect, useState } from 'react';
import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps as ReactTableBodyProps,
  usePagination,
  useTable,
} from 'react-table';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  ViewIcon,
} from '@chakra-ui/icons';

export enum TableAction {
  Delete = 'delete',
  View = 'view',
}

const TABLE_ACTION_TYPE_TO_ICON = {
  [TableAction.Delete]: <DeleteIcon />,
  [TableAction.View]: <ViewIcon />,
};

export interface TableActionConfig {
  type: TableAction;
  redirect: string;
}

interface Props<T extends object = any> {
  data: T[];
  columns: Column<T>[];
  actions?: TableActionConfig[];
  enablePagination?: boolean;
  id?: string;
  onFetchData?: (pageIndex: number, pageSize: number) => void;
  isLoaded: boolean;
  totalPageCount: number; // Total number of pages
}

interface State {
  redirect: null | string; // When set to a string, redirects to that page's route
  openDialog: boolean; // Whether an action wants to open a dialog
}

const DEFAULT_PAGE_SIZES = [10, 20, 30, 40, 50];

export default function Table({
  data,
  columns,
  actions,
  enablePagination,
  id,
  onFetchData,
  totalPageCount,
  isLoaded,
}: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { data, columns, manualPagination: true, pageCount: totalPageCount, initialState: { pageIndex: 0 } },
    usePagination
  );
  const [state, setState] = useState<State>({
    redirect: null,
    openDialog: false,
  });
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('rgb(237, 242, 247)', 'black');
  const navigate = useNavigate();

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    onFetchData && onFetchData(pageIndex, pageSize);
  }, [onFetchData, pageIndex, pageSize]);

  useEffect(() => {
    if (state.redirect) {
      navigate(state.redirect, { replace: true });
    }
  }, [state.redirect]);

  return (
    <Box background={backgroundColor} shadow="md">
      <Box
        id={id}
        w="100%"
        borderStyle="hidden"
        borderTopRightRadius="5px"
        borderTopLeftRadius="5px"
        boxShadow={`0 0 0 1px ${borderColor}`}
        as="table"
        {...getTableProps()}
      >
        <TableHeader actions={actions} headerGroups={headerGroups} />

        <TableBody
          isLoaded={isLoaded}
          actions={actions}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          state={state}
          setState={setState}
        />
      </Box>

      {enablePagination && (
        <TablePagination
          isLoaded={isLoaded}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      )}
    </Box>
  );
}

interface TableHeaderProps {
  headerGroups: HeaderGroup<object>[];
  actions: TableActionConfig[] | undefined;
}

function TableHeader({ headerGroups, actions }: TableHeaderProps) {
  const borderColor = useColorModeValue('#e2e8f0', 'black');
  const backgroundColor = useColorModeValue('#f7fafc', '#242633');
  const headingColor = useColorModeValue('rgb(113, 128, 150)', 'gray.400');

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <Box
          borderTopLeftRadius="5px"
          borderTopRightRadius="5px"
          as="tr"
          bg={backgroundColor}
          borderBottom={`1px solid ${borderColor}`}
          {...headerGroup.getHeaderGroupProps()}
          key={headerGroup.getHeaderGroupProps().key}
        >
          {headerGroup.headers.map((column) => (
            <Box
              textAlign="start"
              borderTopLeftRadius="5px"
              p={2}
              as="th"
              {...column.getHeaderProps()}
              key={column.getHeaderProps().key}
            >
              <Heading color={headingColor} size="sm">
                {column.render('header')}
              </Heading>
            </Box>
          ))}
          {actions && (
            <Box textAlign="start" p={2} borderTopRightRadius="5px" as="th">
              <Heading color={headingColor} size="sm">
                Actions
              </Heading>
            </Box>
          )}
        </Box>
      ))}
    </thead>
  );
}

interface TableBodyProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<object>) => ReactTableBodyProps;
  page: Row<object>[];
  prepareRow: (row: Row<object>) => void;
  actions: TableActionConfig[] | undefined;
  state: State;
  setState: (state: State) => void;
  isLoaded: boolean;
}

function TableBody({ getTableBodyProps, page, prepareRow, actions, state, setState, isLoaded }: TableBodyProps) {
  const borderColor = useColorModeValue('rgb(237, 242, 247)', 'black');

  return (
    <Box as="tbody" position={'relative'} {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);

        return (
          <Box
            opacity={isLoaded ? 1 : 0.3}
            borderBottom={`1px solid ${borderColor}`}
            as="tr"
            {...row.getRowProps()}
            key={row.getRowProps().key}
          >
            {row.cells.map((cell) => (
              <Box p={2} as="td" {...cell.getCellProps()} key={cell.getCellProps().key}>
                {cell.render('Cell')}
              </Box>
            ))}
            {actions && (
              <Box alignItems="center" justifyContent="center" as="td">
                {actions.map((action, i) => (
                  <IconButton
                    size="sm"
                    onClick={() => {
                      // Decode our redirect string, retrieve properties off the original object, then re-build.
                      const redirect = buildRoute(row.original, action.redirect);

                      setState({ ...state, redirect });
                    }}
                    mx={1}
                    key={i}
                    aria-label={action.type}
                    icon={TABLE_ACTION_TYPE_TO_ICON[action.type]}
                  />
                ))}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

interface TablePaginationProps {
  gotoPage: (pageNumber: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageIndex: number;
  pageOptions: number[];
  pageSize: number;
  pageCount: number;
  setPageSize: (pageSize: number) => void;
  isLoaded: boolean;
}

function TablePagination({
  gotoPage,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  pageSize,
  pageCount,
  setPageSize,
  isLoaded,
}: TablePaginationProps) {
  const borderColor = useColorModeValue('rgb(237, 242, 247)', 'black');

  return (
    <Flex
      p={2}
      borderBottomRightRadius="5px"
      borderBottomLeftRadius="5px"
      borderBottom={`1px solid ${borderColor}`}
      borderLeft={`1px solid ${borderColor}`}
      borderRight={`1px solid ${borderColor}`}
      justifyContent="space-between"
      w="100%"
      pt={4}
    >
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Flex>
          <IconButton
            mx={1}
            size="xs"
            aria-label="Back to first page"
            icon={<ArrowLeftIcon />}
            onClick={() => gotoPage(0)}
            isDisabled={!canPreviousPage || !isLoaded}
          />
          <IconButton
            mx={1}
            size="xs"
            aria-label="Go to previous page"
            icon={<ChevronLeftIcon />}
            onClick={() => previousPage()}
            isDisabled={!canPreviousPage || !isLoaded}
          />
          <IconButton
            mx={1}
            size="xs"
            aria-label="Go to next page"
            icon={<ChevronRightIcon />}
            onClick={() => nextPage()}
            isDisabled={!canNextPage || !isLoaded}
          />
          <IconButton
            mx={1}
            size="xs"
            aria-label="Go to next page"
            icon={<ArrowRightIcon />}
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage || !isLoaded}
          />
        </Flex>

        <Box mx={2}>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </Box>

        {!isLoaded && (
          <Box ml={6}>
            <Spinner mr={1} size={'xs'} /> Loading...
          </Box>
        )}
      </Flex>

      <Flex>
        <Box>
          <InputGroup size="sm">
            <InputLeftAddon>Go to page</InputLeftAddon>
            <NumberInput
              isDisabled={!isLoaded}
              defaultValue={pageIndex + 1}
              onChange={(e, eNumber) => {
                gotoPage(eNumber ? eNumber - 1 : 0);
              }}
              style={{ width: '100px' }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </Box>
      </Flex>
      <Box>
        <Select
          isDisabled={!isLoaded}
          size="sm"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {DEFAULT_PAGE_SIZES.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Box>
    </Flex>
  );
}

/**
 * Parses and re-builds a query string, according to the original object specified.
 *
 * Note that minimal error checking is implemented in this function - take care to provide a correctly formatted route.
 *
 * @param originalObject The original object to retrieve properties from, as specified in the route.
 * @param route The route containing embedded property values through `{}` syntax.
 */
export function buildRoute(originalObject: any, route: string): string {
  return route.split('/').reduce<string>((rebuiltRoute, segment) => {
    if (!!segment) {
      // Check that this segment's value is wrapped in `{}` - if so, it's a property we have to access on the row's
      // original object. Any other case is a string literal, which we simply return
      const property = segment.match(/\{([^{}]*?)\}/)?.[1];

      if (property) {
        return `${rebuiltRoute}/${originalObject[property]}`;
      }

      return `${rebuiltRoute}/${segment}`;
    }

    return rebuiltRoute;
  }, '');
}
