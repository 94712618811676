import { sliderAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: '#fff',
  },
  filledTrack: {
    _light: {
      bg: 'primaryBranding.600',
    },
    _dark: {
      bg: 'primaryBranding.200',
    },
  },
});
const disabled = definePartsStyle({
  filledTrack: defineStyle({
    _light: {
      bg: '#27282A',
    },
    _dark: {
      bg: '#27282A',
    },
  }),
});

export const sliderTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { disabled },
});
