import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../../site/siteSlice';
import { useCreateInvitationMutation, useGetInvitationsQuery } from '../../../site/invitationsApi';
import ArcButton from '../../../../common/components/ArcButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGetSiteUsersQuery } from '../../../site/siteUsersApi';
import { useTranslation } from 'react-i18next';

export function EditFamilyMembers() {
  const { data: invitations, isLoading } = useGetInvitationsQuery();
  const { data: users, isLoading: isSiteUsersLoading } = useGetSiteUsersQuery();
  const [isEditing, setEditing] = useState<boolean>(false);
  const isLoaded = !isLoading && !isSiteUsersLoading;
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase title={t('Account.edit family members')} backURL={`../view?direction=back`}>
      <Box m={4}>
        <Text fontWeight={600}>Members</Text>
        <Skeleton
          isLoaded={isLoaded}
          borderBottomLeftRadius={'5px'}
          borderBottomRightRadius={'25px'}
          borderTopRightRadius={'5px'}
          borderTopLeftRadius={'25px'}
        >
          {users?.map((user, i) => (
            <Input
              my={2}
              value={user.user_email}
              size="md"
              readOnly
              borderRadius={0}
              _dark={{ background: 'customGrey.900' }}
              data-testid={`family-member-${i}`}
              key={user.user_id}
            />
          ))}

          {invitations?.map((invitation, i) => (
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              key={`pending-invitation-${invitation.user_email}-${i}`}
            >
              <Input
                my={2}
                value={invitation.user_email}
                size="md"
                data-testid={`pending-invitation-${i}`}
                readOnly
                borderRadius={0}
                _dark={{ background: 'customGrey.900' }}
                position="relative"
                paddingRight="95px" // Here we account for the badge that is overlayed
              />
              <Badge position="absolute" mr={2} data-testid={`pending-badge-${i}`}>
                {t('Common.pending')}
              </Badge>
            </Flex>
          ))}
          {isEditing ? <AddNewFamilyMemberForm onFinishEdit={() => setEditing(false)} /> : <></>}
        </Skeleton>
        <Button
          mt={1}
          variant="ghost"
          colorScheme="schneiderSkyBlue"
          fontWeight={500}
          onClick={() => setEditing(true)}
          hidden={isEditing}
          data-testid="add-new-member-button"
          pl={0}
        >
          {t('Account.add family member')}
        </Button>
      </Box>
    </SlidingAnimationPageBase>
  );
}

type AddFamilyMemberFormData = {
  email: string;
};

type EditPersonalDetailsProps = {
  onFinishEdit: () => void;
};

function AddNewFamilyMemberForm({ onFinishEdit }: EditPersonalDetailsProps) {
  const { site_id: siteId } = useSelector(selectSite);
  const toast = useToast({ isClosable: true });
  const [createInvitation, { isLoading }] = useCreateInvitationMutation();
  const { t } = useTranslation();
  const schema = yup.object({
    email: yup.string().max(100).email().trim().required().label(t('Common.email')),
  });
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<AddFamilyMemberFormData>({
    resolver: yupResolver(schema),
  });

  async function handleSubmit(values: AddFamilyMemberFormData) {
    try {
      await createInvitation({ siteId, ...values }).unwrap();
    } catch {
      toast({
        status: 'error',
        title: t('Account.error inviting family member'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }

    onFinishEdit();
  }

  return (
    <Box data-testid="add-new-family-member-form" mb={16}>
      <FormControl isInvalid={!!errors?.email} my={2} mb={6}>
        <Input
          borderRadius={0}
          _dark={{ background: 'customGrey.900' }}
          size="md"
          data-testid="email-input"
          placeholder={`${t('Common.email')}...`}
          {...register(`email`)}
        />
        <FormErrorMessage data-testid="email-error">{errors?.email?.message}</FormErrorMessage>
      </FormControl>
      <Center>
        <ArcButton
          arcColor="#3DCD57"
          onClick={handleFormSubmit(handleSubmit)}
          width="90%"
          isLoading={isLoading}
          data-testid="save-button"
        >
          {t('Common.save')}
        </ArcButton>
      </Center>
    </Box>
  );
}
