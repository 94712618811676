import React, { useEffect, useState } from 'react';
import { IconButton, Input, InputGroup, InputProps, InputRightElement, useColorModeValue } from '@chakra-ui/react';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { togglePrivacyScreen } from '../../utils/native/privacy-screen';

/*
 * This password input fields includes a toggle to show/hide the password, and enables native privacy screen security
 */
export const PasswordInput = React.forwardRef<
  any,
  { value?: string; onChange: (value: string) => void } & Omit<InputProps, 'value' | 'onChange'>
>(({ onChange, value, ...inputProps }, ref) => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const backgroundColor = useColorModeValue('white', 'customGrey.800');

  useEffect(() => {
    // Enable the privacy screen when the password is shown
    togglePrivacyScreen(shouldShowPassword)
    return function cleanup() {
      // Disables the privacy screen on unmount
      togglePrivacyScreen(false);
    };
  }, [shouldShowPassword]);

  return (
    <InputGroup id="password-input">
      <Input
        ref={ref}
        onChange={(e) => onChange(e.target.value)}
        borderRadius={0}
        backgroundColor={backgroundColor}
        size="md"
        data-testid="edit-password-input"
        type={shouldShowPassword ? 'text' : 'password'}
        onCopy={(e) => e.preventDefault()}
        onCut={(e) => e.preventDefault()}
        value={value}
        data-private
        {...inputProps}
      />
      <InputRightElement>
        <IconButton
          aria-label="Toggle visibility of password"
          icon={shouldShowPassword ? <ViewOffIcon /> : <ViewIcon />}
          variant={'ghost'}
          onClick={() => setShouldShowPassword(!shouldShowPassword)}
          data-testid="toggle-password-visibility-button"
        />
      </InputRightElement>
    </InputGroup>
  );
});

PasswordInput.displayName = 'PasswordInput';
