import React, { useMemo, useState } from 'react';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import ArcButton from '../../../common/components/ArcButton';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ExclamationIcon } from '../../../styles/custom-icons';
import { useTariffFormContext } from '../useTariffFormContext';
import { MIN_TARIFF_PAGE_HEIGHT, useTranslatedCommonInputProps } from '../tariff-constants';
import PeakRateCard from './PeakRateCard';
import MinimumDeliveryCharge from '../MinimumDeliveryCharge';
import { getDefaultTOUTariffRate } from './tou-tariff-helpers';
import { TOU_TARIFF_FORM_KEYS, useTranslatedTouRateTypeToConfig } from './tou-tariff-constants';
import { DAY_TYPES, DayType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import SeasonMultiToggleSwitch from '../SeasonMultiToggleSwitch';
import { useTranslation } from 'react-i18next';
import useParamBackUrl from '../useParamBackUrl';

export const TOUTariffRates = () => {
  const { errors, register, control, handleSubmit } = useTariffFormContext();
  const { tariff } = useWatch({ control });
  const seasons = tariff?.seasons || [];
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const COMMON_INPUT_PROPS = useTranslatedCommonInputProps();
  const { defaultDayType, isAllWeekTariff, hasDeliveryCharge } = useMemo(() => {
    const allWeekTariffs = tariff?.rates?.[selectedSeasonIndex]?.allWeek || {};
    const isAllWeekTariff = !!Object.values(allWeekTariffs).flat().length;
    const hasDeliveryCharge = !!tariff?.deliveryCharge;
    return { isAllWeekTariff, defaultDayType: isAllWeekTariff ? DAY_TYPES[2] : DAY_TYPES[0], hasDeliveryCharge };
  }, [selectedSeasonIndex]);
  const paramBackUrl = useParamBackUrl();
  const backURL = useMemo(() => {
    // Otherwise, go back to select seasons page
    return paramBackUrl ?? `../../select-season?direction=back`;
  }, [paramBackUrl]);
  const [editDayType, setEditDayType] = useState<DayType>(defaultDayType);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.manual energy rates set up')}
      includeTopNav
      px={4}
      backURL={backURL}
      data-testid="tou-tariff-rates"
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT}>
        <Text>{t('Energy Rates.you chose tou rate', { tou: t('Widgets.Energy Mode.TOU') })}</Text>

        {hasDeliveryCharge && (
          <Box mt={3}>
            <MinimumDeliveryCharge />
          </Box>
        )}

        <SeasonMultiToggleSwitch
          value={selectedSeasonIndex}
          onChange={(newIndex) => {
            setSelectedSeasonIndex(newIndex);
            setEditDayType(isAllWeekTariff ? 'allWeek' : 'weekday');
          }}
          containerProps={{ mt: 4 }}
        />

        {/* Without mapping, uncontrolled components are not re-rendered by react */}
        {seasons.map((season, index) => {
          const hasExportRate = !!Object.values(tariff?.rates?.[index]?.export || {}).length;
          return (
            <Box key={season.seasonIndex} mb={4} display={index === selectedSeasonIndex ? undefined : 'none'}>
              {hasExportRate && (
                <>
                  <Box mt={3}>
                    <Text lineHeight={'19px'}>{t('Energy Rates.export rate').toUpperCase()}</Text>
                    <Divider bg={'#C6C6C6'} mb={4} mt={1} />
                    <Text fontWeight={'bold'} lineHeight={'19px'} mb={3}>
                      {t('Energy Rates.enter price per kwh exported')}
                    </Text>
                    <FormControl
                      isInvalid={!!errors?.tariff?.rates?.[index]?.export?.[0]?.rateBands?.[0]?.rate?.message}
                      data-testid={`season-${index}-export-rate`}
                    >
                      <InputGroup>
                        <InputLeftElement pointerEvents="none" color="gray.300" h={12}>
                          $
                        </InputLeftElement>
                        <Input
                          {...COMMON_INPUT_PROPS}
                          {...register(`tariff.rates.${index}.export.0.rateBands.0.rate`, { valueAsNumber: true })}
                          pr={16}
                        />
                        <InputRightElement pointerEvents="none" color="gray.300" h={12} w={16}>
                          / kWh
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>
                        {errors?.tariff?.rates?.[index]?.export?.[0]?.rateBands?.[0]?.rate?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Divider bg={'#C6C6C6'} my={4} />
                </>
              )}

              <TOUTariffImportRates
                {...{
                  editDayType,
                  handleEditDayType: (value) => setEditDayType(value),
                  seasonIndex: index,
                }}
              />
            </Box>
          );
        })}

        <Center mt="auto" mb={6} flexDir={'column'}>
          <ArcButton
            arcColor="#3DCD58"
            minWidth="280px"
            py={6}
            onClick={() => {
              handleSubmit(
                () => {
                  // using `handleSubmit` triggers validation and activates revalidation modes
                  // using `trigger` does not trigger revalidation
                  // Reaching here means form is valid. Thus, go to confirm page
                  navigate('../confirm');
                },
                (errors) => {
                  // Reaching here means form is invalid. Take user to the first tab with errors
                  const firstIndexWithError = seasons.findIndex((_, index) => errors?.tariff?.rates?.[index]);
                  if (firstIndexWithError >= 0) setSelectedSeasonIndex(firstIndexWithError);
                  // there can be a case where it is error of export rate
                  // so just ignore in that case
                  const allErrors = errors?.tariff?.rates?.[firstIndexWithError];
                  const firstDayTypeWithError = DAY_TYPES.find((type) => allErrors?.[type]);
                  if (firstDayTypeWithError) setEditDayType(firstDayTypeWithError);
                }
              )();
            }}
            data-testid="continue-button"
          >
            {t('Common.continue')}
          </ArcButton>
        </Center>
      </VStack>
    </SlidingAnimationPageBase>
  );
};

type TOUTariffImportRatesProps = {
  seasonIndex: number;
  editDayType: DayType;
  handleEditDayType: (editDayType: DayType) => void;
};

function TOUTariffImportRates({ seasonIndex, editDayType, handleEditDayType }: Readonly<TOUTariffImportRatesProps>) {
  const { control, setValue, errors } = useTariffFormContext();
  const { t } = useTranslation();
  const TOU_RATE_TYPE_TO_CONFIG = useTranslatedTouRateTypeToConfig();
  const tariff = useWatch({ control, name: 'tariff' });
  const dayTypeError = errors?.tariff?.rates?.[seasonIndex]?.[editDayType]?.message;

  const { dayTypes, isAllWeekTariff } = useMemo(() => {
    const allWeekTariffs = tariff?.rates?.[seasonIndex]?.allWeek || {};
    const isAllWeekTariff = !!Object.values(allWeekTariffs).flat().length;
    const dayTypes: DayType[] = [];
    if (isAllWeekTariff) dayTypes.push(DAY_TYPES[2]);
    else dayTypes.push(DAY_TYPES[0], DAY_TYPES[1]);
    return { dayTypes, isAllWeekTariff };
  }, [seasonIndex]);

  return (
    <>
      {!isAllWeekTariff && (
        <Flex mt={6}>
          {dayTypes.map((type, index) => {
            const isError = !!errors?.tariff?.rates?.[seasonIndex]?.[type];
            const isSelected = type === editDayType;
            return (
              <Text
                lineHeight={'20px'}
                borderBottom="6px solid"
                borderColor={isSelected ? undefined : 'transparent'}
                onClick={() => handleEditDayType(type)}
                key={`day-type-${type}`}
                data-testid={`select-${seasonIndex}-day-type-${type}`}
                data-selected={isSelected}
                ml={index ? 4 : 0}
              >
                {type.toUpperCase()} {isError && <ExclamationIcon color="red.500" w={2} />}
              </Text>
            );
          })}
        </Flex>
      )}

      {dayTypes.map((type) => {
        if (type !== editDayType) return null;

        const dayTypeRates = tariff?.rates?.[seasonIndex]?.[editDayType];
        return (
          <Box mt={6} key={`form-type-${type}`}>
            <Flex w="100%" justifyContent={'space-between'}>
              <Text lineHeight={'19px'}>{t('Energy Rates.import rate').toUpperCase()}</Text>

              <Menu>
                <MenuButton
                  as={Button}
                  variant={'link'}
                  fontWeight={400}
                  colorScheme="schneiderSkyBlue"
                  data-testid={`add-${seasonIndex}-new-period`}
                >
                  {t('Common.add')}
                </MenuButton>
                <MenuList minW={24}>
                  {TOU_TARIFF_FORM_KEYS.map((key) => {
                    const { title, type } = TOU_RATE_TYPE_TO_CONFIG[key];
                    return (
                      <MenuItem
                        data-testid={`add-${seasonIndex}-${key}-period`}
                        key={`add-${key}`}
                        onClick={() => {
                          const newRate = getDefaultTOUTariffRate(seasonIndex + 1, type, editDayType);
                          setValue(
                            `tariff.rates.${seasonIndex}.${editDayType}.${key}`,
                            [...(dayTypeRates?.[key] || []), newRate],
                            {
                              shouldDirty: true,
                            }
                          );
                        }}
                      >
                        {title}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Flex>

            <Divider bg={'#C6C6C6'} my={4} />

            {TOU_TARIFF_FORM_KEYS.map((key, keyIndex) => {
              return dayTypeRates?.[key]?.map((rate, rateIndex) => {
                // for making unique id for each card
                // used as a part of key for react
                const cardId = (keyIndex + 1) * 100 + rateIndex;
                return (
                  <PeakRateCard
                    key={`${key}-${rate?.seasonIndex}-${cardId}`}
                    dayType={editDayType}
                    seasonIndex={seasonIndex}
                    touTariffType={key}
                    rateIndex={rateIndex}
                  />
                );
              });
            })}
          </Box>
        );
      })}

      {!!dayTypeError && (
        <Text textAlign={'center'} color="red.500" mt={4} data-testid="day-type-error">
          {dayTypeError}
        </Text>
      )}
    </>
  );
}
