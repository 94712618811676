import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { CommonNotificationQueryParams, useGetNotificationsQuery } from './notificationsApi';
import { useParams, useSearchParams } from 'react-router-dom';
import NotificationsListSkeletonLoader from './NotificationsListSkeletonLoader';
import NotificationListItem from './NotificationListItem';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { Notification } from 'clipsal-cortex-types/src/api/api-notifications';
import { useTranslation } from 'react-i18next';

type NotificationViewType = 'chronological' | 'unread' | 'read' | 'alarm' | 'info' | 'warning';

const INFINITE_SCROLL_THRESHOLD_PX = 50;

const PAGE_SIZE = 10;

const VIEW_TYPE_TO_PARAMS: Record<NotificationViewType, Pick<CommonNotificationQueryParams, 'severity' | 'read'>> = {
  chronological: {},
  unread: { read: false },
  read: { read: true },
  alarm: { severity: 'ALARM' },
  info: { severity: 'INFO' },
  warning: { severity: 'WARNING' },
};

export function NotificationsListByType() {
  const { type } = useParams<{ type: NotificationViewType }>();
  const [offset, setOffset] = useState<number>(0);
  const [allNotifications, setAllNotifications] = useState<Notification[]>([]);
  const [search] = useSearchParams();
  const isArchived = search.get('archived') === 'true';
  const { t } = useTranslation();
  const params = {
    ...(type ? VIEW_TYPE_TO_PARAMS[type] : {}),
    limit: PAGE_SIZE,
    offset,
    archived: isArchived,
  };
  const { data, isLoading, isFetching, isError } = useGetNotificationsQuery(params);
  const notifications = data?.results;
  const totalNumNotifications = data?.count;
  const notificationsList = useMemo(() => {
    return isLoading ? (
      <NotificationsListSkeletonLoader />
    ) : (
      allNotifications?.map((notification, notificationIndex) => (
        <NotificationListItem
          notification={notification}
          key={notificationIndex}
          isArchived={isArchived}
          onDelete={(notificationId) =>
            setAllNotifications((prevNotifications) =>
              prevNotifications.filter((n) => n.notification_id !== notificationId)
            )
          }
        />
      ))
    );
  }, [isLoading, allNotifications]);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isLoading || isError) return;

    function handleScroll() {
      const currentScrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const totalScrollableHeight = ref.current!.clientHeight;
      const distanceToBottom = totalScrollableHeight - currentScrollPosition;

      if (
        // Make sure we've populated notifications into the UI before we measure the height
        allNotifications.length &&
        distanceToBottom < INFINITE_SCROLL_THRESHOLD_PX &&
        !isFetching &&
        // There will always be notifications data since `isLoading` is false and there is no error.
        offset + PAGE_SIZE < totalNumNotifications!
      ) {
        setOffset(offset + PAGE_SIZE);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching, isLoading, allNotifications]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setAllNotifications((prevNotifications) => {
        if (offset === 0) return notifications ?? [];
        // Because `useEffect` is invoked twice in development mode, and to avoid any potential bugs, we diff the
        // existing the array with the new one to make sure no duplicates appear.
        // This isn't very performant, but it's React best practice.
        const newNotifications = notifications
          ? notifications.filter((n) => !prevNotifications.find((prev) => prev.notification_id === n.notification_id))
          : [];
        return [...prevNotifications, ...newNotifications];
      });
    }
  }, [notifications]);

  if (isError) {
    return (
      <Alert status="error" my={4}>
        <AlertIcon w={8} h={8} />
        <AlertDescription py={4}>
          {t('Notifications.error fetching notifications')}.{' '}
          {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <SlidingAnimationPageBase
      ref={ref}
      backURL={`../${isArchived ? 'archive' : 'list'}?direction=back`}
      title={`${t(`Notifications.${type}`)} ${t('Notifications.notifications')} ${
        isArchived ? `(${t('Notifications.archived')})` : ''
      }`}
    >
      <Box px={3}>
        {!notifications?.length && !isLoading && !isFetching ? (
          <Center my={4}>
            <Text>{t('Notifications.no notifications')}.</Text>
          </Center>
        ) : (
          notificationsList
        )}

        {isFetching && <NotificationsListSkeletonLoader numItems={2} />}
      </Box>
    </SlidingAnimationPageBase>
  );
}
