import React from 'react';
import { useSelector } from 'react-redux';
import { selectInverters, selectMeters } from '../site/siteSlice';
import MatterOnlyActivity from './matter-only/MatterOnlyActivity';
import { MANUFACTURER_ID_SATURN, MANUFACTURER_ID_SEM } from '../devices/devices-helper';
import DefaultActivity from './DefaultActivity';

export function Activity() {
  const siteInverters = useSelector(selectInverters);
  const siteMeters = useSelector(selectMeters);
  const siteHasSenseMeter = siteMeters.some((meter) => [MANUFACTURER_ID_SEM].includes(meter.manufacturer_id));
  const siteHasSaturnInverter = siteInverters.some((inverter) =>
    [MANUFACTURER_ID_SATURN].includes(inverter.manufacturer_id)
  );
  const siteIsMatterOnly = !siteHasSenseMeter && !siteHasSaturnInverter;

  return siteIsMatterOnly ? <MatterOnlyActivity /> : <DefaultActivity />;
}
