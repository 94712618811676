import { useEffect, useState } from 'react';
import { IS_IOS } from '../../../../common/constants';
import { Matter } from '@clipsalsolar/capacitor-matter';

/**
 * iOS will display a native dialog prompting the user to enable local network access permissions.
 * This is done at the start of the commissioning flow since it can cause race conditions when actually
 * attempting to access local network data before permission has been provided.
 * We can use the Matter version of this check because it will enable the permission regardless
 * of the source of the permission's usage (e.g. in this case, the native Wi-Fi plugin).
 *
 * @returns Whether the user had granted native permission. Always true when not on iOS.
 */
export default function useIOSPermissionCheck() {
  const [hasGrantedPermission, setHasGrantedPermission] = useState(true);
  /* istanbul ignore next -- @preserve */
  useEffect(() => {
    async function checkForPermissions() {
      // iOS will display a native dialog prompting the user to enable local network access permissions.
      // This is done at the start of the commissioning flow since it can cause race conditions when actually
      // attempting to access local network data before permission has been provided.
      // We can use the Matter version of this check because it will enable the permission regardless
      // of the source of the permission's usage (e.g. in this case, the native wifi plugin).
      if (IS_IOS) {
        const networkAuthorization = await Matter.requestPermissions();
        setHasGrantedPermission(networkAuthorization?.localNetwork === 'granted');
      }
    }

    checkForPermissions();
  }, []);

  return hasGrantedPermission;
}
