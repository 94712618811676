import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Skeleton,
  SkeletonCircle,
  Text,
} from '@chakra-ui/react';
import { useGetLiveDevice } from './devicesApi';
import { useNavigate, useParams } from 'react-router-dom';
import DeviceActivity from './DeviceActivity';
import SEHomeCard from '../../common/components/SEHomeCard';
import DeviceStatusIcon from './DeviceStatusIcon';
import { DEVICE_ICON_RESPONSIVE_SIZE } from './devices-helper';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { useTranslation } from 'react-i18next';

export function ViewDevice() {
  const navigate = useNavigate();
  const { deviceId: id } = useParams<{ deviceId: string }>();
  const deviceId = Number(id);
  const isResidualLoad = deviceId === 0;
  const { data: device, isLoading, isError } = useGetLiveDevice(deviceId);
  const { t } = useTranslation();

  const handleButtonClick = async () => {
    navigate(`../edit?direction=forward`);
  };

  return (
    <SlidingAnimationPageBase
      backURL={`../../list`}
      title={t('Devices.discovered device')}
      customTopNavButton={
        <Button
          data-testid="edit-button"
          onClick={handleButtonClick}
          variant={'ghost'}
          size={'sm'}
          hidden={isError || isResidualLoad}
          fontWeight={400}
          colorScheme={'schneiderSkyBlue'}
        >
          {t('Common.edit')}
        </Button>
      }
    >
      <Box data-testid="device-detail-contents" px={3}>
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            <Box>
              <AlertTitle>{t('Common.notice')}</AlertTitle>
              <AlertDescription>
                {t('Devices.error fetching device')}.{' '}
                {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
              </AlertDescription>
            </Box>
          </Alert>
        ) : (
          <>
            <SkeletonCircle
              isLoaded={!isLoading}
              w={DEVICE_ICON_RESPONSIVE_SIZE}
              h={DEVICE_ICON_RESPONSIVE_SIZE}
              m="auto"
            >
              {device && <DeviceStatusIcon assignment={device.user_assignment} isOn={device.power > 0} />}
            </SkeletonCircle>

            <Skeleton isLoaded={!isLoading} borderBottomRightRadius="25px" mt={2} mb={4}>
              <SEHomeCard borderTopLeftRadius={5}>
                <Text fontSize={20} minH="30px" data-testid="device-name" overflowWrap="anywhere">
                  {device?.display_name}
                </Text>
              </SEHomeCard>
            </Skeleton>
            <Skeleton isLoaded={!isLoading} borderBottomRightRadius="25px" mt={2} mb={4}>
              {device && <DeviceActivity applianceId={device.appliance_id} power={device.power} />}
            </Skeleton>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
