import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Flex,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useSelector } from 'react-redux';
import { selectInverters, selectSite } from '../../site/siteSlice';
import { ChevronRightIcon } from '@chakra-ui/icons';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { useNavigate, useParams } from 'react-router-dom';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { useGetFirmwareVersion } from '../accountApi';
import { useTranslation } from 'react-i18next';
import { createCirclePercentageFilledIcon } from '../../../styles/create-circle-percentage-filled-icon';
import { HARDWARE_TYPE_TO_UI_CONFIG } from './constants';
import { VisibleHardwareType } from './types';

export function HardwareDetails() {
  const { devices } = useSelector(selectSite);
  const { deviceId } = useParams<{ deviceId: string }>();
  const device = devices.find((device) => device.id === Number(deviceId));
  const { data, isLoading } = useGetFirmwareVersion(Number(deviceId));
  const { isOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const siteHasInverter = useSelector(selectInverters).length > 0;
  const { t } = useTranslation();
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');

  const CircleIcon = createCirclePercentageFilledIcon(100);
  const { icon: DeviceIcon, title } =
    HARDWARE_TYPE_TO_UI_CONFIG[(device?.device_type as VisibleHardwareType) ?? 'INVERTER'];

  return (
    <SlidingAnimationPageBase title={t('Hardware.hardware')} backURL={`../../list?direction=back`} pb={6}>
      {device ? (
        <Box px={5}>
          <Center position="relative" px={3} flexDirection="column" textAlign="center" mt={8}>
            <DeviceIcon
              w={[100, 130, 150]}
              h={[100, 130, 150]}
              color={iconColor}
              pos="absolute"
              data-testid="status-icon"
            />
            <CircleIcon
              w={[220, 290, 330]}
              h={[220, 290, 330]}
              color={iconColor}
              data-testid="circle-icon"
              zIndex={3}
            />
          </Center>

          <Text textAlign={'center'} fontSize={24} data-testid="device-name" mt={4}>
            {title}
          </Text>

          <Flex direction={'column'} w="100%" data-testid="device-details" mt={8}>
            <Flex direction={'column'}>
              <Flex direction={'column'} data-testid="device-sn">
                <Text fontWeight={'semibold'}>{t('Common.serial number')}</Text>
                <Text>{device.serial_number ?? device.site_identifier}</Text>
              </Flex>
              <Flex direction={'column'} mt={6} data-testid="device-model">
                <Text fontWeight={'semibold'}>{t('Common.model name')}</Text>
                <Text>{device.model}</Text>
              </Flex>
              {/*<Flex direction={'column'} mt={6} data-testid="device-desc">*/}
              {/*  <Text fontWeight={'semibold'}>Description</Text>*/}
              {/*  <Text>Coming soon</Text>*/}
              {/*</Flex>*/}
              {/*<Flex direction={'column'} mt={6} data-testid="device-output">*/}
              {/*  <Text fontWeight={'semibold'}>Output</Text>*/}
              {/*  <Text>Coming soon</Text>*/}
              {/*</Flex>*/}
              <Flex direction={'column'} mt={6} data-testid="device-manufacturer">
                <Text fontWeight={'semibold'}>{t('Hardware.manufacturer')}</Text>
                <Text>Schneider Electric</Text>
              </Flex>
              {/* Firmware version is only supported by Saturn inverters currently. Meter firmware version can only
                  be retrieved when the meter is connected to an inverter (not standalone) */}
              {siteHasInverter && ['METER', 'INVERTER'].includes(device.device_type) && (
                <Flex direction={'column'} mt={6} data-testid="device-firmware">
                  <Text fontWeight={'semibold'}>{t('Common.firmware')}</Text>
                  <Skeleton isLoaded={!isLoading}>
                    <Text>{data?.firmware_version ?? t('Common.not found')}</Text>
                  </Skeleton>
                </Flex>
              )}
            </Flex>

            <Center
              hidden={['BACKUP_GRID_CONTROL_SYSTEM', 'BATTERY_PACK'].includes(device.device_type) || IS_DEMO_LOGIN}
              justifyContent={'space-between'}
              role={'button'}
              mt={6}
              py={6}
              px={4}
              mx={-5}
              bg={'#E7E6E6'}
              _dark={{
                bg: '#363E40',
              }}
              data-testid="wifi-config-button"
              onClick={() => {
                const routeEnd = `/power_up_instructions?shouldRegisterDevice=false&deviceId=${deviceId}`;
                if (device!.device_type === 'EV_CHARGER') {
                  navigate(`../../../../ev_charger/wifi/home`);
                } else if (device!.device_type === 'INVERTER') {
                  navigate(`../../configure/inverter${routeEnd}`);
                } else if (device!.device_type === 'METER') {
                  navigate(`../../configure/meter${routeEnd}`);
                } else {
                  toast({
                    title: t('Hardware.wifi config unavailable'),
                    description: t('Hardware.this feature is currently'),
                    status: 'info',
                    isClosable: true,
                    duration: 5000,
                  });
                }
              }}
            >
              <Text>{t('Hardware.wifi config')}</Text>
              <ChevronRightIcon color={'customGrey.400'} h={6} w={6} />
            </Center>

            {/* <ArcButton
            arcColor="#3DCD58"
            data-testid="device-remove-button"
            minWidth="280px"
            mx="auto"
            mt={10}
            py={6}
            onClick={() => {
              toast({
                title: 'Device removal not implemented',
                description: 'This feature is currently only available for EV Chargers.',
                status: 'info',
                isClosable: true,
                duration: 5000,
              });
            }}
          >
            Remove Device
          </ArcButton> */}

            <AlertDialogModal
              {...{ isOpen, onClose }}
              header={t('Common.are you sure?')}
              subHeader={t('Hardware.your device will be removed')}
              confirmButtonName={t('Common.remove')}
              onConfirm={async () => {
                try {
                  // @TODO: API for removing device
                } catch (error) {
                  toast({
                    title: t('Hardware.error removing device'),
                    status: 'error',
                    duration: 5000,
                  });
                }
              }}
            />
          </Flex>
        </Box>
      ) : (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{t('Hardware.error fetching device')}</AlertTitle>
          <AlertDescription>
            {t('Common.please try again')} {t('Common.if this persists contact support')}
          </AlertDescription>
        </Alert>
      )}
    </SlidingAnimationPageBase>
  );
}
