import { Schedule } from './types';
import { SwitchSchedule } from 'clipsal-cortex-types/src/api/api-switch-schedule';
import { isEqual } from 'lodash';
import { getTimeFormat } from '../../../account/settings/settings-helpers';
import { formatMinutesToHMM } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import i18n from '../../../../i18n';

export type ValidationResult = {
  type: 'SUCCESS' | 'ERROR' | 'WARNING';
  message: string;
  scheduleIndex?: number; // The index which the error occurred at, if relevant
};

/**
 * The API's structure differs significantly from what the UI expects, so we need this grouping algorithm
 * to determine which schedules belong together, and which are singular, i.e. do not have a corresponding event.
 *
 * @TODO: After the API has implemented schedule grouping via foreign keys, we can kill this yucky self-rolled system.
 *
 * @param apiSchedules
 */
export function mapAPISchedulesToUI(apiSchedules: SwitchSchedule[]) {
  const schedulesGroupedByWeekdayIndexes = apiSchedules.reduce<SwitchSchedule[][]>((groupedSchedules, apiSchedule) => {
    const matchingGroupedSchedule = groupedSchedules.find((group) =>
      group.some((scheduleInGroup) => isEqual(apiSchedule.weekly_freq_interval, scheduleInGroup.weekly_freq_interval))
    );

    if (matchingGroupedSchedule) matchingGroupedSchedule.push(apiSchedule);
    else {
      groupedSchedules.push([apiSchedule]);
    }

    return groupedSchedules;
  }, []);

  // For each grouped schedule set, find the nearest matching end time for each start time, and combine them into
  // one single schedule for the UI.
  const uiSchedules: Schedule[] = [];

  schedulesGroupedByWeekdayIndexes.forEach((groupedSchedules) => {
    let closeEventsInGroup = groupedSchedules.filter(
      (s) => s.event_action === 'closed' || s.event_action === 'device_level'
    );
    let openEventsInGroup = groupedSchedules.filter((s) => s.event_action === 'open');

    // Find the nearest open event for every close event -- if there is none, it's just a close event.
    for (const schedule of closeEventsInGroup) {
      let closestOpenEvent: SwitchSchedule | null = null;
      let smallestTimeDelta = Infinity;
      const startTime = new Date();

      if (schedule.schedule_sub_type) {
        const startOffset = schedule.offset ?? 0;
        startTime.setHours(Math.floor(Math.abs(startOffset) / 60));
        startTime.setMinutes(Math.abs(startOffset) % 60);
      } else {
        const [startTimeHours, startTimeMins] = schedule.event_time.split(':').map((value) => Number(value));
        startTime.setHours(startTimeHours);
        startTime.setMinutes(startTimeMins);
      }

      for (const openScheduledEvent of openEventsInGroup) {
        const endTime = new Date();
        if (openScheduledEvent.schedule_sub_type) {
          const endOffset = openScheduledEvent.offset ?? 0;
          endTime.setHours(Math.floor(Math.abs(endOffset) / 60));
          endTime.setMinutes(Math.abs(endOffset) % 60);
        } else {
          const [endTimeHours, endTimeMins] = openScheduledEvent.event_time.split(':').map((value) => Number(value));
          endTime.setHours(endTimeHours);
          endTime.setMinutes(endTimeMins);
        }

        const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());

        if ((timeDiff > 0 && timeDiff < smallestTimeDelta) || schedule.schedule_sub_type) {
          smallestTimeDelta = timeDiff;
          closestOpenEvent = openScheduledEvent;
        }
      }

      // We've got the closest open event, combine them into one.
      uiSchedules.push({
        startScheduleId: schedule.schedule_id,
        endScheduleId: closestOpenEvent?.schedule_id ?? null,
        daysOfWeek: schedule.weekly_freq_interval,
        // We ignore the start/end time if it has a subtype
        startTime: !schedule?.schedule_sub_type ? schedule.event_time : null,
        endTime: !closestOpenEvent?.schedule_sub_type ? closestOpenEvent?.event_time ?? null : null,
        name: schedule.name ?? null,
        isEditing: false,
        startSubType: schedule.schedule_sub_type ?? '',
        endSubType: closestOpenEvent?.schedule_sub_type ?? '',
        startOffset: schedule.offset ?? 0,
        endOffset: closestOpenEvent?.offset ?? 0,
        deviceLevel: schedule.event_action_kwargs?.device_level ?? null,
      });

      // Remove the items we just added from the previous arrays
      closeEventsInGroup = closeEventsInGroup.filter((s) => s.schedule_id !== schedule.schedule_id);
      if (closestOpenEvent) {
        openEventsInGroup = openEventsInGroup.filter((s) => s.schedule_id !== closestOpenEvent?.schedule_id);
      }
    }

    // Find the nearest close event for every open event -- if there is none, it's just an open event.
    for (const schedule of openEventsInGroup) {
      let closestCloseEvent: SwitchSchedule | null = null;
      let smallestTimeDelta = Infinity;
      const endTime = new Date();

      if (schedule.schedule_sub_type) {
        const endOffset = schedule.offset ?? 0;
        endTime.setHours(Math.floor(Math.abs(endOffset) / 60));
        endTime.setMinutes(Math.abs(endOffset) % 60);
      } else {
        const [endTimeHours, endTimeMins] = schedule.event_time.split(':').map((value) => Number(value));
        endTime.setHours(endTimeHours);
        endTime.setMinutes(endTimeMins);
      }

      for (const closedScheduledEvent of closeEventsInGroup) {
        const startTime = new Date();
        if (closedScheduledEvent.schedule_sub_type) {
          const startOffset = closedScheduledEvent.offset ?? 0;
          startTime.setHours(Math.floor(Math.abs(startOffset) / 60));
          startTime.setMinutes(Math.abs(startOffset) % 60);
        } else {
          const [startTimeHours, startTimeMins] = closedScheduledEvent.event_time
            .split(':')
            .map((value) => Number(value));
          startTime.setHours(startTimeHours);
          startTime.setMinutes(startTimeMins);
        }
        const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());

        if (timeDiff > 0 && timeDiff < smallestTimeDelta) {
          smallestTimeDelta = timeDiff;
          closestCloseEvent = closedScheduledEvent;
        }
      }

      // We've got the closest close event, combine them into one.
      uiSchedules.push({
        endScheduleId: schedule.schedule_id,
        startScheduleId: closestCloseEvent?.schedule_id ?? null,
        daysOfWeek: schedule.weekly_freq_interval,
        // We ignore the start/end time if it has a subtype
        endTime: !schedule?.schedule_sub_type ? schedule.event_time : null,
        startTime: !closestCloseEvent?.schedule_sub_type ? closestCloseEvent?.event_time ?? null : null,
        name: schedule.name ?? null,
        isEditing: false,
        startSubType: closestCloseEvent?.schedule_sub_type ?? '',
        endSubType: schedule.schedule_sub_type ?? '',
        startOffset: closestCloseEvent?.offset ?? 0,
        endOffset: schedule.offset ?? 0,
        deviceLevel: closestCloseEvent?.event_action_kwargs?.device_level ?? null,
      });

      // Remove the items we just added from the previous arrays
      openEventsInGroup = closeEventsInGroup.filter((s) => s.schedule_id !== schedule.schedule_id);
      if (closestCloseEvent) {
        closeEventsInGroup = closeEventsInGroup.filter((s) => s.schedule_id !== closestCloseEvent?.schedule_id);
      }
    }
  });

  return uiSchedules;
}

/**
 * Accepts a schedule object and 'start' or 'end' enum to return a formatted string based on the schedule type.
 *
 * @param schedule - The schedule object with time values to format.
 * @param type - 'start' or 'end' to determine which time to format.
 * @returns The formatted schedule, e.g. `12:00 AM` or `00:00`, or `SUNSET -01:00` for Sunrise/Sunset offsets.
 */
export function formatScheduleTimeForType(schedule: Schedule, type: 'start' | 'end') {
  // Check for the sunrise/sunset subtype first - it takes precedence over the time value.
  // The time value exists for these schedules, but it is regularly recalculated in the BE.
  if (schedule[`${type}SubType`]) {
    // Just return 'SUNRISE' or 'SUNSET' if theres no offest
    if (!schedule[`${type}Offset`]) return i18n.t(`Devices.${schedule[`${type}SubType`].toLowerCase()}`).toUpperCase();
    return (
      i18n.t(`Devices.${schedule[`${type}SubType`].toLowerCase()}`).toUpperCase() +
      ' ' +
      formatScheduleOffset(schedule[`${type}Offset`])
    );
  }
  if (schedule[`${type}Time`]) return formatScheduleTime(schedule[`${type}Time`]);

  return 'N/A';
}

/**
 * Accepts the total number of offset minutes, and returns the offset time formatted as `(+/-)HH:MM`.
 *
 * @param minutes - The total number of minutes, it can be negative or positive
 * @returns The formatted time value, e.g. `+01:15` or `-00:15`.
 */
export function formatScheduleOffset(minutes: number) {
  const offsetFormatted = formatMinutesToHMM(minutes);
  const sign = minutes < 0 ? '-' : '+';

  return `${sign}${offsetFormatted}`;
}

/**
 * Accepts a time string, formatted `00:00:00`, and returns the time in the format configured by the user (12 or 24 hr).
 *
 * @param time - The time string to format.
 * @returns The formatted schedule, e.g. `12:00 AM` or `00:00`.
 */
export function formatScheduleTime(time: string | null) {
  if (!time) return 'N/A';
  const timeFormat = getTimeFormat();

  const [hours, mins] = time.split(':');
  if (timeFormat === '24HR') return `${hours}:${mins}`;

  const hoursNumber = Number(hours);
  let hoursFormatted = Number(hours) > 12 ? Number(hours) - 12 : Number(hours);
  if (hoursFormatted === 0) hoursFormatted = 12;

  return `${hoursFormatted}:${mins} ${hoursNumber >= 12 ? 'PM' : 'AM'}`;
}
