import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, IconButton, Input, Text, useDisclosure } from '@chakra-ui/react';
import { useReduxDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import { getSites, selectSites, setNotLoaded, setSearchTerm } from './sitesSlice';
import Table, { TableAction } from 'clipsal-cortex-ui/src/components/Table';
import { useNavigate } from 'react-router-dom';
import { clearCurrentSite } from '../site/siteSlice';
import { debounceEvent } from '../../utils/common/component-helpers';
import { SiteListData } from 'clipsal-cortex-types/src/api/api-site';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { useViewportType } from '../../common/hooks/use-viewport-type';
import { resetEvChargerData } from '../ev_charger/dashboard/evChargerSlice';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

const COLUMNS = [
  { header: 'Site ID', accessor: 'site_id' },
  { header: 'Site Name', accessor: 'site_name' },
  { header: 'City', accessor: 'city' },
  { header: 'Country', accessor: 'country' },
  { header: 'State', accessor: 'state' },
];

export function SitesAdminView() {
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sites, isLoaded, pageSize, totalSites } = useSelector(selectSites);
  const { viewportType } = useViewportType();
  const isLargerViewport = ['tablet', 'desktop', 'desktop-lg'].includes(viewportType);
  const [searchText, setSearchText] = useState('');

  // Clear Redux store on first load.
  useEffect(() => {
    dispatch(clearCurrentSite());
    dispatch(resetEvChargerData());
  }, [dispatch]);

  const handleSearch = useCallback(
    debounceEvent(async (searchTerm: string) => {
      dispatch(setSearchTerm(searchTerm));
      await dispatch(getSites({ pageSize, offset: 0 }));
    }),
    [sites]
  );

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
    handleSearch(e.currentTarget.value);
  };

  const handleFetchData = useCallback(
    async (pageIndex: number, pageSize: number) => {
      dispatch(setNotLoaded());
      const offset = pageIndex * pageSize;
      await dispatch(getSites({ pageSize, offset }));
    },
    [dispatch]
  );

  async function handleSelectSite(site: SiteListData) {
    navigate(`/site/${site.site_id}/home`, { replace: true });
  }

  return (
    <Box m={isLargerViewport ? 5 : 1}>
      <Flex justify={'space-between'} align={'center'}>
        <Heading my={4} size="md">
          All Available Sites (ADMIN)
        </Heading>
        <Button onClick={onOpen}>
          <Heading my={4} size={'md'}>
            Logout
          </Heading>
        </Button>
      </Flex>

      <AlertDialogModal
        {...{ isOpen, onClose }}
        header="Logout"
        subHeader="Are you sure you want to logout?"
        confirmButtonName="Logout"
        onConfirm={() => navigate('/logout')}
      />

      <Box>
        <Box ml={2} width={['50%', '25%']} mb={2}>
          <Input value={searchText} name={'Search'} placeholder={'Search sites'} onChange={onInputChangeHandler} />
        </Box>
        {isLargerViewport ? (
          // Display a table, if the user is viewing on desktop
          <Table
            data-testid="all-sites-table"
            totalPageCount={Math.ceil(totalSites / pageSize)}
            isLoaded={isLoaded}
            onFetchData={handleFetchData}
            enablePagination
            actions={[
              {
                type: TableAction.View,
                redirect: `/site/{site_id}/home`,
              },
            ]}
            columns={COLUMNS}
            data={sites}
          />
        ) : (
          <MobileSitesList onFetchData={handleFetchData} onSelectSite={handleSelectSite} />
        )}
      </Box>
    </Box>
  );
}

type MobileSitesListProps = {
  onSelectSite: (site: SiteListData) => void;
  onFetchData: (pageIndex: number, pageSize: number) => void;
};

function MobileSitesList({ onSelectSite, onFetchData }: MobileSitesListProps) {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const { sites, isLoaded, pageSize, totalSites } = useSelector(selectSites);
  const totalPageCount = Math.ceil(totalSites / pageSize);

  // Listener for page index changes
  useEffect(() => {
    onFetchData(currentPageIndex, 10);
  }, [onFetchData, currentPageIndex]);

  const sitesContents = sites.length ? (
    sites.map((site) => (
      <Box
        aria-label={'view'}
        onClick={() => onSelectSite(site)}
        m={2}
        p={5}
        key={site.site_id}
        shadow="md"
        borderWidth="1px"
      >
        <Heading fontSize="xl" maxW={'85vw'} noOfLines={1}>
          {site.site_name}
        </Heading>
        <Text mt={4}>{site.address}</Text>
      </Box>
    ))
  ) : (
    <Box>No sites found.</Box>
  );

  return (
    <>
      <Flex justify={'center'}>
        <IconButton
          mx={1}
          size="sm"
          aria-label="Go to previous page"
          icon={<ChevronLeftIcon />}
          onClick={() => {
            setCurrentPageIndex(currentPageIndex - 1);
          }}
          isDisabled={currentPageIndex === 0 || !isLoaded}
        />

        <Box>Page {currentPageIndex + 1}</Box>

        <IconButton
          mx={1}
          size="sm"
          aria-label="Go to next page"
          icon={<ChevronRightIcon />}
          onClick={() => {
            setCurrentPageIndex(currentPageIndex + 1);
          }}
          isDisabled={currentPageIndex === totalPageCount - 1 || !isLoaded}
        />
      </Flex>

      <Box id="all-sites-stack">{isLoaded ? sitesContents : <CenteredLoader />}</Box>
    </>
  );
}
