import { HTTPResponse } from '@awesome-cordova-plugins/http';
import CryptoJS from 'crypto-js';
import { get, post, patch, put, del } from '../api-helpers';
import { BASE_IPV4_ADDRESS } from './constants';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

/**
 * Handles responding to a digest auth challenge and re-attempting the request using the cryptographically signed
 * response header.
 *
 * @param errorResponse - The error response from the initial request.
 * @param accountId - The SEM device's account ID.
 * @param method - The HTTP method for the request,
 * @param body - [optional] The JSON body of the request, for write requests.
 */
async function respondToDigestAuthChallenge(
  errorResponse: HTTPResponse,
  accountId: string,
  method: HTTPMethod = 'GET',
  body?: any
) {
  // The username does not matter in this flow, only the password
  const username = 'anything';
  const password = accountId;
  const { url } = errorResponse;
  const wwwAuthenticate = errorResponse.headers['www-authenticate'];
  const params = wwwAuthenticate
    .replace(/digest\s/gi, '')
    .split(', ')
    .reduce<Record<string, string>>((acc, param) => {
      // Use indexOf to find the first '=' and split the string accordingly
      const equalIndex = param.indexOf('=');
      const key = param.substring(0, equalIndex);
      let value = param.substring(equalIndex + 1);

      // Remove quotes from the value, but keep any '=' characters within the value
      value = value.startsWith('"') && value.endsWith('"') ? value.slice(1, -1) : value;

      acc[key] = value;
      return acc;
    }, {});
  const nc = '00000001';
  const cnonce = CryptoJS.lib.WordArray.random(8).toString();
  const A1 = `${username}:${params.realm}:${password}`;
  const A2 = `${method}:${url}`;
  const HA1 = CryptoJS.MD5(A1).toString();
  const HA2 = CryptoJS.MD5(A2).toString();
  const requestDigest = CryptoJS.MD5(`${HA1}:${params.nonce}:${nc}:${cnonce}:${params.qop}:${HA2}`).toString();

  const authorizationHeader =
    `Digest username="${username}", realm="${params.realm}", nonce="${params.nonce}",` +
    ` uri="${url}", qop="${params.qop}", nc="${nc}", cnonce="${cnonce}", response="${requestDigest}",` +
    ` opaque="${params.opaque}", algorithm="${params.algorithm}"`;

  const fn = HTTP_METHOD_TO_FUNCTION[method];
  const isReadRequest = ['GET', 'DELETE'].includes(method);
  const headers = {
    Authorization: authorizationHeader,
  };
  const httpRequestParams = isReadRequest ? [url, headers] : [url, body, headers];

  return await fn(...httpRequestParams);
}

const HTTP_METHOD_TO_FUNCTION: Record<HTTPMethod, (...params: any[]) => any> = {
  GET: get,
  POST: post,
  PATCH: patch,
  PUT: put,
  DELETE: del,
};

/**
 * Make a HTTP request to a specified path in the SEM AP.
 * If the first request fails with a 401 error code, the request will be re-attempted using digest auth.
 *
 * @param accountId - The account ID for the SEM device.
 * @param method - The HTTP method for the request.
 * @param path - The URL path, excluding the domain (auto-included).
 * @param body - [optional] The JSON body for write requests
 * @param headers - [optional] Custom request headers. Defaulted to sensible values if not supplied.
 */
export async function makeRequestWithAuth<T>(
  accountId: string | null,
  method: HTTPMethod,
  path: string,
  body?: any,
  headers?: Record<string, any>
): Promise<T> {
  const uri = `${BASE_IPV4_ADDRESS}${path}`;
  const fn = HTTP_METHOD_TO_FUNCTION[method];
  const isReadRequest = ['GET', 'DELETE'].includes(method);

  try {
    const params = isReadRequest ? [uri, headers] : [uri, body, headers];
    const result = await fn(...params);

    return result as T;
  } catch (e) {
    const error = e as HTTPResponse;

    if (error.status === 401) {
      if (accountId === null) {
        // If there's no provided account ID, but there was an unauthorized error from the AP, throw an appropriate
        // error
        const error = new Error('Could not authorize with SEM AP');
        error.name = 'AccountLockedSEM';
        throw error;
      } else {
        return await respondToDigestAuthChallenge(error, accountId!, method, body);
      }
    } else {
      throw e;
    }
  }
}
