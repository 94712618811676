import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../user/userSlice';

export function SiteRedirect() {
  const navigate = useNavigate();
  const { isLoggedIn, siteIDs } = useSelector(selectUser);

  useEffect(() => {
    if (isLoggedIn) {
      if (siteIDs.length === 1) {
        navigate(`/site/${siteIDs[0]}/home`, { replace: true });
      } else {
        navigate('/sites', { replace: true });
      }
    } else {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn, siteIDs, navigate]);

  return null;
}
