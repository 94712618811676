import * as Sentry from '@sentry/react';
import packageJSON from '../../../package.json';
import { IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';

/**
 * Configures the Sentry setup for this repo.
 */
export function setupSentry() {
  Sentry.init({
    release: `se-home(${packageJSON.version})@` + import.meta.env.VITE_SENTRY_RELEASE,
    dsn: 'https://308b09bbdfc14baebdead23b12a28c29@o482609.ingest.sentry.io/4504517963874304',
    // Allows event caching while offline (e.g. Wi-Fi commissioning with no mobile Internet)
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    beforeSend(event) {
      if (IS_RUNNING_CYPRESS_TESTS) return null;

      if (!event.extra) event.extra = {};
      event.extra['AppVersion'] = packageJSON.version;
      return event;
    },
    integrations: [
      new Sentry.BrowserTracing({
        beforeNavigate: (context) => {
          return {
            ...context,
            name: window.location.pathname.replace(/\d+/g, ':id').replace(/[a-f0-9]{32}/g, '<hash>'),
          };
        },
      }),
    ],
    environment: import.meta.env['MODE'],
    tracesSampleRate: 0.05,
  });
}
