import React, { useState } from 'react';
import { Box, Button, Center, Flex, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import OptionSelectAlertDrawer from '../../../common/components/OptionSelectAlertDrawer';
import Weather from '../Weather';
import { useSelector } from 'react-redux';
import { useLiveData } from '../../site/live-data/liveDataApi';
import { selectSite } from '../../site/siteSlice';
import { getWidgets, HomepageWidget } from './default-home-helpers';
import WelcomeMessage from '../WelcomeMessage';
import { useNavigate } from 'react-router-dom';
import GridOutage from './GridOutage';
import ArcButton from '../../../common/components/ArcButton';
import { GridStatus } from 'clipsal-cortex-types/src/api/api-saturn-live-data';
import { useTranslation } from 'react-i18next';

// This is the default home page for a site that has either an inverter, battery, or EV charger.
// If the site has one of these devices in addition to a Sense meter -
// - they will see this page instead of the Sense-only variant.
export default function DefaultHome() {
  const { site_id: siteId, devices } = useSelector(selectSite);
  const [isEditing, setEditing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  // Array of card configurations which serves as the source of truth for draggable cards.
  const [items, setItems] = useState<HomepageWidget[]>(getWidgets({ devices, siteId }));
  const { data: liveData } = useLiveData();
  const { t } = useTranslation();

  const isPowerOutage = liveData.grid_status === GridStatus.GRID_OUTAGE;

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 4 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 4 } })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    // No action if not dragged over another element.
    if (!over) return;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        // Update local storage whenever an item is moved/removed.
        const result = arrayMove(items, oldIndex, newIndex);

        localStorage.setItem(`homeGridItems_${siteId}`, JSON.stringify(result.map((item) => item.id)));

        return result;
      });
    }
  }

  function handleRemoveGridItem(id: number) {
    const newState = items.filter((item) => item.id !== id);
    localStorage.setItem(`homeGridItems_${siteId}`, JSON.stringify(newState.map((item) => item.id)));
    setItems(newState);
  }

  const homeWidgets = (
    <SimpleGrid gap={3} pt={3} columns={2} mb={3}>
      {items.map((item, index) => (
        <item.Component
          item={item}
          onRemoveGridItem={handleRemoveGridItem}
          index={index}
          key={item.id}
          isEditing={isEditing}
        />
      ))}
    </SimpleGrid>
  );

  const handleToggleEditState = async () => {
    if (isEditing) {
      setEditing(false);
      onClose();
      return;
    }
    onOpen();
  };

  function handleSelectDrawerOption(value: string) {
    if (value === 'EDIT') {
      setEditing(true);
      onClose();
    }
    if (value === 'ADD') {
      navigate(`../account/settings/widgets?direction=forward&backURL=/site/${siteId}/home?direction=back`);
    }
  }

  return (
    <Box data-testid="default-home-page">
      <Flex align="center" justify="space-between" pb={1}>
        <Weather />
        <Button
          data-testid="edit-homepage-tiles-btn"
          onClick={handleToggleEditState}
          variant={'ghost'}
          size={'sm'}
          colorScheme="schneiderSkyBlue"
          fontWeight={400}
        >
          {isEditing ? t('Common.done') : t('Common.edit')}
        </Button>
      </Flex>

      {!isPowerOutage ? (
        <Box mb={2}>
          <WelcomeMessage />
        </Box>
      ) : (
        <GridOutage message={t('Widgets.Grid Outage.message')}>
          <Center mt={5}>
            <ArcButton
              data-testid="grid-outage-manage-devices-btn"
              onClick={() => navigate('../devices')}
              w={'100%'}
              arcColor={'#3DCD57'}
            >
              {t('Home Screen.manage devices')}
            </ArcButton>
          </Center>
        </GridOutage>
      )}

      {/* Wrap with DnD context when in edit mode */}
      {isEditing ? (
        <DndContext
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
          autoScroll={false}
          sensors={sensors}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items}>{homeWidgets}</SortableContext>
        </DndContext>
      ) : (
        homeWidgets
      )}

      <OptionSelectAlertDrawer
        isOpen={isOpen}
        onClose={onClose}
        onSelectOption={handleSelectDrawerOption}
        options={[
          {
            value: 'EDIT',
            label: t('Home Screen.edit widgets'),
          },
          {
            value: 'ADD',
            label: t('Home Screen.add widgets'),
          },
        ]}
        title={t('Home Screen.customize homepage tiles')}
        closeText={t('Common.cancel')}
      />
    </Box>
  );
}
