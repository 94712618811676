import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Center, Divider, Flex, Grid, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { PushNotifications } from '@capacitor/push-notifications';
import { NotificationGrouping } from './notificationsApi';
import NotificationGroupingSelectMenu from './NotificationGroupingSelectMenu';
import NotificationsListChronological from './NotificationsListChronological';
import NotificationsListReadStatus from './NotificationsListReadStatus';
import NotificationsListPriorityStatus from './NotificationsListPriorityStatus';
import { ArchiveIcon } from '../../styles/custom-icons';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import SEHomeCard from '../../common/components/SEHomeCard';
import { IS_DEMO_LOGIN, IS_NATIVE } from '../../common/constants';

type NotificationsHomeState = {
  groupBy: NotificationGrouping;
};

export function NotificationsList() {
  const [state, setState] = useState<NotificationsHomeState>({
    groupBy: getInitialGrouping(),
  });
  const { groupBy } = state;
  const navigate = useNavigate();
  const { t } = useTranslation();

  function getInitialGrouping() {
    const fromLocalStorage = localStorage.getItem('notificationsGroupBy');
    if (fromLocalStorage) {
      return fromLocalStorage as NotificationGrouping;
    }

    return 'CHRONOLOGICAL';
  }

  function handleUpdateGrouping(newGrouping: NotificationGrouping) {
    localStorage.setItem('notificationsGroupBy', newGrouping);

    setState({
      groupBy: newGrouping,
    });
  }

  /* istanbul ignore next -- @preserve */
  useEffect(() => {
    // Remove all delivered notifications, to remove the red badge on the native app home screen icon
    async function removeDeliveredNotifications() {
      const permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'granted') {
        await PushNotifications.removeAllDeliveredNotifications();
      }
    }

    if (IS_NATIVE && !IS_DEMO_LOGIN) {
      removeDeliveredNotifications();
    }
  }, []);

  const selectedGroupingToComponent: Record<NotificationGrouping, React.JSX.Element> = {
    CHRONOLOGICAL: <NotificationsListChronological groupBy={groupBy} />,
    UNREAD: <NotificationsListReadStatus groupBy={groupBy} />,
    PRIORITY: <NotificationsListPriorityStatus groupBy={groupBy} />,
  };

  return (
    <SlidingAnimationPageBase
      includeTopNavHamButton
      includeSchneiderHamButton
      includeBottomNav
      title={t('Notifications.notifications')}
    >
      <Box px={3}>
        <NotificationGroupingSelectMenu onSelectGrouping={handleUpdateGrouping} selectedGrouping={groupBy} />

        <Divider mt={2} mb={4} />

        {selectedGroupingToComponent[groupBy]}

        <Divider my={4} />

        <SEHomeCard
          cursor="pointer"
          onClick={() => navigate(`../archive?direction=forward`)}
          borderTopLeftRadius="5px"
          mb={2}
          pl={0}
          as={'button'}
          w={'100%'}
          data-testid="view-archive-btn"
        >
          <Grid templateColumns={'75px 1fr'}>
            <Center>
              <ArchiveIcon w={10} h={10} />
            </Center>
            <Flex justify="space-between" align="center">
              <Text>{t('Notifications.view archive')}</Text>
              <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
            </Flex>
          </Grid>
        </SEHomeCard>
      </Box>
    </SlidingAnimationPageBase>
  );
}
