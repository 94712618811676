/**
 * Used to debounce a timeout on an `onChange` or similar event emitter.
 *
 * @param fn - The callable which is pseudo-invoked following the delay.
 * @param delay - Time, in ms, to wait until the function is invoked.
 */
export function debounceEvent<T, R>(fn: (...fnArgs: T[]) => R, delay = 300) {
  let timeoutId: NodeJS.Timeout;

  return function (...args: T[]) {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
}
