import { baseApi } from '../../app/services/baseApi';
import { useSelector } from 'react-redux';
import { selectSite } from './siteSlice';
import { SunriseSunsetData } from 'clipsal-cortex-types/src/api/api-sunrise-sunset';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';

export const sunriseSunsetApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSunriseSunset: builder.query<SunriseSunsetData, { siteId: number; date: string }>({
      query: ({ siteId, date }) => `/v1/sites/${siteId}/sunrise_sunset?date=${date}`,
      providesTags: ['SunriseSunset'],
    }),
  }),
});

export const { useGetSunriseSunsetQuery: useOriginalGetSunriseSunsetQuery } = sunriseSunsetApi;

/**
 * Provides some sensible default values for the sunrise sunset query.
 *
 * @param selectedDate - The date to fetch sunrise/sunset times for a site. Note, this is the _local_ date of the site.
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetSunriseSunsetQuery(selectedDate: Date) {
  const { site_id: siteId } = useSelector(selectSite);
  const result = useOriginalGetSunriseSunsetQuery({
    siteId,
    date: formatDate(selectedDate),
  });
  return {
    ...result,
    data: result?.data,
  };
}
